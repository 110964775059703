
class BlockGroupDelete {
    constructor(dom) {
        this.dom = dom;
        this.isSubmited = false;

        eventHandler.observe('block_group_delete', ['language_change', 'user_login'], () => {
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    async render(id_group) {
        if (!this.dom) return;
        
        if(!app.isAuth() || !id_group) {
            this.dom.empty();
            app.renderPage('home');
            return ;
        }
        var can_edit = !!id_group;
        var group = null;
        if(can_edit) {
            var res1 = await ws.request('group/canEdit', {id: id_group});
            group = res1.group;
        }
        if(!group || group.id_owner != app.user.id) {
            this.dom.empty();
            app.renderPage('home');
            return ;
        }

        var html = `
            <div class="block-form">
                <h1 class="page-title"></h1>
                <div class='block_group_delete bl'>
                    <form class="form">
                        <div class="">
                            <div class='ta-c'>
                                <img src='images/warning.svg' class='warning' alt='' />
                                <div class="box-title">${app.lang.group.confirm_delete_group}</div>
                                <div class="box-desc">${app.lang.group.confirm_delete_group_info}</div>
                            </div>
                        </div>

                        <div class="form-group dialog">
                            <button type="submit" class="tip-button tip-button-red" >${app.lang.group.delete_group}</button>
                            <a href="#home" class="tip-button tip-button-transparent" >${app.lang.common.cancel}</a>
                        </div>
                    </form>
                </div>
            </div>
        `;

        this.dom.html(html);

        this.$form = $(this.dom).find('form');
        this.$form.submit(e => {
            this.delete(id_group);
            return false;
        });
    }

    async delete(id_group) {
        await ws.send('group/delete', {id: id_group});
        
        helpers.removeArrayItems(app.data.groups, x => x.id_group == id_group);
        helpers.removeArrayItems(app.data.groups_to_add, x => x.id_group == id_group);
        helpers.removeArrayItems(app.data.groups_history, x => x.id_group == id_group);
        
        await ws.request('data/getGroups');
        app.renderPage('home');
    }
}