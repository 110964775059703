class BlockGroupAdd {
    constructor(dom) {
        this.dom = dom;
        this.isSubmited = false;

        eventHandler.observe('block_group_add', ['language_change', 'user_login'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    renderOld() {
        if (!this.dom) return;
        
        if(!app.isAuth()) {
            this.dom.empty();
            return ;
        }
        
        var fid = 'form' + Date.now();
        var group_image = `images/${config.style ? config.style + '/' : 'sportde-'}default-avatar.svg`;

        this.url_code = helpers.int2Str(app.user.id) + '-' + helpers.int2Str(Math.round(Date.now()/1000) - 1581087943);
        var join_link = (config.app_type == 'mobile'
                ? config.web_front_url
                : window.location.origin + window.location.pathname) + '#' + this.url_code + '-group';
        if(config.share_url) {
            join_link = config.share_url + 'groupid=' + this.url_code;
        }
        
        var group_leagues_html = `
            <div class="group-leagues form-group">
                <h4>${app.lang.group.group_in_leagues}</h4>
        `;
        for(var id_league of app.data.id_leagues) {
            group_leagues_html += `
                <div class='form_checkbox'>
                    <label>
                        <input checked="checked" type="checkbox" name="ids_leagues" value="${id_league}" />
                        ${app.lang.common['league_name_'+id_league]}
                    </label>
                </div>
            `;
        }
        group_leagues_html += '</div>';
        
        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.group.create_group}</h1>
                <div class='block_group_add bl bl20'>
                    <form id="${fid}" class="form-group_add form">
                        <h1 class="form-title">${app.lang.group.your_group}</h1>
                        <div class="form-info">${app.lang.group.create_group_info}</div>
        
                        <div class="ta-c">
                            <div class="form-group form-group-image form-group-avatar">
                                <input type="file" name="image" class="file-image" accept=".png, .jpg, .jpeg" />
                                <img class="${app.mobile ? 'med-img-image' : ''}" src="${group_image}" onerror="app.errorUserAvatar(this)" />
                                <div class="user-name"></div>
                                <a href='' onclick='$("#${fid} .file-image").click(); return false;'>${app.lang.common.change}</a>
                            </div>
                        </div>

                        <div class="form-group name-group form-group-border">
                            <input type="text" name="name" value="" placeholder="${app.lang.group.name}" />
                        </div>

                        
                        <div class="form-group private-group checkbox">
                            <input id="group-private" type="checkbox" name="private" value="1" />
                            <label for="group-private">${app.lang.group.private}</label>
                            <div class="valid-errors"></div>
                        </div>

                        <div class="form-group password-group form-group-border" style="display: none">
                            <input type="password" name="password" value="" placeholder="${app.lang.group.password}" />
                        </div>
                        ${group_leagues_html}

                        <h1 class="form-title">${app.lang.group.invitation}</h1>
                        <div class="form-info">${app.lang.group.invitation_info}</div>

                        <div class="form-group url_code-group success form-group-border">
                            <div class="input-text" >${join_link}</div>
                        </div>
                        
                        <div class="form-group">
                            <button type="submit" class="btn-sportde ffant" >${app.lang.group.finished}</button>
                        </div>

                        <div class="footer-line">
                            
                        </div>
                    </form>
                    
                </div>
            </div>
        `;

        this.dom.html(html);

        this.$form = $(this.dom).find('form');
        this.form = new Form(this.$form, {
            image: {type: 'avatar'},
            name: {required: true},
            private: {},
            password: {valid: {min_length: 4}, messages: {required: app.lang.validation.enterPassword}},
            url_code: {$input: this.$form.find('.url_code-group .input-text')},
            ids_leagues: {required: true, type: 'multi_checkbox'}
        });

        var $passwordGroup = this.$form.find('.password-group');
        var $privateCheckbox = this.$form.find('input[name="private"]');
        $privateCheckbox.change(e => {
            if($privateCheckbox.is(':checked')) {
                $passwordGroup.show();
                this.form.setRequired('password', true);
            }else {
                $passwordGroup.hide();
                this.form.setRequired('password', false);
            }
            this.form.clearServerInvalids();
        });
        this.$form.submit(e => {
            this.onsubmit();
            return false;
        });

        this.$form.find('.btn-login').click(e => {
            app.renderPage('login');
        });  
        
        this.dom.find('.btn_copy_link').click((e) => {
            var join_link = (config.app_type == 'mobile'
                ? config.web_front_url
                : window.location.origin + window.location.pathname) + '#' + this.url_code + '-group';
            if(config.share_url) {
                join_link = config.share_url + 'groupid=' + this.url_code;
            }
            helpers.copyToClipboard(join_link, e);

            return false;
        });

        return this;
    }
    getLeaguesHtml(ids_leagues = []) {
        
        return app.blocks.group_edit.getLeaguesHtml(ids_leagues);
    }
    
    async render(params) {
        if (!this.dom) return;
        
        if(!app.isAuth()) {
            this.dom.empty();
            return ;
        }
        
        var self = this;
        
        var group_image = `images/${config.style ? config.style + '/' : 'sportde-'}default-avatar.svg`;

        var html = `
            <div class="block-form">
            
                <h1 class="page-title">${app.lang.group.create_group}</h1>
                <h2 class="page-headline">${app.lang.group.create_group_headline}</h2>
                <div class='block_edit_group '>
                    <form class="form-edit_group form form-sm form-separators">
        
                        <div class="box part-form">
                            <div class="box-title">${app.lang.group.group_name}</div>
                            <div class="form-group form-group-border">
                                <input type="text" name="name" value="" placeholder="${app.lang.group.name_placeholder}" />
                            </div>
                        </div>
                        <div class="box part-form">
                            <div class="box-title">${app.lang.group.logo}</div>
                            <div class="form-group form-group-image form-group-avatar">
                                <input type="file" name="image" class="file-image" accept=".png, .jpg, .jpeg" />
                                <div class="logo-group-row">
                                    <span>
                                        <img src="${group_image}" onerror="app.errorUserAvatar(this)" />
                                    </span>
                                    <span>
                                        ${app.lang.group.choose_logo}
                                    </span>
                                    <span>
                                        <img src="images/green/right-second.svg" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="box part-form box-group_in_leagues">
                            <div class="box-title">${app.lang.group.group_in_leagues}</div>
                            <div class="group-leagues">
                                ${this.getLeaguesHtml()}
                            </div>
                            <div class='menu group-add_league'>
                                <span>
                                    <img src="images/plus.svg" />
                                </span>
                                <span>
                                    ${app.lang.group.add_league}
                                </span>
                                <span>
                                    <img src="images/green/right-second.svg" />
                                </span>
                            </div>
                        </div>
                        <div class="box part-form pb">
                            <div class="box-title">${app.lang.group.group_type}</div>
                            <div class="box-desc">${app.lang.group.group_type_desc}</div>
                            
                            <div class="form-group form-check">
                                <label>
                                    <input type="checkbox" name="private" value="1" />
                                    <span>${app.lang.group.private}</span>
                                </label>                                    
                            </div>
                            <div class="form-group form-check">
                                <label>
                                    <input type="checkbox" name="public" value="1" checked="checked" />
                                    <span>${app.lang.group.public}</span>
                                </label>                                    
                            </div>                            
                        </div>
                        <div class="box part-form box-password" style="display: none;">
                            <div class="box-title">${app.lang.group.password}</div>
                            <div class="form-group form-group-border password-group">
                                <img src="images/showpassword2.svg" class="show-password-icon">
                                <input type="text" name="password" value="" />
                            </div>
                        </div>
        
                        <div class="form-group line-buttons">
                            <button type="submit" class="tip-button" >${app.lang.group.save_group}</button>
                        </div>
                    </form>
                    
                </div>
            </div>
        `;

        this.dom.html(html);

        this.$form = $(this.dom).find('form');
        setTimeout(() => { this.$form.find('[name="password"]').attr('type', 'text').val(''); }, 100);
        setTimeout(() => { this.$form.find('[name="password"]').attr('type', 'password'); }, 200);
        
        this.form = new Form(this.$form, {
            image: {type: 'avatar'},
            name: {required: true},
            private: {},
            public: {},
            password: {valid: {min_length: 4}, messages: {required: app.lang.validation.enterPassword}},
            //url_code: {$input: this.$form.find('.url_code-group .input-text')},
            ids_leagues: {required: true, type: 'multi_checkbox', $formGroup: this.dom.find('.box-group_in_leagues')}
        });
        
        this.dom.find('.group-add_league').click(() => {
            dialogs.leagues(this.form.getValue('ids_leagues'), (ids_leagues) => {
                this.dom.find('.group-leagues').html(this.getLeaguesHtml(ids_leagues));
                this.form.refreshField('ids_leagues');
                if(ids_leagues.length)  this.dom.find('.box-group_in_leagues .valid-errors').html('')
            });
        });
        this.dom.on('click', '.btn-remove-league', function() {
            $(this).closest('.menu').remove();
            self.form.refreshField('ids_leagues');
        });
        this.dom.find('input:checkbox[name="private"],input:checkbox[name="public"]').change(function() {
            self.dom.find('input:checkbox[name="private"],input:checkbox[name="public"]').prop('checked', false);
            $(this).prop('checked', true);
            if($(this).attr('name') == 'private') {
                self.dom.find('.box-password').show();
            }else {
                self.dom.find('.box-password').hide();                
            }
        }).filter(':checked').trigger('change');
        this.$form.find('.show-password-icon').click(function() {
            var password_input = $(this).closest('.form-group').find('input');
            if(!password_input.hasClass('password-visible')) {
                password_input.addClass('password-visible').attr('type', 'text');
            }else {
                password_input.removeClass('password-visible').attr('type', 'password');
            }
        });
        

        this.$form.submit(e => {
            this.onsubmit(group);
            return false;
        });

    }

    async onsubmit() {
        if(this.isSubmited) {
            return ;
        }
        this.isSubmited = true;

        try {
            if(!this.form.isValid()) {
                this.isSubmited = false;
                return ;
            }
            var data = this.form.getData(true);
            
            if(!data) {
                this.isSubmited = false;
                return ;
            }
            
            this.url_code = helpers.int2Str(app.user.id) + '-' + helpers.int2Str(Math.round(Date.now()) - 1581087943000);
            data.url_code = this.url_code;
            
            var result = await ws.request('group/add', data);
            console.log(result, 'result')
            if(result.success) {
                if(result.id_league == app.user.id_league) {
                    app.data.groups.push(result.group);
                    eventHandler.trigger('set_data_groups', {}, true);
                }
                app.renderPage('group', {id: result.group.id});
            }else {
                this.form.setServerInvalids(result.messages, data);
            }
        }catch(err) {
            console.log(err);
        }

        this.isSubmited = false;
    }
    
    async renderSteps() {
        if (!this.dom) return;
        
        if(!app.isAuth()) {
            this.dom.empty();
            return ;
        }
        
        var leagues_html = '';
        for(var id_league of app.data.id_leagues) {
            leagues_html += `
                <div class='tab3'>
                    <div>
                        <div class='flag' style='background-image: url(${config.web_front_url}/images/leagues/${id_league}.svg)'>
                            
                        </div>
                        <div>
                            ${app.lang.common['league_name_'+id_league]}
                        </div>
                        <div>
                            <input ${app.id_league == parseInt(id_league) ? 'checked="checked"' : ''} type="checkbox" name="leagues" value="${id_league}" />
                        </div>
                    </div>
                </div>
            `;
        }
        
        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.group.new_group}</h1>
                <div class="create-group-steps form">
                    <div class="progress-bar">
                        <div class="progress" style="width: 0%"></div>
                    </div>
                    <div class="step step-1">
                        <div class="step-title">${app.lang.group.new_group_step_1_title}</div>
                        <div class="form-group">
                            <input type="text" maxlength="20" placeholder="${app.lang.group.new_group_name_placeholder}" />
                            <span class="input-name-left"></span>
                        </div>
                    </div>
                    <div class="step step-2">
                        <div class="step-title">${app.lang.group.new_group_step_2_title}</div>
                        <div class="form-group">
                            ${leagues_html}
                        </div>
                    </div>
                    <div class="step step-3">
                        <div class="step-title">${app.lang.group.new_group_step_3_title}</div>
                        <div class="form-group">
                            <div class="box-choose">
                                <div class="choose-title">
                                    <input type="radio" name="private" value="0" /> ${app.lang.common.yes}
                                </div>
                                <div class="choose-desc">${app.lang.group.new_group_step_3_public_desc}</div>
                            </div>
                            <div class="box-choose">
                                <div class="choose-title">
                                    <input type="radio" name="private" value="1" /> ${app.lang.common.no}
                                </div>
                                <div class="choose-desc">${app.lang.group.new_group_step_3_private_desc}</div>
                            </div>
                        </div>
                        <div class="form-group password-group" style="display: none">
                            <input type="password" name="password" placeholder="${app.lang.group.new_group_password_placeholder}" />
                        </div>
                    </div>
        
                    <div class="step step-4">
                        <div class="step-image"><img src="images/checkmark.svg" /></div>
                        <div class="step-title">${app.lang.group.new_group_step_4_title}</div>
                        <div class="step-desc">${app.lang.group.new_group_step_4_desc}</div>
                    </div>
        
                    <button type="button" class="tip-button btn-invite-friends">${app.mobile ? app.lang.group.new_group_invite_frinds : app.lang.group.copy_link}</button>
                    <button type="button" class="tip-button btn-next">${app.lang.common.next}</button>
                </div>
            </div>
        `;
        this.dom.html(html);
        
        var current_step = 0;
        var data = {};
        var showStep = (step, init = false) => {
            current_step = step;
            this.dom.find('.create-group-steps').attr('step', step);
            this.dom.find('.step').hide();
            this.dom.find('.step-'+step).show();
            
            this.dom.find('.progress').css('width', Math.round(100 / 4 * step) + '%');
            
            if(window.innerWidth >= app.breakPoint) {
                if(step < 4) {
                    app.topbar();
                    app.backCallback = () => {
                        if(step > 1 && step < 4) {
                            showStep(current_step - 1);
                        }else {
                            if(this.group) {
                                app.renderPage('group', {id: this.group.id});                            
                            }else {
                                app.renderPage('home');
                            }
                        }

                        return false;
                    };
                }else {
                    app.$topbar.empty();
                }
            }else if(!init && this.dom.isDialog()) {
                this.dom.dialog().backBtn(step > 1 && step < 4 ? () => {
                    showStep(current_step - 1);
                } : null);
            }
        };
        
        var $input_name = this.dom.find('.step-1 input:text');
        var $input_name_left = this.dom.find('.step-1 .input-name-left');
        
        $input_name.keyup(() => {
            var name = $input_name.val();
            if(name.length == 0) {
                $input_name_left.hide();
            }else {
                $input_name_left.html(20 - name.length).show();
            }
        });
        
        this.dom.find('input[name="private"]').change(() => {
            var priv = parseInt(this.dom.find('input[name="private"]:checked').val());
            if(priv) {
                this.dom.find('.password-group').show();
            }else {
                this.dom.find('.password-group').hide();
            }
        });
        
        var submit = {};
        submit.step_1 = () => {
            var name = $input_name.val();
            if(name.trim() != '') {
                data.name = name;
                showStep(2);
            }
        };
        submit.step_2 = () => {
            data.ids_leagues = [];
            this.dom.find('input:checkbox:checked[name="leagues"]').each(function() {
                data.ids_leagues.push(this.value);
            });
            if(data.ids_leagues.length > 0) {
                showStep(3);
            }
        };
        submit.step_3 = async () => {
            if(this.dom.find('input[name="private"]:checked').length == 0) {
                return ;
            }
            data.private = parseInt(this.dom.find('input[name="private"]:checked').val());
            data.password = this.dom.find('.password-group input[name="password"]').val();
            
            if(data.private) {
                if(data.password == '') {
                    flash.error(app.lang.group.password_is_required);
                    return ;
                }else if(data.password.length < 4) {
                    flash.error(app.lang.group.password_is_too_short);                    
                    return ;
                }
            }
                        
            showStep(4);
            
            data.url_code = helpers.int2Str(app.user.id) + '-' + helpers.int2Str(Math.round(Date.now()/1000) - 1581087943);
            var result = await ws.request('group/add', data);
            if(result.success) {
                if(result.id_league == app.user.id_league) {
                    app.data.groups.push(result.group);
                    eventHandler.trigger('set_data_groups', {}, true);
                }
                this.group = result.group;
                this.dom.find('.create-group-steps').addClass('create-success');
                
                if(this.dom.isDialog()) {
                    this.dom.dialog().onClosed = () => {
                        setTimeout(() => {
                            app.renderPage('group', {id: result.group.id});
                        }, 100);
                    };
                }
            }else {
                var msg = '';
                for(var fn in result.messages) {
                    if(msg) {
                        msg += '<br/>';
                    }
                    if(result.messages[fn]) {
                        for(let e of result.messages[fn]) {
                            msg += (app.lang.group[fn] || fn) + ': ' + (app.lang.validation[e] || e);
                        }
                    }
                }
                flash.error(msg);
                showStep(1);
            }
        };
        submit.step_4 = async () => {
            if(this.group) {
                app.renderPage('group', {id: this.group.id});
            }else {
                app.renderPage('home');
            }
        };
        
        showStep(1, true);
        this.dom.find('.btn-next').click(function() {
            submit['step_'+current_step]();
        });
        this.dom.find('.btn-invite-friends').click(async () => {
            if(this.group) {
                var bif = app.blocks.group_invite_friends;
                bif.group = this.group;
                if(app.mobile) {
                    bif.share(() => {
                        app.renderPage('group', {id: this.group.id});
                    });
                }else {
                    await bif.copyLink();
                    app.renderPage('group', {id: this.group.id});
                }
            }
        });
    }

}
