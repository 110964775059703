$.fn.dotsScroll = function (options) {
    options = options || {};
    window.__dotsScrollNr = window.__dotsScrollNr || 0;
    window.__dotsScrollNr++;
    var scrollNr = window.__dotsScrollNr;

    this.active = -1;
    this.dest_active = options.dest_active || 0;
    this.is_native_scroll = options.is_native_scroll || false;
    if(options.is_native_scroll && typeof options.is_native_scroll == 'function') {
        this.is_native_scroll = options.is_native_scroll();
    }
    this.dots_count = -1;
    this.prev_p = 0;
    this.prev_w = 0;
    this.last_wheel_time = 0;
    this.touchstartX = -1;
    this.scrollToElement = false;
    this.animate = false;
    this.e = false;
    this.moveto = 0;
    
    var dots_scroll;
    var has_scroll = $(this).parent().hasClass('scroll');
    var show_dots_scroll = options.show_dots_scroll || typeof options.dots == 'object';
    
    if(has_scroll) {
        if (show_dots_scroll) {
            dots_scroll = this.parent().next();
            if(dots_scroll.length == 0 || !dots_scroll.hasClass('dots_scroll')) {
                has_scroll = false;
            }
        }else {
            dots_scroll = $();
        }
    }

    if (!has_scroll) {
        if (show_dots_scroll) {
            if(this.parent().next().hasClass('dots_scroll')) {
                this.parent().next().remove();
            }
            dots_scroll = $("<div class='dots_scroll'></div>").insertAfter(this.parent());
        } else {
            dots_scroll = $();
        }
        $(this).parent().addClass('scroll');
    }

    if (typeof options.extra_class == 'string') {
        dots_scroll.addClass(options.extra_class);
    }

    var t = this;
    
    var scrollElement = this.parent();
    if(t.is_native_scroll) {
        scrollElement = this;
    }
    
    var scrollElementIsVisible = function(checkNr) {
        if(scrollElement.css('display') == 'none' || scrollElement.parents('.block_container').css('display') == 'none') {
            if(checkNr && checkNr == 1) {
                setTimeout(() => {
                    if(scrollElementIsVisible(checkNr++)) {
                        setDots();
                        scrollElement.scroll();
                    }
                }, 200);
            }
            return false;
        }
        return true;
    };

    var setDots = function () {
        if(!scrollElementIsVisible(1)) {
            dots_scroll.hide();
            return ;
        }
        dots_scroll.show().css('display', 'block !important');
        var p = t.parent().outerWidth()
        var w = t.outerWidth();
        if(t.is_native_scroll) {
            w = t[0].scrollHeight;
        }
        if (p != t.prev_p || w != t.prev_w) {
            var count = 0;
            if (typeof options.dots == 'object') {
                count = options.dots.length;
                dots_scroll.html(options.dots.join(''));
                $(dots_scroll).show().css('display', 'block !important');
                if (Math.ceil((w - p) / options.inside_element_width) + 1 < 2) {
                    $(dots_scroll).addClass('full_content_visible');
                }
                if (!t.is(':visible') || w < 10) {
                    $(dots_scroll).hide();
                }
                // desktop bets arrow scroll
                if(!app.mobile && options.bets_scroll) {
                    var $deskScrollLeft = $(`<img src="images/${config.style ? config.style + '/' : 'sportde-'}arrow-tip.svg">`).addClass('scroll-left').appendTo(dots_scroll);
                    var $deskScrollRight = $(`<img src="images/${config.style ? config.style + '/' : 'sportde-'}arrow-tip.svg">`).addClass('scroll-right').appendTo(dots_scroll);

                    $deskScrollLeft.addClass('scroll-invisible');

                    $deskScrollLeft.click(e => {
                        if (t.dest_active > 1) {
                            $deskScrollRight.removeClass('scroll-invisible');
                            t.dest_active--;

                            if (t.dest_active == 1) {
                                $deskScrollLeft.addClass('scroll-invisible');
                            }
    
                            if(t.is_native_scroll) {
                                $(t).stop().animate({
                                    scrollTop: (t.dest_active - 1) * (t[0].scrollHeight - t.outerHeight()) / (t.dots_count - 1)
                                }, 500);
                            }else {
                                var w = (parseInt(($(t).width() - $(window).width() ) / t.dots_count ) + 40);
                                if(options.bets_scroll && $(window).width() < 400) {
                                    w = $(window).width() - 20 + Math.ceil(20 / t.dots_count);
                                }
                                $(t).parent().stop().animate({
                                    scrollLeft: (t.dest_active - 1) * w
                                }, 500);
                            }
                        }
                    });

                    $deskScrollRight.click(e => {
                        if (t.dest_active < t.dots_count) {
                            $deskScrollLeft.removeClass('scroll-invisible');
                            t.dest_active++;

                            if (t.dest_active == t.dots_count) {
                                $deskScrollRight.addClass('scroll-invisible');
                            }

                            if(t.is_native_scroll) {
                                $(t).stop().animate({
                                    scrollTop: (t.dest_active - 1) * (t[0].scrollHeight - t.outerHeight()) / (t.dots_count - 1)
                                }, 500);
                            }else {
                                $(t).parent().stop().animate({
                                    scrollLeft: (t.dest_active) * (parseInt(($(t).width() - $(window).width() ) / t.dots_count ) + 40)
                                }, 500);
                            }
                        }
                    });
                }

            } else {
                if (options.count_elements) {
                    count = $(t).find('> *').length;
                } else {
                    count = Math.floor((w - p) / options.inside_element_width) + 1;
                    var max_count = Math.floor(($(window).width() - 30) / 11);
                    if(count > max_count) {
                        count = max_count;
                    }
                }

                if (count < 2) {
                    count = 0;
                }

                if (count) {
                    $(dots_scroll).show().css('display', 'block !important');
                } else {
                    $(dots_scroll).hide();
                }

                dots_scroll.html(options.dot.repeat(count));
            }

            t.prev_p = p;
            t.prev_w = w;
            t.dots_count = count;

            $(dots_scroll).find('div').on('click', function (event) {
                event.preventDefault();
                var i = $(this).index();

                if(t.is_native_scroll) {
                    var eh = (t[0].scrollHeight - t.outerHeight()) / (t.dots_count - 1);
                    $(t).stop().animate({
                        scrollTop: Math.ceil(i * eh)
                    }, 500);

                    return ;
                }

                var scrollLeft = $(t).parents('.bl').find('.scroll-left');
                var scrollRight = $(t).parents('.bl').find('.scroll-right');

                var deskScrollLeft = $(t).parents('#main').find('.dots_scroll.dots_scroll_bets .scroll-left');
                var deskScrollRight = $(t).parents('#main').find('.dots_scroll.dots_scroll_bets .scroll-right');

                t.dest_active = i;

                if(t.dest_active > 0) {
                    scrollLeft.show();
                    deskScrollLeft.removeClass('scroll-invisible');
                } else {
                    scrollLeft.hide();
                    deskScrollLeft.addClass('scroll-invisible');
                }

                if(t.dest_active == (t.dots_count - 1)) {
                    scrollRight.hide();
                    deskScrollRight.addClass('scroll-invisible');
                } else {
                    scrollRight.show();
                    deskScrollRight.removeClass('scroll-invisible')
                }

                if(options.bets_scroll) {
                    var width = parseInt(($(t).width() - $(window).width() ) / t.dots_count ) + 40;
                    if($(window).width() < 400) {
                        width = $(window).width() - 20 + Math.ceil(20 / t.dots_count);
                    }
                }else {
                    var width = options.selector ? $(t).find(options.selector).width() : options.inside_element_width;
                }

                $(t).parent().stop().animate({
                    scrollLeft: i * width
                }, 500);
            })
        } else {
        }
    };
    
    var is_scrolling = false;
    var is_touch_start = false;
    var is_touch_end = false;
    var stop_scroll = false;
    var scrollTimeOutId = 0;
    var endScrollCallback = () => {
        if(is_scrolling && is_touch_start && options.bets_scroll && stop_scroll && is_touch_end) {
            if ($(window).width() < 400) {
                var width = $(window).width() - 20 + Math.ceil(20 / t.dots_count);
            }else {
                var width = parseInt(($(t).width() - $(window).width() ) / t.dots_count ) + 40;
            }
            /*$(t).parent().stop().animate({
                scrollLeft: (t.active - 1) * width
            }, 200);*/
            
            if(options.onBlockView) {
                
            }
        }
        
        if(is_scrolling && is_touch_start && stop_scroll && is_touch_end) {
            is_touch_start = false;
            is_touch_end = false;
            is_scrolling = false;
            stop_scroll = false;
        }
    };
    
    scrollElement.scroll(function (event) {
        if(!scrollElementIsVisible()) {
            return ;
        }
        //var o = Math.abs(t.position().left);
        if(t.parent().length == 0) {
            return ;
        }
        var o = t.parent().get(0).scrollLeft;
        if(t.is_native_scroll) {
            var eh = (t[0].scrollHeight - t.outerHeight()) / t.dots_count;
            if (eh) {
                var st = t.scrollTop();
                var active = Math.floor(st / eh) + 1;
                if (active > t.dots_count) {
                    active = t.dots_count;
                }            
                t.active = active;
                dots_scroll.find('.' + options.active_dot_class).removeClass(options.active_dot_class);    
                dots_scroll.find('.dot:nth-child('+active+')').addClass(options.active_dot_class);               
                
                dots_scroll.scrollLeft(o / 50);
                
                if(!$(t).is(':animated')) {
                    t.dest_active = active;

                    var deskScrollLeft = $(t).parents('#main').find('.dots_scroll.dots_scroll_bets .scroll-left');
                    var deskScrollRight = $(t).parents('#main').find('.dots_scroll.dots_scroll_bets .scroll-right');
                    
                    if(active == 1) {
                        deskScrollRight.removeClass('scroll-invisible');
                        deskScrollLeft.addClass('scroll-invisible');
                    }else if(active == t.dots_count) {
                        deskScrollLeft.removeClass('scroll-invisible');
                        deskScrollRight.addClass('scroll-invisible');
                    }else {
                        deskScrollLeft.removeClass('scroll-invisible');
                        deskScrollRight.removeClass('scroll-invisible');
                    }
                }
            }
            return ;
        }
        if(options.bets_scroll) {
            var width = parseInt(($(t).width() - $(window).width() ) / t.dots_count ) + 40;
        }else {
            var width = options.selector ? $(t).find(options.selector).width() : options.inside_element_width;
        }

        var active;
        if (!width) {
            active = 0;
        } else {
            if(options.bets_scroll) {
                active = Math.round(o / (width)) + 1;
            }else {
                active = Math.floor(o / (width)) + 1;
            }
        }
        
        if(t.is_native_scroll) {
            if (o + t.parent().width() >= t[0].scrollHeight - 1) {
                active++;
            }
        }else if (o + t.parent().width() >= t.width() - 1) {
            active++;
        }
        
        if (active > t.dots_count) {
            active = t.dots_count;
        }
        
        t.active = active;
        
        if (t.touchstart > o) {
            active--;
        }
        
        dots_scroll.find('.' + options.active_dot_class).removeClass(options.active_dot_class)
        if (active < 1) {
            active = 1;
        }
        
        dots_scroll.find('.dot:nth-child('+active+')').addClass(options.active_dot_class);
        is_scrolling = true;
        stop_scroll = false;
        
        dots_scroll.scrollLeft(o / 50);
        
        clearTimeout(scrollTimeOutId);
        scrollTimeOutId = setTimeout(() => {
            stop_scroll = true;
            endScrollCallback();
        }, 100);
    });

    this.parent().bind('touchstart.scroll'+scrollNr, function (e) {
        is_touch_start = true;
    });
    this.parent().bind('touchend.scroll'+scrollNr, function (e) {
        is_touch_end = true;
        endScrollCallback();
    });
    
    this.parent().bind('wheel.scroll'+scrollNr, function (e) {
        if(t.is_native_scroll) {
            return ;
        }
        if ($(t).parent().width() < $(t).width() && Date.now() - t.last_wheel_time > 300) {
            t.last_wheel_time = Date.now();
            e.stopPropagation();
            if (!t.dest_active) {
                var o = Math.abs(t.position().left);
                var active = Math.floor(o / options.inside_element_width) + 2;
                t.dest_active = active;
            }

            var deskScrollLeft = $(t).parents('#main').find('.dots_scroll.dots_scroll_bets .scroll-left');
            var deskScrollRight = $(t).parents('#main').find('.dots_scroll.dots_scroll_bets .scroll-right');

            if (e.originalEvent.deltaY > 0) {
                if (t.dest_active == t.dots_count) {
                    deskScrollLeft.removeClass('scroll-invisible');
                    deskScrollRight.addClass('scroll-invisible');
                    return false;
                }
                t.dest_active++;
            } else {
                if (t.dest_active == 1) {
                    deskScrollLeft.addClass('scroll-invisible');
                    deskScrollRight.removeClass('scroll-invisible');
                    return false;
                }
                t.dest_active--;
            }

            if(options.bets_scroll) {
                if ($(window).width() < 400) {
                    var width = $(window).width() - 20 + Math.ceil(20 / t.dots_count);
                }else {
                    var width = parseInt(($(t).width() - $(window).width() ) / t.dots_count ) + 40;
                }
            }else {
                var width = options.selector ? $(t).find(options.selector).width() : options.inside_element_width;
            }

            $(t).parent().stop().animate({
                scrollLeft: (t.dest_active - 1) * width
            }, 500);
            return false;
        }
    });

    var setHiddenNavigation = () => {
        if (options.hasOwnProperty('hidden_navigation') && options.hidden_navigation) {
            let $hn = $('<div/>').addClass('hidden_navigation').insertAfter(this.parent());
            let $hnLeft = $('<div/>').addClass('nav_left').appendTo($hn);
            let $hnRight = $('<div/>').addClass('nav_right').appendTo($hn);
            let $scrollLeft = $('<span><img src="images/scroll_left.svg"></span>').addClass('scroll-left').appendTo($hnLeft);
            let $scrollRight = $('<span><img src="images/scroll_right.svg"></span>').addClass('scroll-right').appendTo($hnRight);

            $scrollLeft.hide();

            $hnLeft.click(e => {

                $scrollRight.show();

                if (!t.dest_active) {
                    t.dest_active = 1;
                }
                if (t.dest_active == 2) {
                    $scrollLeft.hide();
                }
                if (t.dest_active == 1) {
                    //t.dest_active = t.dots_count + 1;
                    return false;
                }
                t.dest_active--;

                $(t).parent().stop().animate({
                    scrollLeft: (t.dest_active - 1) * (options.selector ? $(t).find(options.selector).width() : options.inside_element_width) 
                }, 500);

                return false;
            });

            $hnRight.click(e => {

                $scrollLeft.show();

                if (!t.dest_active) {
                    t.dest_active = 1;
                }
                if (t.dest_active == (t.dots_count - 1)) {
                    $scrollRight.hide();
                }
                if (t.dest_active == t.dots_count) {
                    //t.dest_active = 0;
                    return false;
                }
                t.dest_active++;

                $(t).parent().stop().animate({
                    scrollLeft: (t.dest_active - 1) * (options.selector ? $(t).find(options.selector).width() : options.inside_element_width)
                }, 500);

                return false;
            });
        }
    };

    $(document).ready(function () {
        if(options.is_native_scroll && typeof options.is_native_scroll == 'function') {
            t.is_native_scroll = options.is_native_scroll();
        }
        setDots();
        setHiddenNavigation();
        if(t.is_native_scroll) {
            t.scroll();
        }else {
            t.parent().scroll();
        }
        $(window, t).bind("resize.scroll"+scrollNr, function () {
            if(options.is_native_scroll && typeof options.is_native_scroll == 'function') {
                t.is_native_scroll = options.is_native_scroll();
            }
            if (typeof options.dest_active == 'undefined') {
                t.dest_active = 0;
            }
            setDots();
            if(t.is_native_scroll) {
                t.scroll();
            }else {
                t.parent().scroll();
            }
        });
    
        var $parent = t.parent();
        
        var removed = false;
        var removedTimeoutId;
        $parent.bind("DOMNodeRemoved.scroll"+scrollNr, function () {
            if(removed) {
                return ;
            }
            var removedI = 0;
            var func = () => {
                clearTimeout(removedTimeoutId);
                removedTimeoutId = setTimeout(function() {
                    if(removed) {
                        return ;
                    }
                    if(!t || t.length == 0 || t.parent().length == 0) {
                        $parent.unbind(".scroll"+scrollNr);
                        $parent.removeClass('scroll');
                        dots_scroll.remove();
                        t.remove();
                        removed = true;
                    }else {
                        removedI++;
                        if(removedI < 10) {
                            func();
                        }
                    }
                }, 10 * removedI);
            };
            func();
        });
    });
    
    return {
        clickRight: () => {
            $(t).parents('#main').find('.dots_scroll.dots_scroll_bets .scroll-right').trigger('click');
        },
        clickLeft: () => {
            $(t).parents('#main').find('.dots_scroll.dots_scroll_bets .scroll-left').trigger('click');
        }
    };
}

$.fn.hScroll = function (options = {})
{
    function scroll(obj, e)
    {
        var evt = e.originalEvent;
        var direction = evt.detail ? evt.detail * (-120) : -e.originalEvent.deltaY;
        var move = options.move ? options.move : 55;

        if (direction > 0)
        {
            direction = $(obj).scrollLeft() - move;
        } else
        {
            direction = $(obj).scrollLeft() + move;
        }

        $(obj).stop().animate({
            scrollLeft: direction
        });

        e.preventDefault();
    }

    $(this).bind('DOMMouseScroll mousewheel', function (e)
    {
        e.stopPropagation();
        scroll(this, e);
    });
}

$.fn.hScroll2 = function (options)
{
    options = options || {};
    function scroll(obj, e)
    {
        var wsp = options.wsp || 2;
        var evt = e.originalEvent;
        var move = $(obj).scrollLeft();
        if(evt.deltaY > 0) {
            move += 150;
        }else {
            move -= 150;
        }

        $(obj).stop(true, false).animate({
            scrollLeft: move + 'px'
        }, 300, 'linear');
    }

    $(this).bind('wheel', function (e)
    {
        e.stopPropagation();
        e.preventDefault();
        scroll(this, e);
    });
}


/** Native scroll */
var nativeScrollCenter = (element) => {
    var $nodes;
    if(element) {
        $nodes = $(element).filter('.native-scroll');
        if($nodes.length == 0) {
            $(element).parents('.native-scroll:visible');
        }
    }else {
        $nodes = $('.native-scroll:visible');
    }
    $nodes.each(function() {
        if(!this.children || !this.children[0]) {
            return ;
        }
        this.children[0].style.transform = '';
        var pw = this.clientWidth;
        var cw = this.children[0].scrollHeight;
        if(cw > pw) {
            return ;
        }
        var pt = parseInt(window.getComputedStyle(this.children[0]).paddingTop);

        var ml = 0;
        if(cw < pw) {
            ml = Math.round((pw - cw) / 2);
        }
        
        var style = window.getComputedStyle(this.children[0]);
        var tab = style.transform.split(',');
        var tl = parseInt(tab[4]);
        
        if($(this.children[0]).width() < 1030) {
            this.children[0].style.transform = '';
        } else if(tab.length == 6 && ml > 0 && !isNaN(tl)) {
            ml += pt;
            tab[4] = tl + ml;
            this.children[0].style.transform = tab.join(',');
        } 
    });
};
$(window).resize(() => {
    nativeScrollCenter();
});
$(function() {
    nativeScrollCenter();
    eventHandler.observe('native_scroll', ['show', 'hide', 'addClass', 'removeClass'], (params) => {
        nativeScrollCenter(params.element);
    });
});
