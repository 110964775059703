
class BlockFrontpage {
    
    static render() {
        var btn_google_play = '';
        if(config.app_type == 'web') {
            btn_google_play = `
                <a href='https://play.google.com/store/apps/details?id=com.worldtipsterleague.android' target='_blank' class='btn-google-play'>
                    <img src="images/google-play-badge.svg" />
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.worldtipsterleague.android' target='_blank' class='btn-app-store'>
                    <img src="images/app-store-badge.svg" />
                </a>
            `;
        }
        var prizes = '';
        /*
        prizes = `
            <div class='bg'>
                <div class='row prizes'>
                    <div>
                        <div class='text-section'><span class='text-header-section'>${app.lang.frontpage.section4}</span></div>
                        <img src='images/prizes.svg' alt='' />
                    </div>  
                </div>
            </div>
        `;
        */
        
        $('#frontpage').html(`
    <div class='content-wrapper'>
        <div class='content'>
            <div class='buttons'>
                <a href='#login' class='btn-white-short fs-14'>${app.lang.frontpage.login}</a>
                <a href='#register' class='btn-sportde-short fs-14'>${app.lang.frontpage.register}</a>
            </div>
            <div class="mobile-store-buttons">asd
                ${btn_google_play}
            </div>
            <div class='logo'></div>
            <div class='group-overlay'>
                <div class='group'>
                    <h2>${app.lang.frontpage.group_header}</h2>
                    <div class='group_image'></div>
                    <div class='title'><b></b></div>
                    <div class='text'>${app.lang.frontpage.group_message}</div>
                    <div class='buttons'>
                        <!-- <a href='#register' class='btn-sportde-short>${app.lang.frontpage.register}</a> -->
                        <a href='#login' class='btn-group-lets-login '>${app.lang.frontpage.login}</a>
                    </div>
                </div>
            </div>
            <h1>${app.lang.frontpage.header}</h1>
            <a href='#register' class='btn-lets-login frontpage-register-button'>${app.lang.frontpage.create_account}</a>
            <div class="fronpage-content-footer">
                  <div class="ftext">${app.lang.frontpage.presented_by}</div>                                                                                                                                              
                  <div class="flogo"><img src="images/logo-tiptastic-white.svg" /></div>                                                                                                                                              
            </div>
        </div>
    </div>                      
<div class='rows'>
    ${prizes}
    <div class='bg ball'>
        <div class='row'>
            <span class='text-header-section'>${app.lang.frontpage.section5}</span>

            <div class='step-section'>
                <div class='step step-1'>
                    <div class='step-number'><span class='digit'>1</span></div>
                    <span class='step-name'>${app.lang.frontpage.step1}</span>
                </div>

                <div class='step step-2'>
                    <div class='step-number'><span class='digit'>2</span></div>
                    <span class='step-name'>${app.lang.frontpage.step2}</span>
                </div>

                <div class='step step-3'>
                    <div class='step-number'><span class='digit'>3</span></div>
                    <span class='step-name'>${app.lang.frontpage.step3}</span>
                </div>
            </div>
        </div>
    </div>
    <div class='bg'>
        <div class='row'>                                                                                                                                        
            <div><img src='images/sportde-risk.png' alt='' /></div>
            <div class='hd'></div>                                                                                                                                        
            <div class='text'>
                <div><span class='text-header-section'>${app.lang.frontpage.section1}</span></div>
            </div>
            <div class='hd'></div>
        </div>
    </div>
    <div class='bg winner'>
        <div class='row'>            
            <div class='mobile'><img src='images/sportde-winner.png' alt='' /></div>                                                                                                                            
            <div class='hd'></div>
            <div class='text'>
                <div>
                    <span class='text-header-section'>${app.lang.frontpage.section2}</span>
                    <br />
                    <br />
                    <a href='#register' class='btn-sportde-big winner ffant'>${app.lang.frontpage.register_now}</a>
                </div>
            </div>
            <div class='hd'></div>
            <div class='nomobile'><img src='images/sportde-winner.png' alt='' /></div>
        </div>
    </div>
    <div class='bg'>
        <div class='row'>                                                                                                                                        
            <div><img src='images/friends.svg' alt='' /></div>
            <div class='hd'></div>
            <div class='text'>
                <div>
                    <span class='text-header-section'>${app.lang.frontpage.section3}</span>
                    <br />
                    <br />
                    <a href='#login' class='btn-sportde-big ffant'>${app.lang.frontpage.create_group}</a>
                </div>
            </div>
            <div class='hd'></div>
        </div>
    </div>
                                                                                                                                        
    <div class='bg'>   
        <div class='footer'>
            <img src='images/sport-de-logo-svg.svg' alt='' /> 
            <br />
            <span><a href='${config.external_url.impressum}' target="_blank">${app.lang.frontpage.impressum}</a> <a href='#' onclick="window._sp_.loadPrivacyManagerModal(152351); return false;">${app.lang.frontpage.privacy_center}</a>  <a href='${config.external_url.datenschutz}' target="_blank">${app.lang.frontpage.privacy}</a></span> 
            <!-- <br /> -->
            <div class='break'></div>
            ${app.lang.frontpage.copyrights}                                                                                                                             
        </div>
    </div>
</div>
        `);
    }
    
    static render_fussballdaten() {
        this.render_green({
            lotties: ['fd-tippen.json', 'tipptastic-add-points.json', 'home3.json', 'fd-Friends-tippen.json']
        });
    }
    
    static render_mybet() {
        this.render_green({
            lotties: ['tippen-mybet.json', 'tipptastic-add-points-mybet.json', 'home3.json', 'Friends-tippen-mybet.json']
        });
    }
    
    static render_green(options = {}) {
        var btn_google_play = '';
        if(config.app_type == 'web') {
            btn_google_play = `
                <a href='https://play.google.com/store/apps/details?id=com.worldtipsterleague.android' target='_blank' class='btn-google-play'>
                    <img src="images/google-play-badge.svg" />
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.worldtipsterleague.android' target='_blank' class='btn-app-store'>
                    <img src="images/app-store-badge.svg" />
                </a>
            `;
        }
        var prizes = '';

        var defaults = {
            lotties: ['home1.json', 'home2.json', 'home3.json', 'home4.json']
        };
        var s = Object.assign({}, defaults, options);
        
        let app_buttons = ``;
        if (config.style == 'green') {
            app_buttons = `<a class='tip-button app-store-button' href='https://apps.apple.com/am/app/tipptastic/id1555218250' target='_blank' ><img src='images/green/app_store.svg' alt='' /> ${app.lang.frontpage.app_store}</a>
                <a class='tip-button play-store-button' href='https://play.google.com/store/apps/details?id=com.tipptastic.android' target='_blank' ><img src='images/green/play_store.svg' alt='' /> ${app.lang.frontpage.play_store}</a>`;
        }
        
        if(config.app_type == 'web') {
            $('#frontpage').html(`
                <div class='group-overlay'>
                    <div class='group'>
                        <h2>${app.lang.frontpage.group_header}</h2>
                        <div class='group_image'></div>
                        <div class='title'><b></b></div>
                        <div class='text'>${app.lang.frontpage.group_message}</div>
                        <div class='buttons'>
                            <br />
                            <!-- <a href='#register' class='btn-sportde-short>${app.lang.frontpage.register}</a> -->
                            <a href='#login' class='tip-button tip-button-yellow'>${app.lang.frontpage.login}</a>
                        </div>
                    </div>
                </div>
                <div class='rows'>
                    ${prizes}
                    <div class='bg s10'>
                        ${app.blocks.header.getHtml()}
                        <div class='row'>   
                            <div class='col2 reverse'>
                                <div class='tro_container'>
                                    <img class='tro ' src='images/tro.svg' alt='' />
                                </div>
                                <div class='header_padding'>
                                    ${app.lang.frontpage.header2}
                                    <br /><br /><br />
                                    <div class='flex'>
                                        ${app_buttons}
                                    </div>
                                </div>
                            </div>
                        </div>        
                    </div>

                    <div class='mt s20 info1'>
                        <div class='row'>                                                                                                                                        
                            ${app.lang.frontpage.info1}
                            <br /><br /><br />
                            <a class='tip-button tip-button-yellow big' href='#register'>${app.lang.frontpage.info1_button}</a>
                        </div>
                    </div>

                    <div class='bg mt s30 info2'>
                        <div class='row'>                        
                            <div class='col2 reverse'>                
                                <div class='pr'>
                                    ${app.lang.frontpage.info2}
                                </div>
                                <div class='ta-c'><lottie-player src="animations/${config.style}/${s.lotties[0]}"  background="transparent"  speed="1" style="max-width: 500px; max-height: 260px; width: 100%;" loop autoplay></lottie-player></div>
                            </div>
                        </div>
                    </div>

                    <div class='s40 info3'>
                        <div class='row'>   
                            <div class='col2'>
                                <div class='ta-c' style='padding-top: 30px;'><lottie-player src="animations/${config.style}/${s.lotties[1]}"  background="transparent"  speed="1" style="margin: 0 auto; margin-bottom: 60px; max-width: 554px; max-height: 530px; width: 100%;" loop autoplay></lottie-player></div>
                                <div class='pl'>
                                    ${app.lang.frontpage.info3}
                                </div>
                            </div>
                        </div>        
                    </div>

                    <div class='bg mt s50 info4'>
                        <div class='row'>                        
                            <div class='col2 reverse'>                
                                <div class='pr'>
                                    ${app.lang.frontpage.info4}
                                </div>
                                <div><lottie-player src="animations/${config.style}/${s.lotties[2]}"  background="transparent"  speed="1" style="margin: 0 auto; max-width: 500px; max-height: 345px; width: 100%;" loop autoplay></lottie-player></div>
                            </div>
                        </div>
                    </div>

                    <div class='s60 '>
                        <div class='row mb info5'>   
                            <div class='col2'>
                                <div class='ta-c'><lottie-player src="animations/${config.style}/${s.lotties[3]}"  background="transparent"  speed="1" style="max-width: 450px; width: 100%;" loop autoplay></lottie-player></div>
                                <div class='pl'>
                                    <br /><br />
                                    ${app.lang.frontpage.info5}
                                    <br /><br /><br />
                                    <div class='smaller'>
                                        <a class='tip-button tip-button-yellow big' href='#register'>${app.lang.frontpage.info5_button}</a>
                                    </a>
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>

                <div class='footer_page rows'>
                    ${app.blocks.footer.getHtml()}
                </div>
            `);
        } else {
            // For mobile only
            $('#frontpage').addClass('mobile').html(`
                <div class='bg-mobile'>
                    <div class='logo'>
                        <img src="images/${config.style}/logo_frontpage.svg" />
                        <div>
                            ${config.style == 'green' ? app.lang.frontpage.mobile_info_text : ''}            
                        </div>
                    </div>
                    <div class='buttons'>
                        <a class='tip-button' href='#register'>${app.lang.frontpage.register}</a>
                        <a class='tip-button tip-button-transparent tip-button-border' href='#login'>${app.lang.frontpage.login}</a>
                    </div>
                </div>
            `);
        }
        
        $('#frontpage a.menu').click(function() {
            BlockFrontpage.menu();
        
            return false;
        });
        
    }
    
    static menu() {
        
        let buttons = `
            <a class='tip-button' href='#register'>${app.lang.frontpage.register}</a>
            <a class='tip-button tip-button-transparent tip-button-border' href='#login'>${app.lang.frontpage.login}</a>
        `;
        
        let menu = `
            <div class=''>
                <!--<a class='menu' href='#frontpage'>
                    <span>
                        <img src='images/green/home2.svg' alt='' /> 
                    </span>         
                    <span>
                        ${app.lang.common.home2}
                    </span>
                    <span>
                        <img src='images/green/right.svg' alt='' /> 
                    </span>
                </a>-->

                <a class='menu' href='#how_it_works'>
                    <span>
                        <img src='images/green/how.svg' alt='' /> 
                    </span>         
                    <span>
                        ${app.lang.common.how_it_works}
                    </span>
                    <span>
                        <img src='images/green/right.svg' alt='' /> 
                    </span>
                </a>
            </div>
        `;



        var dialog = new Dialog();
        dialog.html(`<h1 class="page-title">${app.lang.common.menu_title}</h1> ${menu} <br /> ${buttons}`)
            .show();
    }
}