class BlockGroupSearch {
    constructor(dom) {
        this.dom = dom;
        this.rendered = false;

        eventHandler.observe('block_group', ['language_change', 'user_login', 'set_data_groups'], () => {
            this.refresh();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        
        return this;
    }

    refresh() {
        this.rendered = false;
        if (this.dom) {
            this.dom.empty();
        }
        if(app.current_page == 'group_search') {
            this.render();
        }
        return this;
    }
    
    render() {
        if (!this.dom) {
            return this;
        }
        
        if(this.rendered) {
            return this;
        }
        var self = this;
        this.loadGroups();
        
        var results = '';
        for(let group of this.groups) {
            results += this.renderGroup(group);
        }
        
        var html = `
            <div class="block-group_search">
                <h1 class="page-title">${app.lang.common.group_search}</h1>
                <form class="form-group_search form form-sm">
                    <div class="form-group login-group form-group-border">
                        <input type="text" value="" placeholder="${app.lang.common.search}" />
                    </div>
                </form>
                <div class="group_search-section">
                    <div class="group_search-header">
                        <div class="group_search-title">${app.lang.common.group_search_title}</div>
                        <div class="group_search-desc">${app.lang.common.group_search_desc}</div>
                    </div>
                    <div class="group_search-results">
                        ${results}
                    </div>
                    <div style="display: none" class='no-groups-found'>${app.lang.common.no_groups_found}</div>
                    
                </div>
            </div>
        `;
        
        /* 
         <div class="line-buttons">
                        <button type="submit" class="tip-button btn-create_group" >${app.lang.common.create_group}</button>
                    </div>
         */
        
        this.dom.html(html);
        this.items = [];
        this.$noGroupsFound = this.dom.find('.no-groups-found');
        
        this.dom.find('.group_search-results .item').each(function() {
            self.items.push({
                node: this,
                name: this.querySelector('.name').innerText.trim().toLowerCase()
            });
        });
        
        this.dom.find('.group_search-results .item[data-urlcode]').click(function() {
            self.onClickGroup(this);
        });
        
        this.dom.find('.form-group_search input').keyup(function() {
            self.search(this.value);
            return false;
        });
        
        this.dom.find('.btn-create_group').click(function() {
            app.renderPage('create_group');
        });
        
        return this;
    }
    
    onClickGroup(node) {
        var exists_ids = app.data.groups.map(x => x.id);
        var groupId = $(node).data('groupid');
        if(exists_ids.indexOf(groupId) != -1) {
            app.renderPage('group', {id: groupId});
        }else {
            var to_add = app.data.groups_to_add.find(x => x.id == groupId);
            if(typeof to_add.options != 'object') {
                try {
                    to_add.options = JSON.parse(to_add.options);
                }catch(err) {
                    to_add.options = {};
                }
            }

            if(to_add && helpers.isSponsoredChallengeGroup(to_add)) {
                app.blocks.groups.sponsoredChallenge(to_add);
            }else if(to_add && to_add.options.type == 1) {
                app.blocks.groups.promoBox(to_add);
            }else if(to_add) {
                app.renderPage('join_to_group', {c: $(node).data('urlcode')});
            }
        }
        return false;
    }
    
    search(text) {
        text = text.trim().toLowerCase();
        if(text) {
            var found = 0;
            for(let item of this.items) {
                if(item.name.includes(text)) {
                    item.node.style.display = null;                    
                    found++;
                }else {
                    item.node.style.display = 'none';
                }
            }
            if(found > 0) {
                this.$noGroupsFound.hide();                
            }else {
                this.$noGroupsFound.show();
            }
        }else {
            this.$noGroupsFound.hide();
            for(let item of this.items) {
                item.node.style.display = null;
            }
        }
    }


    renderGroup(group) {
        var group_image = '';
        if (!group.image_thumb && group.options && group.options.image) {
            group_image = group.options.image ? config.web_front_url+`/usergroup/${group.options.image}` : `images/${config.style ? config.style + '/' : 'sportde-'}default-avatar.svg`;
            group_image = `<img src="${group_image}" alt=""><br />`;
        }
        var html = `
            <div class="item" data-groupid='${group.id}' data-urlcode='${group.url_code}' >
                <div class="item-logo">
                    <span>${group_image}</span>
                </div>
                <div class="item-content">
                    <div class="name">${group.name}</div>
                    <div class="user-count">${group.users_count} ${app.lang.common.users}</div>
                </div>
                <div class="right">
                    <img src='images/green/right.svg' alt='' /> 
                </div>
            </div>
        `;
        return html;
    }

    loadGroups() {
        //this.exists_ids = app.data.groups.map(x => x.id);
        this.groups = app.data.groups_to_add.concat(app.data.groups.filter(x => x.id != 'overall_ranking' && x.division == 0));
        this.groups = helpers.uniqBy(this.groups, x => x.id);
        this.groups.sort((a,b) => {
            if((!!a.image_thumb) != (!!b.image_thumb)) {
                return !a.image_thumb ? 1 : -1;
            }
            if(a.users_count == b.users_count) {
                return a.id < b.id ? 1 : -1;
            }
            return a.users_count < b.users_count ? 1 : -1;
        });
    }

    createOrSearchDialog(slide = true) {
        var html = `
            <div class="block-group_create_or_search standard-dialog">
                <div class="dialog-image">
                    <img src="images/award.svg" />
                </div>
                <div class="dialog-title">${app.lang.group.create_or_search_title}</div>
                <div class="dialog-desc">${app.lang.group.create_or_search_desc}</div>
                <div class="dialog-buttons">
                    <button type="button" class="tip-button btn-create">${app.lang.group.create_or_search_btn_create}</button>
                    <button type="button" class="tip-button tip-button-transparent btn-search">${app.lang.group.create_or_search_btn_search}</button>
                </div>
            </div>
        `;
        var $div = $(html);
        $div.dialog({
            slide: slide && !app.is_back_proccess,
            onClose: (closeOptions = {}) => {
                if(closeOptions.from && closeOptions.from != 'cloaseAll') {
                    app.setHashWithoutChange('home');
                }
            }
        });
        app.setHashWithoutChange('group_create_or_search');
        
        $div.find('.btn-create').click(() => {
            app.renderPage('create_group');
        });
        $div.find('.btn-search').click(() => {
            app.renderPage('group_search');
        });
    }
    
}