class BlockHeader {
    constructor(dom) {
        this.dom = dom;
        this.dom2 = null;
        this.menu_visible = false;
        this.click_settings_callback = null;

        eventHandler.observe('block_header', ['language_change', 'user_login', 'update_profile'], () => {
            this.render();
			this.changePage();
        }, this);

        eventHandler.observe('block_header', ['change_page'], () => {
            this.changePage();
        }, this);        
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }
    setDom2(dom) {
        this.dom2 = dom;
        return this;
    }
    
    getHtml() {
        let html = `
        <div class='row'>                                                                
            <div class='col2fixed'>
                <div>
                    <a href=''><img class='mm20' src='images/${config.style}/logo_frontpage.svg' alt='' /></a>
                </div>
                <div class='ta-r'>
                    <a class='tip-button tip-button-transparent how-it-works-link' href='#how_it_works'>${app.lang.common.how_it_works}</a>
                    <a class='tip-button login-button' href='#login'><img src='images/green/user_icon.png' class="login-button-icon" alt='' /> ${app.lang.frontpage.login}</a>
                    <a class='menu' href=''><img src='images/green/burger.svg' alt='' /></a>
                </div>
            </div>
        </div>`;
        
        return html;
    }
    
    render() {
        if (!this.dom) return;
        
        var right_content = '';
        
        if(app.isAuth()) {
            let avatar_url = app.getUserAvatar();
            right_content = `
                <a class='tip-button tip-button-transparent' href='javascript:void(0);' onclick="app.blocks.settings.render();" >
                    <img class="img-avatar" src='${avatar_url}' alt='Avatar' onerror="app.errorUserAvatar(this)" />
                    ${app.user.name}
                </a>
                <a class='menu' href='javascript:void(0);' onclick="app.blocks.settings.render();"><img src='images/green/burger.svg' alt='' /></a>
            `;
        }else {
            right_content = `
                <a class='tip-button login-button' href='#login'><img src='images/green/user_icon.png' alt='' /> ${app.lang.frontpage.login}</a>
                <a class='menu' href=''><img src='images/green/burger.svg' alt='' /></a>
            `;            
        }
        
        let html = `
            <div class='col2fixed'>
                <div>
                    <a href=''><img class='mm20' src='images/${config.style}/logo_header.svg' alt='' /></a>
                </div>
                <div class='ta-r'>
                    <a class='tip-button tip-button-transparent how-it-works-link' href='#how_it_works'>${app.lang.common.how_it_works}</a>
                    ${right_content}
                </div>
            </div>`;
        
        this.dom.html(html);
        if(this.dom2) {
            this.dom2.html(html);
        }
    }

    render_OLD() {
        if (!this.dom) return;
        
        var t = this;
        var avatar_url = app.getUserAvatar();

        var menu = '';
        var menu_el = ``;
        var user_small_ico = '';
        
        if(!app.config.hide_burger_menu_and_user_icon) {
            for (let [key, value] of Object.entries(app.lang.common.menu)) {
                if(key == 'invite_friends' && !app.mobile) {
                    continue;
                }
                if(key == 'logout' && app.isMybet) {
                    continue;
                }
                menu += `<a href='#${key}'>${value}</a>`;
            }

            menu_el = `<a href='#menu'><img src='images/menu.svg' class='menu hide menu-icon' /></a>`;
            user_small_ico = `<a href='#${app.isAuth() ? 'edit_profile' : app.getUserCookie('rt90_register') == '1' ? 'login' : 'register'}'><img class="sm-img-avatar img-avatar" src='${avatar_url}' onerror="app.errorUserAvatar(this)" /></a>`;
        }
        
        var html = `
            <div class='block_header'>

                <div class='sportde-header'>
                    <div class='section section0'></div>
                    <div class='section section1'>
                        <a href='${config.external_url.home}' class='header-link'><img class='header-logo' src='images/${(config.style ? config.style + '/logo.svg' : 'sport-de-logo-svg.svg')}' class=''/></a>
                    </div>
                    <div class='section section2'>
                        <img class='tipptastic-logo' src='images/${config.style ? config.style+'/' : ''}logo-tipptastic.svg' class=''/>
                    </div>
                </div>

                <div class="header-subblock">
                    <div>
                        <div class='back hide'><span class='back-word'>${app.lang.common.back}</span></div>
                        ${menu_el}
                    </div>
                    <div>
                        <div>${app.lang.common.welcome}</div>
                        <div class="disconnection-message"></div>
                    </div>
                    <div class="user-avatar">                        
                        <img src='images/sportde-settings.svg' class='cog hide profile-settings'/>                                        
                        ${user_small_ico}
                    </div>
                </div>
                <div class='menu hide'>
                    <span>
                        ${menu}
                        <span>${app.lang.common.versionnumber} - <span class="versionnumber"></span></span>
                    </span>
                </div>
                <div class='home-icon-box'><a href='#home' class='home-icon'><img src='images/${config.style ? config.style + '/' : 'sportde-'}home-icon.svg'' /></a> <a href='#home' class='home-icon home-icon-yellow'><img src='images/${config.style ? config.style + '/' : 'sportde-'}home-icon-yellow.svg' /></a></div>
            </div>
        `;
        
        this.dom.html(html)
        this.changePage(app.getPageWithPrevious());
        
        this.dom.find('a.home-icon').click(function(e) {
            e.stopPropagation();
            app.renderPage('home', null, true);
            return false;
        });

        this.dom.find('.back').click(function(e) {
            e.stopPropagation();
            app.back();
        });

        this.dom.find('img.menu').click(function(event) {
            
        });

        $('body, img.white').click(function() {
            if (t.menu_visible) {
                //window.history.back();
				
                // t.menu_visible = false;
                // t.dom.find('div.menu').hide();
                // t.dom.find('img.menu').show();
                // app.hideOverlay();
                // app.renderPage('home');
            }
        });

        this.dom.find('img.cog').click(e => {
            if(this.click_settings_callback) {
                this.click_settings_callback();
            }
        });
        
        if(config.app_type == 'mobile') {
            this.dom.find('a.header-link').click(function() {
                window.open($(this).attr('href'), '_system');
                return false;
            });
        }
        
        this.renderVersionnumber();

        return this;
    }
    
    async renderVersionnumber() {
        if(config.app_type == 'web') {
            this.dom.find('.versionnumber').html($('#content').attr('versionnumber'));
        }else {
            var v = await cordova.getAppVersion.getVersionNumber();
            this.dom.find('.versionnumber').html(v);
        }
    }

    changePage(page) {  
        if (!this.dom) return;
        
        var t = this;      
        var page = app.getPageWithPrevious();

        if (page.current != 'home') {
            this.dom.find('img.menu').hide();
            this.dom.find('.back').show();
            this.dom.find('.home-icon-box').show();
        }

        if (page.current == 'home' && page.previous != 'home') {
            this.dom.find('img.menu').show();
            this.dom.find('.back').hide();
            this.dom.find('.home-icon-box').hide();
            this.dom.find('.user-avatar').show();
        }

        if (app.first_login && page.current == 'tutorial') {
            this.dom.find('.back.hide').hide();
            this.dom.find('.home-icon-box').hide();
            this.dom.find('.user-avatar').hide();
        }

        /*
        if (page.current == 'group_search' || page.current == 'home') {
            this.dom.find('img.cog').show();
        } else {
            this.dom.find('img.cog').hide();
        } 
        */    
        
        if (page.current == 'menu') {            
            $('.betano-logo-content').hide();
            if (!t.menu_visible) {                
                t.menu_visible = true;
                t.dom.find('div.menu, .back').show();
                t.dom.find('img.menu').hide();
                app.showOverlay();
            } else {                
                t.menu_visible = false;
                t.dom.find('div.menu').hide();
                app.hideOverlay();
            }
        } else {
            $('.betano-logo-content').show();
        }

        if (page.current != 'menu' && page.previous == 'menu') {
            if (t.menu_visible) {
                t.menu_visible = false;
                t.dom.find('div.menu').hide();
                app.hideOverlay();
            }
        }
    }

    hideSettingsIcon() {
        this.click_settings_callback = null;
        this.dom.find('img.cog').hide();
    }

    showSettingsIcon() {
        this.dom.find('img.cog').show();
    }

    onClickSettings(callback) {
        this.showSettingsIcon();
        this.click_settings_callback = callback;
    }
}
