
var Langs = Langs || {};
Langs.de = Langs.de || {};

Langs.de.validation = {
    required: 'Dieses Feld darf nicht leer sein',
    required_one_checkbox: 'Check at least one checkbox',
    min_length: 'Wähle einen Wert, der mindestens 4 Zeichen lang ist',
    exists: 'Wird bereits verwendet',
    not_exists: 'Der Benutzername existiert nicht',
    incorrect: 'Falsches Passwort',
    message_has_been_sent: 'Die Nachricht wurde bereits versendet. Bitte warte 5 Minuten.',
    incorrect_password: 'Falsches Passwort',
    email: 'Gib eine gültige E-Mail Adresse ein',
    enterPassword: 'Gib ein Passwort ein',
    account_inactive: 'Account has not been activated',
    account_deactivated: 'Dein Account wurde geschlossen',
    repeat: 'The values are not the same',
};