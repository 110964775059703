
var Langs = Langs || {};
Langs.de = Langs.de || {};

Langs.de.user = {
    hello: 'Willkommen zurück',
    hi: '',
    login_info: 'Melde dich mit deinem Benutzernamen oder E-Mail an oder wähle <span class="social-services">Weiter mit Facebook</span>',
    not_member_yet: 'Noch kein Mitglied?',
    join_now: 'Jetzt registrieren',
    login: 'Anmelden',
    email: 'E-Mail',
    account_info: 'Account info',
	user_name: 'Benutzername',
    password: 'Passwort',
    new_password: 'Neues Passwort',
    new_password_repeat: 'Neues Passwort wiederholen',
    register: 'Registrierung',
    register_info: 'Registriere dich kostenlos mit deiner E-Mail-Adresse oder mit deinem Facebook Account.',
    you_have_account: 'Du hast bereits ein Profil?',
    avatar_has_been_updated: 'Avatar has been updated',
    create_account: 'Registrieren',
    forgot_password: 'Passwort vergessen?',
    reset_password: 'Passwort zurücksetzen',
    reset_password_info: 'Gib deine E-Mail-Adresse ein, mit der du dich bei der World Tipster League registriert hast. Wir senden dir dann eine E-Mail mit einem Link, mit dem du dein Passwort zurücksetzen kannst.',
    set_reset_password: 'Erstelle ein neues Passwort',
    set_reset_password_info: 'Erstelle ein neues Passwort für dein World Tipster League Profil.',
    url_incorrect: 'Ups, hier hat etwas nicht geklappt. Dieser Link existiert nicht.',
    url_is_old: 'Dieser Link ist nicht mehr verfügbar.',
    edit_profile: 'Profil bearbeiten',
    change_login: 'Benutzernamen ändern',
    change_password: 'Passwort ändern',
    change_email: 'E-Mail ändern',
    accept: 'Zustimmen',
    reject: 'Nicht zustimmen',
    accept2: 'Nutzungsbedingungen zustimmen',
    reject2: 'Account schließen',
    logout: 'Ausloggen',
    delete_account: 'Profil löschen',
    confirm_delete_account: 'Jetzt echt?',
    confirm_delete_account_info: 'Bestätige, dass du dein Profil unwiderruflich löschen möchtest.',
    incorrect_avatar_file: 'Profilbild nicht gefunden',
    continue_with_facebook: 'Weiter mit Facebook',
    reset_email_sent: 'Wir haben dir eine E-Mail gesendet, mit der du dein Passwort zurücksetzen kannst.',
    other_login_forms: 'oder',
    invalid_recaptcha: 'Invalid recaptcha',
    email_reset_password: 'Passwort zurücksetzen',
    email_reset_password_content: `<p>Wir haben eine Anfrage erhalten, dein Passwort zurückzusetzen.</p>
                        <p> 
                            Falls du diese Anfrage nicht verschickt hast ignoriere diese E-Mail einfach. <br /><br /><br />
                            Ansonsten kannst du dein Passwort über diesen Link zurücksetzen:
                        </p>`,
    email_reset_password_button: 'Passwort zurücksetzen',
    email_reset_password_greetings: 'Sportliche Grüße! <br /> Dein tipptastic Team',
    email_reset_password_footer: `
                arena365 GmbH <br />
                Olendeelskoppel 29 - 22397 Hamburg - Deutschland <br />
                <a href="mailto:support@tipptastic.de">support@tipptastic.de</a> - <a target="_blank" href="www.tipptastic.de">www.tipptastic.de</a> <br /> <br />
                <a target="_blank" href="https://tipptastic.de/datenschutz/">Datenschutz - Teilnahmebedingungen</a> <br />
                Handelsregister HRB 149126 - Amtsgericht Hamburg
                `,
    email_tip_reminder: 'Tipp Reminder',
    email_tip_reminder_content: `<p>Hallo, <b>{name}</b></p>
                        <p> 
                            Der <b>{round}. Spieltag</b> der Bundesliga steht an.<br />Hast du deine Tipps schon platziert?
                        </p>`,
    push_tip_reminder_title: 'Tipp Reminder',
    push_tip_reminder_content: 'Der {round}. Spieltag der Bundesliga steht an. Hast du deine Tipps schon platziert?',
    email_tip_reminder_button: 'JETZT TIPPEN',
    email_tip_reminder_content2: 'Viel Erfolg!<br />Dein WTL-Team',
    email_tip_reminder_footer: 'Mit <img src="https://www.worldtipsterleague.com/images/heart.png" /> von der WTL verschickt:<br /><a href="https://www.worldtipsterleague.com/app#edit_profile">E-Mail-Einstellungen</a>',
    register_confirm: `I'have read the <a href='#privacy'>data security agreement</a> and confirm`,
    register_confirm_terms_of_use: `Ich akzeptiere die <a href="#terms_of_use">Teilnahmebedingungen.</a>`,
    register_confirm_datenschutz: `Ich akzeptiere die <a href="#privacy">Datenschutzbedingungen.</a>`,
    register_confirm_datenschutz_fussballdaten: `Ich akzeptiere die <a href="https://www.fussballdaten.de/datenschutzerklaerung/" target='_blank'>Datenschutzbedingungen.</a>`,
    tip_reminder: '<b>TIPP-REMINDER</b><br />(Erinnert dich automatisch per E-mail an die Tippabgabe)',
    start_playing: 'Start playing',
    mybet_login: 'mybet login',
    email_register_confirm_title: 'Confirm registration',
    email_register_confirm_content: `<p>Hallo, <b>{login}</b></p>
                        <p> 
                            Please confirm registration
                        </p>`,
    email_register_confirm_button: 'Confirm',
    email_register_confirm_footer: 'Mit <img src="https://www.worldtipsterleague.com/images/heart.png" /> von der WTL verschickt:<br /><a href="https://www.worldtipsterleague.com/app#edit_profile">E-Mail-Einstellungen</a>',
    sent_rigister_confirm: 'Sent confirm registration on your email. ',
    registration_has_been_confirmed: 'Registration has been confirmed',
    register_confirm_errors: {
        invalid_data: 'Invalid data',
        not_found_user: 'Not found user',
        incorrect_hash: 'Incorrect hash',
        is_already_activated: 'Is already activated'
    },
    connect_with_mybet: 'Connect with your existing mybet account',
    turn_on_tip_reminder: 'Turn On Tipp-Reminder',
    turn_on_tip_reminder_confirm_msg: 'Sent confirm on your email.',
    email_turn_on_tipp_reminder_confirm_title: 'Confirm Turn On Tipp-Reminder',
    email_turn_on_tipp_reminder_confirm_content: `<p>Hallo, <b>{login}</b></p>
                        <p> 
                            Please confirm turn on Tipp-Reminder
                        </p>`,
    email_turn_on_tipp_reminder_confirm_button: 'Confirm',
    email_turn_on_tipp_reminder_confirm_footer: 'Mit <img src="https://www.worldtipsterleague.com/images/heart.png" /> von der WTL verschickt:<br /><a href="https://www.worldtipsterleague.com/app#edit_profile">E-Mail-Einstellungen</a>',
    turn_on_tipp_reminder_has_been_confirmed: 'Tipp-Reminder has been Turned On',
    email_unsubscribe: 'Unsubscribe',
    notifi: {
        reminder: 'Tipperinnerung',
        notifications: 'Neuigkeiten'
    },
    support_and_feedback: 'Support & Feedback',
    support_and_feedback_info: 'Du hast eine Frage oder möchtest uns feedback geben? Dann schreibe uns eine Nachricht und wir melden uns bei dir.',
    subject: 'Betreff',
    message: 'Deine Nachricht',
    support_message_title: 'Support-Nachricht',
    send_message: 'Nachricht senden',
    send_message_error_text: 'Bei deiner Eingabe hat etwas nicht geklappt. Bitte überprüfe deine Eingabe.',
    send_message_success_title: 'Nachricht versendet',
    send_message_success_text: 'Vielen Dank für deine Nachricht, wir werden uns schnellstmöglich bei dir melden.',
    sender_name: 'Absender',
    sender_email: 'Absender E-Mail',
    after_registration_title: 'Wie möchtest du tippen?',
    after_registration_desc: 'Du kannst jederzeit wieder alleine tippen oder eine neue Tipprunde erstellen.',
    after_registration_choose1_title: 'Allein gegen die Community?',
    after_registration_choose1_desc: 'Messe dich mit den besten Spielern! Steige in die erste Liga auf und werde Champion?',
    after_registration_choose2_title: 'Mit Freunden tippen',
    after_registration_choose2_desc: 'Du willst nur mit deinen Freunden Spielen? Dann eröffne eine eigene Tipprunde.',
    contact: 'Kontakt',
    contact_info: 'Du hast eine Frage oder möchtest etwas mit uns klären? Dann fülle das folgende Kontaktformular aus und wir melden uns umgehend bei dir.',
    select_user_league_info: '',
    unsubscribed_title: 'Schade, Du hast den Reminder abgemeldet',
    unsubscribed_email_address: 'Deine E-Mail-Adresse ,{email}`',
    unsubscribed_content: 'Wenn Du den Reminder abmeldest bekommst du keine weiteren Infos über nicht getippte Spiele. Du kannst den Reminder jederzeit wieder in deinem Profil unter Benachrichtigungen aktivieren. ',
    unsubscribed_button: 'Reminder wieder aktivieren '
    
};