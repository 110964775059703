class Form {
    constructor($form, fields, translations) {
        this.$form = $form;
        this.fields = fields;
        this.messages = {};
        this.file_field_temp = false;

        this._bind();
    }

    _bind() {
        for(let fn in this.fields) {
            let field = this.getField(fn);

            if(field.type == 'avatar') {
                this._bindAvatar(field);
                continue;
            }

            field.$input.blur(e => {
                this.isValidField(fn);
            });
            field.$input.keyup(e => {
                if(field.invalid) {
                    this.isValidField(fn);
                }
            });
            
            var borderGroup = ['text', 'password'];
            if(borderGroup.indexOf(field.type) != -1) {
                field.$formGroup.addClass('form-group-border');
                var labelStr = field.$formGroup.find('label, .label').html() || '';
                if(labelStr.indexOf('<br>') != -1 || labelStr.indexOf('</br>') != -1) {
                    field.$formGroup.addClass('label-2-lines');
                }
            }
        }
    }

    _bindAvatar(field) {
        field.$formGroup.click((e) => {
            if(!$(e.target).is(':input') && !$(e.target).is('a')) {
                if (config.app_type == 'mobile') {
                    this.file_field_temp = field;
                    
                    navigator.camera.getPicture(this.mobileCropAvatar.bind(this), this.mobileChangeAvatarErrorHandler.bind(this), {
                        sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
                        destinationType: Camera.DestinationType.FILE_URI,
                        quality: 100,
//                        targetWidth: 200,
//                        targetHeight: 200
                    });
                } else {
                    field.$input.click();
                }
            }
        });

        if (config.app_type == 'web') {
            const preview = field.$formGroup.find('img')[0];
            const reader = new FileReader();

            reader.addEventListener("load", function () {
                // convert image file to base64 string
                field.$formGroup.find('.plus-circle').remove();
                preview.src = reader.result;
                field.data = reader.result;
                if(field.hasOwnProperty('onChange') && field.onChange) {
                    field.onChange.call(field);
                }
            }, false);

            field.$input.change(async e => {
                const file = field.$input[0].files[0];
                if (file) {
                    var is_image = await helpers.isFileImage(file);
                    if(is_image) {
                        reader.readAsDataURL(file);
                    }else {
                        dialogs.alert(app.lang.user.incorrect_avatar_file);
                    }
                }
            });
        }
    }

    refreshField(fieldName) {
        var field = this.fields[fieldName];
        if(!field) {
            throw 'Field "'+fieldName+'" does not exists!';
        }
        field.$input = null;
        return this.getField(fieldName);
    }
    
    getField(fieldName) {
        var field = this.fields[fieldName];
        if(!field) {
            throw 'Field "'+fieldName+'" does not exists!';
        }
        if(!field.$input) {
            field.$input = this.$form.find('[name="'+fieldName+'"]');
        }
        if(!field.$formGroup) {
            field.$formGroup = field.$input.parents('.form-group');
            if(field.$formGroup.length == 0) {
                field.$formGroup = field.$input.parents('.part-form');                
            }
        }
        if(!field.$errorsGroup) {
            field.$errorsGroup = field.$formGroup.find('.valid-errors');
            if(field.$errorsGroup.length == 0) {
                if (field.type == 'multi_checkbox') {
                    field.$errorsGroup = $('<div/>').addClass('valid-errors').appendTo(field.$formGroup);
                }else if (field.$input.attr('type') == 'checkbox') {
                    field.$errorsGroup = $('<div/>').addClass('valid-errors').insertAfter(this.$form.find('[name="'+fieldName+'"]').parent().find('span'));
                } else {
                    field.$errorsGroup = $('<div/>').addClass('valid-errors').insertAfter(field.$input);
                }
            }
        }
        if(!field.valid) {
            field.valid = {};
        }
        if(!field.invalid) {
            field.invalid = false;
        }
        if(!field.invalidValues) {
            field.invalidValues = {};
        }
        if(!field.type) {
            field.type = field.$input.is('input') ? field.$input.attr('type') : 'string';
        }

        return field;
    }

    getValue(fieldName) {
        var field = this.getField(fieldName);
        if(field.type == 'avatar') {
            return field.data || null;
        }
        if(field.type == 'checkbox') {
            return field.$input.is(':checked') ? field.$input.val() : 0;
        }
        if(field.type == 'multi_checkbox') {
            var values = [];
            field.$input.filter(':checked').each(function() {
                values.push(this.value);
            });
            field.$input.filter('[type="hidden"]').each(function() {
                values.push(this.value);
            });
            return values;
        }
        return field.$input.val();
    }

    setValue(fieldName, value) {
        var field = this.getField(fieldName);
        if(field.type == 'avatar') {
            return ;
        }
        if(field.type == 'checkbox') {
            field.$input.prop('checked', value == 1);
            return ;
        }
        if(field.type == 'multi_checkbox') {
            field.$input.prop('checked', false).each(function() {
                if(value.indexOf(this.value) != -1 || value.indexOf(parseInt(this.value)) != -1) {
                    $(this).prop('checked', true);
                }
            });
            return values;
        }
        field.$input.val(value);
    }

    isValidField(fieldName) {
        this.messages[fieldName] = [];
        var field = this.getField(fieldName);
        var value = this.getValue(fieldName);
        field.invalid = false;
        field.$errorsGroup.empty();

        if(field.required && !value) {
            field.invalid = true;
            this.messages[fieldName].push('required');
        }else if(field.required && field.type == 'multi_checkbox' && value.length == 0) {
            field.invalid = true;
            this.messages[fieldName].push('required_one_checkbox');
        }else if(value) {
            for(let validator in field.valid) {
                let attr = field.valid[validator];
                var func = 'valid_' + validator;
                if(!this[func](value, attr)) {
                    field.invalid = true;
                    this.messages[fieldName].push(validator);
                    break;
                }
            }
        }
        
        if(!field.invalid && field.invalidValues.hasOwnProperty(value)) {
            this.messages[fieldName] = field.invalidValues[value];
            field.invalid = true;
        }

        if(field.invalid) {
            field.$formGroup.addClass('invalid');
            for(var error of this.messages[fieldName]) {
                var indivisualMessage = this.fields[fieldName] && this.fields[fieldName].messages && this.fields[fieldName].messages[error] ? this.fields[fieldName].messages[error] : false;
                var errorText = indivisualMessage || app.lang.validation[error] || error;
                $('<div/>').addClass('error text-danger').html(errorText).appendTo(field.$errorsGroup);
            }
            var o = field.$formGroup.offset();
            var ps = helpers.getScrollParent(field.$formGroup[0]);
            if(ps && ps.scrollTop > 0) {
                if(!$(ps).is('html') && o.top < 0) {
                    var m = ps.scrollTop + o.top;
                    if(ps.scrollTop - m < 0) {
                        m = ps.scrollTop;
                    }
                    $(ps).animate({
                        scrollTop: m
                    });
                }else if($(ps).is('html') && o.top < ps.scrollTop) {
                    $(ps).animate({
                        scrollTop: o.top
                    });
                }
            }
        }else {
            field.$formGroup.removeClass('invalid');
        }
        return !field.invalid;
    }

    isValid() {
        this.messages = {};
        for(var fn in this.fields) {
            if(!this.isValidField(fn)) {
                return false;
            }
        }
        return true;
    }

    setServerInvalids(messages, data) {
        for(var fn in messages) {
            if(messages[fn].length > 0) {
                this.getField(fn).invalidValues[data[fn]] = messages[fn];
                this.isValidField(fn);
                this.getField(fn).invalidValues = {};
            }
        }
    }

    clearServerInvalids() {
        for(var fn in this.fields) {
            this.getField(fn).invalidValues = {};
            this.isValidField(fn);
        }
    }

    setRequired(fieldName, required) {
        var field = this.getField(fieldName);
        field.required = required;
    }

    getData(notEmty = false) {
        var data = {};
        var returnNull = notEmty;

        for(var fn in this.fields) {
            let val = this.getValue(fn);
            if(!notEmty || val) {
                data[fn] = val;
                returnNull = false;
            }
        }

        if(returnNull) {
            return null;
        }
        return data;
    }

    valid_email(value) {
        var re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i;
        return re.test(String(value).toLowerCase());
    }

    valid_min_length(value, length) {
        return String(value).length >= length;
    }
    
    valid_repeat(value, field) {
        return this.getValue(field) == value;
    }
    
    mobileChangeAvatarErrorHandler = function(message, error) {
        error = error || message;
        if(typeof message == 'object') {
            if(typeof message.code == 'string' && message.code == 'userCancelled') {
                return ;
            }
            message = message.message;
        }
        if(message == 'No Image Selected') {
            return ;
        }
        
        dialogs.alert(message);
    };
    
    mobileSaveAvatar = function(fileURL, i) {
        var self = this;
        i = i || 0;
        
        const preview = self.file_field_temp.$formGroup.find('img')[0];
        //preview.src = fileURL;
        
        var win = function(fileEntry) {
            fileEntry.file(function(file) {
                const reader = new FileReader();

                reader.onloadend = function(evt) {
                    preview.src = evt.target.result;
                    self.file_field_temp.data = evt.target.result;
                    if(self.file_field_temp.hasOwnProperty('onChange') && self.file_field_temp.onChange) {
                        self.file_field_temp.onChange.call(self.file_field_temp);
                    }
                };
                
                reader.readAsDataURL(file);
            });
        };
        
        var fail = function() {
            var message = error.body || error.exception;
            flash.error(message);
        }
        
        window.resolveLocalFileSystemURL(fileURL, win, fail);
    };
    
    mobileCropAvatar = function(fileUri) {
        fileUri = 'file://' + fileUri;
        plugins.crop(this.mobileSaveAvatar.bind(this), this.mobileChangeAvatarErrorHandler.bind(this), fileUri, {
            quality: 100,
            targetWidth: 200,
            targetHeight: 200
        });
    };
}