
class BlockGroupEdit {
    constructor(dom) {
        this.dom = dom;
        this.isSubmited = false;

        eventHandler.observe('block_group_add', ['language_change', 'user_login'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    async renderOld(params) {
        if (!this.dom) return;
        
        if(!app.isAuth()) {
            this.dom.empty();
            return ;
        }
        
        var html = `
            <div class="message please_wait">
                ${app.lang.common.please_wait}
            </div>
        `;
        this.dom.html(html);

        var fid = 'form' + Date.now();
        var id = params ? params.get('id') : null;
        var can_edit = !!id;
        var group = null;
        if(can_edit) {
            var res1 = await ws.request('group/canEdit', {id: id});
            can_edit = res1.success && res1.can;
            group = res1.group;
        }

        if(!group) {
            var html = `
                <div class="page-message error">
                    ${app.lang.group.can_not_edit_this_group}
                </div>
            `;
            this.dom.html(html);
            return ;
        }
        
        if(typeof group.options != 'object') {
            try {
                group.options = JSON.parse(group.options);
            }catch(err) {
                group.options = {};
            }
        }

        var change_password_html = '';
        if(group.private == 1) {
            change_password_html = `
                <div class="form-group password-group" >
                    <input type="password" name="password" value="" placeholder="************" />
                    <label>${app.lang.group.change_password}</label>
                </div>
            `;
        }

        var save_btn = `<button type="submit" class="btn-sportde" >${app.lang.common.save}</button>`;
        var unjoin_btn = `<span class="fl-l btn-link btn-unjoin-group">${app.lang.group.unjoin_btn}</span>`;
        var delete_group_btn = `<span class="fl-r btn-link btn-delete-group">${app.lang.group.delete_group}</span>`;
        var extra_class = '';

        if(!app.data.groups.find(x => x.id == group.id)) {
            unjoin_btn = '';
        }
        
        var group_image = group.options && group.options.image ? config.web_front_url+`/usergroup/${group.options.image}` : `images/${config.style ? config.style + '/' : 'sportde-'}default-avatar.svg`;
        var image_change = '';
        
        if(!can_edit) {
            save_btn = '';
            delete_group_btn = '';
            extra_class = ' can-not-edit';
            image_change = `<div class="ta-c">
                            <div class="form-group form-group-image form-group-avatar">
                                <img class="${app.mobile ? 'med-img-image' : ''}" src="${group_image}" />
                            </div>
                        </div>`;
            
        } else {
            image_change = `<div class="ta-c">
                            <div class="form-group form-group-image form-group-avatar">
                                <input type="file" name="image" class="file-image" accept=".png, .jpg, .jpeg" />
                                <img class="${app.mobile ? 'med-img-image' : ''}" src="${group_image}" onerror="app.errorUserAvatar(this)" />
                                <div class="user-name"></div>
                                <span class='avatar-change'><a href='' onclick='$("#${fid} .file-image").click(); return false;'>${app.lang.common.change}</a></span>
                            </div>
                        </div>`;
        }

        var invite_friends_html = '';
        var copy_link_class = 'desktop-copy-link';
        if(app.mobile) {
            invite_friends_html = `<label class="btn-link btn_group_invite_friends">${app.lang.group.invite_friends}</label>`;
            copy_link_class = '';
        }

        var join_link = (config.app_type == 'mobile'
                ? config.web_front_url
                : window.location.origin + window.location.pathname) + '#' + group.url_code + '-group';
        if(config.share_url) {
            join_link = config.share_url + 'groupid=' + group.url_code;
        }
        
        var group_leagues_html = '';
        if(can_edit) {
            group_leagues_html += `
                <div class="group-leagues form-group">
                    <h4>${app.lang.group.group_in_leagues}</h4>
            `;
            for(var id_league of app.data.id_leagues) {
                group_leagues_html += `
                    <div class='form_checkbox'>
                        <label>
                            <input ${group.ids_leagues.indexOf(parseInt(id_league)) != -1 ? 'checked="checked"' : ''} type="checkbox" name="ids_leagues" value="${id_league}" />
                            ${app.lang.common['league_name_'+id_league]}
                        </label>
                    </div>
                `;
            }
            group_leagues_html += '</div>';
        }

        var html = `
            <div class="block-form ${extra_class}">
                <h1 class="page-title">${app.lang.group.edit_group}</h1>
                <div class='block_group_add bl bl20'>
                    <form id="${fid}" class="form-group_add form margin-group">
                        <h1 class="form-sub-title">${group.name}</h1>
                        ${image_change}
                        
                        <div class="form-group name-group form-group-border label-2-lines">
                            <input type="text" name="name" value="" placeholder="${group.name}" />
                            <label>${app.lang.group.change_name}</label>
                        </div>
                        ${change_password_html}
                        ${group_leagues_html}

                        <div class="form-group">
                            <h4 class='invite-friends-label'>${app.lang.group.invite_friends}</h4>
                            <span class='invite-friends-label-text'>${app.lang.group.invite_friends_text}</span>
                        </div>
                        <div class="form-group url_code-group success">
                            <div class="input-text" >${join_link}</div>
                        </div>

                        <div class="form-group invite-friends-form-group url_code-group success">
                            ${invite_friends_html}
                            <label class="btn-link btn_copy_link ${copy_link_class}">${app.lang.group.copy_link}</label>
                        </div>                       
                        
                        <div class="form-group">
                            ${save_btn}
                        </div>

                        <div class="footer-line">
                            ${unjoin_btn}
                            ${delete_group_btn}
                            <div class="clear"></div>
                        </div>
                    </form>
                    
                </div>
            </div>
        `;

        this.dom.html(html);

        this.$form = $(this.dom).find('form');
        this.form = new Form(this.$form, {
            image: {type: 'avatar'},
            name: {},
            password: {valid: {min_length: 4}, messages: {required: app.lang.validation.enterPassword}},
            url_code: {$input: this.$form.find('.url_code-group .input-text')},
            ids_leagues: {required: true, type: 'multi_checkbox'}
        });

        this.$form.submit(e => {
            this.onsubmit(group);
            return false;
        });      
        
        this.dom.find('.btn_copy_link').click(function(e) {
            var join_link = (config.app_type == 'mobile'
                ? config.web_front_url
                : window.location.origin + window.location.pathname) + '#' + group.url_code + '-group';
            if(config.share_url) {
                join_link = config.share_url + 'groupid=' + group.url_code;
            }
            helpers.copyToClipboard(join_link, e);
        });

        this.dom.find('.btn-delete-group').click(function() {
            app.renderPage('group_delete', {id});
        });

        this.dom.find('.btn-unjoin-group').click(function() {
            app.renderPage('group_unjoin', {id});
        });

        this.dom.find('.btn_group_invite_friends').click(function() {
            app.renderPage('group_invite_friends', {id: group.id});
            return false;
        });
    }
    
    getLeaguesHtml(ids_leagues) {
        var leagues_html = '';
        for(var id_league of ids_leagues) {
            leagues_html += `
                    <div class="menu">
                        <span class='flag' >
                            <img src="${config.web_front_url}/images/leagues/${id_league}.svg" />
                        </span>
                        <span>
                            <input type="hidden" name="ids_leagues" value="${id_league}" />
                            ${app.lang.common['league_name_'+id_league]}
                        </span>
                        <span>
                            <span class="btn-remove-league" ><img src="images/trash.svg" /></span>
                        </span>
                    </div>
            `;
        }
        return leagues_html;
    }
    
    async renderForNoAdmin(group, users) {
        var bif = app.blocks.group_invite_friends;
        bif.group = group;
        
        var html = `
            <div class="block-form edit-group-for-no-admin">
                <h1 class="page-title">${app.lang.group.invite_friends_title}</h1>
        
                <div class="invite-friends-box">
                    <div class="desc">${app.lang.group.invite_friends_desc}</div>
                    <div class="menu btn-whatsapp" style="display:${app.mobile ? 'grid' : 'none' }">
                        <span>
                            <img src="images/whatsapp.svg" />
                        </span>
                        <span>
                            ${app.lang.common.whatsapp}
                        </span>
                        <span></span>
                    </div>
                    <div class="menu btn-share" style="display:${app.mobile ? 'grid' : 'none' }">
                        <span>
                            <img src="images/share.svg" />
                        </span>
                        <span>
                            ${app.lang.common.share}
                        </span>
                        <span></span>
                    </div>
                    <div class="btn-link" style="display:${app.mobile ? 'none' : 'block' }">
                        <button class="tip-button join-link-button" >${bif.getJoinLink()}</button>
                    </div>
                    <div class="form-group line-buttons">
                        <button class="tip-button tip-button-block tip-button-color-yellow btn-copy_link" >${app.lang.group.copy_link}</button>
                    </div>
                </div>
                
                <div class="form-group line-buttons">
                    <button type="button" class="tip-button tip-button-transparent btn-unjoin-group">${app.lang.group.unjoin_group}</button>
                </div>
            </div>
        `;
        
        this.dom.html(html);
        
        this.dom.find('.btn-whatsapp').click(bif.whatsapp.bind(bif));
        this.dom.find('.btn-share').click(bif.share.bind(bif));
        this.dom.find('.btn-copy_link').click(bif.copyLink.bind(bif));
        this.dom.find('.btn-unjoin-group').click(() => {
            app.renderPage('group_unjoin', {id: group.id});
        });
        
    }
    
    async render(params) {
        if (!this.dom) return;
        
        if(!app.isAuth()) {
            this.dom.empty();
            return ;
        }
        
        var html = `
            <div class="message please_wait">
                ${app.lang.common.please_wait}
            </div>
        `;
        this.dom.html(html);

        var self = this;
        var fid = 'form' + Date.now();
        var id = params ? params.get('id') : null;
        var can_edit = !!id;
        var group = null;
        var users = null;
        console.log('can_edit', can_edit);
        if(can_edit) {
            var res1 = await ws.request('group/canEdit', {id: id});
            can_edit = res1.success && res1.can;
            group = res1.group;
            users = res1.users;
            console.log(res1);
        }

        if(!group) {
            return;
        }
        
        if(!can_edit) {
            //flash.error(app.lang.group.can_not_edit_this_group);
            await this.renderForNoAdmin(group, users);
            return ;
        }
        
        if(typeof group.options != 'object') {
            try {
                group.options = JSON.parse(group.options);
            }catch(err) {
                group.options = {};
            }
        }
        
        var group_image = group.options && group.options.image ? config.web_front_url+`/usergroup/${group.options.image}` : `images/${config.style ? config.style + '/' : 'sportde-'}default-avatar.svg`;


        var users_html = '';
        if (users) {
            for(var user of users) {
                let avatar_url = app.getUserAvatar(user);
                users_html += `
                        <div class="menu">
                            <span class='flag' >
                                <img src="${avatar_url}" />
                            </span>
                            <span>
                                ${user.login}
                            </span>
                            <span>
                                ${user.id != app.user.id ? `<span class="btn-remove-user" data-id="${user.id}" ><img src="images/trash.svg" /></span>` : '' }
                            </span>
                        </div>
                `;
            }
        }

        var html = `
            <div class="block-form ${can_edit ? 'can-edit' : 'not-can-edit'}">
                <h1 class="page-title">${app.lang.group.edit_group}</h1>
                <div class='block_edit_group '>
                    <form class="form-edit_group form form-sm form-separators">
        
                        <div class="box part-form">
                            <div class="box-title">${app.lang.group.group_name}</div>
                            <div class="form-group form-group-border">
                                <input type="text" name="name" value="${group.name}" placeholder="${app.lang.group.name_placeholder}" />
                            </div>
                        </div>
                        <div class="box part-form">
                            <div class="box-title">${app.lang.group.logo}</div>
                            <div class="form-group form-group-image form-group-avatar">
                                <input type="file" name="image" class="file-image" accept=".png, .jpg, .jpeg" />
                                <div class="logo-group-row">
                                    <span>
                                        <img src="${group_image}" onerror="app.errorUserAvatar(this)" />
                                    </span>
                                    <span>
                                        ${app.lang.group.choose_logo}
                                    </span>
                                    <span>
                                        <img src="images/green/right-second.svg" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="box part-form box-group_in_leagues">
                            <div class="box-title">${app.lang.group.group_in_leagues}</div>
                            <div class="group-leagues">
                                ${this.getLeaguesHtml(group.ids_leagues)}
                            </div>
                            <div class='menu group-add_league'>
                                <span>
                                    <img src="images/plus.svg" />
                                </span>
                                <span>
                                    ${app.lang.group.add_league}
                                </span>
                                <span></span>
                            </div>
                        </div>
                        <div class="box part-form">
                            <div class="box-title">${app.lang.group.group_type}</div>
                            <div class="box-desc">${app.lang.group.group_type_desc}</div>
                            
                            <div class="form-group form-check">
                                <label>
                                    <input type="checkbox" name="private" value="1" ${group.private == 1 ? 'checked' : ''} />
                                    <span>${app.lang.group.private}</span>
                                </label>                                    
                            </div>
                            <div class="form-group form-check">
                                <label>
                                    <input type="checkbox" name="public" value="1" ${group.private != 1 ? 'checked' : ''} />
                                    <span>${app.lang.group.public}</span>
                                </label>                                    
                            </div>                            
                        </div>
                        <div class="box part-form box-password" style="display: none;">
                            <div class="box-title">${app.lang.group.password}</div>
                            <div class="form-group form-group-border password-group">
                                <img src="images/showpassword2.svg" class="show-password-icon">
                                <input type="text" name="password" value="" />
                            </div>
                        </div>
        
                        <div class="box part-form box-group_users">
                            <div class="box-title">${app.lang.group.group_users} (${users ? users.length : 0})</div>
                            <div class="group-users">
                                ${users_html}
                            </div>
                            <div class='menu group-add_user'>
                                <span>
                                    <img src="images/plus.svg" />
                                </span>
                                <span>
                                    ${app.lang.group.add_user}
                                </span>
                                <span></span>
                            </div>
                        </div>
        
                        <div class="form-group line-buttons">
                            <button type="submit" class="tip-button" >${app.lang.common.save}</button>
                            <button type="button" class="tip-button tip-button-transparent btn-delete-group">${app.lang.group.delete_group}</button>
                            <button type="button" class="tip-button tip-button-transparent btn-unjoin-group">${app.lang.group.unjoin_group}</button>
                        </div>
                    </form>
                    
                </div>
            </div>
        `;

        this.dom.html(html);

        this.$form = $(this.dom).find('form');
        setTimeout(() => { this.$form.find('[name="password"]').attr('type', 'password'); }, 200);
        
        this.form = new Form(this.$form, {
            image: {type: 'avatar'},
            name: {required: true},
            private: {},
            public: {},
            password: {valid: {min_length: 4}, messages: {required: app.lang.validation.enterPassword}},
            //url_code: {$input: this.$form.find('.url_code-group .input-text')},
            ids_leagues: {required: true, type: 'multi_checkbox', $formGroup: this.dom.find('.box-group_in_leagues')}
        });
        
        if(can_edit) {
            this.dom.find('.group-add_league').click(() => {
                dialogs.leagues(this.form.getValue('ids_leagues'), (ids_leagues) => {
                    this.dom.find('.group-leagues').html(this.getLeaguesHtml(ids_leagues));
                    this.form.refreshField('ids_leagues');
                    if(ids_leagues.length)  this.dom.find('.box-group_in_leagues .valid-errors').html('')
                });
            });
            this.dom.on('click', '.btn-remove-league', function() {
                $(this).closest('.menu').remove();
                self.form.refreshField('ids_leagues');
            });
        }
        this.dom.find('.group-add_user span:not(:empty)').click((e) => {
            var bif = app.blocks.group_invite_friends;
            bif.group = group;
            bif.share();
        });
        
        this.remove_users = [];
        this.dom.on('click', '.btn-remove-user', function() {
            dialogs.confirm(app.lang.group.confirm_unjoin_user_by_admin, () => {
                self.remove_users.push($(this).data('id'));
                $(this).closest('.menu').remove();                
            });
        });
        this.dom.find('input:checkbox[name="private"],input:checkbox[name="public"]').change(function() {
            self.dom.find('input:checkbox[name="private"],input:checkbox[name="public"]').prop('checked', false);
            $(this).prop('checked', true);
            if($(this).attr('name') == 'private') {
                self.dom.find('.box-password').show();
            }else {
                self.dom.find('.box-password').hide();                
            }
        }).filter(':checked').trigger('change');
        
        this.$form.find('.show-password-icon').click(function() {
            var password_input = $(this).closest('.form-group').find('input');
            if(!password_input.hasClass('password-visible')) {
                password_input.addClass('password-visible').attr('type', 'text');
            }else {
                password_input.removeClass('password-visible').attr('type', 'password');
            }
        });

        this.$form.submit(e => {
            if(!can_edit) {
                return false;
            }
            this.onsubmit(group);
            return false;
        });

        if(can_edit) {
            this.dom.find('.btn-delete-group').click(function() {
                app.renderPage('group_delete', {id});
            });
        }
        this.dom.find('.btn-unjoin-group').click(function() {
            app.renderPage('group_unjoin', {id});
        });
        
        /*
        this.dom.find('.btn_copy_link').click(function(e) {
            var join_link = (config.app_type == 'mobile'
                ? config.web_front_url
                : window.location.origin + window.location.pathname) + '#' + group.url_code + '-group';
            if(config.share_url) {
                join_link = config.share_url + 'groupid=' + group.url_code;
            }
            helpers.copyToClipboard(join_link, e);
        });

        this.dom.find('.btn-unjoin-group').click(function() {
            app.renderPage('group_unjoin', {id});
        });

        this.dom.find('.btn_group_invite_friends').click(function() {
            app.renderPage('group_invite_friends', {id: group.id});
            return false;
        });
        */
    }

    async onsubmit(group) {
        if(this.isSubmited) {
            return ;
        }
        this.isSubmited = true;

        try {
            if(!this.form.isValid()) {
                this.isSubmited = false;
                return ;
            }
            var data = this.form.getData(true);
            
            if(!data) {
                this.isSubmited = false;
                app.renderPage('home');
                return ;
            }            
            data.id = group.id;
            data.remove_users = this.remove_users;
            var result = await ws.request('group/edit', data);
            if(result.success) {
                if(result.group) {
                    var gi = app.data.groups.findIndex(x => x.id == result.group.id);
                    Object.assign(app.data.groups[gi], result.group);
                    //app.renderPage('group', {id: result.group.id});
                }else {
                    helpers.removeArrayItems(app.data.groups, x => x.id == group.id);
                }
                eventHandler.trigger('set_data_groups', {}, true);
                app.renderPage('home');
            }else {
                this.form.setServerInvalids(result.messages, data);
            }
        }catch(err) {
            console.log(err);
        }

        this.isSubmited = false;
    }
}
