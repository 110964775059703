

$.fn.swipeIndicator = function(options = {}) {
    if(app.mobile) {
        return this;
    }
    var $root = $(this);
    $root.addClass('swipe-indicator-container');

    var $block = $root.children().first();
    var step = 0;

    var $navLeft = $('<div/>')
        .addClass('swipe-indicator-left swipe-indicator-btn')
        //.html(`<img src="images/${(config.style ? config.style+'/' : '')}arrow-white-left.svg" />`)
        .html(`<img src="images/green/arrow-white-left.svg" />`)
        .appendTo($root);

    var $navRight = $('<div/>')
        .addClass('swipe-indicator-right swipe-indicator-btn')
        //.html(`<img src="images/${(config.style ? config.style+'/' : '')}arrow-white-right.svg" />`)
        .html(`<img src="images/green/arrow-white-right.svg" />`)
        .appendTo($root);

    var beforeMove = () => {
        if(!step) {
            var $el = $block.children().filter(':visible:not(.euro_mod-matchday)').first();
            step = $el.outerHeight() + parseInt($el.css('margin-bottom'));
            if(options.hasOwnProperty('stepMultiply')) {
                step *= parseInt(options.stepMultiply);
            }
        }
        if(!step) {
            step = 200;
        }
    };

    var setBtnActive = () => {        
        var ct1 = $block.scrollTop();
        if(ct1 > 0) {
            $navLeft.addClass('active');
        }else {
            $navLeft.removeClass('active');
        }
        var sh1 = $block[0].scrollHeight;
        var h1 = $block.outerHeight();
        if(ct1 + h1 < sh1) {
            $navRight.addClass('active');
        }else {
            $navRight.removeClass('active');
        }

        if(sh1 > h1) {
            $root.addClass('swipe-indicator-active');
        }else {
            $root.removeClass('swipe-indicator-active');
        }
    };

    $navLeft.click(e => {
        if(options.hasOwnProperty('onClickLeft')) {
            options.onClickLeft();
        }else {
            beforeMove();
            var st = $block.scrollTop() - step;
            if(st < 15) {
                st = 0;
            }
            $block.animate({
                scrollTop: st + 'px'
            });
        }
        setBtnActive();
    });

    $navRight.click(e => {
        if(options.hasOwnProperty('onClickRight')) {
            options.onClickRight();
        }else {
            beforeMove();
            var ct = $block.scrollTop();
            if(ct < 15) {
                ct = 10;
            }
            var st = ct + step;
            $block.animate({
                scrollTop: st + 'px'
            });
        }
        setBtnActive();
    });

    setBtnActive();
    $block.scroll(setBtnActive);
    $(window).resize(e => {
        step = 0;
        setBtnActive();
    });

    return this;
};
