class Session {
    
    constructor() {
        this.storageType = 'localStorage';
    }
    
    setStorageType(type) {
        this.storageType = type+'Storage';
    }

    set(key, value) {
        window[this.storageType].setItem(key, JSON.stringify(value));
    }

    get(key, default_value = null) {
        var str = window[this.storageType].getItem(key);
        if(!str) {
            return default_value;
        }
        try {
            return JSON.parse(str);
        }catch(err) {
            return default_value;
        }
    }

    clear() {
        window[this.storageType].clear();
    }
}

var session = new Session();