
class Flash {
    
    message(title, message, style = 'success') {
        var extra_class = window.innerWidth < app.breakPoint ? 'mobile' : 'desktop';
        var html = `
            <div class="flash-message ${style} ${extra_class}">
                <div class="body">
                    <div class="icon"></div>
                    <div class="text">
                        <div class="title">${title}</div>
                        <div class="message">${message}</div>
                    </div>
                </div>
            </div>
        `;
        var $div = $(html).appendTo('body');
        setTimeout(() => {
            $div.remove();
        }, 5000);
    }
    
    success(title, message) {
        if(typeof message == 'undefined') {
            message = title;
            title = app.lang.common.success;
        }
        return this.message(title, message, 'success');
    }
    
    error(title, message) {
        if(typeof message == 'undefined') {
            message = title;
            title = app.lang.common.error;
        }
        return this.message(title, message, 'error');
    }
}

var flash = new Flash();