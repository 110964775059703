var Langs = Langs || {};
Langs.de = Langs.de || {};

Langs.de.tutorial = {
    top1: 'Beim Tipp auf Sieg oder Unentschieden ist<br/> das genaue Endergebnis egal.',
    bottom1: '<i>DORTMUND GEWINNT</i><br /> du kassierst <b>55 Punkte</b>',
    button1: 'TUTORIAL ÜBERSPRINGEN',
    
    top2: 'Tippe auf Sieg, Unentschieden oder Turbo (Sieg mit mind. 2 Toren Unterschied).',
    bottom2: '<i>SIEG DORTMUND</i><br />du kassierst <b>32 Punkte</b>',
    button2: 'TUTORIAL ÜBERSPRINGEN',
    
    top3: 'Tippe auf Sieg, Unentschieden oder Turbo (Sieg mit mind. 2 Toren Unterschied).',
    bottom3: '<i>SIEG DORTMUND</i><br />du kassierst <b>32 Punkte</b>',
    button3: 'TUTORIAL ÜBERSPRINGEN',
    
    top4: 'Tippe auf Sieg, Unentschieden oder Turbo (Sieg mit mind. 2 Toren Unterschied).',
    bottom4: '<i>SIEG DORTMUND</i><br />du kassierst <b>32 Punkte</b>',
    button4: 'TUTORIAL ÜBERSPRINGEN',
    
    top5: 'Tippe auf Sieg, Unentschieden oder Turbo (Sieg mit mind. 2 Toren Unterschied).',
    bottom5: '<i>SIEG DORTMUND</i><br />du kassierst <b>32 Punkte</b>',
    button5: 'TUTORIAL ÜBERSPRINGEN',
    
    top6: 'Tippe auf Sieg, Unentschieden oder Turbo (Sieg mit mind. 2 Toren Unterschied).',
    bottom6: '<i>SIEG DORTMUND</i><br />du kassierst <b>32 Punkte</b>',
    button6: 'Alles klar'
}