
var Langs = Langs || {};
Langs.en = Langs.en || {};

Langs.en.validation = {
    required: 'This field is required!',
    min_length: 'Value is to short',
    exists: 'Value exists in db',
    not_exists: 'Value not exists in db',
    incorrect: 'Value is incorrect',
    message_has_been_sent: 'Message has been sent already. Please wait five minutes.',
    incorrect_password: 'Incorrect password',
    enterPassword: 'Please enter a password'
};