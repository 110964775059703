
var Langs = Langs || {};
Langs.en = Langs.en || {};

Langs.en.frontpage = {
    how_it_works: {
        title: 'How it works',
        header_info: 'Tired of entering results all the time? Do not feel like the 2:1 tippers who always collect the same points? Do you want to compete with the best in a league? Then you are exactly right here. Check out how we do it:',
        section_1_title: 'This is how you type',
        section_2_title: 'Our league system',
        section_3_title: 'Alone or group',
        section_4_title: 'Rewards',
        section_1_text: 'With us you bet on a win or a draw, no matter what the result looks like. Depending on how much risk you are willing to take, you will receive more or fewer points. <br /><br /> Do you have the guts to predict an underdog win? With our TURBO you bet on a win with at least 2 goals ahead. <br /><br /> More risk - more points.',
        section_2_text: 'With us, a whole season is divided into small units of 2 rounds each. The points from both rounds are added up.<br /><br />Place 1 - 2 lead to promotion.<br />Place 15 - 18 play against relegation.',
        section_3_text: 'With us you can compete against the entire community as a single bet or in a betting group against your friends. Create private or public betting groups and invite everyone.',
        section_4_text: 'With us you can win attractive prizes every game day. Whether climber, daily winner or challenge winner. Plenty of trophies are waiting for you.'

    }
};
