class BlockLogin {
    constructor(dom) {
        this.dom = dom;
        this.isSubmited = false;

        eventHandler.observe('block_login', ['language_change', 'user_login'], () => {
            if(!app.isAuth()) {
                this.render();
            }
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    render() {
        if(!this.dom) {
            setTimeout(this.render.bind(this), 100);
            return ;
        }
        var title_html = '';
        if(app.lang.user.hello) {
            title_html = `<h1 class="form-title">${app.lang.user.hello}</h1>`;
        }
        
        var extra_buttons = '';
        
        if(app.isMybet) {
            extra_buttons += `
                <div class="form-group">
                    <button type="button" class="btn-black btn-connect_with_mybet" >${app.lang.user.connect_with_mybet}</button>
                </div>
            `;
        }
        var fb = ``;
        
        if (0) { // sport.de
            fb = `
                <div class="form-group other-login-forms">
                    <span>${app.lang.user.other_login_forms}</span>
                </div>
                <div class="form-group facebook-login-form">
                    <button type="submit" class="btn-black btn-fb ffant fs-16" ><span class="facebook-f">f</span>${app.lang.user.continue_with_facebook}</button>
                </div>`;
        }

        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.user.login}</h1>
                <div class='block_login bl'>
                    <form class="form-login form">
                        ${title_html}
                        <div style="display: none;" class="form-notification"></div>

                        <div class="form-group user_name-group form-group-border">
                            <input type="text" name="login" value="" placeholder="${app.lang.user.user_name} / ${app.lang.user.email}" />
                        </div>
                        <div class="form-group password-group form-group-border">
                            <img src="images/showpassword2.svg" class="show-password-icon">
                            <input type="password" name="password" value="" class="password-input" placeholder="${app.lang.user.password}" />
                        </div>
                        <span class="btn-forgot_password link-button">${app.lang.user.forgot_password}</span>
                        <div class="form-group form-login-buttons line-buttons">
                            <button type="submit" class="tip-button" >${app.lang.user.login}</button>
                            <button type="button" class="tip-button tip-button-transparent btn-register" >${app.lang.user.join_now}</button>
                        </div>
                        
                        ${fb}
        
                        ${extra_buttons}
                    </form>
                    
                </div>
        
                <a href='#reset_password' class="btn-forgot_password link-button">${app.lang.user.forgot_password}</a>
            </div>
        `;

        this.dom.html(html);

        this.$form = $(this.dom).find('form');
        this.$form.submit(e => {
            this.onsubmit();
            return false;
        });
        this.form = new Form(this.$form, {
            login: {required: true, valid: {min_length: 4}},
            password: {required: true, valid: {min_length: 4}}
        });

        this.$form.find('.btn-register').click(e => {
            app.renderPage('register');
        });
        this.$form.find('.show-password-icon').click(e => {
            var password_input = this.$form.find('.password-input');
            if(!password_input.hasClass('password-visible')) {
                password_input.addClass('password-visible').attr('type', 'text');
            }else {
                password_input.removeClass('password-visible').attr('type', 'password');
            }
        });
        this.$form.find('.btn-forgot_password').click(e => {
            app.renderPage('reset_password');
        });
        this.$form.find('.btn-connect_with_mybet').click(e => {
            window.parent.postMessage({type: 'login'}, "*");
            return false;
        });
        this.$form.find('.btn-fb').click(e => {
            if (config.app_type == 'mobile') {
                Lib.Facebook.Login(response => {
                    if (response.authResponse) {
                        app.blocks.login.continueWithFb(response.authResponse);
                        app.setUserCookie('rt90_register', '1');
                    } else {
                        console.log('User cancelled login or did not fully authorize.');
                    }
                });
            } else {
                FB.login(response => {
                    if (response.authResponse) {
                        app.blocks.login.continueWithFb(response.authResponse);
                        app.setUserCookie('rt90_register', '1');
                    } else {
                        console.log('User cancelled login or did not fully authorize.');
                    }
                }, {scope: 'email'});
            }

            return false;
        });

        return this;
    }

    async continueWithFb(auth) {
        var result = await ws.request('user/loginFb', auth);
        if(result.success) {
            app.first_login = result.first_login;
            app.login(result.user, result.first_login);
        }else {
            this.form.setValue('login', result.data.login);
            this.form.setServerInvalids(result.messages, result.data);
        }
    }

    async onsubmit() {
        if(this.isSubmited) {
            return ;
        }
        this.isSubmited = true;

        try {
            if(!this.form.isValid()) {
                this.isSubmited = false;
                return ;
            }
            var data = this.form.getData();
            var result = await ws.request('user/login', data);
            if(result.success) {
                app.login(result.user);
                app.setUserCookie('rt90_register', '1');
            }else {
                this.form.setServerInvalids(result.messages, data);
            }
        }catch(err) {
            console.log(err);
        }

        this.isSubmited = false;
    }
    
    async mybetLogin(token) {
        var html = `
            <form class="form-login form">
                <div class='pr_title'>${app.lang.user.mybet_login}</div>
                <div class="form-group user_name-group">
                    <input type="text" name="login" value="" placeholder="${app.lang.user.user_name}" />
                </div>
            </form>
        `;
        var dialog = new Dialog(); 
        dialog.html(html);
    
        var $form = dialog.content().find('form');
        var form = new Form($form, {
            login: {required: true, valid: {min_length: 4}}
        });
        
        var isSubmited = false;
        var onSubmit = async function () {
            if(isSubmited) {
                return ;
            }
            isSubmited = true;

            try {
                if(!form.isValid()) {
                    isSubmited = false;
                    return ;
                }
                var data = form.getData();
                data.token = token;
                data.groupid = groupid;
                var result = await ws.request('user/mybetRegister', data);
                if(result.success) {
                    if(result.joinToGroup) {
                        app.go_after_login = 'join_to_group';
                        app.go_after_login_params = {c: result.joinToGroup};
                    }
                    app.login(result.user);
                    app.setUserCookie('rt90_register', '1');
                    dialog.close();
                }else {
                    form.setServerInvalids(result.messages, data);
                }
            }catch(err) {
                console.log(err);
            }

            isSubmited = false;
        };
        $form.submit(e => {
            onSubmit();
            return false;
        });
        dialog.btn(app.lang.user.start_playing, () => {
                onSubmit();
            }, {black: true})
            .show();
    }
    
    async confirmRegister(id, hash, groupid) {
        if(groupid) {
            app.go_after_login = 'join_to_group';
            app.go_after_login_params = {c: groupid};
        }
        
        var result = await ws.request('user/confirmRegister', {id, hash});
        var html = '';

        if(result.success) {
            $('.form-login input[name="login"]').val(result.login);
            html += `<div class='pr_title'>`+app.lang.group.thanks_for_register_done+'</div>';
            html += `<div class='pr_message'>`+app.lang.group.thanks_for_register_done_message+'</div>';
        }else {
            html += `<div class='pr_title'>`+app.lang.user.register_confirm_errors[result.message]+'</div>';            
        }

        var infoc_dialog = new Dialog({wrapper_dark: true});

        infoc_dialog
            .html(html)
            .btn(app.lang.group.confirm_reg_button_done, () => {
                infoc_dialog.close();
            }, {black: true})
            .show();
    }
}