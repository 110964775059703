class BlockResetPassword {
    constructor(dom) {
        this.dom = dom;
        this.isSubmited = false;

        eventHandler.observe('block_reset_password', ['language_change', 'user_reset_password'], () => {
            if(!app.isAuth()) {
                this.render();
            }
        }, this);
        
        eventHandler.observe('block_header', ['change_page'], () => {            
            if (app.getPage() != 'login') {
                $('.form-notification').hide();
            }
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    render() {
        var title_html = '';
        if(app.lang.user.hello) {
            title_html = `<h1 class="form-title">${app.lang.user.hello}</h1>`;
        }

        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.user.reset_password}</h1>
                <div class='block_reset_password bl'>
                    <form class="form-reset_password form">
                        ${title_html}
                        <div class="form-info">${app.lang.user.reset_password_info}</div>

                        <div class="form-group email-group form-group-border">
                            <input type="text" name="email" value="" placeholder="${app.lang.user.email}" />
                        </div>
        
                        <div class="form-group form-login-buttons">
                            <button type="submit" class="tip-button mb15" >${app.lang.user.reset_password}</button>
                            <a href='#login' class="tip-button tip-button-transparent">${app.lang.user.login}</a>
                        </div>

                    </form>
                    
                </div>
            </div>
        `;
        
        /*
         <div class="footer-line">
                            <span>${app.lang.user.you_have_account}</span>
                            <span class="fl-r btn-login">${app.lang.user.login}</span>
                            <div class="clear"></div>
                        </div>
        */

        this.dom.html(html)

        this.$form = $(this.dom).find('form');
        this.$form.submit(e => {
            this.onsubmit();
            return false;
        });
        this.form = new Form(this.$form, {
            email: {required: true, valid: {email: true}}
        });

        this.$form.find('.btn-login').click(e => {
            app.renderPage('login');
        });

        return this;
    }

    async onsubmit() {
        if(this.isSubmited) {
            return ;
        }
        this.isSubmited = true;

        try {
            if(!this.form.isValid()) {
                this.isSubmited = false;
                return ;
            }
            var data = this.form.getData();
            var result = await ws.request('user/resetPassword', data);
            if(result.success) {
                app.renderPage('login', {sent_password_reset_confirm: 1});
            }else {
                this.form.setServerInvalids(result.messages, data);
            }
        }catch(err) {
            console.log(err);
        }

        this.isSubmited = false;
    }

}