class BlockAd3 {
    constructor(dom) {
        this.dom = dom;
        
        eventHandler.observe('block_header', ['language_change', 'user_login'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    render() {
        if (!this.dom) return;
        
        var html = `
            <div class='block_ad3' >
                <div class="ad3-content">
                    <span class="ad3-text">${app.lang.common.ad3_text}</span>
                    <span class="ad3-amount"><span id="betano-odd-amount"></span> €</span> 
                    <span class="ad3-no-bet">${app.lang.common.ad3_no_bet}</span>
                    <span class="ad3-link"><a id='betano' href='https://de.betano.com' target='_blank'>${app.lang.common.ad3_button}</a></span>
                </div>
                <div class="ad3-footer">
                    <span class="ad3-logo"><img src="images/betano-white.png" /></span>
                    <span class="ad3-warning">${app.lang.common.ad3_warning}</span>
                </div>
            </div>
        `;

        this.dom.html(html)
        app.blocks.bets.updateBetanoBannerLink();
        return this;
    }
}