
class BlockSettings {
    
    render(slide = true) {
        if(!app.isAuth()) {
            app.renderPage('login');
            return ;
        }
        var avatar_url = app.getUserAvatar();
        
        let icon = '';
        if (!app.hasUserAvatar()){
            icon = `<div class="plus-circle"></div>`;
        }
        
        var league_name = '';
        if(app.config.league_system) {
            league_name = helpers.getGroupName(app.data.groups.find(x => x.id == app.user.id_group));            
        }
                
        var html = `
            <div class="block-settings">
                <div class="settings-header ta-c">
                    <div class="form-group form-group-avatar">
                        <img class="med-img-avatar img-avatar" src='${avatar_url}' alt='Avatar' onerror="app.errorUserAvatar(this)" />
                        ${icon}
                        <input type="file" name="avatar" class="file-avatar" accept=".png, .jpg, .jpeg" />
                    </div>
                    <div class="user-name">${app.user.name}</div>
                    <div class="league-name">${league_name}</div>
                </div>
                <div class="body">
                    <a class='menu' href='#edit_profile'>
                        <span>
                            <img src='images/green/settings.svg' alt='' /> 
                        </span>         
                        <span>
                            ${app.lang.common.settings.edit_profile}
                        </span>
                        <span>
                            <img src='images/green/right.svg' alt='' /> 
                        </span>
                    </a>
                    <a class='menu btn-leagues' href='javascript:void(0);'>
                        <span>
                            <img src='images/green/leagues.svg' alt='' /> 
                        </span>         
                        <span>
                            ${app.lang.common.settings.leagues}
                        </span>
                        <span>
                            <img src='images/green/right.svg' alt='' /> 
                        </span>
                    </a>
                    <a class='menu btn-notifications' href='javascript:void(0);'>
                        <span>
                            <img src='images/green/notifications.svg' alt='' /> 
                        </span>         
                        <span>
                            ${app.lang.common.settings.notifications}
                        </span>
                        <span>
                            <img src='images/green/right.svg' alt='' /> 
                        </span>
                    </a>
                    <a class='menu' href='#faq'>
                        <span>
                            <img src='images/green/faq.svg' alt='' /> 
                        </span>         
                        <span>
                            ${app.lang.common.settings.faq}
                        </span>
                        <span>
                            <img src='images/green/right.svg' alt='' /> 
                        </span>
                    </a>
                    <a class='menu btn-more' href='javascript:void(0);'>
                        <span>
                            <img src='images/green/more.svg' alt='' /> 
                        </span>         
                        <span>
                            ${app.lang.common.settings.more}
                        </span>
                        <span>
                            <img src='images/green/right.svg' alt='' /> 
                        </span>
                    </a>
                </div>
                <div class="buttons">
                    <a class='tip-button btn-logout' href='javascript:void(0);'>${app.lang.user.logout}</a>
                </div>
            </div>
        `;
        
        this.dom = $(html);
        this.dom.dialog({
            slide: slide && !app.is_back_proccess,
            onClose: (closeOptions = {}) => {
                if(closeOptions.from && closeOptions.from != 'cloaseAll') {
                    app.setHashWithoutChange('home');
                }
            }
        });
        app.setHashWithoutChange('settings');
        
        this.dom.find('.btn-logout').click(() => {
            app.logout();
        });
        this.dom.find('.btn-more').click(() => {
            this.moreSettings();
        });
        this.dom.find('.btn-notifications').click(() => {
            this.notifications();
        });
        this.dom.find('.btn-leagues').click(() => {
            this.leaguesChoose();
        });
        
        var formAvatar = new Form(this.dom, {
            avatar: {type: 'avatar'}
        });
        formAvatar.fields.avatar.onChange = async () => {
            let avatar = formAvatar.getValue('avatar');
            var result = await ws.request('user/updateAvatar', {avatar});
            console.log(result, 'result')
            if(result.success) {
                app.setUser(result.user);
                eventHandler.trigger('update_profile');
                flash.success(app.lang.user.avatar_has_been_updated);                
            }else {
                flash.error(result.message);
            }
        };
        console.log(formAvatar, 'formAvatar')
    }
    
    moreSettings(slide = true) {
        if(!app.isAuth()) {
            app.renderPage('login');
            return ;
        }
        
        let links;
        
        if (config.style != 'fussballdaten') {
            links = `<a class='menu menu-second' href='#impress'>
                        <span>
                            ${app.lang.common.menu.impress}
                        </span>
                        <span>
                            <img src='images/green/right-second.svg' alt='' /> 
                        </span>
                    </a>
                    <a class='menu menu-second' href='#privacy'>
                        <span>
                            ${app.lang.common.menu.privacy}
                        </span>
                        <span>
                            <img src='images/green/right-second.svg' alt='' /> 
                        </span>
                    </a>
                    <a class='menu menu-second' href='#terms_of_use'>
                        <span>
                            ${app.lang.common.menu.terms_of_use}
                        </span>
                        <span>
                            <img src='images/green/right-second.svg' alt='' /> 
                        </span>
                    </a>`;
        } else {
            links = `<a class='menu menu-second'target='_blank' href='https://www.fussballdaten.de/impressum/'>
                        <span>
                            ${app.lang.common.menu.impress}
                        </span>
                        <span>
                            <img src='images/green/right-second.svg' alt='' /> 
                        </span>
                    </a>
                    <a class='menu menu-second' target='_blank' href='https://www.fussballdaten.de/datenschutzerklaerung/'>
                        <span>
                            ${app.lang.common.menu.privacy}
                        </span>
                        <span>
                            <img src='images/green/right-second.svg' alt='' /> 
                        </span>
                    </a>
                    <a class='menu menu-second' href='#terms_of_use'>
                        <span>
                            ${app.lang.common.menu.terms_of_use}
                        </span>
                        <span>
                            <img src='images/green/right-second.svg' alt='' /> 
                        </span>
                    </a>`;
        }
                
        var html = `
            <div class="block-settings">
                <div class="settings-header ta-c">
                    <div class="more-title">${app.lang.common.settings.more_info}</div>
                </div>
                <div class="body">
                    <a class='menu' href='#how_it_works'>
                        <span>
                            <img src='images/green/how.svg' alt='' /> 
                        </span>         
                        <span>
                            ${app.lang.common.settings.how_it_works}
                        </span>
                        <span>
                            <img src='images/green/right.svg' alt='' /> 
                        </span>
                    </a>
                    <a class='menu' href='#support'>
                        <span>
                            <img src='images/green/support.svg' alt='' /> 
                        </span>         
                        <span>
                            ${app.lang.common.settings.support}
                        </span>
                        <span>
                            <img src='images/green/right.svg' alt='' /> 
                        </span>
                    </a>
                </div>
                <div class="body2">
                    <div class="body-title">${app.lang.common.menu.legal}</div>
                    ${links}
                </div>
            </div>
        `;
        
        this.more = $(html);
        this.more.dialog({
            slide: slide && !app.is_back_proccess,
            onClose: (closeOptions = {}) => {
                if(closeOptions.from && closeOptions.from != 'cloaseAll') {
                    app.setHashWithoutChange('settings');
                }
            }
        });
        app.setHashWithoutChange('settings_more');
    }
    
    async leaguesChoose(slide = true, after_registration = false, fromPage = 'settings') {
        let leagues_html = '';
        leagues_html += `
            <div class="group-leagues system-leagues form-group ${after_registration ? 'after_registration' : ''}">
        `;

        for(var id_league of app.data.id_leagues) {
            leagues_html += `
                <div class='tab3'>
                    <div>
                        <div class='flag' style='background-image: url(${config.web_front_url}/images/leagues/${id_league}.svg)'>
                            
                        </div>
                        <div>
                            ${app.lang.common['league_name_'+id_league]}
                        </div>
                        <div>
                            <input ${app.user.leagues.indexOf(parseInt(id_league)) != -1 ? 'checked="checked"' : ''} type="checkbox" name="leagues" value="${id_league}" />
                        </div>
                    </div>
                </div>
            `;
        }
        leagues_html += '</div>';
        
        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.common.choose_leagues}</h1>
                ${after_registration ? `<h5 class="info">${app.lang.common.choose_leagues_info}</h5>` : ''}
                <div class="form form-sm form-notifications form-separators">
                    ${leagues_html}
                    <div class="buttons">
                        <a class='tip-button btn-save' href='javascript:void(0);'>${app.lang.common[after_registration ? 'take_over' : 'save']}</a>
                    </div>
                </div>
            </div>
        `;
        
        this.leagues = $(html);
        this.leagues.dialog({
            slide: slide && !app.is_back_proccess,
            onClose: (closeOptions = {}) => {
                if(closeOptions.from && closeOptions.from != 'cloaseAll' && !after_registration) {
                    app.setHashWithoutChange(fromPage);
                }
            }
        });
        app.setHashWithoutChange(after_registration ? 'leagues_after_registration' : fromPage != 'home' ? (fromPage + '_leagues') : 'leagues');
        
        this.leagues.find('.btn-save').click(async () => {
            var data = { leagues: [] };
            this.leagues.find('input:checkbox[name="leagues"]:checked').each(function() { 
                data.leagues.push($(this).val())
            });
            
            var ret = await ws.request('user/setLeagues', data);
            if(ret.success) {
                app.setUser(ret.user);
                eventHandler.trigger('update_profile');
                if(after_registration) {
                    this.afterRegistrationReminderStep(this.leagues);
                }else {
                    flash.success(app.lang.common.settings.leagues_have_been_saved);
                }
                this.leagues.hide();
            }else {
                flash.error(app.lang.common.settings.one_league_is_required);
            }
        });
    }
    
    async afterRegistrationReminderStep() {
        var html = `
            <div class="block-after-registration-reminder-step standard-dialog">
                <div class="dialog-image">
                    <img src="images/ding.svg" />
                </div>
                <div class="dialog-title">${app.lang.common.after_registration_reminder_step_title}</div>
                <div class="dialog-desc">${app.lang.common.after_registration_reminder_step_desc}</div>
                <div class="dialog-buttons">
                    <button type="button" class="tip-button btn-show">${app.lang.common.after_registration_reminder_step_btn_show}</button>
                    <button type="button" class="tip-button tip-button-transparent btn-close">${app.lang.common.after_registration_reminder_step_btn_close}</button>
                </div>
                <div class="dialog-footer-info">${app.lang.common.after_registration_reminder_step_footer_info}</div>
            </div>
        `;
        var $div = $(html);
        $div.dialog();
        
        $div.find('.btn-show').click(() => {
            this.notifications(true, true);
            $div.hide();
        });
        $div.find('.btn-close').click(() => {
            $div.hide();
            app.setHashWithoutChange('home');
        });
    }
    
    async notifications(slide = true, after_registration = false) {
        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.common.settings.notifications}</h1>
                <div class="form form-sm form-notifications form-separators">
                    <div class="box">
                        <div class="box-title">${app.lang.common.settings.push_notifications}</div>
                        <div class="form-group form-check">
                            <label>
                                <input type="checkbox" name="reminder" value="1" ${app.user.reminder == 1 ? 'checked="checked"' : ''} />
                                <span>${app.lang.user.notifi.reminder}</span>
                            </label>
                        </div>
                        <div class="form-group form-check">
                            <label>
                                <input type="checkbox" name="notifications" value="1" ${app.user.notifications == 1 ? 'checked="checked"' : ''} />
                                <span>${app.lang.user.notifi.notifications}</span>
                            </label>
                        </div>
                    </div>
                    <div class="buttons">
                        <a class='tip-button btn-save' href='javascript:void(0);'>${app.lang.common.save}</a>
                    </div>
                </div>
            </div>
        `;
        
        this.notifi = $(html);
        this.notifi.dialog({
            slide: slide && !app.is_back_proccess,
            onClose: (closeOptions = {}) => {
                if(closeOptions.from && closeOptions.from != 'cloaseAll') {
                    app.setHashWithoutChange(after_registration ? 'home' : 'settings');
                }
            }
        });
        app.setHashWithoutChange('settings_notifications');
        
        this.notifi.find('.btn-save').click(async () => {
            var data = {};
            data.reminder = this.notifi.find('input:checkbox[name="reminder"]').is(':checked') ? 1 : 0;
            data.notifications = this.notifi.find('input:checkbox[name="notifications"]').is(':checked') ? 1 : 0;
            var ret = await ws.request('user/setNotifications', data);
            if(ret.success) {
                flash.success(app.lang.common.settings.notifications_have_been_saved);
                app.setUser(ret.user);
                eventHandler.trigger('update_profile');
                this.notifi.hide();
            }else {
                flash.error(ret.error);
            }
        });
    }
}