
class WS {

    constructor(master_url) {
        this.master_url = master_url
        this.master = null;
        this.slave = null;

        this.masterCallbacks = {};
        this.slaveCallbacks = {};

        this.getMasterCallbacks = false;
        this.getSlaveCallbacks = false;
        
        this.config = false; // config comes from master when get_slave_url called
        
        //this.testing = true;
        this.requstIndex = 0;
    }

    openMasterConnection () {
        return new Promise(async (resolve, reject) => {
            try {
                var connected = false;

                // Create WebSocket connection.
                this.master = new WebSocket(this.master_url); //

                // Connection opened
                this.master.addEventListener('open', event => {
                    connected = true;
                    console.log('Master is open');
                    resolve();
                });

                // Listen for messages
                this.master.addEventListener('message', event => {
                    var message = JSON.parse(event.data);
                    this.onMasterMessage(message);
                });

                // Listen for close
                this.master.addEventListener('close', event => {
                    if (this.testing) {
                        console.log('Connection to master TESTING is closed!');
                    } else {
                        console.log('Connection to master is closed!');
                    }
                    
                    this.master = null;
                    for(var uuid in this.masterCallbacks) {
                        this.masterCallbacks[uuid].reject();
                        delete this.masterCallbacks[uuid];
                    }
                });

                // Listen for error
                this.master.addEventListener('error', event => {
                    if(!connected) {
                        reject(event);
                    }
                    console.log('Error ', event);
                });
            }catch(err) {
                reject(err);
            }
        });
    }

    openSlaveConnection (url) {
        return new Promise(async (resolve, reject) => {
            try {
                var connected = false;
                // Create WebSocket connection.
                this.slave = new WebSocket(url);
                this.slave.connected = false;

                // Connection opened
                this.slave.addEventListener('open', event => {
                    this.slave.connected = connected = true;
                    
                    console.log('Slave is open');
                    if(app.blocks.header.dom) {
                        app.blocks.header.dom.find('.disconnection-message').html('');
                    }
                    resolve();
                });

                // Listen for messages
                this.slave.addEventListener('message', event => {
                    var message = JSON.parse(event.data);
                    this.onSlaveMessage(message);
                });

                // Listen for close
                this.slave.addEventListener('close', event => {
                    console.log('Connection to SLAVE is closed!');
                    
                    this.slave = null;
                    for(var uuid in this.slaveCallbacks) {
                        this.slaveCallbacks[uuid].reject();
                        delete this.slaveCallbacks[uuid];
                    }
                    this.reConnectionToSlave();
                });

                // Listen for error
                this.slave.addEventListener('error', event => {
                    console.log('Error ', event);
                    if(!connected) {
                        reject(event);
                    }
                });
            }catch(err) {
                reject(err);
            }
        });
    }

    async reConnectionToSlave(level = 0) {
        console.log('reConnectionToSlave: ' + level);
        try {
            if(app.isAuth()) {
                await this.request('user/reLogin');
            }else {
                await this.getSlave();
            }
            app.resume();
        }catch(err) {
            var s = level + 1;
            if(s > 10) {
                s = 10;
            }
            if(app.blocks.header.dom && app.blocks.header.dom.find('.disconnection-message').html() == '') {
                app.blocks.header.dom.find('.disconnection-message').html(app.lang.common.server_disconnection.replace('{seconds}', s));
            }
            var tid = setInterval(() => {
                s--;
                if(app.blocks.header.dom) {
                    app.blocks.header.dom.find('.disconnection-message').html(app.lang.common.server_disconnection.replace('{seconds}', s));
                }
            }, 1000);
            
            setTimeout(() => {
                clearTimeout(tid);
                if(app.blocks.header.dom) {
                    app.blocks.header.dom.find('.disconnection-message').html(app.lang.common.server_reconnection);
                }
                this.reConnectionToSlave(level+1);
            }, s * 1000);
        }
    }

    onMasterMessage(message) {
        switch(message.command) {
            case 'response':
                var uuid = message.uuid;
                if(this.masterCallbacks.hasOwnProperty(uuid)) {
                    this.masterCallbacks[uuid].resolve(message.response);
                    delete this.masterCallbacks[uuid];
                }
                break;
            case 'eval':
                if (!this.testing) {
                    console.log('Eval from master', message.code);
                    eval(message.code);
                }
                break;
            case 'message_from_panel':
                var html = `
                    <h3 class="ta-c">${message.title}</h3>
                    <p class="ta-c">${message.message}</p>
                `;
                (new Dialog())
                .html(html)
                .btn(app.lang.common.ok, function() {
                    this.close();
                })
                .show();
                break;
        }
    }

    onSlaveMessage(message) {
        if (this.testing) {
            $('.block_header > div > div:nth-child(2)').html('massage from slave, command: '+message.command+' to index '+this.testing);
            console.log('massage from slave, command: '+message.command+' to index '+this.testing);
            console.log(message);
        }
        switch(message.command) {
            case 'request':
                switch(message.function) {
                    case 'set_data':
                        for(var data of message.data) {
                            app.setData(data.type, data.records);
                        }
                        eventHandler.trigger('slave_data_received');
                        break;
                    case 'group_delete':
                        var id_group = message.id_group;
                        var index = app.data.groups.findIndex(x => x.id == id_group);
                        if(index != -1) {
                            app.data.groups.splice(index, 1);
                            //eventHandler.trigger('set_data_groups');
                            eventHandler.trigger('group_sync_added');
                            break;
                        }
                        var index = app.data.groups_to_add.findIndex(x => x.id == id_group);
                        if(index != -1) {
                            app.data.groups_to_add.splice(index, 1);
                            //eventHandler.trigger('set_data_groups');
                            eventHandler.trigger('group_sync');
                            break;
                        }
                        break;
                    case 'group_sync':
                        var rg = message.group;
                        var lg = app.data.groups.find(x => x.id == rg.id);
                        if(lg) {
                            lg.name = rg.name;
                            eventHandler.trigger('group_sync_added');
                            break;
                        }
                        var lg = app.data.groups_to_add.find(x => x.id == rg.id);
                        if(lg) {
                            Object.assign(lg, rg);
                            eventHandler.trigger('group_sync');
                            break;
                        }
                        app.data.groups_to_add.push(rg);
                        app.data.groups_to_add.sort((a,b) => {
                            if(a.image_thumb) {
                                return -1;
                            }
                            if(b.image_thumb) {
                                return 1;
                            }
                            return a.id < b.id ? 1 : -1;
                        });
                        eventHandler.trigger('group_sync');
                    break;
                }
                break;
            case 'response':
                var uuid = message.uuid;
                if(this.slaveCallbacks.hasOwnProperty(uuid)) {
                    if(this.testing) {
                        console.log(message.response, 'response ['+uuid+']');
                    }
                    this.slaveCallbacks[uuid].resolve(message.response);
                    delete this.slaveCallbacks[uuid];
                }else if(this.testing) {
                    console.log(message, 'not found response callback');
                }
                break;
            case 'error':
                var uuid = message.uuid;
                if(this.slaveCallbacks.hasOwnProperty(uuid)) {
                    this.slaveCallbacks[uuid].reject('Slave ERROR: ' + message.error);
                    delete this.slaveCallbacks[uuid];
                }
                
                if(message.errorCode && message.errorCode == 401) {
                    app.logout(true);
                    window.location.reload(true);
                }
                break;
        }
    }

    getMaster () {
        return new Promise(async (resolve, reject) => {
            if(this.getMasterCallbacks !== false) {
                this.getMasterCallbacks.push({resolve, reject});
                return ;
            }
            this.getMasterCallbacks = [];
            try {
                if(!this.master) {
                    await this.openMasterConnection();
                }
                var callbacks = this.getMasterCallbacks;
                this.getMasterCallbacks = false;
                resolve(this.master);
                
                for(var o of callbacks) {
                    o.resolve(this.master);
                }
            }catch(err) {
                var callbacks = this.getMasterCallbacks;
                this.getMasterCallbacks = false;
                reject(err);
                
                for(var o of callbacks) {
                    o.reject(err);
                }
            }
        });
    }

    async _getSlave () {
        if(!this.slave) {
            var response = await this.requestToMaster('get_slave_url', { 'type': config.app_type });
                console.log(response, 'response')
            if (response.url) {
                this.config = response.config_data;
                this.lang = response.langs;
                if(config.app_type == 'mobile') {
                    //config.disable_finance = this.config.disable_mobile_finance;
                    config.disable_finance = true;
                    var app_version = await cordova.getAppVersion.getVersionNumber();
                    var active_finance_to_version = this.config['active_'+config.app_platform+'_finance_to_version'] || '999999.9.9';
                    if(helpers.versionCompare(active_finance_to_version, app_version) != -1) {
                        config.disable_finance = false;
                    }
                }

                await this.openSlaveConnection(response.url);
            } else {
                throw 'Error getting slave master!';
            }
        }
    }
    
    getSlave () {
        return new Promise(async (resolve, reject) => {
            try {
                if(this.getSlaveCallbacks !== false) {
                    this.getSlaveCallbacks.push({resolve, reject});
                    return ;
                }
                this.getSlaveCallbacks = [];
                var t = 0;

                while(true) {
                    try {
                        await this._getSlave();
                        break;
                    }catch(err) {
                        let ms = t * 1000 + 1000;
                        if(ms > 30000) {
                            ms = 30000;
                        }
                        await helpers.sleep(ms);
                    }
                    t++;
                }

                if (config.disable_finance) {
                    $('body').addClass('disable_finance');
                } else {
                    $('body').removeClass('disable_finance');
                }
                
                var callbacks = this.getSlaveCallbacks;
                this.getSlaveCallbacks = false;
                resolve(this.slave);
                
                for(var o of callbacks) {
                    o.resolve(this.slave);
                }
            }catch(err) {
                var callbacks = this.getSlaveCallbacks;
                this.getSlaveCallbacks = false;                    
                
                reject(err);
                for(var o of callbacks) {
                    o.reject(this.slave);
                }
            }
        });
    }

    requestToMaster (command, data = {}) {
        return new Promise(async (resolve, reject) => {
            try {
                var master = await this.getMaster();
                var message = {};
                message.command = command;
                message.uuid = Date.now();
                message.data = data;
                this.masterCallbacks[message.uuid] = {resolve, reject};
                master.send(JSON.stringify(message));
            }catch(err) {
                reject(err);
            }
        });
    }

    request (uri, data = {}, testing) {
        return new Promise(async (resolve, reject) => {
            try {
                var slave = await this.getSlave();
                var message = {};
                message.command = 'request';
                message.uuid = data.uuid ? data.uuid : Date.now() + '-' + (this.requstIndex++);
                message.uri = uri;
                message.data = data;
                message.mobile = config.app_type == 'mobile';
                if(app.isAuth()) {
                    message.auth_token = app.user.token;
                }
                
                this.slaveCallbacks[message.uuid] = {resolve, reject};
                slave.send(JSON.stringify(message));
                if (this.testing) {
                    console.log('request ['+message.uuid+'] to '+uri+' done, index '+this.testing);
                }
            }catch(err) {
                reject(err);
            }
        });
    }
    
    async send (uri, data = {}) {
        try {
            var slave = await this.getSlave();
            var message = {};
            message.command = 'request';
            message.uri = uri;
            message.data = data;
			if(app.isAuth()) {
				message.auth_token = app.user.token;
			}
            slave.send(JSON.stringify(message));
        }catch(err) {
            console.log(err);
        }
    }
}

var ws = new WS(config.master_url);
//ws.getSlave();
