    
class BlockTermsOfUseApprove {
    
    render(slide = true) {
        if(!app.isAuth()) {
            app.renderPage('login');
            return ;
        }
        
        if (this.dom) {
            return;
        }
                
        var html = `
            <div class="block-terms-of-use-approve">
                <div class="">
                    <div>
                        ${app.lang.common.terms_of_use_accept_title}
                    </div>
                    <p>
                        ${app.lang.common.terms_of_use_accept_message}
                    </p>
                    <br /><br />
                </div>
                <div class="buttons">
                    <a class='tip-button btn-accept'>${app.lang.user.accept}</a>
                    <a class='tip-button tip-button-transparent btn-reject'>${app.lang.user.reject}</a>
                </div>
            </div>
        `;
        
        this.dom = $(html);
        this.dom.dialog({
            no_close: true,
            slide: slide && !app.is_back_proccess,
            onClose: (closeOptions = {}) => {
                this.dom = false;
            }
        });
        
        this.dom.find('.btn-accept').click(() => {
            app.acceptTermsOfUseAcceptance();
            $(this.dom).data('dialog').close();
        });
        
        this.dom.find('.btn-reject').click(() => {
            $(this.dom).data('dialog').close();
            this.renderRejectedTermsOfUseAcceptance();
        });
    }
    
    renderRejectedTermsOfUseAcceptance(slide = true) {
        if(!app.isAuth()) {
            app.renderPage('login');
            return ;
        }
        
        if (this.dom) {
            return;
        }
        
        var html = `
            <div class="block-terms-of-use-approve">
                <div class="">
                    <div>
                        ${app.lang.common.terms_of_use_accept_title2}
                    </div>
                    <p>
                        ${app.lang.common.terms_of_use_accept_message2}
                    </p>
                    <br /><br />
                </div>
                <div class="buttons">
                    <a class='tip-button btn-accept'>${app.lang.user.accept2}</a>
                    <a class='tip-button tip-button-transparent btn-reject'>${app.lang.user.reject2}</a>
                </div>
            </div>
        `;
        
        this.dom = $(html);
        this.dom.dialog({
            no_close: true,
            slide: slide && !app.is_back_proccess,
            onClose: (closeOptions = {}) => {
                this.dom = false;
            }
        });
        
        this.dom.find('.btn-accept').click(() => {
            app.acceptTermsOfUseAcceptance();
            $(this.dom).data('dialog').close();
        });
        
        this.dom.find('.btn-reject').click(() => {
            app.acceptTermsOfUseAcceptance(2);
            $(this.dom).data('dialog').close();
        });
    }
}