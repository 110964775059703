
var Langs = Langs || {};
Langs.en = Langs.en || {};

Langs.en.user = {
    hello: 'Hallo',
    hi: 'Hi',
    login_info: 'Log in with your data or use <span class="social-services">Facebook Login</span>',
    not_member_yet: 'Not a member yet?',
    join_now: 'Join Now',
    login: 'Log in',
    email: 'Email',
	user_name: 'User name',
    password: 'Password',
    register: 'Register',
    register_info: 'Register info text',
    you_have_account: 'You have an account',
    create_account: 'Create Account',
    forgot_password: 'Forgot Password?',
    reset_password: 'Reset password',
    reset_password_info: 'Reset password info',
    set_reset_password: 'Set new password',
    set_reset_password_info: 'Set new password info',
    url_incorrect: 'Url is incorrect',
    url_is_old: 'Url is old',
    edit_profile: 'Edit profile',
    change_login: 'Chaneg user name',
    change_password: 'Change password',
    change_email: 'Change email',
    logout: 'Log out',
    delete_account: 'Delete account',
    confirm_delete_account: 'Confirm delete account',
    confirm_delete_account_info: 'Confirm delete account info text',
    incorrect_avatar_file: 'Incorrect avatar file!',
    continue_with_facebook: 'Continue with Facebook',
    other_login_forms: 'or',
    invalid_recaptcha: 'Invalid recaptcha',
    notifications: '<b>Notifications</b>',
    start_playing: 'Start playing',
    email_unsubscribe: 'Unsubscribe',
    support_and_feedback: 'Support & Feedback',
    support_and_feedback_info: 'Do you have a question or want to give us feedback? Then write us a message and we will get in touch with you.',
    subject: 'Subject',
    message: 'Your message',
    support_message_title: 'Support Message',
    send_message: 'Send Message',
    send_message_error_text: 'Something went wrong with your input. Please check your input.',
    send_message_success_title: 'Message sent.',
    send_message_success_text: 'Thank you for your message, we will get back to you as soon as possible.',
    sender_name: 'Sender',
    sender_email: 'Sender E-mail'
};