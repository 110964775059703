
class BlockInviteFriends {
    constructor(dom) {
        this.dom = dom;
        this.log_dialog = null;

        eventHandler.observe('block_invite_friends', ['language_change'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    async render(params) {
        if (!this.dom) return;

        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.common.invite_friends}</h1>
                <div class='block_invite_friends bl bl20'>
                    <div class="app-title">${app.lang.common.app_invite_title}</div>
                    <div class="app-desc">${app.lang.common.app_invite_desc}</div>
                    <div class="send-ticket-with">${app.lang.common.send_ticket_with}</div>
                    <div class="share-buttons">
                        <div class="btn-whatsapp"></div>
                        <div class="btn-share"></div>
                    </div>
                    <button type="button" class="btn-black btn-finish">${app.lang.common.finished}</button>
                </div>
            </div>
        `;

        this.dom.html(html);

        this.dom.find('.btn-whatsapp').click(this.whatsapp.bind(this));
        this.dom.find('.btn-share').click(this.share.bind(this));

        this.dom.find('.btn-finish').click(function() {
            app.renderPage('home', {id: group.id});
            return false;
        });

        return this;
    }

    async logError(message) {
        if(this.log_dialog) {
            this.log_dialog.close();
        }
        this.log_dialog = new Dialog(); 
        this.log_dialog
            .html(message)
            .btn(app.lang.common.close, () => {
                this.log_dialog.close();
                this.log_dialog = null;
            })
            .show();
    }
    
    getShareMessage() {
        var msg = config.app_type == 'mobile' 
                ? app.lang.common.share_message_app
                : app.lang.common.share_message_web;
        var web_url = config.app_type == 'mobile' 
                ? config.web_front_url
                : window.location.origin + window.location.pathname;
        var app_url = 'https://play.google.com/store/apps/details?id=com.worldtipsterleague.android';
        
        if(config.share_url) {
            web_url = config.share_url;
        }
        
        msg = msg.replace('{web_url}', web_url);
        msg = msg.replace('{app_url}', app_url);
        
        return msg;
    }

    async whatsapp() {
        var self = this;
        
        if (config.app_type == 'mobile') {
            var options = {
                message: this.getShareMessage(),
                subject: app.lang.common.share_title,
                url: ''
            };
            
            var onSuccess = function(result) {
                
            };

            var onError = function(msg) {
                self.logError("Sharing failed with message: " + msg);
            };
            
            window.plugins.socialsharing.shareViaWhatsApp(options.message, null /* img */, options.url, onSuccess, onError);
        }else {
            window.open('whatsapp://send?text='+encodeURIComponent(this.getShareMessage()));
        }
    } 

    async share() {
        var self = this;
        
        if (config.app_type == 'mobile') {
            var options = {
                message: this.getShareMessage(),
                subject: app.lang.common.share_title,
                url: ''
            };
            
            var onSuccess = function(result) {
                
            };

            var onError = function(msg) {
                this.logError("Sharing failed with message: " + msg);
            };
            
            window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
        }else {
            if (navigator.share === undefined) {
                self.logError('Error: Unsupported feature: navigator.share');
                return;
            }

            const title = app.lang.common.share_title;
            const text = this.getShareMessage();
            const url = '';
            try {
                await navigator.share({title, text});
            } catch (error) {
                //this.logError('Error sharing: ' + error);
            }
        }
    } 
}