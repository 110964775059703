
class Dialog {

    constructor(options = {}) {
        this.$wrapper = $('<div/>').addClass('dialog-wrapper').hide().appendTo('body');
        this.$dialog = $('<div/>').addClass('dialog').appendTo(this.$wrapper);
        this.$content = $('<div/>').addClass('dialog-content').appendTo(this.$dialog);
        this.$footer = $('<div/>').addClass('dialog-footer').appendTo(this.$dialog);
        this.closeCallback = null;
        this.onClosed = null;

        if(options.hasOwnProperty('wrapper_dark') && options.wrapper_dark) {
            this.$wrapper.addClass('wrapper-dark');
        }
        
        if(options.hasOwnProperty('dialog_class')) {
            this.$dialog.addClass(options.dialog_class);
        }

        var self = this;
        
        if (!options.no_close) {
            this.addCloseBtn();
        
            this.$wrapper.click(function(e) {
                if(e.target == self.$wrapper[0]) {
                    self.close({from: 'wrapper'});
                }
            });
        }
        
        this.is_closed = false;
        if(options.hasOwnProperty('fixed') && options.fixed) {
            
        }else {
            dialogs.list.push(this);
        }
    }

    addCloseBtn() {
        var $div = $('<div/>').addClass('dialog-close-btn').html('<img src="images/green/close.svg" />').appendTo(this.$dialog);
        $div.click(() => {
            this.close({from: 'button'});
        });
    }

    content() {
        return this.$content;
    }

    html(html) {
        this.$content.html(html);
        return this;
    }

    btn(html, callback, options = {}) {
        var $btn = $('<button/>').addClass('tip-button').html(html).appendTo(this.$footer);
        if(options.black || (app && html == app.lang.common.ok) && !options.sportde) {
            //$btn.addClass('btn-black');
        }
        if(options.sportde) {
            //$btn.addClass('btn-sportde');
        }
        $btn.click(callback.bind(this));
        return this;
    }
    
    btnText(html, callback, options = {}) {
        var $btn = $('<div/>').addClass('tip-button tip-button-transparent tip-button-border').html(html).appendTo(this.$footer);
        $btn.click(callback.bind(this));
        return this;
    }

    show() {
        this.$wrapper.show();
        $('body').addClass('no-scroll');
        return this;
    } 

    close(options = {}) {
        if(this.is_closed) {
            return ;
        }
        if(this.closeCallback) {
            this.closeCallback(options);
        }
        $('body').removeClass('no-scroll');
        this.$wrapper.remove();
        this.is_closed = true;
        if(this.onClosed) {
            this.onClosed(options);
        }
    }
    
    backBtn(callback) {
        this.$dialog.find('.dialog-back-btn').remove();
        if(callback) {
            var $div = $('<div/>').addClass('dialog-back-btn').html('<img src="images/arrow-back-gray.svg" />').appendTo(this.$dialog);
            $div.click(callback);
        }
    }
}

var dialogs = {
    list: [],
    count: function() {
        var i = 0;
        for(var d of dialogs.list) {
            if(!d.is_closed) {
                i++;
            }
        }
        return i;
    },
    countBlocks: function() {
        var i = 0;
        for(var d of dialogs.list) {
            if(!d.is_closed && d.content().find('.block_container').length > 0) {
                i++;
            }
        }
        return i;
    },
    cloaseAll: function() {
        for(var d of dialogs.list) {
            d.close({from: 'cloaseAll'});
        }
        dialogs.list = [];
    },
    alert: function(message) {
        var dialog = new Dialog();
        dialog
            .html(message)
            .btn(app.lang.common.ok, () => {
                dialog.close();
            })
            .show();
    },
    confirm: function(message, callbackYes, callbackNo, desc = '') {
        var html = `
            <div class="block-after-registration-reminder-step standard-dialog">
                <div class="dialog-image">
                    <img src="images/warning.svg" />
                </div>
                <div class="dialog-title">${message}</div>
                <div class="dialog-desc">${desc}</div>
                <div class="dialog-buttons">
                    <button type="button" class="tip-button tip-button-red btn-yes">${app.lang.common.yes}</button>
                    <button type="button" class="tip-button tip-button-transparent btn-no">${app.lang.common.no}</button>
                </div>
            </div>
        `;

        var $div = $(html);
        $div.dialog();
        $div.find('.btn-yes').click(() => {
            if(callbackYes) {
                callbackYes();
            }
            $div.hide();
        });
        $div.find('.btn-no').click(() => {
            if(callbackNo) {
                callbackNo();
            }
            $div.hide();
        });
    },
    choose: function(title, options, callback, selected) {
        var html = `<h4>${title}</h4>`;
        html += `<div class="choose">`;
        for(var k in options) {
            let id, label;
            if (typeof options[k] === 'object') {
                id = options[k].value;
                label = options[k].label;
            } else {
                id = k;
                label = options[k];
            }
            
            html += `<div class="item" data-key="${id}">
                <label>
                    <input ${id == selected ? 'checked="checked"' : ''} type="checkbox" value="${label}" />
                        ${label}
                </label>
            </div>`;
        }
        html += `</div>`;
        var dialog = new Dialog({dialog_class: 'choose-dialog'});
        dialog
            .html(html)
            .show();
        
        dialog.content().find('.item').click(function() {
            dialog.content().find('.item').removeClass('selected');
            $(this).addClass('selected');
            callback($(this).data('key'));
            dialog.close();
        });
    },
    
    leagues(selected = [], callback = null, id_leagues = null, title = null, one = false, info = null) {
        if(!selected) {
            selected = [];
        }
        if(!title) {
            title = app.lang.common.choose_leagues;
        }
        if(!info && info !== '') {
            info = app.lang.group.select_leagues_for_bet;
        }
        if(!id_leagues) {
            id_leagues = app.data.id_leagues;
        }
        selected = selected.map(x => parseInt(x));
        let leagues_html = '';
        leagues_html += `
            <div class="group-leagues form-group">
                <h4 class="group-leagues-title">${title}</h4>
                <h5 class="info">${info}</h5>
        `;

        for(var id_league of id_leagues) {
            leagues_html += `
                <div class='tab3'>
                    <div>
                        <div class='flag' style='background-image: url(${config.web_front_url}/images/leagues/${id_league}.svg)'>
                            
                        </div>
                        <div>
                            ${app.lang.common['league_name_'+id_league]}
                        </div>
                        <div>
                            <input ${selected.indexOf(parseInt(id_league)) != -1 ? 'checked="checked"' : ''} type="checkbox" name="leagues" value="${id_league}" />
                        </div>
                    </div>
                </div>
            `;
        }
        leagues_html += '</div><br />';
        
        var html = `
            <div class="block-form">
                <h1 class="page-title">${title}</h1>
                <div class="form form-sm form-separators">
                    ${leagues_html}
                    ${one 
                        ? '' 
                        : `
                            <div class="buttons">
                                <a class='tip-button btn-save' href='javascript:void(0);'>${app.lang.common.save}</a>
                            </div>
                        `
                    }
                </div>
            </div>
        `;
        
        var $leagues = $(html);
        $leagues.dialog();
        if(one) {
            $leagues.find('input:checkbox[name="leagues"]').click(function() {
                var id = parseInt($(this).val());
                $leagues.find('input:checkbox[name="leagues"]:checked').prop('checked', false);
                $(this).prop('checked', true);
                if(callback) {
                    callback(id);
                }
                $leagues.dialog().close();
            });
        }else {
            $leagues.find('.btn-save').click(async () => {
                var ids = [];
                $leagues.find('input:checkbox[name="leagues"]:checked').each(function() { 
                    ids.push(parseInt($(this).val()));
                });
                if(callback) {
                    callback(ids);
                }
                $leagues.dialog().close();
            });
        }
    }
};

jQuery(function ($) {
    $.fn.isDialog = function() {
        return !!$(this).data('dialog');
    };
    $.fn.dialog = function(options = {}) {
        if($(this).data('dialog')) {
            return $(this).data('dialog');
        }
        var isMobile = window.innerWidth < app.breakPoint;
        var $parent = $(this).parent();
        var dialog_options = Object.assign({wrapper_dark: true, dialog_class: 'dialog-for-block'}, options);
        
        var dialog = new Dialog(dialog_options);
        var slide = typeof options.slide == 'undefined' ? true : options.slide;
        dialog
            .content().append(this); 
        $(this).show();
        
        if(isMobile) {
            if(slide) {
                dialog.$wrapper.css({
                    overflow: 'hidden'
                });
                dialog.$dialog.css({
                    opacity: '0'
                });
                dialog.show();
                dialog.$dialog.css({
                    opacity: 1,
                    bottom: (-1 * dialog.$dialog.height()) + 'px'
                });
                dialog.$dialog.animate({
                    bottom: 0
                }, () => {
                    dialog.$wrapper.css({
                        overflow: 'auto'
                    });
                });
            }else {
                dialog.show();
            }
        }else {
            if(slide) {
                dialog.$wrapper.css({
                    overflow: 'hidden'
                });
                dialog.$dialog.css({
                    opacity: '0'
                });
                dialog.show();
                dialog.$dialog.css({
                    opacity: 1,
                    top: window.innerHeight + 'px'
                });
                dialog.$dialog.animate({
                    top: '100px'
                }, () => {
                    dialog.$wrapper.css({
                        overflow: 'auto'
                    });
                });
            }else {
                dialog.show();
            }
        }
        
        $(this).data('dialog', dialog);
        $(this).attr('role', 'dialog');
        
        dialog.closeCallback = (closeOptions = {}) => {
            $(this).data('dialog', null);
            $(this).removeAttr('role');
            $(this).hide();
            if($parent.length > 0) {
                $parent.append(this);
            }
            if($(this).hasClass('block_container') && closeOptions.from != 'cloaseAll') {
                /*
                if(dialogs.count() <= 1) {
                    app.back();                    
                }else {
                    //location.hash = options.previous_page || 'home';
                    app.setHashWithoutChange(options.previous_hash || 'home');
                }
                */
                $('body').attr('data-page', options.previous_body_page || 'home');
                $('html').attr('page', options.previous_body_page || 'home');
                app.no_change_for_new_hash = true;
                window.history.back();
                setTimeout(() => {
                    app.no_change_for_new_hash = false;
                }, 100);
                
                /*
                if(closeOptions.from && closeOptions.from != 'hide') {
                    if(app.isAuth()) {
                        location.hash = 'home';                        
                    }else {
                        location.hash = 'frontpage';                        
                    }
                }*/
            }
            if(options.hasOwnProperty('onClose') && options.onClose) {
                options.onClose(closeOptions);
            }
        };
    };
    
    var _oldHide = $.fn.hide;
    $.fn.hide = function(speed, callback) {
        this.each(function() {
            if(this.getAttribute('role') == 'dialog') {
                $(this).dialog().close({from: 'hide'});
            }
        });
        _oldHide.apply(this, [speed, callback]);
        return this;
    };
});