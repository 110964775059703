class App {

    constructor() {
        //this.isMybet = window.location.pathname == '/mybet';
        this.isMybet = $('#content').attr('is_mybet') == '1';
        if(this.isMybet) {
            //session.setStorageType('session');
            $('body').addClass('web-mybet');
        }
        if(typeof config.style == 'undefined') {
            config.style = '';
        }
        
        this.resume_index = 0;
        this.resume_process = false;
        this.resume_last = 0;
        
        this.breakPoint = 767.98;
        this.breakPoint2 = 800; // for chart type: bar
        this.debug = session.get('app.debug') == true;
        this.lang = false;
        this.current_language = false;
        this.first_login = false;
        this.blocks = {};
        this.data = {
            scores: [],
            trends: [],
            bets: [],
            groups: [],
            groups_to_add: [],
            group_hiddens: [],
            tutorial: [],
            max_division: null,
            user_division: null,
            groups_history: [],
            division_history: [],
            groups_wins: {}
        };
        
        // Other user profile data
        this.social = false;
        
        this.live_matches = [];
        this.current_matchday_matches = [];
        this.next_matchday_matches = [];
        
        this.current_page = false;
        this.current_params = {};
        this.previous_page = false;
        this.previous_params = {};
        this.go_after_login = false;
        this.go_after_login_params = null;
        this.show_group_on_frontpage = false;
        this.layout_rendered = false;
        this.current_round = false;
        this.mobile = false;
        this.backCallback = null;
        this.match_started_dialog = null;
        this.is_back_proccess = false;
        
        
        var group = this.getGroupFromHash();     
        this.groupid_register_user = '';
        if (group) {
            if (this.getGroupData(group)) {
                this.groupid_register_user = group;
                this.go_after_login = 'join_to_group';
                this.go_after_login_params = {c: group};
            }
        }
        
        this.testConnections = [];
        
        this.config = { }; // config is loaded on ws.getSlave(). Variables can be set on master on "get_slave_url".

        if('ontouchstart' in document.documentElement || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            this.mobile = true;
            $('body').addClass('mobile');
        }
        
        if (!this.mobile) {
            //$('body').removeClass('preload');
        }
        
        // Calculate time zone offset
        this.timezone_offset = 0;
        
        eventHandler.observe('app', ['slave_data_received'], () => {
            if (!this.layout_rendered) {
                this.renderLayout();
                this.hashChange();
                
                if(this.mobile) {
                    $('.block_container:not(.native-scroll):not(.no-scroll), .mobile_overflow_x').css('overflow-x', 'visible');
                }
        
                if(this.mobile || config.disabled_ad_grid || !this.config.ad_header) {
                    $('body').addClass('no-grid-header');
                }
            }
        }); 
		
        eventHandler.observe('app', ['set_data_user'], (user) => {
            if (user) {
                if (!this.user) {
                    console.log('Error - get user data but there is no logged user!');
                }
                
                session.set('app.user', this.user);
                
                // [05788] Matchday summary - disable promote dialog                 
                //this.promoteDialog(); // <- it calls this.matchdaySummaryDialog();
                
                this.matchdaySummaryDialog();
                
                this.refreshSocial();
            }
        }); 
        
        eventHandler.observe('app', ['set_data_risk'], (risk) => {
			this.user.risk = risk;
            session.set('app.user', this.user);
        });
        
        eventHandler.observe('app', ['set_data_current_round'], (data) => {
            this.current_round = parseInt(data);
        });
        
        setTimeout(async () => {
            try {
                await this.loadConfig();
                this.setLanguage(this.config.force_language ? this.config.force_language : navigator.language);

                this.createFrontpage();
                await this.login();		
                await this.loadAllData();
            }catch(err) {
                console.log(err, 'err')
                await app.logout();
                window.location.reload();
            }
        }, 100);
		
        setInterval(function() {
            eventHandler.trigger('refresh_live_matches');
        }, 5000);
        
        setInterval(function() {
            app.updateIsBetable();
        }, 5000);
        
        setInterval(() => {
            this.blocks.scores.refreshTimeToStart();
        }, 60000);
        
        setTimeout(() => {
            try {
                fcm = new FCM();
                fcm.SaveToken();
            }catch(err) {
                console.log('Error: ' + err);
            }
        }, 1000);
    }

    setData(key, data) {     
        this.data[key] = data;

        switch(key) {
            case 'id_league':
                this.id_league = data;
                this.loadConfig();
                app.blocks.leagues.render();
                break;
            case 'leagues_name':
                //console.log('get leagues_name', data);
                break;
            case 'group_notifications':
                this.showGroupNotificationsDialog(data);
                break;
            case 'scores':
                this.live_matches = [];
                this.current_matchday_matches = [];
                this.next_matchday_matches = [];
        
                // Fix time zone.
                for (var match of this.data.scores) {
                    match.started += this.timezone_offset;
                    match.time += this.timezone_offset;                  
                }
                
                this.setMatchesState(this.data.scores);
                break;
            case 'updated_goals':
                eventHandler.trigger('updated_goals', data);
                return ;
            case 'updated_minutes':
                for(var live of data) {
                    for (var match of this.data.scores) {
                        if (match.id == live.id) {
                            match.elapsed = live.elapsed;
                            this.setMatchesState([match]);
                            break;
                        }
                    }
                }
                eventHandler.trigger('refresh_live_matches');
                return;
                break;
            case 'live_matches':
                for(var live of data) {
                    for (var match of this.data.scores) {
                        if (match.id == live.id) {
                            let refresh_scores_block = false;
                            if(!helpers.isLive(match) && helpers.isLive(live)) {
                                this.matchStartedDialog();
                                refresh_scores_block = true;
                            }
                            
                            // Is match over?
                            if(helpers.isLive(match) && !helpers.isLive(live)) {
                                refresh_scores_block = true;
                            }
                            
                            match.team1.score = live.goals_home_team;
                            match.team2.score = live.goals_away_team;
                            match.team1.display_score = live.display_goals_home_team;
                            match.team2.display_score = live.display_goals_away_team;
                            match.finished = live.finished;
                            match.started = live.started + this.timezone_offset;
                            match.status_short = live.status_short;
                            match.elapsed = live.elapsed;
                            match.time = live.event_date + this.timezone_offset;
                            this.setMatchesState([match]);
                            
                            if (refresh_scores_block) {
                                this.blocks.scores.render();
                                this.blocks.scores.scrollToCurrentMatchday();
                            }
                            
                            eventHandler.trigger('refresh_live_matches');
                            break;
                        }
                    }
                }
                
                return;
                break;
            case 'groups_history':
                if (this.user) {
                    this.user.groups_history = app.data.groups_history;
                    this.setRoundScores(this.user);
                }
                break;
            case 'division_history':
                if (this.user) {
                    this.user.division_history = app.data.division_history;
                }
                break;
            case 'groups_wins':
                if (this.user) {
                    this.user.groups_wins = app.data.groups_wins;
                }
                break;
            case 'user':
                for (var mkey in data) {
                    this.user[mkey] = data[mkey];
                }
                /*
                if(this.user.leagues.indexOf(this.user.id_league) == -1) {
                    app.loadAllData(this.user.leagues[0]);
                    return ;
                }
                */
                //console.log('user data', data);
                
                if (this.user && app.data.division_history) {
                    var row = app.data.division_history.find(x => x.season == app.config.current_season);
                    if(row) {
                        row.division = data.division ? data.division : 100;
                        row.score = data.division_score;
                        row.position = data.division_user_position;
                    }
                }
                break;
            case 'groups':
                if (this.user && app.data.groups_history) {
                    for(var group of data) {
                        if(group.division == 0) {
                            var id_group = group.id == 'overall_ranking' ? 0 : group.id;
                            var row = app.data.groups_history.find(x => x.round == app.config.current_round && x.id_group == id_group );
                            if(row) {
                                row.name = group.name;
                                row.score = this.user.score;
                                row.position = group.position;
                                row.division = group.division;
                            }
                        }
                    }
                }
                break;
            case 'current_round':
                this.current_round = parseInt(data);
                break;
            case 'show_app_review':
                this.showAppReviewDialog();
                break;
        }
        
        eventHandler.trigger('set_data_'+key, data);
    }
    
    setRoundScores(user) {
        user.round_scores = {};
        var last_scores = {};
        for(var row of user.groups_history) {
            var id_group = parseInt(row.id_group);
            var round = parseInt(row.round);
            if(!user.round_scores.hasOwnProperty(id_group)) {
                user.round_scores[id_group] = {};
            }
            if(!last_scores.hasOwnProperty(id_group)) {
                last_scores[id_group] = 0;
            }
            var score = row.score - last_scores[id_group];
            last_scores[id_group] = row.score;
            user.round_scores[id_group][round] = score;
        }
    }
    
    updateIsBetable() {
        var now = Date.now();
        for (var match of this.current_matchday_matches) {
            var is_betable = match.time - parseInt(app.config.time_offset_before_start) * 60000 > now;
            if (match.is_betable != is_betable) {
                match.is_betable = is_betable;
                this.blocks.scores.refreshGame(match);
            }
        }
    }

    matchStartedDialog() {
        if(this.match_started_dialog) {
            this.match_started_dialog.close();
        }
        this.match_started_dialog = new Dialog(); 

        var html = '';
        html += `<div class='center'>`;
        html += `<lottie-player src="animations/${config.style}/ball-animation.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"    autoplay></lottie-player>`;
        html += `</div>`;
        html += `<div class='pr_message'>`+app.lang.group.start_match_message+'</div>';

        this.match_started_dialog
            .html(html)
            .btn(this.lang.common.ok, () => {
                if(app.current_page == 'group') {
                    app.blocks.group.refreshData();
                }
                this.match_started_dialog.close();
            }, {black: false, sportde: true})
            .show();
    }
    
    setMatchesState(matches, source) {
        if(!source) {
            source = app;
        }
        var now = Date.now();
        for (var match of matches) {              
            // Time of the game +1 minute
            match.timer = helpers.getMatchTimer(match);
            
            // Is user still can bet?
            match.is_betable = match.time - app.config.time_offset_before_start * 60000 > now;            
            
            // Is it a live match?
            if (match.is_live = helpers.isLive(match)) {
                this.addMatchToArray([match], source.live_matches);
            } 
            
            // Is it a finished match?
            if (match.is_finished = helpers.isCompleted(match)) {
                /*this.removeMatchFromArray([match], this.live_matches);*/
            }
            
            if (match.is_overtime = helpers.isOvertime(match)) {
                
            }
            
            if (match.is_current_matchday = (match.matchday == app.getCurrentMatchday(source))) {
                this.addMatchToArray([match], source.current_matchday_matches);
            }
            if (match.is_next_matchday = match.matchday == (app.getCurrentMatchday(source) + 1)) {
                this.addMatchToArray([match], source.next_matchday_matches);
            }
            
            // Can we go click and to bet screen?
            match.is_clickable = match.options[0].points !== null && (match.is_current_matchday || match.is_next_matchday); // match.options[0].points !== null; // All games have link even if we can't realy bet
            match.has_bet = source.data.bets[match.id] !== undefined;
            
            if (match.has_bet) {
                match.points = 0;
                match.label = '';
                
                switch(parseInt(this.data.bets[match.id])) {
                    case 0: if (match.team1.score - match.team2.score >= 2) { match.points = match.options[0].points; }; match.label = `1 <img src="images/${config.style ? config.style + '/' : 'sportde-'}thunder-bet.svg">`; break;
                    case 1: if (match.team1.score - match.team2.score >= 1) { match.points = match.options[1].points; }; match.label = '1'; break;
                    case 2: if (match.team1.score - match.team2.score == 0) { match.points = match.options[2].points; }; match.label = 'X'; break;
                    case 3: if (match.team1.score - match.team2.score <= -1) { match.points = match.options[3].points; }; match.label = '2'; break;
                    case 4: if (match.team1.score - match.team2.score <= -2) { match.points = match.options[4].points; }; match.label = `2 <img src="images/${config.style ? config.style + '/' : 'sportde-'}thunder-bet.svg">`; break;
                }
            }else {
                match.points = 0;
                match.label = '';
            }
            
            /*this.blocks.scores.refreshGame(match);
            this.blocks.bets.refreshGame(match);*/
        }
    }
    
    addMatchToArray(matches, destination) {
        if (matches) {
            for (var match of matches) {
                var exists = false;
                for (var c of destination) {
                    if (match.id == c.id) {
                        exists = true;
                        break;
                    }
                }
                
                if (!exists) {
                    destination.push(match)
                }
            }
        }
    }
    
    // To nie dziaÄąâ€ša
    removeMatchFromArray(matches, destination) {
        if (matches) {
            var tmp = [];
            for (var c of destination) {
                var exists = false;
                for (var match of matches) {
                    if (match.id == c.id) {
                        exists = true;
                        break;
                    }
                }
                
                if (!exists) {
                    tmp.push(match)
                }
            }
            
            destination = tmp;
        }
    }

    renderLayout() {
        var html = `
            <div class='topbar'></div>
            <div id='main'>
                <div id='leagues' class='block_container'></div>
                <div id='user' class='block_container'></div>
                <div id='social_leagues' class='block_container'><!-- other leagues --></div>
                <div id='social_user' class='block_container'><!-- other user --></div>
                <div id='scores' class='block_container'></div>
                <div id='social_scores' class='block_container'><!-- other user --></div>
                <div id='divisions' class='block_container'></div>
                <div id='bets' class='block_container'></div>
                <div id='group_page' class='block_container'>
                    <div id='ad1'></div>
                    <div id='group_ad'>
                        <div id='group'></div>
                        <div id='ad2'></div>
                    </div>
                </div>
                <div id='groups' class='block_container'></div>
                <div id='social_groups' class='block_container'><!-- other user --></div>
                <div id='sponsored_challenge' class='block_container'></div>
                <div id='charts' class='block_container'></div>
                <div id='social_charts' class='block_container'><!-- other user --></div>
                <div id='tutorial' class='block_container'></div>
                
                <div id='home_ad' class='block_container'></div>
                <div id='1ad_left'></div>
                <div id='1ad_right'></div>
            </div>            
            <div id='footer'></div>
        `;
        $('body #content').html(html);
        $('body #content_grid').prepend(`<div id='header'></div>`);
        
        html = `
            <div class='header'>
                
            </div>
            <div class='topbar'>

            </div>
            <div id='content_dialog_main'>
                <div id='login' class='block_container'></div>
                <div id='register' class='block_container'></div>
                <div id='reset_password' class='block_container'></div>
                <div id='set_reset_password' class='block_container'></div>
                <div id='edit_profile' class='block_container'></div>
                <div id='support' class='block_container' data-full_width="true"></div>
                <div id='contact' class='block_container' data-full_width="true"></div>
                <div id='delete_profile' class='block_container'></div>
                <div id='group_search' class='block_container' data-full_width="true"></div>
                <div id='group_add' class='block_container' data-full_width="true"></div>
                <div id='group_edit' class='block_container' data-full_width="true"></div>
                <div id='group_delete' class='block_container'></div>
                <div id='group_join' class='block_container'></div>
                <div id='group_unjoin' class='block_container'></div>
                <div id='group_invite_friends' class='block_container'></div>
                <div id='invite_friends' class='block_container'></div>            
                <div id='content_page' class='block_container' data-full_width="true" data-no_mobile_modal="true"></div>
            </div>
            <div class='footer_page rows'>
                ${this.blocks.footer.getHtml()}
            </div>
        `;
        $('body #content_dialog').html(html);
        
//        var ad_header = `<div id='ad_header'></div>`;
//        $('body').prepend(ad_header);
//
//        var ad3 = `<div id='ad3' class='block_container'></div>`;
//        $('body').append(ad3);

        $('#overlay').click(function(e) {
            e.stopPropagation();
            window.history.back();
        });

        this.blocks.header.setDom($('#header')).setDom2($('#content_dialog .header')).render();
        this.blocks.login.setDom($('#login')).render();
        this.blocks.register.setDom($('#register')).render();
        this.blocks.reset_password.setDom($('#reset_password')).render();
        this.blocks.set_reset_password.setDom($('#set_reset_password')).render();
        this.blocks.edit_profile.setDom($('#edit_profile')).render();
        this.blocks.support.setDom($('#support')).render();
        this.blocks.contact.setDom($('#contact')).render();
        this.blocks.delete_profile.setDom($('#delete_profile')).render();
        this.blocks.user.setDom($('#user')).render();
        this.blocks.social_user.setDom($('#social_user')); // for viewing other users profile
        this.blocks.social_leagues.setDom($('#social_leagues')); // for viewing other users leagues
        this.blocks.leagues.setDom($('#leagues')).render();
        this.blocks.scores.setDom($('#scores')).render();
        this.blocks.social_scores.setDom($('#social_scores')); // for viewing other users profile
        this.blocks.bets.setDom($('#bets')).render();;
        this.blocks.groups.setDom($('#groups'), $('#group')).render();
        //this.blocks.social_groups.setDom($('#social_groups')); // for viewing other users profile
        this.blocks.charts.setDom($('#charts')).render();
        this.blocks.social_charts.setDom($('#social_charts')); // for viewing other users profile
        
        
        this.addAds();
        
        this.blocks.group.setDom($('#group'));
        this.blocks.group_search.setDom($('#group_search'));
        this.blocks.group_add.setDom($('#group_add'));
        this.blocks.group_edit.setDom($('#group_edit'));
        this.blocks.group_delete.setDom($('#group_delete'));
        this.blocks.group_join.setDom($('#group_join'));
        this.blocks.group_unjoin.setDom($('#group_unjoin'));
        this.blocks.group_invite_friends.setDom($('#group_invite_friends'));
        this.blocks.sponsored_challenge.setDom($('#sponsored_challenge'));
        //this.blocks.tutorial.setDom($('#tutorial')).render();
        this.blocks.content_page.setDom($('#content_page')).render();
        this.blocks.divisions.setDom($('#divisions')).render();
        this.blocks.invite_friends.setDom($('#invite_friends')).render();
    
        const ptr = PullToRefresh.init({
            mainElement: '#main',
            triggerElement: '#main',
            instructionsPullToRefresh: ' ', //'Pull down to refresh',
            instructionsReleaseToRefresh: ' ', //'Release to refresh',
            instructionsRefreshing: ' ', //'Refreshing',
//            iconRefreshing: '<span class="loading"><img src="images/loading.svg" /></span>',
            iconRefreshing: '<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
            refreshTimeout: 1500,
            onRefresh() {
//                window.location.reload();
                app.resume();
            }
        });
        
        this.layout_rendered = true;
        
        
        this.$topbar = $('.topbar');
        
        return this;
    }

    createBlocks() {
        this.blocks.header = new BlockHeader();
        this.blocks.login = new BlockLogin();
        this.blocks.register = new BlockRegister();
        this.blocks.reset_password = new BlockResetPassword();
        this.blocks.set_reset_password = new BlockSetResetPassword();
        this.blocks.edit_profile = new BlockEditProfile();
        this.blocks.delete_profile = new BlockDeleteProfile();
        this.blocks.user = new BlockUser();
        this.blocks.social_user = new BlockUser(false);
        this.blocks.social_leagues = new BlockLeagues();
        this.blocks.social_leagues.social = true;
        this.blocks.leagues = new BlockLeagues();
        this.blocks.scores = new BlockScores();
        this.blocks.social_scores = new BlockScores();
        this.blocks.bets = new BlockBets();
        this.blocks.ad1 = new BlockAd1();
        this.blocks.ad2 = new BlockAd2();
        this.blocks.ad3 = new BlockAd3();
        this.blocks.home_ad = new BlockAd1();
        this.blocks.group = new BlockGroup();
        this.blocks.groups = new BlockGroups();
        this.blocks.group_add = new BlockGroupAdd();
        this.blocks.group_edit = new BlockGroupEdit();
        this.blocks.group_delete = new BlockGroupDelete();
        this.blocks.group_join = new BlockGroupJoin();
        this.blocks.group_unjoin = new BlockGroupUnjoin();
        this.blocks.group_invite_friends = new BlockGroupInviteFriends();
        this.blocks.sponsored_challenge = new BlockSponsoredChallenge();
        this.blocks.charts = new BlockCharts();
        this.blocks.social_charts = new BlockCharts();
        this.blocks.social_charts.social = true;
        this.blocks.group_search = new BlockGroupSearch();
        //this.blocks.tutorial = new BlockTutorial();
        this.blocks.content_page = new BlockContentPage();
        this.blocks.divisions = new BlockDivisions();
        this.blocks.invite_friends = new BlockInviteFriends();
        this.blocks.footer = new BlockFooter();
        this.blocks.settings = new BlockSettings();
        this.blocks.terms_of_use_approve = new BlockTermsOfUseApprove();
        this.blocks.support = new BlockSupport();
        this.blocks.contact = new BlockSupport(null, false);
    }
    
    addAds() {
        if(!config.disable_finance) {
            if(!app.mobile && !config.disabled_ad_grid) {
                let $ad_header = $(app.config.ad_header);

                $('#ad_header').html($ad_header);
                $('#content_left').html(app.config.ad_left);
                $('#content_right').html(app.config.ad_right);
            
                // Set header top to get space for header ad
                document.documentElement.style.setProperty('--ad_header_height', parseInt(app.config.ad_header_height)+'px');
                document.documentElement.style.setProperty('--ad_header_height_negative', app.config.ad_header_height ? '-80px' : 0);
            }
            
            //this.blocks.ad1.setDom($('#ad1')).render();
            //this.blocks.ad2.setDom($('#ad2')).render();
            if(!app.config.bets_sum_block) {
                this.blocks.ad3.setDom($('#ad3')).render();
            }
            
            // Footer ad
            this.blocks.home_ad.setDom($('#home_ad')).render(); 
            
        }
    }

    setHashWithoutChange(new_hash) {
        app.no_change_for_new_hash = true;
        location.hash = new_hash;
        
        var tab = location.hash.split('?');
        var name = tab[0].substring(1, 999);
        var params = new URLSearchParams(tab[1] || '');
        app.current_hash = new_hash;
        app.current_page = name;
        app.current_params = params;
        
        setTimeout(() => {
            app.no_change_for_new_hash = false;
        }, 100);
    }
    
    async hashChange() {
        if(app.no_change_for_new_hash) {
            return ;
        }
        var tab = location.hash.split('?');
        var name = tab[0].substring(1, 999);
        var params = new URLSearchParams(tab[1] || '');
        await this._renderPage(name, params);
        
        if (this.callback_hash_resolve) {
            this.callback_hash_resolve();
            this.callback_hash_resolve = null;
        }
    }

    renderPageNoHistory(name, params = {}) {
        return new Promise(async (resolve, reject) => {
            var p = new URLSearchParams($.param(params));
            await app._renderPage(name, p);
            
            resolve();
        });
    }

    renderPage(name, params = null, force_hash_change = false) {
        return new Promise((resolve, reject) => {
			var new_hash = '#'+name + (params ? '?' + $.param(params) : '');
			this.callback_hash_resolve = resolve;
			
			if (location.hash != new_hash) {
				location.hash = new_hash;
			} else {
				if (force_hash_change) {
					this.hashChange();
				}
			}
        });
    }

    _resetPagesBindings() {
        $('body').removeClass('scrolled');
        app.blocks.scores.dom_content.removeClass('fixed').children().removeClass('sm');
        app.blocks.divisions.dom_content.removeClass('fixed').children().removeClass('sm');
        $(window).off('scroll.block_users');
        $('html').removeAttr('page_style');
    }
    
    async back() {
        app.is_back_proccess = true;        
        if(app.previous_page == 'login' || app.previous_page == 'register') {
            await app.renderPage(app.previous_page);
            app.is_back_proccess = false;
            return ;
        }
        if(app.current_page == 'group_invite_friends' && app.previous_page != 'group') {
            await app.renderPage('group', {id: app.current_params.get('id')});
            app.is_back_proccess = false;
            return ;
        }
        if(app.current_page == 'group' && app.previous_page == 'group_invite_friends') {
            await app.renderPage('home');
            app.is_back_proccess = false;
            return ;
        }
        if(app.current_page == 'group' && app.previous_page == 'join_to_group') {
            await app.renderPage('home');
            app.is_back_proccess = false;
            return ;
        }
        if(app.current_page == 'join_to_group' && app.previous_page != 'group_search') {
            await app.renderPage('home', null, true);
            app.is_back_proccess = false;
            return ;
        }
        if(['faq', 'edit_profile'].indexOf(app.current_page) != -1) {
            await app.renderPage('settings');
            app.is_back_proccess = false;
            return ;
        }
        if(['how_it_works', 'support'].indexOf(app.current_page) != -1) {
            if(app.isAuth()) {
                await app.renderPage('settings_more');
            }else {
                await app.renderPage('frontpage');
                BlockFrontpage.menu();
            }
            app.is_back_proccess = false;
            return ;
        }
        if(app.current_page == 'group' && app.blocks.group.is_division_group && !app.blocks.group.start_from_other_division) {
            await app.blocks.group.render(app.user.id_group);
            app.is_back_proccess = false;
            return ;
        }
        
        console.log(app.backCallback);
        if(app.backCallback) {
            console.log('backCallback');
            var r = app.backCallback();
            if(r === false) {
                app.is_back_proccess = false;
                return false;
            }
        }

        if (!app.previous_page) {
            await app.renderPage(app.isAuth() ? 'home' : 'login', null, true)
        } else if(app.current_page == 'group' && app.previous_page == 'create_group') {
            await app.renderPage('home', null, true);
        } else if(window.location !== window.parent.location) {
            window.parent.postMessage({type: 'backPage', command: 'back-page'}, "*");
        } else {
            //window.history.back();
            await app.renderPage(app.isAuth() ? 'home' : 'login');
        }
        app.is_back_proccess = false;
    }
    
    topbar(title = '', choose_item = '', choose_callback = null) {
        var force_pages = ['group'];
        
        if(dialogs.countBlocks() > 0 && force_pages.indexOf(this.current_page) == -1) {
            return ;
        }
        if(typeof title == 'string') {
            var go_back = '';
            if(title == '') {
                go_back = app.lang.common.go_back;
            }
            if(choose_item) {
                choose_item = `
                    <div class="topbar-separator">|</div>
                    <div class="topbar-choose">${choose_item} ${choose_callback ? `<img class="arrow-down" src="images/angle-down-solid.svg">` : ''}</div>
                `;
            }
            this.$topbar.html(`
                <div class="topbar-content">
                    <div class="back ${go_back ? 'with-text' : 'no-text'}"><img src="images/back-arrow.svg" /> ${go_back}</div>
                    ${title ? `<div class="topbar-title">${title}</div>` : ''}
                    ${choose_item}
                    <div class="topbar-free"></div>
                </div>
            `);
        }
        
        this.$topbar.off('click', '.back').on('click', '.back', app.back.bind(this));
        if(choose_callback) {
            this.$topbar.off('click', '.topbar-choose').on('click', '.topbar-choose', choose_callback);            
        }
        return this.$topbar;
    }
    
    topbarSettings(callback) {
        this.$topbar.find('.topbar-content').find('.topbar-settings').remove();
        this.$topbar.find('.topbar-content').append(`
            <div class="topbar-settings"><img src="images/topbar_settings.svg"></div>
        `);
        this.$topbar.off('click', '.topbar-settings').on('click', '.topbar-settings', callback);
    }
    
    async _renderPage(name, params) {
		if (!this.layout_rendered) {
			console.log('no layout rendered');
			return false;
		}
        
        setTimeout(() => { this.checkTermsOfUseAcceptance() }, 500);
        
        var t = this;
        this.backCallback = null;
        this.blocks.header.hideSettingsIcon();
        this.hideOverlay(); 
        this.lightTheme();
        this._resetPagesBindings();
        
        window.scrollTo(0, 0);
        document.scrollingElement.scrollTop = 0;

        this.previous_main_visible = $('#main div.block_container:visible');
        this.previous_main_topbar = $('#content .topbar .topbar-content');
        if(window.innerWidth < this.breakPoint) {
            $('#main .block_container').hide();
        }else {
            $('.block_container').hide();            
        }
        $('body').removeClass('tutorial');
        $('#content').css('padding-bottom', 0);
        //dialogs.cloaseAll();
        this.$topbar.empty().removeClass('fixed');
        
        var group = this.getGroupFromUrl();       
        
        if (group) {
            if (this.getGroupData(group)) {
                name = 'join_to_group';
                params = new URLSearchParams('c='+group);
            }
        }
        
        if(params.get('tipp_reminder_confirm') == '1') {
            (async () => {
                var tr_res = await ws.request('user/turnOnTippReminderActive', {id: params.get('id'), hash: params.get('hash')});
                if(app.user && tr_res.success) {
                    app.user.reminder = 1;
                    app.setUser(app.user);
                    eventHandler.trigger('update_profile');
                }
            })();
            if(!this.isAuth()) {
                name = 'login';
            }else {
                name = 'home';
            }
        }
        
        if(params.get('unsubscribe') == '1') {
            var tr_res = await ws.request('user/turnOffTippReminder', {id: params.get('id'), hash: params.get('hash')});
            console.log('unsubscribe', tr_res);
            if(tr_res.success) {
                params.append('unsubscribed_email', tr_res.email);
                if(app.user) {
                    app.user.reminder = 0;
                    app.setUser(app.user);
                    eventHandler.trigger('update_profile');
                }
                name = 'unsubscribed';
            }else {
                if(!this.isAuth()) {
                    name = 'login';
                }else {
                    name = 'home';
                }
            }
        }
        
        var public_groups = ['', 'menu', 'register', 'login', 'reset_password', 'set_reset_password', 'frontpage', 'unsubscribed'];
        if(!this.isAuth() && public_groups.indexOf(name) === -1 && !this.lang.common.menu[name]) {            
            this.go_after_login = name;
            this.go_after_login_params = null;
            if(params) {
                params.forEach(function(value, key) {
                    if(!t.go_after_login_params) {
                        t.go_after_login_params = {};
                    }
                    t.go_after_login_params[key] = value;
                });
            }

            name = group ? 'frontpage' : ( app.getUserCookie('rt90_register') == '1' ? 'login' : 'register' );
            if(this.go_after_login == 'edit_profile') {
                name = 'login';
            }
        }
        
        if(this.isAuth() && ['login','register','frontpage'].indexOf(name) > -1) {
            app.renderPage('home');
            return ;
        }
        
        if (name == '') {
            name = 'home';            
        }
        
        if (name != 'frontpage' && name != 'login') {
            //$('#frontpage').hide();
            //$('#content,#main,#footer').show();
        }

        if(['register', 'login', 'reset_password', 'set_reset_password', 'frontpage', 'unsubscribed'].indexOf(name) == -1 && !this.isAuth()) {
            this.go_after_login = false;
            this.go_after_login_params = null;
        }
        
        if (typeof ga !== 'undefined' && allowGATracking !== false) {
            ga('send', 'event', 'Page', 'open', name);
        }

        $('#round_max_points').hide();
        
        if(app.previous_page == 'group') {
            app.blocks.scores.updateRoundScores(0);
        }
        
        this.previous_params = this.current_params;
        this.previous_page = this.current_page;
        this.previous_content_name = this.content_name;
        this.previous_hash = this.current_hash;
        this.previous_body_page = $('body').data('page');
        this.current_page = name;
        this.current_params = params;
        this.current_hash = location.hash;
        $('body').attr('data-page', name);
        $('html').attr('page', name);
        switch(name) {
            case 'frontpage': // showing menu handled on header.js
                $(window).off("resize.content_dialog");
                $('#frontpage,#content').show();
                $('#main,#footer').hide();
                $('#content_dialog').hide();
                $('#content_grid').hide();
                this.content_name = '';
                break;
            case 'how': 
                $('#how,#content').show();
                $('#main,#footer').hide();
                break;
            case 'menu': // showing menu handled on header.js

                break;
            case 'terms_of_use_approve':
                this.blocks.terms_of_use_approve.render();
                break;
            case 'settings_more':
            case 'leagues':
            case 'settings_leagues':
            case 'settings_notifications':
            case 'settings':
                setTimeout(() => {
                    if(name.substr(0, 8) == 'settings') {
                        app.blocks.settings.render(false);
                    }
                    if(name == 'settings_more') {
                        app.blocks.settings.moreSettings(false);
                    }
                    if(name == 'leagues') {
                        app.blocks.settings.leaguesChoose(false, false, name == 'leagues' ? 'home' : 'settings');
                    }
                    if(name == 'settings_notifications') {
                        app.blocks.settings.notifications(false);
                    }
                }, 100);
            case 'group_create_or_search':
                if(name == 'group_create_or_search') {
                    setTimeout(() => {
                        app.blocks.group_search.createOrSearchDialog(false);
                    }, 100);
                }
            
            case 'leagues_after_registration':
                if(name == 'leagues_after_registration') {
                    setTimeout(() => {
                        app.blocks.settings.leaguesChoose(true, true);
                    }, 100);
                }
            case 'home':
                $('#scores, #leagues, #groups, #charts, #user, #home_ad').show(); //
                //app.blocks.scores.scrollToMatchday(false, true);
                app.blocks.scores.scrollToCurrentMatchday();
                if(!app.blocks.charts.scrolled_to_current_round) {
                    app.blocks.charts.scrolled_to_current_round = true;
                    app.blocks.charts.scrollToCurrentRound();
                }                
                break;
            case 'profile':
                $('#scores, #charts, #user').show();
                app.blocks.scores.scrollToCurrentMatchday();
                app.blocks.charts.scrollToCurrentRound();
                app.blocks.header.onClickSettings(() => {
                    app.renderPage('edit_profile');
                });
                break;
            case 'social': // showing menu handled on header.js
                if (!this.social) {
                    this.social = session.get('app.social');
                    if (!this.social || this.social.user.login !=  params.get('login')) {
                        this.getSocial({ login: params.get('login') });
                    } else {
                        this.blocks.social_leagues.setSocial(this.social).renderTopbar();
                        this.blocks.social_user.setSocial(this.social).render();
                        this.blocks.social_charts.setSocial(this.social).rewriteChartsData().render();
                        //this.blocks.social_groups.setSocial(this.social).render();
                        this.blocks.social_scores.setSocial(this.social).render();
                    }
                }else {
                    this.blocks.social_leagues.setSocial(this.social).renderTopbar();
                }

                $('#social_user, #social_scores, #social_charts').show();
                app.blocks.social_scores.scrollToCurrentMatchday();
                app.blocks.social_charts.scrollToCurrentRound();                
                break;
            case 'bets':
                app.topbar(app.data.leagues_name[app.id_league] + ' ' + app.lang.common.bet_title);
                if (this.isAuth()) {
                    $('#bets, #ad3, #round_max_points').show();
                } else {
                    $('#bets, #groups, #ad3').show();
                }
                
                $('#home_ad').show();
                
                $('#content').css('padding-bottom', $('#ad3').outerHeight() + 'px');
                
                if (params.get('id')) {
                    // Check if the id is from current league
                    var exists = app.data.scores.find(x => x.id == params.get('id'));
                    if (!exists) {
                        for(let j in this.user.leagues) {
                            let i = this.user.leagues[j];
                            if (i == this.id_league) {
                                continue;
                            } else {
                                // Change for second league - so it works for 2 leagues only!
                                this.loadAllData(i);
                                this.blocks.leagues.selectCurrentLeague();
                                app.topbar(app.data.leagues_name[i] + ' ' + app.lang.common.bet_title);
                                break;
                            }
                        }
                    } else {
                        app.blocks.bets.scrollToMatch(params.get('id'));
                    }
                }
                break;
            case 'group':
                var id_group = params.get('id');
                var round = params.get('round');
                var id_fixture = params.get('fixture');
                var division = params.get('division');
                var position = params.get('position');
                var id_league = params.get('id_league') || app.id_league;
                app.blocks.group.render(id_group, round, id_fixture, division, position, true, null, 0, id_league);
                if(!id_group && division) {
                    app.blocks.divisions.setActivePosition(division, position);
                    app.blocks.group.start_from_other_division = true;
                }
                break;
            case 'group_search':
                app.blocks.group_search.render();
                $('#group_search').show();
                $('#group_search').find('.group-search-box').val('').trigger('keyup');
                app.topbar();
                break;
            case 'create_group_step_by_step':
                this.blocks.group_add.renderSteps();
                $('#group_add').show();
                break;
            case 'create_group':
                this.blocks.group_add.render();
                $('#group_add').show();
                this.topbar();
                break;
            case 'group_edit':
                this.blocks.group_edit.render(params);
                $('#group_edit').show();
                this.topbar();
                break;
            case 'group_delete':
                this.blocks.group_delete.render(params.get('id'));
                $('#group_delete').show();
                break;
            case 'group_unjoin':
                this.blocks.group_unjoin.render(params.get('id'));
                $('#group_unjoin').show();
                break;
            case 'join_to_group':
                this.blocks.group_join.render(params);
                $('#group_join').show();
                $('#ad-banner').hide();
                break;
            case 'group_invite_friends':
                this.blocks.group_invite_friends.render(params);
                $('#group_invite_friends').show();
                $('#ad-banner').hide();
                break;
            case 'sponsored_challenge':
                this.blocks.sponsored_challenge.render(params.get('id'));
                $('#sponsored_challenge').show();
                break;
            case 'login':
                $('#login').show();
                
                if(params.get('sent_rigister_confirm') == '1') {
                    var info_dialog = new Dialog({wrapper_dark: true}); 
                    var html = '';
                    html += `<div class='pr_title'>`+app.lang.group.thanks_for_register+'</div>';
                    html += `<div class='pr_message'>`+app.lang.group.thanks_for_register_message+'</div>';

                    info_dialog
                        .html(html)
                        .btn(app.lang.group.confirm_reg_button, () => {
                            info_dialog.close();
                        }, {black: true})
                        .show();

                    $('.form-login input[name="login"]').val(params.get('login'));
                }

                if(params.get('sent_password_reset_confirm') == '1') {
                    var info_dialog = new Dialog({wrapper_dark: true}); 
                    var html = '';
                    html += `<div class='pr_title'>`+app.lang.group.password_reset_confirm+'</div>';
                    html += `<div class='pr_message'>`+app.lang.group.password_reset_confirm_message+'</div>';

                    info_dialog
                        .html(html)
                        .btn(app.lang.group.confirm_reg_button, () => {
                            info_dialog.close();
                        }, {black: true})
                        .show();
                }

                if(params.get('confirm') == '1') {
                    app.blocks.login.confirmRegister(params.get('id'), params.get('hash'), params.get('groupid'));
                }
                break;
            case 'register':
                app.blocks.register.loadScripts();
                $('#register').show();
                break;
            case 'reset_password':
                this.blocks.reset_password.render();
                $('#reset_password').show();
                break;
            case 'set_reset_password':
                this.blocks.set_reset_password.render(params);
                $('#set_reset_password').show();
                break;
            case 'edit_profile':
                $('#edit_profile').show();
                if(params.get('change_avatar') == 1) {
                    app.blocks.edit_profile.trigger_change_avatar();
                }
                break;
            case 'support':
                $('#support').show();
                break;
            case 'contact':
                $('#contact').show();
                break;
            case 'logout':
                app.logout();
                /*window.history.back();*/
                break;    
            case 'delete_profile':
                $('#delete_profile').show();
                break;
            case 'invite_friends':
                $('#invite_friends').show();
                break;
            case 'matchdaySummaryDialog':
                this.matchdaySummaryDialog(1);
                break;
            case 'after_registration':
            case 'faq':
                $('#content_page').data('full_width', true).data('no_mobile_modal', false);
                await this.blocks.content_page.setContent(name);
                $('#content_page').show();
                this.blocks.content_page.scrollTo(params.get('open'));        
                break;
            default:
                if (this.lang.common.menu[name]) {
                    //this.darkTheme();
                    $('#content_page').data('full_width', true).data('no_mobile_modal', true);
                    await this.blocks.content_page.setContent(name);
                    $('#content_page').show();
                    this.blocks.content_page.scrollTo(params.get('open'));
                }                
        }

        
        eventHandler.trigger('change_page', this.getPageWithPrevious());
        $(window).resize();
    }
    
    setContentDialog(view_mobile) {
        if(this.content_process) {
            return ;
        }
        this.content_process = true;
        this.content_name = 'dialog';
        if(1 || this.previous_content_name != 'dialog') {
            $('#content_grid').hide();
            if(typeof view_mobile == 'undefined') {
                view_mobile = window.innerWidth < this.breakPoint;
            }
            if(view_mobile) {
                $('#content_dialog').hide();
                if(app.isAuth()) {
                    if(this.previous_main_visible.length > 0) {
                        $('#content_grid').show();
                        this.previous_main_visible.show(); 
                        $('#content .topbar').empty().append(this.previous_main_topbar);
                    }else {
                        $('#frontpage').hide();
                        $('#scores, #leagues, #groups, #charts, #charts_header, #user, #home_ad, #content_grid, #main').show(); //
                        //app.blocks.scores.scrollToMatchday(false, true);
                        app.blocks.scores.scrollToCurrentMatchday();
                        if(!app.blocks.charts.scrolled_to_current_round) {
                            app.blocks.charts.scrolled_to_current_round = true;
                            app.blocks.charts.scrollToCurrentRound();
                        }                        
                    }
                }else {
                    $('#frontpage,#content').show();
                    $('#main,#footer').hide();
                }
            }else {
                $('#frontpage').hide();
                $('#content_dialog').show();
                dialogs.cloaseAll();
            }
        }
        this.content_process = false;
    }
    
    setContentGrid() {
        if(this.content_process) {
            return ;
        }
        this.content_process = true;
        dialogs.cloaseAll();
        this.content_name = 'grid';
        if(this.previous_content_name != 'grid') {
            $('#content_dialog').hide();
            $('#content_grid').show();
            
            $('#frontpage').hide();
            $('#content,#main,#footer').show();
        }
        this.content_process = false;
    }
    
    async getSocial(params) {
        if (app.isAuth() && params.login == app.user.login) {
            this.renderPage('profile')
        } else {
            var result = await ws.request('data/social', params);
            if(result.success) {
                let is_group_page = app.current_page == 'group';
                this.social = result;
                this.social.user.name = this.social.user.login;
                this.setRoundScores(this.social.user);
                session.set('app.social', this.social);

                this.blocks.social_leagues.setSocial(this.social).renderTopbar();
                this.blocks.social_user.setSocial(this.social).render();
                this.blocks.social_charts.setSocial(this.social).rewriteChartsData().render();
                this.blocks.social_scores.setSocial(this.social).render();
                
                this.social.live_matches = [];
                this.social.current_matchday_matches = [];
                this.social.next_matchday_matches = [];
                this.setMatchesState(this.social.data.scores, this.social);
                this.blocks.social_scores.setSocial(this.social).render().setOtherDaysVisibility().scrollToCurrentMatchday();
               // this.blocks.social_groups.setSocial(this.social).render();
                
                await this.renderPage('social', params);
                
                // Back (Zuruck) to the group, not to home
                if (is_group_page) {
                    app.backCallback = () => {
                        console.log('cll b');
                        app.renderPage('group', {id: app.blocks.group.group_id});                            
                        return false;
                    };
                }
            }
        }
    }

    async refreshSocial(id_league) {
        session.set('app.social', null);
        if(this.current_page == 'social' && this.social) {
            $('#social_scores, #social_charts').hide();
            
            var params = { login: app.social.user.login, id_league: id_league ? id_league : app.social.id_league };
            var result = await ws.request('data/social', params);
            if(result.success) {
                this.social = result;
                this.social.user.name = this.social.user.login;
                this.setRoundScores(this.social.user);
                session.set('app.social', this.social);
                this.blocks.social_leagues.setSocial(this.social).renderTopbar();
                this.blocks.social_user.setSocial(this.social).render();
                this.blocks.social_charts.setSocial(this.social).rewriteChartsData().render();
                
                this.social.live_matches = [];
                this.social.current_matchday_matches = [];
                this.social.next_matchday_matches = [];
                this.setMatchesState(this.social.data.scores, this.social);
                this.blocks.social_scores.setSocial(this.social).render().setOtherDaysVisibility();
                
                $('#social_scores, #social_charts').show();
                this.blocks.social_scores.scrollToCurrentMatchday();
            }
        }else {
            this.social = null;
        }
    }

    getPage() {
        return this.current_page;
    }

    getPageWithPrevious() {
        return { current: this.current_page, previous: this.previous_page }
    }

    setLanguage(lang) {
        if (Langs[lang] === undefined) {            
            lang = config.default_language;
        }

        if (lang != this.current_language) {
            if (this.current_language) {
                $('body').addClass('lang-' + this.current_language);
            }

            this.lang = Langs[lang];
            this.current_language = lang;
            
            $('body').addClass('lang-' + this.current_language);
            $('html').attr('lang', this.current_language);
        }

        return this;
    }

    isAuth() {
        return !!this.user;
    }

    setUser(user) {
        user.name = user.login;
        user.groups_history = app.data.groups_history;
        user.division_history = app.data.division_history;
        user.groups_wins = app.data.groups_wins || {};
        this.setRoundScores(user);
        this.user = user;
        
        if (this.user.leagues.indexOf(app.id_league) == -1) {
            app.id_league = this.user.leagues[0];
            app.loadConfig();
            app.blocks.leagues.render();
        }

        session.set('app.user', this.user);
        if(fcm) {
            fcm.SaveToken();
        }
        
        if(this.user.leagues.indexOf(this.user.id_league) == -1) {
            app.loadAllData(this.user.leagues[0]);
        }
        
                        
        this.checkTermsOfUseAcceptance();
    }
	
    updateUser(user) {
        this.user.score = user.score;
        session.set('app.user', this.user);
    }

    async mybetLogin(token, groupid) {
        console.log(token, 'token')
        var res = await ws.request('user/mybetLogin', {token, groupid});
        console.log(res, 'res login')
        if(res.success) {
            if(res.user) {
                if(res.joinToGroup) {
                    this.go_after_login = 'join_to_group';
                    this.go_after_login_params = {c: res.joinToGroup};
                }
                if(!app.user || app.user.id != res.user.id) {
                    this.login(res.user);
                }
            }else {
                this.logout();
                if(res.not_exists) {
                    app.blocks.login.mybetLogin(token, groupid);
                }
            }
        }else {
            this.logout();
        }
    }
    
    async login(user, first_login = false) {
        var logged_from_session = false; 
        if (!user) {
            user = session.get('app.user');
            if(app.isMybet) {
                var searchParams = new URLSearchParams(window.location.search);
                var groupid = searchParams.has('groupid') ? searchParams.get('groupid') : null;
                if(searchParams.has('userId') && searchParams.get('userId')) {
                    var user_mybet_token = searchParams.get('userId');
                    this.mybetLogin(user_mybet_token, groupid);
                    return ;
                }else {
                    if(groupid) {
                        this.go_after_login = 'join_to_group';
                        this.go_after_login_params = {c: groupid};
                    }
                    if(user && user.mybet_id > 0) {
                        user = null;
                    }
                }
            }
            if (!user && this.user) {
                this.logout();
            }

            this.user = user;
            if(user) {
                logged_from_session = true;
            }
            eventHandler.trigger('user_login');
            eventHandler.trigger('refresh_live_matches');
        } else {

            if(first_login && !this.go_after_login_params) {
                this.go_after_login = 'after_registration';
                this.go_after_login_params = null;
            }

            this.setUser(user);
            await this.loadUserData();
            this.setMatchesState(this.data.scores);
			
            //await this.renderPage('home');
            eventHandler.trigger('user_login');
            eventHandler.trigger('refresh_live_matches');
        }

        if (this.isAuth()) {   
            //$('.grecaptcha-badge').remove();
            $('.user-logged').show();
            this.setUserCookie('rt90_register', '1');
            if (this.go_after_login) {
                if(window.innerWidth < this.breakPoint) {
                    await this.renderPage('home');
                    setTimeout(() => {
                        this.renderPage(this.go_after_login, this.go_after_login_params, true);                        
                    }, 500);
                }else {
                    await this.renderPage(this.go_after_login, this.go_after_login_params, true);
                }
            } else {
                var url = location.hash.split('-group');
                if (url.length > 1) {
                    if(window.innerWidth < this.breakPoint) {
                        await this.renderPage('home');
                        setTimeout(() => {
                            this.renderPage('join_to_group', {'c': url[0].substr(1, 999) } );
                        }, 500);
                    }else {
                        await this.renderPage('join_to_group', {'c': url[0].substr(1, 999) } );
                    }
                }else if(location.hash.length > 2 && location.hash != '#login' && location.hash != '#register' && logged_from_session) {
                    var tab = location.hash.split('?');
                    var name = tab[0].substring(1, 999);
                    var usp = new URLSearchParams(tab[1] || '');
                    var params = {};
                    usp.forEach((val, key) => {
                        params[key] = val;
                    });
                    this.renderPage(name, params);
                } else {
                    await this.renderPage('home');
                }
            }
              
            // [05788] Matchday summary - disable promote dialog     
            //this.promoteDialog(); // <- it calls this.matchdaySummaryDialog();
            this.matchdaySummaryDialog();
            
            session.set('app.user', this.user);
        } else {
            var current_page = '';
            var page_params;
            if(this.hasOwnProperty('current_page') && this.current_page) {
                current_page = this.current_page;
                page_params = this.current_params;
            }else {
                var tab = location.hash.split('?');
                current_page = tab[0].substring(1, 999);
                page_params = new URLSearchParams(tab[1]);
            }

            var group = this.getGroupFromUrl();
            if(['set_reset_password', 'privacy', 'terms_of_use', 'login', 'edit_profile'].indexOf(current_page) == -1 && !group) {
                if(app.isMybet) {
                    this.renderPage(app.getUserCookie('rt90_register') == '1' ? 'login' : 'register');
                }else if(page_params.get('tipp_reminder_confirm') == '1' || page_params.get('unsubscribe') == '1') {
                    this.renderPage('home', page_params);
                }else {
                    this.renderPage('frontpage');
                }
            }
        }
    }

    async logout(no_send_request) {
        if(!no_send_request) {
            await ws.request('user/logout');
        }
        this.user = null;
		this.data.bets = [];
        this.setMatchesState(this.data.scores);
        session.clear();
        eventHandler.trigger('user_login', null, true);
        eventHandler.trigger('refresh_live_matches', null, true);
        $('.user-logged').hide();
        
        dialogs.cloaseAll();
        await this.renderPage('frontpage');
    }

    getCurrentMatchday(source) {
        if(!source) {
            source = app;
        }
        if (!source.current_round) {
            var now = Date.now();
            for(var i in source.data.scores) {
                if (source.data.scores[i].time > now) {
                    source.current_round = source.data.scores[i].matchday;
                    break;
                }
            }
        }

        return source.current_round;
    }
    
    getUserDivisionName() {
        for (var group of app.data.groups) {
            if (group.division > 0) {
                return helpers.getGroupName(group);
            }
        }

        return '???';
    }

    getUserAvatar(user) {
        if(!user && app.isAuth()) {
            user = app.user;
        }
        return user && user.avatar ? config.web_front_url+'/avatar/'+user.avatar : `images/${config.style ? config.style + '/' : 'sportde-'}default-avatar.svg`;
    }
    
    hasUserAvatar(user) {
        if(!user && app.isAuth()) {
            user = app.user;
        }
        return user && user.avatar;
    }

    errorUserAvatar(img) {
        if(config.style) {
            $(img).attr('src', `images/${config.style}/default-avatar.svg`);
        }else {
            $(img).attr('src', 'images/sportde-default-avatar.svg');
        }
    }

    showOverlay() {
        $('#overlay').show();
        $('body').addClass('overlay');
    }

    hideOverlay() {
        $('#overlay').hide();
        $('body').removeClass('overlay');
    }

    lightTheme() {
        $('html').removeClass('dark');
        $('body').removeClass('dark');
        document.body.style.backgroundColor = null;
    }

    darkTheme() {
        $('html').addClass('dark');
        $('body').addClass('dark');
    }
    
    async loadAllData(id_league) {
        try {
            if(id_league) {
                session.set('id_league', id_league);
            }else {
                id_league = session.get('id_league');
            }
            await ws.request('data/getAll', { id_league, is_web_connection: true, style: config.style });
            $('body').attr('id_league', id_league);
            
            setTimeout(() => {
                $('body').removeClass('preload');
            }, this.mobile ? 300 : 5);
        }catch(err) {
            await app.logout();
            window.location.reload();
        }
    }
    
    async resume() {
        if(this.resume_process) {
            return ;
        }
        if(Date.now() - this.resume_last < 1000) {
            return ;
        }
        this.resume_process = true;
        this.resume_last = Date.now();
        this.resume_index++;
        console.log(this.resume_index, 'resume_index');
        
        var reconnect = () => {
            var ws_master = ws.master;
            var ws_slave = ws.slave;
            ws.master = null;
            ws.slave = null;
            
            try {
                if(ws_master) {
                    ws_master.close();
                }
            }catch(err) {};
            
            try {
                if(ws_slave) {
                    ws_slave.close();
                }
            }catch(err) {};
            
            this.resume();
        };
        
        var timeout_id = setTimeout(reconnect, 3000);
        try {
            var data = await ws.request('data/resume', { id_league: app.id_league, is_web_connection: true, style: config.style });
            //var data = await ws.request('data/getAll');
            clearTimeout(timeout_id);
            if(app.current_page == 'group') {
                app.blocks.group.refreshData();
            }
        }catch(err) {
            clearTimeout(timeout_id);
            this.resume_process = false;
            setTimeout(reconnect, 3000);
            return ;
        }
        var has_changes = false;
        
        for(var live of data.matches) {
            for (var match of this.data.scores) {
                if (match.id == live.id) {
                    if(match.team1.score != live.goals_home_team || match.team2.score != live.goals_away_team || match.status_short != live.status_short) {
                        has_changes = true;
                    }
                    match.team1.score = live.goals_home_team;
                    match.team2.score = live.goals_away_team;
                    match.finished = live.finished;
                    match.started = live.started + this.timezone_offset;
                    match.status_short = live.status_short;
                    match.elapsed = live.elapsed;
                    match.time = live.event_date + this.timezone_offset;
                    this.setMatchesState([match]);
                }
            }
        }
        
        eventHandler.trigger('refresh_live_matches');
        
        if(has_changes && app.current_page == 'group') {
            this.refreshDataDialog();
        }
        this.resume_process = false;
    }
    
    loadConfig = () => {
        return new Promise((resolve,  reject) => {
           if(typeof ws.config == 'object') {
                this.config = ws.config[app.id_league] || ws.config[ws.config.id_leagues[0]];
                if(ws.lang) {
                    Langs = ws.lang;
                }
                eventHandler.trigger('refresh_app_config');
                resolve();
           } else {
               (async () => {
                    await ws.getSlave();
                    this.config = ws.config[app.id_league] || ws.config[ws.config.id_leagues[0]];
                    if(ws.lang) {
                        Langs = ws.lang;
                    }
                    eventHandler.trigger('refresh_app_config');
                    resolve();
               })();
           }
        });
    }
    
    createFrontpage() {
        if(config.style) {
            BlockFrontpage['render_'+config.style]();            
        }else {
            BlockFrontpage.render();
        }
    }
    
    promoteDialog() {
        if (this.user.promote_inform) {
            //See master notification.addPromoteDivisionNotifications

            var html = '';
            if(this.user.promote_inform == 1000) {
                html += `<div class="pr_title">${this.lang.common.no_league_change_title}</div>`;
                html += '<div class="no_league_change_message"></div>';
                if(this.user.division == 100) {
                    html += `<div class='pr_message'>${this.lang.common.no_league_change_message_100}</div>`;
                }else {
                    html += `<div class='pr_message'>${this.lang.common.no_league_change_message.replace('{division}', this.user.division)}</div>`;                
                }
            } else if(this.user.promote_inform > 0 && this.user.promote_inform != 100) {
                html += `<div class="pr_title">${this.lang.common.promote_title}</div>`;
                html += `<div class="promote_message">
                    <span class="division-label">${this.lang.common.liga}</span>
                    <span class="division-nr">${this.user.promote_inform}</span>
                </div>`;
                html += `<div class='pr_message'>${this.lang.common.promote_message.replace('{pos}', this.user.promote_inform)}</div>`;
            }else if(this.user.promote_inform != 100) {
                html += `<div class="pr_title">${this.lang.common.relegate_title}</div>`;
                html += '<div class="relegate_message"></div>';
                if (this.user.promote_inform == -100) {
                    var max_division = parseInt(app.data.max_division);
                    html += `<div class='pr_message'>`+this.lang.common.relegate_message_100.replace('{division}', (max_division+1))+`</div>`;
                } else {
                    html += `<div class='pr_message'>`+this.lang.common.relegate_message.replace('{pos}', Math.abs(this.user.promote_inform))+`</div>`;
                }
            }else {
                return ;
            }

            this.user.promote_inform = 0;
            session.set('app.user', this.user);

            (new Dialog())
            .html(html)
            .btn(this.lang.common.ok, function() {                
                this.close();
                app.matchdaySummaryDialog();
            })
            .show();
        } else {
            this.matchdaySummaryDialog();
        }
    }
    
    async matchdaySummaryDialog(force = 0){
        if (this.user.matchday_summary && app.user.matchday_summary.score !== undefined || force == 1) {
            let promote = '-';
            if (app.user.matchday_summary.hasOwnProperty('promote')) {
                promote = app.user.matchday_summary.promote;
                if (promote == 999999) {
                    promote = `<img src='images/${config.style ? config.style + '/' : 'sportde-'}checked.svg' alt='' />`;
                }
            }
            
            let relegate = '-';
            if (app.user.matchday_summary.hasOwnProperty('relegate')) {
                relegate = app.user.matchday_summary.relegate;
                if (relegate == 999999) {
                    relegate = `<img src='images/${config.style ? config.style + '/' : 'sportde-'}checked.svg' alt='' />`;
                }
            }
            
            var html = `
                <div class='matchday_summary'>
                    <div class='header'>${this.lang.common.evaluation} ${app.current_round - 1}. ${this.lang.common.matchday}</div>
                    <div class='line'><div>${this.lang.common.points}</div></div>
                    <div class='bg'>
                        <div class='stats stats-1'>
                            <div>${this.lang.common.matchday}</div>
                            <div>${app.user.matchday_summary.score}</div>
                            <div>${this.lang.common.ranking_total}</div>
                            <div>${app.user.score}</div>
                        </div>
                    </div>
            
                    <div class='line'><div>${this.lang.common.position2}</div></div>
                    <div class='bg'>
                        <div class='stats stats-2'>
                            <div>${this.lang.common.division}</div>
                            <div>${app.user.matchday_summary.division || '-'}</div>
                            <div>${this.lang.common.position3}</div>
                            <div>${app.user.matchday_summary.position || '-'}</div>
                            <div>${this.lang.common.ranking_total}</div>
                            <div>${app.user.position}.</div>
                        </div>
                    </div>
            
                    <div class='line'><div>${this.lang.common.points_to}</div></div>
                    <div class='bg'>
                        <div class='stats stats-3'>
                            <div>${this.lang.common.points_to_promote}</div>
                            <div>${promote}</div>
                            <div>${this.lang.common.points_to_relegate}</div>
                            <div>${relegate}</div>
                        </div>
                    </div>
                    
                    <div class='line'><div>${this.lang.common.bets}</div></div>
                    <div class='bg'>
                        <div class='stats stats-4'>
                            <div>${this.lang.common.correct}</div>
                            <div>${app.user.matchday_summary.correct}</div>
                            <div>${this.lang.common.incorrect}</div>
                            <div>${app.user.matchday_summary.incorrect}</div>
                        </div>
                    </div>
                </div>
                <div class='p15 matchday_summary-button'><a class='tip-button tip-button-transparent' href='#'>${this.lang.common.thanks}</a></div>
            `;

            this.user.matchday_summary = false;
            session.set('app.user', this.user);
            
            setTimeout(() => {
                let d = new Dialog({ dialog_class: 'nopadding', wrapper_dark: 1 });
                d.html(html).show();
                $('.matchday_summary a').click(function() { d.close(); return close; });
            }, 500);
        }
    }
	
	async loadUserData() {
        await ws.request('data/getUserData', { });
    }
    
    setFrontPageGroup(group) {
        if (group && group.group) {
            group = group.group;
            if(typeof group.options != 'object') {
                try {
                    group.options = JSON.parse(group.options);
                }catch(err) {
                    group.options = {};
                }
            }

            var image = `<img src="images/${config.style ? config.style + '/' : 'sportde-'}default-avatar.svg" alt="" />`;
            if (group.options && group.options.image) {
                image = '<img src="'+config.web_front_url+'/usergroup/'+group.options.image+'" alt="" />';
            }

            $('#frontpage').addClass('group');
            $('#frontpage .group b').html(group.name);
            $('#frontpage .group .group_image').html(image);
        } else {
            $('#frontpage').removeClass('group');
        }
    }
    
    showGroupNotificationsDialog(data) {
        if (data && data.length) {
            let groups_names = [];
            let leagues_names = [];
            for(let n of data) {
                groups_names.push(n.name);
                leagues_names.push(app.lang.common['league_name_'+n.id_league]);
            }
            
            let message = app.lang.common.join_to_other_leagues;
            message = message.replaceAll('{groups_names}', groups_names.join(', '));
            message = message.replaceAll('{leagues_names}', leagues_names.join(', '));
            
            var info_dialog = new Dialog({wrapper_dark: true}); 
                
                var html = '';
                html += `<div class='pr_title'>`+app.lang.common.join_to_other_leagues_title+'</div>';
                html += `<div class='pr_message'>`+message+'</div>';

                info_dialog
                    .html(html)
                    .btn(app.lang.common.yes, async () => {
                        let tmp = await ws.request('user/joinLeaguesFromGroups', { confirm: 1 });
                        app.user.leagues = tmp.leagues;
                        app.setData('id_league', app.id_league)
                
                        info_dialog.close();
                    }, {black: true})
                    .btnText(app.lang.common.no, () => {
                        ws.request('user/joinLeaguesFromGroups', { confirm: 0 });
                    }, {black: true})
                    .show();
        }
    }
    
    getGroupFromUrl() {
        var url_code = '';
        var url = location.hash.substr(1, 999).split('-group');

        if (url.length > 1) {
            url_code = url[0];
        }
        
        return url_code;
    }
    
    getGroupFromHash() {
        var url_code = '';
        var url = location.hash.split('groupid=');

        if (url.length > 1) {
            url_code = url[1];
        }
        
        return url_code;
    }
    
    async getGroupData(url_code) {
        var group = await ws.request('group/get', { uuid: 10001, url_code: url_code });
        if (group) {
            this.setFrontPageGroup(group);
        }
        return group;
    }

    setUserCookie(name, value) {
        if (navigator.cookieEnabled && !config.cookie_disabled) { 
            var cookie_name = encodeURIComponent(name);
            var cookie_value = encodeURIComponent(value);
            var cookie_text = cookie_name + "=" + cookie_value;
            cookie_text += "; expires=Fri, 31 Dec 3120 23:59:59 GMT";
            document.cookie = cookie_text;
        }
    }

    getUserCookie(name) {
        if (document.cookie !== "" && !config.cookie_disabled) {
            const cookies = document.cookie.split(/; */);
    
            for (let i=0; i<cookies.length; i++) {
                const cookie_name = cookies[i].split("=")[0];
                const cookie_value = cookies[i].split("=")[1];
                if (cookie_name === decodeURIComponent(name)) {
                    return decodeURIComponent(cookie_value);
                }
            }
        }
    }
    
    checkTermsOfUseAcceptance() {
        if (this.user && !this.user.terms_of_use_approve) {
            if (this.getPage() != 'terms_of_use') {
                this.blocks.terms_of_use_approve.render();
            }
            
            return false;
        }
        
        return true ;
    }
    
    acceptTermsOfUseAcceptance(accept = 1) {
        if (this.user && !this.user.terms_of_use_approve) {
            this.user.terms_of_use_approve = accept;
            ws.send('user/setAcceptTermsOfUse', { accept });
            
            if (accept != 1) {
                app.logout();
            } else {
                 app.renderPage("home");
            }
        }
    }
    
    showAppReviewDialog() {
        var html = `
                <div class="logo">
                    <img src="images/app-logo.png" />
                </div>
                <div class="subject">
                    ${this.lang.common.app_review_subject}
                </div>                                                                                                   
                <div class="description">
                    ${this.lang.common.app_review_description}
                </div>                                                                                                   
            `;

            (new Dialog({ dialog_class: 'app-review-dialog', wrapper_dark: 1, fixed: 1 }))
            .html(html)
            .btn(this.lang.common.app_review_yes, function() {
                ws.send('user/setAppReview', {flag: 1});
                this.close();
                cordova.plugins.AppReview.requestReview().catch(function() {
                    return cordova.plugins.AppReview.openStoreScreen();
                });
            })
            .btn(this.lang.common.app_review_no, function() {
                ws.send('user/setAppReview', {flag: 2});
                this.close();
            })
            .btn(this.lang.common.app_review_later, function() {
                this.close();
            })
            .show();
    }
}

var app;
startApp = function() {
    if(config.app_type == 'mobile') {
        try {
            window.open = cordova.InAppBrowser.open;
        }catch(err) {}
    }
    if(config.app_type == 'mobile' && !config.app_platform && typeof device != 'undefined') {
        config.app_platform = device.platform.toLowerCase();
    }    
    if (config.app_type == 'web') {
        $.ajax({
            url: 'translations',
            dataType: 'json',
            success: translations => {
                Langs = translations;
                app = new App();    
                app.createBlocks();
                window.addEventListener("hashchange", function() {
                    app.hashChange()
                }, false);
            }
        });
    } else {
        cordova.getAppVersion.getVersionNumber().then(function (version) {
            app.versionNumber = version;            
            $('.app-versionnumber').html('Versionsnummer ' + version);
        }).catch(function(err) {
            console.log(err, 'get version number');
        });
        
        if(window.MobileAccessibility){
            window.MobileAccessibility.usePreferredTextZoom(false);
        }
        
        app = new App();
        app.createBlocks();
        window.addEventListener("hashchange", function() {
            app.hashChange()
        }, false);
        
        var event_resume_index = 0;
        document.addEventListener("resume", function() {
            event_resume_index++;
            console.log(event_resume_index, 'event_resume_index');
            setTimeout(function() {
                try {
                    if(ws.master.readyState === WebSocket.CLOSED) {
                        ws.master = null;
                    }
                    if(ws.slave.readyState === WebSocket.CLOSED) {
                        ws.slave = null;
                    }
                }catch(err) {
                    ws.master = null;
                    ws.slave = null;
                }
                app.resume();
            }, 0);
        }, false);
    }
    
    window.addEventListener('storage', (e) => {
        if(!e.key || e.key == 'app.user') {
            var newUser = e.newValue ? JSON.parse(e.newValue) : {};
            var oldUser = app.user;
            var checkFields = ['id', 'login', 'token'];
            var runLogin = false;
            for(var field of checkFields) {
                if(!newUser || !oldUser || newUser[field] != oldUser[field]) {
                    runLogin = true;
                    break;
                }
            }
            if(runLogin) {
                app.login();
            }
        }
    });
    
    $('body').on('focus', 'button, a, .block_charts .footer > div', function() {
        $(this).blur();
    })        

    $(document).on('click', 'a.no-history', function() {
        var hash = $(this).attr('href');

        var tab = hash.split('?');
        var name = tab[0].substring(1, 999);
        var params = new URLSearchParams(tab[1] || '');
        app._renderPage(name, params);

        return false;
    });
    
    if(config.app_type == 'mobile') {
        $(document).on('click', 'a[target="_blank"]', function() {
            var url = $(this).attr('href');
            window.open(url, '_system');
            return false;
        });
    }
}

onReady = function(func) {
    if (config.app_type == 'web') {
        $(document).ready(function() {
            func();
        });
    } else {
        document.addEventListener("deviceready", func, false);
    }
}

onReady(startApp);

window.onresize = function(event) {
    resizeDiv();
}

let prev_width = $(window).width();
function resizeDiv() {
    let ww = $(window).width();
    if (ww > app.breakPoint2 && prev_width <= app.breakPoint2) {
        prev_width = ww;
        app.blocks.charts.render();
    }
    
    if (ww <= app.breakPoint2 && prev_width > app.breakPoint2) {
        prev_width = ww;
        app.blocks.charts.render();
    }
    
    if (ww < 400) {
        vpw = ww - 40;
        vpw2 = ww - 70;
        $('.block_bets > div').css({ width: vpw+'px'});
        $('.block_bets .elem').css({ width: vpw2+'px'});
    }
    
    if (!this.iw) {
        this.iw = $('#ad_header > *').width();
    }
    
    if (this.iw) {
        if (ww < this.iw) {
            $('#ad_header > *').css('margin-left', -(this.iw - ww) / 2);
        } else {
            $('#ad_header > *').css('margin-left', 0);
        }
    }
}

//setTimeout(resizeDiv, 750);

jQuery(function ($) {
    var _oldShow = $.fn.show;
    var _oldHide = $.fn.hide;
    var _oldAddClass = $.fn.addClass;
    var _oldRemoveClass = $.fn.removeClass;
    var dialog_with_slide = true;

    $.fn.show = function (speed, callback) {
        _oldShow.apply(this, [speed, callback]);
        if(!app.content_process) {
            this.each(function() {
                if(!this.classList.contains('block_container')) {
                    return;
                }
                if(this.parentNode && this.parentNode.id == 'content_dialog_main') {
                    $(window).off("resize.content_dialog");
                    if($(this).data('full_width')) {
                        $('#content_dialog').removeClass('no_full_width').addClass('full_width');
                    }else {
                        $('#content_dialog').removeClass('full_width').addClass('no_full_width');                        
                    }
                    let view_mobile = window.innerWidth < app.breakPoint;
                    if($(this).data('no_mobile_modal')) {
                        view_mobile = false;
                    }
                    if(app.is_back_proccess) {
                        dialog_with_slide = false;
                    }
                    app.setContentDialog(view_mobile);
                    if(view_mobile) {
                        $(this).dialog({
                            slide: dialog_with_slide,
                            previous_page: app.previous_page,
                            previous_params: app.previous_params,
                            previous_body_page: app.previous_body_page,
                            previous_hash: app.previous_hash,
                            body_page: $('body').data('page')
                        });
                    }
                    dialog_with_slide = true;
                    $(window).on('resize.content_dialog', () => {
                        let view_mobile_tmp = window.innerWidth < app.breakPoint;
                        if(view_mobile_tmp != view_mobile) {
                            dialog_with_slide = false;
                            app.previous_content_name = '';
                            $(this).hide().show();
                        }
                    });
                }else if(this.parentNode && this.parentNode.id == 'main') {
                    $(window).off("resize.content_dialog");
                    app.setContentGrid();
                }
            });
        }
        eventHandler.trigger('show', {element: this});
        return this;
    }

    $.fn.hide = function (speed, callback) {
        _oldHide.apply(this, [speed, callback]);
        eventHandler.trigger('hide', {element: this});
        return this;
    }

    $.fn.addClass = function (callback) {
        _oldAddClass.apply(this, [callback]);
        eventHandler.trigger('addClass', {element: this});
        return this;
    }

    $.fn.removeClass = function (callback) {
        _oldRemoveClass.apply(this, [callback]);
        eventHandler.trigger('removeClass', {element: this});
        return this;
    }
});
