class BlockBets {
    constructor(dom) {
        this.dom = dom;
        this.dom_content = false;
        this.bet_charts = [];
        this.el_index = this.ad_index = 0;

        eventHandler.observe('block_bets', ['language_change', 'user_login', 'set_data_scores', 'set_data_bets', 'set_data_trends'], () => {
            this.render();
            this.updateBetanoBannerLink();
        }, this);
        
        eventHandler.observe('block_bets_2', ['refresh_live_matches'], (live) => {            
            this.refreshLiveMatches();
            this.updateBetanoBannerLink();
        });
        
        eventHandler.observe('block_bets_3', ['change_page'], (data) => {     
            var page = app.getPageWithPrevious();
            if (page.current != 'bets' && page.previous == 'bets') {
                $('body').find('#round_max_points').addClass('nosave');
            }
        });
    }

    setDom(dom) {
        this.dom = dom;

        var html = `<div class='scroll'></div>`;
        this.dom.html(html);
        $("<div id='round_max_points' class='nosave'>&nbsp;</div>").insertAfter(this.dom);

        this.dom_content = this.dom;
        
        return this;
    }
    
    refreshLiveMatches() {
        for (var match of app.live_matches) {
            this.refreshGame(match);
        }
    }
    
    getMatches() {
        return app.current_matchday_matches.concat(app.next_matchday_matches);
    }

    render() {
        var self = this;
        if (!this.dom_content) {
            return this;
        }
		
		if(!app.isAuth()) {
            this.dom.empty();
            return ;
        }
		
        var games = [];
        this.bet_charts = [];
        var bet_scroll_dots = [];

        var matchdaymax = this.getMaxMatchdayPoints();
        $('body').find('#round_max_points').html(`<div><div>${app.lang.common.your_max_playsday_points}</div><div>${matchdaymax}</div><div><a href='#home' class='tip-button tip-button-small' href='#home'>${app.lang.common.ok}</a></div></div>`);
        
        for(var game of this.getMatches()) {
            var html = this.renderGame(game);
            games.push(html);
            
            if(html != '') {
                var match_indicator = this.renderMatchIndicator(game)
                bet_scroll_dots.push(match_indicator);
            
                if(app.config.bets_sum_block && !config.disable_finance) {
                    this.el_index++;
                    if (this.el_index == app.config.ads_insert_every_nth) {
                        var html = this.renderAd();
                        games.push(html);
                        this.el_index = 0;
                    }
                }
            }
        }
        
        if(app.config.bets_sum_block && !config.disable_finance && !app.config.odd_amount_block_hide) {
            var html = this.renderBetsSumBlock(game);
            games.push(html);
            
            if(html != '') {
                bet_scroll_dots.push(`<div class='dot dot_game_sum_bet'></div>`);
            }
        }

        var games_html = games.join('');
        var _class = '';
        if(app.config.bets_sum_block && !config.disable_finance) {
            _class += ' is_bets_sum_block';
            $(this.dom_content).addClass('is_bets_sum_block');
        }
        var html = `
            <div class='block_bets ${_class}'>
                ${games_html}
            </div>
        `;
        
        
        $('#bets').attr('betano_operator', app.config.odds_source);
        this.dom_content.html(html);        
        $(this.dom_content).find('.subscroll').each(function() {
            var options = {
                dot: "<div class='dot'></div>",
                active_dot_class: "dot_active",
                inside_element_width: 378,
                count_elements: true,
                hidden_navigation: true,
                dest_active: 1,
                show_dots_scroll: true,
                selector: '.elem'
            };
            if ($(window).width() < 450) {
                options.inside_element_width = $(window).width() - 70;
            }
            $(this).dotsScroll(options);
        });
        $(this.dom_content).find('.statistics-tabs-nav > div').click(function() {
            $(this).closest('.statistics-tabs-nav').children().removeClass('active');
            $(this).addClass('active');
            var dest = $(this).data('target');
            $(this).closest('.statistics-tabs-nav').parent().find('.statistics-tabs-content > div').hide();
            $(self.dom_content).find(dest).show();
            $(window).resize();
        });

        for(var i in this.bet_charts) {
            let ch = this.bet_charts[i];
            this.drawChartCircle(ch.id, ch.value);
        }

        $(this.dom_content).find('.available-points-content').on('click', '.bet-btn', function() {
            self.setBet(this.getAttribute('data-game'), this.getAttribute('data-value'), true);
        });

        $(this.dom_content).find('button.statistics-btn').click(function() {
            var game_id = $(this).data('game_id');
            var current_game = self.getMatches().find(x => x.id == game_id);
            var statistics_html = self.renderStatistics(current_game);
            $(statistics_html).dialog();
        })
        
        //Source Mybet
        if(app.config.odds_source == '1' && window.location !== window.parent.location && !$(this.dom_content).data('register_betano_event_click')) {
            $(this.dom_content).data('register_betano_event_click', 1).on('click', '.betano-odd-link', function(e) {
                e.stopPropagation();
                var ids = $(this).data('betano_ids');
                window.parent.postMessage({outcomeIds: ids,type: 'updateBetslip'}, "*");
                return false;
            });            
        }
        
        var options = {
            active_dot_class: "dot_active",
            inside_element_width: 343,
            inside_offset: 0, //-9,
            extra_class: 'dots_scroll_bets',
            dots: bet_scroll_dots,
            bets_scroll: true,
            is_native_scroll: () => {
                if(!app.mobile) {
                    $(this.dom_content).css('overflow-x', '')
                    return true;
                }else {
                    $(this.dom_content).css('overflow-x', 'visible')
                    return false;
                }
            }
        };
        if(app.config.bets_sum_block && !config.disable_finance && !app.config.odd_amount_block_hide) {
            options.extra_class += ' dots_scroll_bets_with_sum_block';
        }
        if ($(window).width() < 400) {
            options.inside_element_width = $(window).width() - 20;
        }
        
        setTimeout(() => {            
            if(!app.mobile) {
                var dotsScroll = $(this.dom_content).addClass('native-scroll').find('.block_bets').dotsScroll(options);
                $(this.dom_content).addClass('native-scroll').swipeIndicator({
                    onClickLeft: dotsScroll.clickLeft,
                    onClickRight: dotsScroll.clickRight
                });
            }else {
                var dotsScroll = $(this.dom_content).addClass('mobile-scroll').find('.block_bets').dotsScroll(options);            
            }
        }, 50);
        
        //this.refreshLiveMatches();
        for(var game of app.current_matchday_matches) {
            if(game.is_live || game.is_finished) {
                this.refreshGame(game);
            }
        }
        
        this.updateBetanoBannerLink();
        
        
        var tab = location.hash.split('?');
        var params = new URLSearchParams(tab[1] || '');
        if (params.get('id')) {
            this.scrollToMatch(params.get('id'));
        }
        
        resizeDiv();
        return this;
    }

    refreshMatchIndicator(game) {
        var {tipped_class, bet_result_class, indicator_icon, active_indicator} = this.renderMatchIndicator(game, true);
        var $el = $(`.dot.dot_game_${game.id}`);
        if(bet_result_class && !$el.hasClass(bet_result_class)) {
            $(`.dot.dot_game_${game.id}`).removeClass('tipped bet-success bet-fail bet-on').addClass(`${tipped_class} ${bet_result_class}`).html(`${indicator_icon}${active_indicator}`);
        }
    }
    
    renderMatchIndicator(game = null, for_refresh = false) {

        let tipped_class = app.data.bets.hasOwnProperty(game.id) ? 'tipped' : '';
        let bet_result_class = 'no-bet';
        let active_indicator = '';
        let indicator_icon = '';
        let html = '';
        if(game.is_finished || game.is_live) {
            if(game.has_bet) {
                active_indicator = `<div class='active-indicator'></div>`;
                bet_result_class = game.points > 0 ? 'bet-success' : 'bet-fail';
                indicator_icon = `<img class="icon" src="images/${game.points > 0 ? 'checked_black' : 'cross_black'}.svg" />`;
            } else {
                bet_result_class = '';
            }
        } else {
            active_indicator = `<div class='active-indicator'></div>`;
            indicator_icon = `<img class="icon" src="images/${config.style}/checked.svg" />`;
            if(game.has_bet) bet_result_class = 'bet-on';
        }
        
        if(for_refresh) {
            return {tipped_class, bet_result_class, indicator_icon, active_indicator};
        }

        html += `<div class='dot dot_game_${game.id} ${tipped_class} ${bet_result_class}'>${indicator_icon}${active_indicator}</div>`;

        return html;
    }
    
    renderAd() {
        if (!app.config.ads_on_prediction_screen[this.ad_index]) {
            this.ad_index = 0;
        }
        
        if (app.config.ads_on_prediction_screen[this.ad_index]) {
            var html = `
                <div class="ad">${app.config.ads_on_prediction_screen[this.ad_index]}</div>
            `;
            
            this.ad_index++;
            return html;
        }
        
        return '';
    }

    async setBet(game_id, bet, after_click) {
        if (!app.checkTermsOfUseAcceptance()) return;
        
        app.updateIsBetable();
        
        for(var game of this.getMatches()) {
            if (game.id == game_id && game.is_betable) {
                var old_bet = app.data.bets[game_id];
                app.data.bets[game_id] = bet;
                var matchdaymax = this.getMaxMatchdayPoints();

                var saved = await ws.request('data/setBet', {
                    game_id: game_id,
                    bet: bet
                });
                
                if(saved) {
                    this.updateBet(game_id, matchdaymax, after_click);
                    $('.dot_game_'+game_id).addClass('tipped');

                    this.updateBetanoBannerLink();

                    $('body').find('#round_max_points').removeClass('nosave');
                }else if(after_click) {
                    app.data.bets[game_id] = old_bet;
                    if(game.options && game.options[bet] && game.options[bet].chart_id) {
                        let chart_id = game.options[bet].chart_id;
                        let html = this.getTickHtml('anim'+chart_id, 'faild');
                        $('#'+chart_id).hide();
                        $('#'+chart_id).parent().append(html);
                        setTimeout(function() {
                            $('#anim'+chart_id).remove();
                            $('#'+chart_id).show();
                        }, 1500);
                    }
                }
            }            
        }

        $('.dots_scroll_bets .dot_game_' + game_id).removeClass('no-bet').addClass('bet-on');
    }
    
    updateBetanoBannerLink() {
        var matches = [];
        var bets = [];
        var odd_amount = 10;
        var isset_bet = false;
        var link = 'https://gml-grp.com/C.ashx?btag=a_7043b_223c_&affid=1865&siteid=7043&adid=223&c=WTL-Conversion-Banner&asclurl=https://de.betano.com/mybet/?siteid=7043&selection_ids=';
        
        $('#bets').attr('betano_operator', app.config.odds_source);
        
        //Source mybet
        if(app.config.odds_source == '1') {
            if(window.location !== window.parent.location) {
                link = '#selection_ids=';                
            }else {
                link = 'https://www.mybet.de/de/sport#home';                
            }
        }
        
        var mlist = this.getMatches();
        for(var i in mlist) {
            var m = mlist[i];
            if (m.is_betable && app.data.bets.hasOwnProperty(m.id)) {
                var bet = app.data.bets[m.id];
                if(m.betano_link) {
                    link = m.betano_link;
                }
                if (!m.betano_home_odd) {
                    m.betano_home_odd = 1;
                }
                
                if (!m.betano_draw_odd) {
                    m.betano_draw_odd = 1;
                }
                
                if (!m.betano_away_odd) {
                    m.betano_away_odd = 1;
                }
                let odd_item_amount = 0;
                let betano_id = 0;
                
                if (bet < 2) {
                    betano_id = m.betano_home;
                    odd_amount *= m.betano_home_odd;
                    odd_item_amount = m.betano_home_odd;
                } else if (bet == 2) {
                    betano_id = m.betano_draw;
                    odd_amount *= m.betano_draw_odd;
                    odd_item_amount = m.betano_draw_odd;
                } else {
                    betano_id = m.betano_away;
                    odd_amount *= m.betano_away_odd;
                    odd_item_amount = m.betano_away_odd;
                }
                
                if(betano_id) {
                    betano_id = parseInt(betano_id);
                    bets.push(betano_id);
                    matches.push(m.id_deeplink_match);
                    isset_bet = true;
                    $('#odd-amount-'+m.id).html(new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(Math.round(odd_item_amount * 10)));
                    if(app.config.odds_source == '3') {
                        $('#odd-link-'+m.id).attr('href', link);                    
                    }else if(app.config.odds_source == '1' && window.location === window.parent.location) {
                        $('#odd-link-'+m.id).attr('href', link + '?coupon=single|' + betano_id + '|10|append|').data('betano_ids', [betano_id]);                    
                    }else {
                        $('#odd-link-'+m.id).attr('href', link + betano_id).data('betano_ids', [betano_id]);
                    }
                }else {
                    $('#odd-link-'+m.id).attr('href', link).data('betano_ids', []);
                }
            }
        }
        
        if (matches.length) {
            if(app.config.odds_source == '3') {
                
            }else if(app.config.odds_source == '1' && window.location === window.parent.location) {
                link += '?coupon=combination|' + bets.join(',') + '|10|append|';
            }else {
                link += bets.join(',');
            }
        }

        if(isset_bet) {
            if(app.config.maximum_betslip_combo_amount > 0 && odd_amount > app.config.maximum_betslip_combo_amount) {
                odd_amount = app.config.maximum_betslip_combo_amount;
            }
            $('#betano').attr('href', link);
            $('#betano-odd-amount').text(new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(Math.round(odd_amount))).show();
            $('#ad3 .block_ad3 .ad3-text').show();
            $('#ad3 .block_ad3 .ad3-amount').show();
            $('#ad3 .block_ad3 .ad3-no-bet').hide();
            
            $('#odd-amount-sum').html(new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(Math.round(odd_amount)));
            $('#odd-link-sum').attr('href', link).data('betano_ids', bets);
        }else {
            $('#betano-odd-amount').text(0).hide();
            $('#ad3 .block_ad3 .ad3-text').hide();
            $('#ad3 .block_ad3 .ad3-amount').hide();
            $('#ad3 .block_ad3 .ad3-no-bet').show();
            
            $('#odd-amount-sum').html(new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(Math.round(0)));
            $('#odd-link-sum').attr('href', this.getBetanoOddLink()).data('betano_ids', []);
        }
        
        if(config.disable_finance) {
            $('#odd-link-sum, .betano-odd-link').removeAttr('href');
        }
    }

    renderBet(game, after_click) {
        var charts = game.options;
		
		charts[0].label = charts[4].label = `<img src="images/${config.style ? config.style + '/' : 'sportde-'}thunder-black.svg" />`;
		charts[1].label = '1';
		charts[2].label = 'X';
		charts[3].label = '2';
        
        charts[0].label_active = charts[4].label_active = `<img src="images/${config.style ? config.style + '/' : 'sportde-'}thunder-green.svg" />`;
		charts[1].label_active = '1';
		charts[2].label_active = 'X';
		charts[3].label_active = '2';
		
		var max_points = charts[0].points > charts[4].points ? charts[0].points : charts[4].points;
        var html = '';

		var selected = app.data.bets[game.id];
        for(var i in charts) {
            let item = charts[i];
			item.i = i;
			item.value = item.points / max_points;
            item.chart_id = 'c'+helpers.uniqid(8);
            item.active = selected == item.i;
            item.game_id = game.id;

            if(item.active == 1) {
                var chart_html = this.getChartCircleActiveHtml(item, after_click);
                if (after_click) {                    
                    chart_html += this.getTickHtml('anim'+item.chart_id);
                    setTimeout(function() {
                        $('#anim'+item.chart_id).hide();
                        $('#'+item.chart_id).show();
                    }, 1500);
                }
                
                html += `<div>${chart_html}</div>`;
            } else {
                var chart_html = this.getChartCircleHtml(item);
                html += `<div>${chart_html}</div>`;
                this.bet_charts.push({
                    id: item.chart_id,
                    value: item.value
                });
            }
        }

        return html;
    }

    updateBet(game_id, matchdaymax, after_click) {
		for(var game of app.data.scores) {
			if (game.id == game_id) {
				this.bet_charts = [];
				var html = this.renderBet(game, after_click);
				$(this.dom_content).find('#bets_game_'+game_id+' .available-points-content').html(html);
				
				var win_message = this.getWinMessage(game);
				$(this.dom_content).find('#bets_game_'+game_id+' .win-section').html(win_message);
                
                $('body').find('#round_max_points').html(`<div><div>${app.lang.common.your_max_playsday_points}</div><div>${matchdaymax}</div><div><a href='#home' class='tip-button tip-button-small' href='#home'>${app.lang.common.ok}</a></div></div>`);

				for(var i in this.bet_charts) {
					let ch = this.bet_charts[i];
					this.drawChartCircle(ch.id, ch.value);
				}
                
                app.blocks.scores.refreshGame(game);                
			}
		}
	}

    renderBetsSumBlock() {
        var footer = '';
        if(app.config.odds_source == '3') {
            footer = '<div class="betano-sum-logo"><img src="images/logo-sporttwatten.svg" /></div>'
        }else {
            footer = `<div class="odd_sum-info">${app.lang.common.bets_sum_odd_info}</div>`;
        }
        var html = `
            <div id="bets_odd_sum_block" class='bl' >
                <div class="odd_sum-top-title">${app.lang.common.bets_sum_odd_top_title}</div>
                <div class="odd_sum-title">
                    <div class="line-1">${app.lang.common.bets_sum_odd_title_1}</div>
                    <div class="line-2">${app.lang.common.bets_sum_odd_title_2}</div>
                </div>
                <div class="odd_sum-amount"><span id="odd-amount-sum">0</span> €</div>
                <div class="odd_sum-link"><a id="odd-link-sum" class="btn-white-ad betano-odd-link" href='${this.getBetanoOddLink()}' target='_blank'>${app.lang.common.bets_sum_odd_link}</a></div>
                ${footer}
            </div>
        `;
        
        return html;
    }
    
    getBetanoOddLink() {
        var betano_odd_link = 'https://de.betano.com';
        switch(app.config.odds_source) {
            case '1':
                betano_odd_link = 'https://www.mybet.de/de/sport#home';
                break;
            case '3':
                betano_odd_link = 'https://www.sportwetten.de/';
                break;
        }
        return betano_odd_link;
    }
    
    renderGame(game) {
        var _class = '';
        var trends = '';
        
        // Skip game if there is no odds.
        if (!game.options[0].points) {
            return '';
        }
        
        if (app.data.trends) {
            for(var trend of app.data.trends) {
                if (trend.id_fixture == game.id) {
                    trends += `<div class='elem'>
                            <div class='message'>
                                <span>
                                        ${trend.trend}
                                </span>
                            </div>
                        </div>`;
                }
            }
        }
        
        if (trends == '') {
            trends = `<br /><br />${app.lang.common.no_trends}`;
        } else {
            trends = `<div>
                <div class='subscroll'>        
                    ${trends}
                </div>
            </div>`;
        }
		
		var win_message = this.getWinMessage(game);
        
        // Upcomming game
        var time = new Date(game.time);
        var minutes = helpers.fixMinutes(time.getMinutes());
        var date_or_duration;  
        
        var match_progress_bar_width = this.getMatchProgress(game);
        var match_time_box = `<div class="match-time-box"><div class="match-time">${game.timer}'</div><div class="match-progress-box"><div class="match-progress" style="width: ${match_progress_bar_width}%"></div></div></div>`;
        
        var date = '';
        if (game.is_live || game.is_finished) {
            if (game.status_short == 'HT') {
                date_or_duration = `<span class='live'>${app.lang.common.halftime}</span>`;
            } else if (!game.is_finished && !game.is_overtime) {
                date_or_duration = `<span class='live'>${app.lang.common.live_match} | ${game.timer}'</span>`;
            } else if (game.is_overtime) {
                date_or_duration = `<span class='live'>${game.team1.score}:${game.team2.score} ${app.lang.common.completed}</span>`;
            } else {
                date_or_duration = `<span class='live completed'>${app.lang.common.completed}</span>`;
                match_time_box = '';
            }
            var overtime_desc = '';
            if(['ET','AET','BT'].includes(game.status_short)) {
                overtime_desc = 'n.V.';
            }else if(['P','PEN'].includes(game.status_short)) {
                overtime_desc = 'n.E.';                    
            }
            date = `${match_time_box}<b><b>${game.team1.display_score}:${game.team2.display_score}</b></b> ${overtime_desc}<br/>${date_or_duration}`;
        } else {
            date = `<span>${app.lang.common.days_short[time.getDay()]}. ${time.getDate()}.${time.getMonth()+1}.</span><br/><b>${time.getHours()}:${minutes}</b>`;
        }

        var bets_html = this.renderBet(game);
        var betano_logo = "<img class='mybet-logo' src='images/logo-mybet.svg'>";
        if(app.config.odds_source == '3') {
            betano_logo = "<img class='sporttwatten-logo' src='images/logo-sporttwatten.svg'>";
        }
        
        var odd_amount_block = '';
        if(app.config.bets_sum_block && !config.disable_finance && !app.config.odd_amount_block_hide) {
            odd_amount_block = `
                <div class="odd-amount-block">
                    ${betano_logo}
                    <div class="col-text">${app.lang.common.ad3_text}</div>
                    <div class="col-link"><a id="odd-link-${game.id}" class="betano-odd-link" href='${this.getBetanoOddLink()}' target='_blank'></a></div>
                    <div class="col-amount"><span id="odd-amount-${game.id}">0</span> €</div>
                    <div class="col-warning">${app.lang.common.ad3_warning}</div>
                </div>
            `;
        }
        
        //var last_matches_count = game.is_finished ? 6 : 5;

        var html = `
            <div id="bets_game_${game.id}" class='bl ${_class}' data-status='${game.status_short}' matchday='${game.matchday}' matchday='${game.matchday}'>
                <!-- <div class='league'>${app.lang.common.league_name}</div> -->
                <div class='teams top-teams-element'>
                    <div>
                        <div>
                            <div class="team1 team-img"><img src="${config.web_front_url}/${game.team1.img}?home" /></div>
                            <div class="time">${date}</div>
                            <div class="team2 team-img"><img src="${config.web_front_url}/${game.team2.img}?away" /></div>
                        </div>
                        <div>
                            <div class="team1 team-name">${game.team1.name}</div>
                            <div></div>
                            <div class="team1 team-name">${game.team2.name}</div>
                        </div>
                    </div>
                </div>

                <div class="line-separator"></div>
        
                <div class='possible-points'>
                    <div class="line"></div>
                    <div class="title possible-points-title">${app.lang.common.possible_points}</div>
                </div>
                <div class='points-section'>
                    <div class='available-points-content'>
                        ${bets_html}
                    </div>
                </div>
                <div class="win-section">
                    ${win_message}
                </div>
                <div class="statistics">
                    <button class="statistics-btn" data-game_id="${game.id}">
                        <img class="stats" src="images/stats.svg" /><span class="btn-text">${app.lang.common.statistics}</span>
                    </button>
                </div>
                <div class="footer-section">
                    
                </div>
        
                ${odd_amount_block}
            </div>
        `;
        
        return html;
    }

    renderStatistics(game) {

        var trends = '';
        var html = '';

        if (app.data.trends) {
            for(var trend of app.data.trends) {
                if (trend.id_fixture == game.id) {
                    trends += `<div class='message'>
                                    <span>${trend.trend}</span>
                                </div>`;
                }
            }
        }

        trends = trends == '' ?  `<div class="no-trends">${app.lang.common.no_trends}</div>` : `${trends}`;

        var last_matches_count = 5;
        var team1_last_matches = app.data.scores.filter(x => x.is_finished && (x.team1.id == game.team1.id || x.team2.id == game.team1.id)).sort((a,b) => a.time - b.time).reverse().slice(0, last_matches_count);
        var team2_last_matches = app.data.scores.filter(x => x.is_finished && (x.team1.id == game.team2.id || x.team2.id == game.team2.id)).sort((a,b) => a.time - b.time).reverse().slice(0, last_matches_count);
        team1_last_matches = team1_last_matches.reverse();
        team2_last_matches = team2_last_matches.reverse();
        var team1_last_matches_html = '';
        var team2_last_matches_html = '';
        
        for(let m of team1_last_matches) {
            let team = m.team1.id == game.team1.id ? m.team1 : m.team2;
            team1_last_matches_html += `
                <td>
                    <span class="last-game-circle game-result-${team.result_short}">${team.result_short}</span>
                </td>
            `;
        }
        for(let m of team2_last_matches) {
            let team = m.team1.id == game.team2.id ? m.team1 : m.team2;
            team2_last_matches_html += `
                <td class="row-divider">
                    <span class="last-game-circle game-result-${team.result_short}">${team.result_short}</span>
                </td>
            `;
        }
        if(true || !game.is_finished) {
            team1_last_matches_html += `
                <td>
                    <span class="last-game-dot"></span>
                </td>
            `;
            team2_last_matches_html += `
                <td class="row-divider">
                    <span class="last-game-dot"></span>
                </td>
            `;
        }
        
        var teams_table = '';        
        if(game.team1.position && game.team2.position) {
            let teams_table_a = `
                <tr>
                    <td class="row-divider">${game.team1.position}.</td>
                    <td class="row-divider"><div class="logo"> <img class="team-logo" src="${config.web_front_url}/${game.team1.img}" /> <span class="team-name">${game.team1.name}</span></div></td>
                    <td class="row-divider pl-10">${game.team1.matchs_played}</td>
                    <td class="row-divider">${game.team1.goals_for}:${game.team1.goals_for - game.team1.goals_diff}</td>
                    <td class="row-divider pl-10">${game.team1.points}</td>
                </tr>`;

            let teams_table_b = `
                <tr>
                    <td class="row-divider">${game.team2.position}.</td>
                    <td class="row-divider"><div class="logo"><img class="team-logo" src="${config.web_front_url}/${game.team2.img}" /> <span class="team-name">${game.team2.name}</span></div></td>
                    <td class="row-divider pl-10">${game.team2.matchs_played}</td>
                    <td class="row-divider">${game.team2.goals_for}:${game.team2.goals_for - game.team2.goals_diff}</td>
                    <td class="row-divider pl-10">${game.team2.points}</td>
                </tr>
            `;
            let teams_table_body = '';

            if (game.team1.position < game.team2.position) {
                teams_table_body = teams_table_a + teams_table_b;
            } else {
                teams_table_body = teams_table_b + teams_table_a;
            }
        
            teams_table = `
                <div class="statistics-section table-section">
                    <h5 class="section-name">${app.lang.common.table}</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>${app.lang.common.team}</th>
                                <th width="12%">${app.lang.common.matchs_played}</th>
                                <th width="12%">${app.lang.common.goals_for}</th>
                                <th width="12%">${app.lang.common.points}</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${teams_table_body}
                        </tbody>
                    </table>
                </div>
            `;
        }
        
        let sum_points = game.options[1].points + game.options[2].points + game.options[3].points;
        game.options[1].win_probability = 1 / (game.options[1].points / sum_points);
        game.options[2].win_probability = 1 / (game.options[2].points / sum_points);
        game.options[3].win_probability = 1 / (game.options[3].points / sum_points);
        let sum_win_probability = game.options[1].win_probability + game.options[2].win_probability + game.options[3].win_probability;
        game.options[1].win_probability = Math.round(game.options[1].win_probability / sum_win_probability * 100);
        game.options[3].win_probability = Math.round(game.options[3].win_probability / sum_win_probability * 100);
        game.options[2].win_probability = 100 - game.options[1].win_probability - game.options[3].win_probability;
        
        if (game.options[1].win_probability > game.options[3].win_probability) {
            game.options[1].class = 'most';
        } else {
            game.options[3].class = 'most';
        }
        
        //game.options[2].win_probability = Math.round(game.options[2].win_probability / sum_win_probability * 100);
        //console.log(sum_win_probability, game.options);

        var html = `
            <div class="statistics-dialog-box">
                <h4 class="dialog-title">${app.lang.common.statistics}</h4>
                <div class="statistics-section form-probability">
                    <h5 class="section-name">${app.lang.common.win_probability}</h5>
                    <div>
                        <div>
                            ${game.options[1].win_probability} %
                            <div class='${game.options[1].class}' style='height: ${game.options[1].win_probability}px'></div>
                        </div>
                        <div>
                            ${game.options[2].win_probability} %
                            <div class='${game.options[2].class}' style='height: ${game.options[2].win_probability}px'></div>
                        </div>
                        <div>
                            ${game.options[3].win_probability} %
                            <div class='${game.options[3].class}' style='height: ${game.options[3].win_probability}px'></div>
                        </div>
                        <div>
                            ${game.team1.name}
                        </div>
                        <div>
                            ${app.lang.common.remis}
                        </div>
                        <div>
                            ${game.team2.name}
                        </div>
                    </div>
                </div>
                <div class="statistics-section trends-section">
                    <h5 class="section-name">${app.lang.common.trends}</h5>
                    ${trends}
                </div>
                <div class="statistics-section form-section">
                    <h5 class="section-name">${app.lang.common.form}</h5>
                    <table style="border-collapse: collapse">
                        <tbody>
                            <tr>
                                <td>${game.team1.name}</td>
                                ${team1_last_matches_html}
                            </tr>
                            <tr>
                                <td class="row-divider">${game.team2.name}</td>
                                ${team2_last_matches_html}
                            </tr>
                        </tbody>
                    </table>
                    <div class="last-game-legend">
                        <span class="last-game-dot"></span> <span class="legend-text">${app.lang.common.last_game}</span>
                    </div>
                </div>
                ${teams_table}
            </div>
        `;

        return html;
    }
    
    refreshGame(game) {
        if (this.dom) {
            if (game.has_bet) {
                if (game.points > 0) {
                    this.dom.find(`#bets_game_${game.id} .footer-section`).addClass('green');
                    this.dom.find(`#bets_game_${game.id} .footer-section`).removeClass('red');
                } else {
                    this.dom.find(`#bets_game_${game.id} .footer-section`).addClass('red');
                    this.dom.find(`#bets_game_${game.id} .footer-section`).removeClass('green');
                }

                this.dom.find(`#bets_game_${game.id} .footer-section`).html(`
                    <span class="label">${app.lang.common.your_points}</span>
                    <span class="points">${game.points}</span>`);

                if (game.points > 0) {
                    this.dom.find(`#bets_game_${game.id} .points-section`).addClass('green-circle');
                    this.dom.find(`#bets_game_${game.id} .points-section`).removeClass('red-circle');
                }else {
                    this.dom.find(`#bets_game_${game.id} .points-section`).addClass('red-circle');
                    this.dom.find(`#bets_game_${game.id} .points-section`).removeClass('green-circle');
                }
            }else if(!game.is_betable) {
                this.dom.find(`#bets_game_${game.id} .footer-section`).html(`
                    <span class="label">${app.lang.common.your_points}</span>
                    <span class="points">0</span>`);
            }

            this.dom.find(`#bets_game_${game.id}`).attr('data-status', game.status_short);

            if(!game.is_betable) {
                this.dom.find(`#bets_game_${game.id}`).addClass('is_not_betable');
            }else {
                this.dom.find(`#bets_game_${game.id}`).removeClass('is_not_betable');
            }

            // Upcomming game
            var time_or_score = '';
            var time = new Date(game.time);
            var minutes = helpers.fixMinutes(time.getMinutes());
            if (game.is_live || game.is_finished) {
                time_or_score = `<b>${game.team1.display_score}:${game.team2.display_score}</b>`;
            } else {
                time_or_score = `${time.getHours()}:${minutes}`;
            }

            var date = '';
            var date_or_duration = '';
            var match_progress_bar_width = this.getMatchProgress(game);
            var match_time_box = `<div class="match-time-box"><div class="match-time">${game.timer}'</div><div class="match-progress-box"><div class="match-progress" style="width: ${match_progress_bar_width}%"></div></div></div>`;

            if (game.is_live || game.is_finished) {
                if (game.status_short == 'HT') {
                    date_or_duration = `<span class='live'>${app.lang.common.halftime}</span>`;
                } else if (!game.is_finished && !game.is_overtime) {
                    date_or_duration = `<span class='live ongoing'>${app.lang.common.live_match} | ${game.timer}'</span>`;
                } else if (game.is_overtime) {
                    date_or_duration = `<span class='live completed'>${game.team1.score}:${game.team2.score} ${app.lang.common.completed}</span>`;
                } else {
                    date_or_duration = `<span class='live completed'>${app.lang.common.completed}</span>`;
                    match_time_box = '';
                }
                var overtime_desc = '';
                if(['ET','AET','BT'].includes(game.status_short)) {
                    overtime_desc = 'n.V.';
                }else if(['P','PEN'].includes(game.status_short)) {
                    overtime_desc = 'n.E.';                    
                }
                date = `${match_time_box}<b><b>${game.team1.display_score}:${game.team2.display_score}</b></b> ${overtime_desc}<br/>${date_or_duration}`;
            } else {
                date = `<b>${time.getHours()}:${minutes}</b><br/><span>${app.lang.common.days_short[time.getDay()]}. ${time.getDate()}.${time.getMonth()+1}.</span>`;
            }
            
            this.dom.find(`#bets_game_${game.id} .time`).html(date);
            
            this.refreshMatchIndicator(game);
        }
    }

    getMatchProgress(game) {
        var progress = Math.ceil((parseInt(game.timer) / 90) * 100);
        return game.timer < 90 ? progress : 100;
    }
    
    getChartCircleHtml(data) {
        var html = `<div class='chart_circle bet-btn' data-game="${data.game_id}" data-value='${data.i}' id='${data.chart_id}'>
            <div class='svg'></div>
            <div class='points'><span>${data.points}</span></div>
            <div class="label">
                ${data.label}
            </div>
        </div>`;

        return html;
    }

    getChartCircleActiveHtml(data, hidden) {
        var html = `<div class='chart_circle2' id='${data.chart_id}' style='${hidden ? "display: none": ""}'>
            <div class='points'><span>${data.points}</span></div>
            <div class="divider"></div>
            <div class="label">
                ${data.label_active}
            </div>
        </div>`;

        return html;
    }

    drawChartCircle(chart_id, value) {
        var d = helpers.describeArc(26, 26, 25, 0, (value * 307 - 1) + 29);
        var color = config.base_color;
        var color2 = '#DDD';
        if(config.style == 'green') {
            color = '#000';
            color2 = '#E5E5E5';
        }
        var svg = `
            <svg width="53" height="53">
            <circle cx="26" cy="26" r="25" stroke="${color2}" stroke-width="1" fill='white' />
            <path id="arc1" fill="none" stroke="${color}" stroke-width="1" d="${d}" />
            </svg>`;
        $('#'+chart_id+' .svg').html(svg);
    }
	
	getWinMessage(game) {
		var win_message = '';
		switch(parseInt(app.data.bets[game.id])) {
			case 0: win_message = `${app.lang.common.win} ${game.team1.name} <br /><small>${app.lang.common.turbo}</small>`; break;
			case 1: win_message = `${app.lang.common.win} ${game.team1.name}`; break;
			case 2: win_message = `${app.lang.common.draw}`; break;
			case 3: win_message = `${app.lang.common.win} ${game.team2.name}`; break;
			case 4: win_message = `${app.lang.common.win} ${game.team2.name} <br /><small>${app.lang.common.turbo}</small>`; break;
			default: win_message = `<span class="bet_choose_desc">${app.lang.common.bet_choose_desc}</span>`;
		}
		
		return win_message
	}
	
	getMaxMatchdayPoints() {
		var max = 0;
		for(var game of this.getMatches()) {
			if (typeof app.data.bets[game.id] !== 'undefined') {
				var bet = parseInt(app.data.bets[game.id]);
                max += game.options[bet].points;
			}
		}
        
        return max;
	}
    
    getTickHtml(id, type = 'success') {
        var html = `<div class="${type}-checkmark" id='${id}'>
        <div class="check-icon">
          <span class="icon-line line-tip"></span>
          <span class="icon-line line-long"></span>
          <div class="icon-circle"></div>
          <div class="icon-fix"></div>
        </div>
      </div>`;
            
        return html;
    }
    
    scrollToMatch(id, i) {
        var up = this.dom.find('#bets_game_'+id);
        $('#bets_game_'+id+' .available-points-content > div:first-child').append(this.getTickHtml('novisible'));
        if (up.length) {
            if ($(up).is(':visible')) {
                if(this.dom.hasClass('native-scroll') && $(window).width() >= 400) {
                    var pos = $(up)[0].offsetTop - ($(window).width() >= 1030 ? 1030 : $(window).width()) / 2 + 165;
                    $(this.dom_content).find('.block_bets').animate({ scrollTop: pos}, 0);
                }else {
                    var pos = $(up)[0].offsetLeft - $(window).width() / 2 + 165;
                    if(pos < 10) {
                        pos = 0;
                    }
                    $(this.dom_content).animate({ scrollLeft: pos}, 0);
                }
            } else {
                if ($(this.dom_content).css('display') != 'none') {
                    if (i && i < 20) {
                        setTimeout(() => { this.scrollToMatch(id, i ? i+1 : 1); }, 30);
                    }
                }
            }
        }
    }
}
