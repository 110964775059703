class BlockUser {
    constructor() {
        this.source = app;
        this.social = false;        
        
        eventHandler.observe('block_user', ['language_change', 'user_login', 'update_profile'], () => {
            if (!this.social) {
                this.render();
            }
        }, this);
		
		eventHandler.observe('block_user', ['set_data_user'], (user) => {
            if (!this.social) {
                this.updateUserData(user);
            }
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }
    
    setSocial(source) {
        this.source = source;
        this.social = true;        
        return this;
    }

    render() {
        if (!this.dom) {
            return this;
        }

        var html = '';
        if(app.isAuth() && this.source && this.source.user) {
            var arrow = '';
            var previous_position = helpers.formatPreviousPosition(this.source.user.position, this.source.user.previous_position);

            if (this.source.user.previous_position == 0 || this.source.user.position == this.source.user.previous_position) {
                arrow = '';
                previous_position = '';
            }else if (this.source.user.position > this.source.user.previous_position) {
                arrow = `<img class='arrow-indicator' src='images/${config.style ? config.style+'/' : ''}down-arrow2.svg' />`;
            } else if (this.source.user.position < this.source.user.previous_position) {
                arrow = `<img class='arrow-indicator' src='images/${config.style ? config.style+'/' : ''}up-arrow2.svg' />`;
            }
            
            var avatar_url = app.getUserAvatar(this.source.user);
            var division = this.source.user.division_to_show;
            
            var overall_ranking_class = this.source.user.position.toString().length > 4 ? 'small' : '';
            
            // Liga label: <div>${division}<br /><small>${app.lang.common.liga}</small></div>
            var division_html = '';
            if(division) {
                division_html = `<div class="go-to-division" ><small>${app.lang.common.liga}</small><br />${division}</div>`;
            }
            html = `            
                <div class='block_user_marg'>
                    <div class='block_user bl'>
                        <div class="go-to-overall_ranking ${overall_ranking_class}" >
                            ${this.source.user.position}.
                            <span>
                                ${arrow}
                                ${previous_position}                        
                            </span>
                            <br />
                            <small>${app.lang.common.rank}</small>
                        </div>
                        <div>
                            <div>
                                <img class="img-avatar go-to-change-avatar" src='${avatar_url}' alt='' onerror="app.errorUserAvatar(this)" /><br />
                                ${division_html}
                            </div>
                            <p class="go-to-change-avatar">${this.source.user.name}</p>
                        </div>
                        <div class="go-to-overall_ranking" >
                            <span class='score'>${helpers.formatPoints(this.source.user.score)}</span>
                            <br />
                            <small>${app.lang.common.points}</small>
                        </div>
                    </div>
                </div>
            `;
        } else {

        }

        this.dom.html(html);

        if(!this.social) {
            this.dom.find('.go-to-overall_ranking').click(e => {
                app.renderPage('group', {id: 'overall_ranking'});
            });

            this.dom.find('.go-to-division').click(e => {
                app.renderPage('group', {id: app.user.id_group});
            });

            this.dom.find('.go-to-change-avatar').click(e => {
                //app.renderPage('edit_profile', {change_avatar: 1});
                app.blocks.settings.render();
            });
        }else {
            this.dom.find('.go-to-division').click(e => {
                app.renderPage('group', {division: this.source.user.division, position: this.source.user.division_position});
            });
        }
        
        return this;
    }
	
	updateUserData(user) {
		this.render();
	}
}