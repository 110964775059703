class BlockRegister {
    constructor(dom) {
        this.dom = dom;
        this.isSubmited = false;
        this.loadedScripts = false;

        eventHandler.observe('block_register', ['language_change', 'user_register', 'user_login'], () => {
            if(!app.isAuth()) {
                this.render();
            }
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    loadScripts() {
        if(this.loadedScripts || !app.config.recaptcha_enabled) {
            return ;
        }
        $('body').append(`<script src="https://www.google.com/recaptcha/api.js?render=${app.config.recaptcha_site_key}"></script>`);
        this.loadedScripts = true;
    }

    render() {
        var title_html = '';
        if(app.lang.user.hi) {
            title_html = `<h1 class="form-title">${app.lang.user.hi}</h1>`;
        }

        var extra_buttons = '';
        
        if(app.isMybet) {
            extra_buttons += `
                <div class="form-group">
                    <button type="button" class="btn-black btn-connect_with_mybet" >${app.lang.user.connect_with_mybet}</button>
                </div>
            `;
        }
        
        // sport.de
        var fb = `<div class="form-group other-login-forms">
                            <span>${app.lang.user.other_login_forms}</span>
                        </div>
                        <div class="form-group facebook-login-form">
                            <button type="submit" class="btn-black btn-fb" ><span class="facebook-f">f</span>${app.lang.user.continue_with_facebook}</button>
                        </div>`;
        
        
        let privacy = config.style == 'fussballdaten' ? app.lang.user.register_confirm_datenschutz_fussballdaten : app.lang.user.register_confirm_datenschutz;
        
        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.user.register}</h1>
                <div class='block_register bl'>
                    <form class="form-register form">
                        ${title_html}
                        <input class='groupid' type="hidden" name="groupid" value="${app.groupid_register_user}" />

                        <div class="form-group login-group form-group-border">
                            <input type="text" name="login" value="" placeholder="${app.lang.user.user_name}" />
                        </div>
                        <div class="form-group email-group form-group-border">
                            <input type="text" name="email" value="" placeholder="${app.lang.user.email}" />
                        </div>
                        <div class="form-group password-group form-group-border">
                            <img src="images/showpassword2.svg" class="show-password-icon">
                            <input type="password" name="password" class="password-input" value="" placeholder="${app.lang.user.password}" />
                        </div>
                        <div class="form-group confirm-group">
                            <input type="checkbox" name="confirm_terms_of_use" id="confirm_terms_of_use" value="1" />
                            <div><span><label for="confirm_terms_of_use">${app.lang.user.register_confirm_terms_of_use}<br /> ${privacy}</label></span></div>
                        </div>
        
                        <div class="form-group line-buttons">
                            <button type="submit" class="tip-button btn-create-account" >${app.lang.user.create_account}</button>
                        </div>
                        <div class="form-group invalid_recaptcha error">${app.lang.user.invalid_recaptcha}</div>
        
                        ${extra_buttons}
                    </form>
                    
                </div>
            </div>
        `;

        this.dom.html(html)

        this.$form = $(this.dom).find('form');
        this.$form.submit(e => {
            this.onsubmit();
            return false;
        });
        this.form = new Form(this.$form, {
            login: {required: true, valid: {min_length: 4}},
            email: {required: true, valid: {email: true}},
            groupid: {required: false},
            password: {required: true, valid: {min_length: 4}},
            confirm_terms_of_use: {required: true},
            //confirm_datenschutz: {required: true},
        });

        this.$form.find('.show-password-icon').click(e => {
            var password_input = this.$form.find('.password-input');
            if(!password_input.hasClass('password-visible')) {
                password_input.addClass('password-visible').attr('type', 'text');
            }else {
                password_input.removeClass('password-visible').attr('type', 'password');
            }
        });

        this.$form.find('.btn-login').click(e => {
            app.renderPage('login');
        });

        this.$form.find('.btn-connect_with_mybet').click(e => {
            window.parent.postMessage({type: 'login'}, "*");
            return false;
        });
        
        this.$form.find('.btn-fb').click(e => {
            if (config.app_type == 'mobile') {
                Lib.Facebook.Login(response => {
                    if (response.authResponse) {
                        app.blocks.login.continueWithFb(response.authResponse);
                        app.setUserCookie('rt90_register', '1');
                    } else {
                        console.log('User cancelled login or did not fully authorize.');
                    }
                });
            } else {
                FB.login(response => {
                    if (response.authResponse) {
                        app.blocks.login.continueWithFb(response.authResponse);
                        app.setUserCookie('rt90_register', '1');
                    } else {
                        console.log('User cancelled login or did not fully authorize.');
                    }
                }, {scope: 'email'});
            }

            return false;
        });

        return this;
    }

    async recaptcha() {
        try {
            this.$form.find('.invalid_recaptcha').hide();
            
            var data = this.form.getData();
            
            if (!app.config.recaptcha_enabled || !app.config.recaptcha_site_key) {
            } else {
                var token = await grecaptcha.execute(app.config.recaptcha_site_key, {action: 'submit'});
                if(!token) {
                    this.$form.find('.invalid_recaptcha').show();
                    return false;
                }
                
                data.recaptcha_token = token;
            }

            var result = await ws.request('user/register', data);
            if(result.success) {
                app.first_login = true;
                app.setUserCookie('rt90_register', '1');
                if(result.rigister_confirm == '1') {
                    app.renderPage('login', {sent_rigister_confirm: 1, login: data.login});
                }else {
                    app.login(result.user, true);
                }
            }else {
                if(result.messages && result.messages.recaptcha && result.messages.recaptcha[0] == 'not_valid') {
                    this.$form.find('.invalid_recaptcha').show();
                }
                this.form.setServerInvalids(result.messages, data);
            }
        }catch(err) {
            console.log(err);
        }
    }

    async onsubmit() {
        if(this.isSubmited) {
            return ;
        }
        this.isSubmited = true;

        try {
            if(!this.form.isValid()) {
                this.isSubmited = false;
                return ;
            }
            if(!app.config.recaptcha_enabled) {
                    this.recaptcha();                
            }else {
                grecaptcha.ready(() => {
                    this.recaptcha();
                });
            }
            /*
            var data = this.form.getData();
            var result = await ws.request('user/register', data);
            if(result.success) {
                app.first_login = true;
                app.login(result.user, true);
                app.setUserCookie('rt90_register', '1');
            }else {
                this.form.setServerInvalids(result.messages, data);
            }
            */
        }catch(err) {
            console.log(err);
        }

        this.isSubmited = false;
    }

}