class BlockContentPage {
    constructor(dom) {
        this.dom = dom;

        eventHandler.observe('block_content_page', ['language_change'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    render() {
        var html = `
            <h1 class="page-title">${app.lang.common.login}</h1>
            <div class='content_page'>
                
            </div>            
        `;

        //<a href='#home'><button class='btn-black'>${app.lang.common.home}</button></a>

        this.dom.html(html)
        return this;
    }

    setTitle(title) {
        this.dom.find('h1').html(title);
    }
    
    async setContent(title) {
        var html = '';
        //this.setTitle(app.lang.common.menu[title]);
        
        switch(title) {
            case 'about_us':
                this.setTitle(app.lang.common.menu[title]);
                html = `
                <p>Krake Paul oder Waschbär Wladi – nachdem eine ganze Armada an Tierversuchen mehr oder weniger
                kläglich gescheitert ist, bist jetzt Du dran. Beweise Dein Fußball-Wissen, katapultier Dich in die
                Tipster Bundesliga und werde zum besten Fussball-Orakel aller Zeiten.</p>

                <p>Viele *** ECHT COOLE FEATURES *** warten auf Dich:</p>
                <p><b>FAIRES PUNKTE-SYSTEM - THE BEST!</b><br />
                Du tippst auf einen Außenseiter und Dein Tipp gewinnt? Respekt! Du wirst für Dein Fußballwissen
                und Deinen Mut belohnt und kassierst mehr Punkte für Deinen Außenseiter-Tipp.
                </p>

                <p>
                <b>FROM ZERO TO HERO</b><br />
                Neu dabei? Es ist nie zu spät, in der Betano World Tipster League einzusteigen. Nach jedem 2.
                Spieltag startet eine neue Saison bei der Du auf- oder absteigen kannst. Hast Du das Zeug für die
                Tipster Bundesliga?
                </p>

                <p>
                <b>HENGST ODER PONY?</b><br />
                Sicher kennst Du das aus anderen Tippspielen: Du tippst auf einen klaren Sieg Deines Teams - 3:0!
                Das Spiel endet 4:0 und obwohl Dein Tipp eigentlich ein Volltreffer war bekommst Du genauso wenig
                Punkte wie Dein kleiner Bruder, der 1:0 getippt und von Fußball überhaupt keine Ahnung hat. Wir
                machen Schluss mit diesem frustrierenden Erlebnis. Bei uns tippst Du entweder - zahm wie ein Pony -
                auf einen einfachen Sieg Deines Teams oder Du zeigst Eier und zündest den Race Turbo. Siegt Dein
                Team dann mit mindestens 2 Toren Vorsprung, bekommst Du *boom* mehr Punkte und bist der
                Hengst, die #1 auf der Weide. Yihaaa!
                </p>                
                <p>Hast Du Fragen oder Feedback, das Du mit uns teilen möchtest? Dann schreib uns eine Nachricht. Du
                erreichst uns unter contact@worldtipsterleague.com.
                </p>`;
                break;
                
            // case 'contact':
            //     this.setTitle(app.lang.common.menu[title]);
            //     html = `
            //     <p>Du hast Fragen oder möchtest uns Feedback geben? Wir freuen uns über dein Feedback, um
            //     das Erlebnis für dich immer weiter zu verbessern. Kontaktier uns bitte per E-Mail an
            //     contact@worldtipsterleague.com.</p>`;
            //     break;

            case 'privacy': 
                app.topbar();
                
                if (config.style == 'mybet') {
                    // It goes form the translations!
                    break; 
                }
                
                html = `
                    <h1 class="privacy-page-title">${app.lang.common.privacy}</h1>

                    Datenschutz <br />
                    Der Schutz Ihrer Daten hat für uns oberste Priorität. Die Erhebung sowie die Verarbeitung Ihrer personenbezogenen Daten erfolgt deshalb unter strenger Beachtung geltender Vorschriften, 
                    insbesondere der Datenschutzgrundverordnung (DSGVO). Die im Folgenden detailliert beschriebene Datenschutzerklärung gilt für die Nutzung auf unserer Webseite tipptastic.de. 
                    Im Folgenden als „Angebot“ bezeichnet.
                    <br /><br />

                    <b>Verantwortlicher</b><br />
                    Verantwortlich für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten im Sinne von Art. 4 Nr. 7 DSGVO ist die Arena 365 GmbH, Olendeelskoppel 29, 22397 Hamburg. 
                    Diese wird vertreten durch den Geschäftsführer Michael Proch. Kontaktieren können Sie uns jederzeit unter contact@tipptastic.de Sollten Sie der Erhebung, Verarbeitung oder 
                    Nutzung Ihrer personenbezogenen Daten durch die Arena365 GmbH insgesamt oder in Teilen widersprechen wollen, können Sie Ihren Widerspruch an den Verantwortlichen richten. 
                    Unser Tipp: Speichern Sie diese Datenschutzerklärung ab. 
                    <br /><br />

                    <b>2. Allgemeine Zwecke der Datenverarbeitung</b><br />
                    Ihre personenbezogenen Daten werden durch uns zum Zweck des Betriebs unserer Angebote verwendet. Für die Nutzung unseres Angebotes ist die Angabe einer E-Mail-Adresse nötig, um ggfs.
                    Ein neues Passwort zu generieren und zu schicken. Die Bilddateien Ihres Mobilgerätes werden – mit Ausnahme des ausgewählten Profilfotos – von der Arena365 GmbH nicht gespeichert oder in anderer 
                    Form verarbeitet. Ihr Profilfoto ist in den Apps für alle Mitglieder Ihrer Gruppe sichtbar, aber auch nur für diese. Wenn Sie in unseren Apps eine eigene Gruppe gründen und Bekannte in diese 
                    Gruppe einladen, kann dies z. Bsp. über eine WhatsApp-Einladung, eine iMessage, SMS oder eine E-Mail erfolgen. Die Kontaktaufnahme erfolgt in allen Fällen der Einladung direkt durch den Nutzer, 
                    niemals durch die Arena365 GmbH. Eine Einwilligung des Empfängers ist somit nicht notwendig. Zu keinem Zeitpunkt werden Ihre Kontakte in unseren Datenbanken gespeichert. 
                    <br /><br />

                    <b>3. Konkrete Datenverwendung</b> <br /><br />

                    <b>3.1. Zugriffsdaten</b><br /> 
                    Wir sammeln Informationen über Sie, wenn Sie unsere Angebote nutzen. Ihre Zugriffe generieren sogenannte „Access Logs“, als kleine Textdateien, mit folgenden Daten: • URL der abgerufenen Daten 
                    • Datum und Uhrzeit des Abrufs • übertragene Datenmenge • Meldung über erfolgreichen Abruf (HTTP response code) • IP Adresse Diese Daten werden nicht automatisiert ausgewertet und dienen 
                    ausschließlich dazu, den ordnungsgemäßen Betrieb der Plattform sicherzustellen. 
                    <br /><br />

                    <b>3.2. Hosting</b><br />
                    Wir nutzen für unsere Angebote einen Hosting-Dienstleister. Die bei diesem in Anspruch genommenen Leistungen dienen der Sicherstellung von: Infrastruktur- und Plattformdienstleistungen, 
                    Rechenkapazität, Speicherplatz, Datenbankdiensten, Sicherheitsleistungen sowie technischen Wartungsdienstleistungen. Ausdrücklich nicht verarbeitet werden durch den Hosting-Dienstleister 
                    unserer Angebote: Bestands- und Vertragsdaten, Kontaktdaten, Inhaltsdaten, Nutzungsdaten, sogenannte Metadaten und Kommunikationsdaten von Nutzern unserer Angebote. 
                    <br /><br />

                    <b>3.3 Cookies</b><br />
                    Die Internetseiten der Arena365 GmbH verwenden Cookies. Cookies sind Textdateien, welche über einen Internetbrowser auf einem Computersystem abgelegt und gespeichert werden. 
                    Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer 
                    Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten 
                    und Servern, den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige 
                    Cookie-ID wiedererkannt und identifiziert werden. Durch den Einsatz von Cookies können wir den Besuchern unserer Internetseiten nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung 
                    nicht möglich wären. Mittels eines Cookies können die Informationen und Angebote auf unseren Internetseiten im Sinne des Benutzers optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt, 
                    die Benutzer unserer Internetseiten wiederzuerkennen. Zweck dieser Wiedererkennung ist es, den Nutzern die Verwendung unserer Internetseiten zu erleichtern. Die betroffene Person kann die Setzung von 
                    Cookies durch unsere Internetseiten jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen.
                    Ferner können bereits gesetzte Cookies jederzeit über einen Internetbrowser oder andere Softwareprogramme gelöscht werden. Dies ist in allen gängigen Browsern möglich.
                    Deaktiviert die betroffene Person die Setzung von Cookies in dem genutzten Internetbrowser, sind unter Umständen nicht alle Funktionen unserer Internetseiten vollumfänglich nutzbar. 
                    <br /><br />

                    <b>3.4 Google Analytics</b><br />
                    Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) 
                    Google Analytics, einen Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies. Die durch den Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer
                    werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische 
                    Datenschutzrecht einzuhalten (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active). Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres
                    Onlineangebotes durch die Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und um weitere, mit der Nutzung dieses Onlineangebotes und der
                    Internetnutzung verbundene Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt werden. Wir setzen Google Analytics
                    nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens 
                    über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die von dem Browser des 
                    Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung ihrer Browser-Software 
                    verhindern sowie die Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, 
                    indem sie das unter folgendem Link verfügbare Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de. Weitere Informationen zur Datennutzung durch Google, 
                    Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von Google (https://policies.google.com/technologies/ads) sowie in den Einstellungen für die Darstellung von 
                    Werbeeinblendungen durch Google (https://adssettings.google.com/authenticated). Die personenbezogenen Daten der Nutzer werden nach 14 Monaten gelöscht oder anonymisiert. 
                    <br /><br />

                    <b>3.5 Daten zur Erfüllung unserer vertraglichen Pflichten</b><br />
                    Wir verarbeiten personenbezogene Daten, die für die Erfüllung unserer vertraglichen Pflichten notwendig sind. Das sind unter anderem der Username oder das Profilbild. 
                    Die Erhebung dieser Daten ist für den Vertragsabschluss erforderlich. Die Löschung der Daten erfolgt nach Ablauf der Gewährleistungsfristen und gesetzlicher Aufbewahrungsfristen. 
                    Daten, die mit einem Nutzerkonto verknüpft sind, bleiben für die Zeit der Führung dieses Kontos erhalten. Die Rechtsgrundlage für die Verarbeitung dieser Daten ist Art. 6 Abs. 1 S. 1 b) DSGVO.
                    <br /><br />

                    <b>3.6 Nutzerkonto</b><br /> 
                    Wir speichern folgende Ihrer Nutzerdaten: • Von Ihnen gewählter Nickname • Ihre registrierte E-Mail-Adresse • Von Ihnen hochgeladenes Profilbild • Gruppenzugehörigkeit • Von Ihnen hochgeladenes 
                    Gruppenprofil-Foto • Spielergebnisse • Alle Aktionen, die für den Spielerverlauf relevant sind • Datum und Uhrzeit von “Logins“. 
                    Die Speicherung dieser Daten erfolgt ausschließlich, um den Betrieb des Spiels sicherzustellen. 
                    <br /><br />

                    <b>3.7 Kontaktaufnahme per E-Mail</b><br /> 
                    Wenn Sie mit uns per E-Mail Kontakt aufnehmen, werden Ihre Angaben zur Bearbeitung der Anfrage (z. B. Support) und für mögliche weitere mit der 
                    Kontaktaufnahme verbunden Folgefragen verarbeitet. Erfolgt die Datenverarbeitung zur Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen, bzw., wenn Sie bereits unser 
                    Kunde sind, zur Durchführung des Vertrages, ist Rechtsgrundlage für diese Datenverarbeitung Art. 6 Abs. 1 S. 1 b) DSGVO.
                    Weitere personenbezogene Daten verarbeiten wir nur, wenn Sie dazu einwilligen (Art. 6 Abs. 1 S. 1 a) DSGVO) oder wir ein berechtigtes Interesse an der Verarbeitung Ihrer 
                    Daten haben (Art. 6 Abs. 1 S. 1 f) DSGVO) 
                    <br /><br />

                    <b>4. Speicherdauer</b><br />
                    Sofern nicht spezifisch angegeben speichern wir personenbezogene Daten nur so lange, wie dies zur Erfüllung der 
                    verfolgten Zwecke notwendig ist. In einigen Fällen sieht der Gesetzgeber die Aufbewahrung von personenbezogenen Daten vor, etwa im Steuer- oder Handelsrecht. In diesen Fällen werden die Daten 
                    von uns lediglich für diese gesetzlichen Zwecke weiter gespeichert, aber nicht anderweitig verarbeitet und nach Ablauf der gesetzlichen Aufbewahrungsfrist gelöscht.
                    <br /><br />

                    <b>5. Ihre Rechte als von der Datenverarbeitung Betroffener</b><br />
                    Gesetzlich haben Sie unterschiedliche Rechte bezüglich Ihrer personenbezogenen Daten. Möchten Sie diese Rechte geltend machen, so richten Sie Ihre Anfrage bitte per E-Mail 
                    oder Post unter eindeutiger Identifizierung Ihrer Person und Angabe Ihres Benutzernamens oder Ihrer E-Mail-Adresse an die in Ziffer 1 genannte Adresse. 
                    Nachfolgend finden Sie eine Übersicht über Ihre Rechte. • Recht auf Bestätigung und Auskunft über die Verarbeitung Ihrer personenbezogenen Daten • Recht auf Berichtigung und ggf. auch das 
                    Verlangen der Vervollständigung Sie betreffender personenbezogener Daten • Recht auf Löschung („Recht auf Vergessenwerden“) gemäß Art. 17 Abs. 1 DSGVO • Recht auf Einschränkung der Verarbeitung 
                    Ihrer personenbezogenen Daten • Recht auf Datenübertragung Sie betreffender personenbezogener Daten an Sie • Recht des Widerspruchs einer rechtmäßigen Verarbeitung Ihrer personenbezogenen 
                    Daten durch uns, wenn sich dies aus Ihrer besonderen Situation begründet und unsere Interessen an der Verarbeitung nicht überwiegen • Recht auf Widerruf einer durch Sie erteilten datenschutzrechtlichen 
                    Einwilligung gem. Art. 7 (3) DSGVO • Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten rechtswidrig ist.
                    <br /><br />

                    <b>6. Datensicherheit</b><br /> 
                    Zur Sicherung Ihrer Daten unterhalten wir technische und organisatorische Sicherungsmaßnahmen entsprechend Art. 32 DSGVO, die wir immer wieder dem Stand der Technik anpassen. 
                    Wir gewährleisten nicht, dass unser Angebot zu bestimmten Zeiten zur Verfügung steht; Störungen, Unterbrechungen oder Ausfälle können nicht ausgeschlossen werden. Die von uns verwendeten Server 
                    werden regelmäßig sorgfältig gesichert. 
                    <br /><br /><br />

                    <b>7. Weitergabe von Daten an Dritte</b><br /> 
                    Für den Fall, dass wir bestimmte Teile der Datenverarbeitung auslagern („Auftragsverarbeitung“), verpflichten wir 
                    Auftragsverarbeiter vertraglich dazu, personenbezogene Daten nur im Einklang mit den Anforderungen der Datenschutzgesetze zu verwenden und den Schutz der betreffenden Person zu gewährleisten. 
                    Eine Datenübertragung an Stellen oder Personen außerhalb der EU außerhalb des in dieser Erklärung in Ziffer 4 genannten Falls findet nicht statt.
                    <br /><br />

                    <b>8. Änderung der Datenschutzerklärung</b><br />
                    Sollten sich datenschutzrechtliche Vorschriften ändern, werden wir diese Datenschutzerklärung möglicherweise aktualisieren. 
                    Gültig ist jeweils die aktuelle, unter https://tipptastic.de/datenschutz abrufbare Version.

                    <br /><br /><br /><br /><br /><br /><br /><br />
                `;

            break;
                
            case 'impress':
                app.topbar();
                
                if (config.style == 'mybet') {
                    // It goes form the translations!
                    break; 
                }
                
                html = `

                    <h1 class="impress-page-title">${app.lang.common.impress}</h1>
                    Angaben gemäß § 5 TMG <br /><br />
                    arena365 GmbH <br />
                    Olendeelskoppel 29 <br />
                    22397 Hamburg <br /><br />
                    Handelsregister: HRB 149126 <br />
                    Registergericht: Amtsgericht Hamburg <br /><br />

                    <b>Vertreten durch:</b><br />
                    Michael Proch <br /><br />

                    <b>Kontakt</b><br />
                    E-Mail: contact@tipptatstic.de <br /><br />

                    <b>Umsatzsteuer-ID</b> <br />
                    Umsatzsteuer-Identifikationsnummer gemäß § 27 a <br />
                    Umsatzsteuergesetz: DE315033886 <br /><br /><br />


                    <b>EU-Streitschlichtung</b><br /> <br />

                    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr. 
                    Unsere E-Mail-Adresse finden Sie oben im Impressum. <br /><br />


                    <b>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</b><br /><br />

                    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.

                    <br /><br /><br />

                    <b>Haftung für Inhalte</b><br /><br />

                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen 
                    Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte
                    fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                    <br /><br />

                    Verpflichtungen zur Entfernung oder Sperrung der
                    Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
                    Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. 
                    <br /><br /><br />

                    <b>Haftung für Links</b><br /><br />
                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren
                    Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
                    Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung 
                    nicht erkennbar.
                    <br /><br />

                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen 
                    werden wir derartige Links umgehend entfernen. 
                    <br /><br /><br />

                    <b>Urheberrecht</b><br /><br />
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, 
                    Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser 
                    Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. 
                    <br /><br />

                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
                    Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis.
                    Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.

                    <br /><br /><br /><br /><br /><br /><br /><br />
                `;
                break;
                
            case 'faq':
                this.setTitle(app.lang.common.menu[title]);
                
                if (!this.faq) {
                    this.faq = await ws.request('data/getFaq');
                }
                html = `<div class="accordion">`;

                for(let q of this.faq) {
                    html += `
                    <section>
                        <header>
                            <div class="text">${q.question}</div>
                            <div class="arrow"><img class="down" src='images/green/angle-down-solid.svg' alt='' /></div>
                        </header>
                        <main>
                            <p>${q.content}</p>
                        </main>
                    </section>
                    `;
                }

                html += `</div>`;
                break;
            case 'how_it_works':
                app.topbar(app.lang.frontpage.how_it_works.title);
                html = `
                    <div class="top-box">
                        <h1 class="how-page-title">${app.lang.frontpage.how_it_works.title}</h1>

                        <div class="header-info">
                            ${app.lang.frontpage.how_it_works.header_info}
                        </div>

                        <div class="navigation">

                            <div class="nav-section nav-section-1" data-section_id="section-1">
                                ${app.lang.frontpage.how_it_works.section_1_title} 
                                <a href="#how_it_works" class="nav-arrow-link"><img class="mm20 nav-arrow" src='images/green/arrow_down.svg' alt='' /></a>
                            </div>
                            <div class="nav-section nav-section-2" data-section_id="section-2">
                                ${app.lang.frontpage.how_it_works.section_2_title} 
                                <a href="#how_it_works" class="nav-arrow-link"><img class="mm20 nav-arrow" src='images/green/arrow_down.svg' alt='' /></a>
                            </div>
                            <div class="nav-section nav-section-3" data-section_id="section-3">
                                ${app.lang.frontpage.how_it_works.section_3_title} 
                                <a href="#how_it_works" class="nav-arrow-link"><img class="mm20 nav-arrow" src='images/green/arrow_down.svg' alt='' /></a>
                            </div>
                            <div class="nav-section nav-section-4" data-section_id="section-4">
                                ${app.lang.frontpage.how_it_works.section_4_title} 
                                <a href="#how_it_works" class="nav-arrow-link"><img class="mm20 nav-arrow" src='images/green/arrow_down.svg' alt='' /></a>
                            </div>

                        </div>
                    </div>

                        <div class="sections">

                            <div class="section-container section-1-container">
                                <div id="section-1" class="section">
                                    <div class="image">
                                        <img src='images/green/how1.svg' alt='' />
                                    </div>
                                    <div class="info">
                                        <div class="info-header">
                                            ${app.lang.frontpage.how_it_works.section_1_title}
                                        </div>
                                        <div class="text">
                                            ${app.lang.frontpage.how_it_works.section_1_text}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="section-container section-2-container">
                                <div id="section-2" class="section">
                                    <div class="image">
                                        <img src='images/green/how2.svg' alt='' />
                                    </div>
                                    <div class="info">
                                        <div class="info-header">
                                            ${app.lang.frontpage.how_it_works.section_2_title}
                                        </div>
                                        <div class="text">
                                            ${app.lang.frontpage.how_it_works.section_2_text}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="section-container section-3-container">
                                <div id="section-3" class="section">
                                    <div class="image">
                                        <img src='images/green/how3.svg' alt='' />
                                    </div>
                                    <div class="info">
                                        <div class="info-header">
                                            ${app.lang.frontpage.how_it_works.section_3_title}?
                                        </div>
                                        <div class="text">
                                            ${app.lang.frontpage.how_it_works.section_3_text}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="section-container section-4-container">
                                <div id="section-4" class="section">
                                    <div class="image">
                                        <img src='images/green/how4.svg' alt='' />
                                    </div>
                                    <div class="info">
                                        <div class="info-header">
                                            ${app.lang.frontpage.how_it_works.section_4_title}
                                        </div>
                                        <div class="text">
                                            ${app.lang.frontpage.how_it_works.section_4_text}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                `;
                break;
            case 'after_registration':
                this.setTitle(app.lang.user.after_registration_title);
                html = `
                    <div class="block-after_registration">
                        <div class="desc">${app.lang.user.after_registration_desc}</div>
                                    
                        <div class="box-choose choose1">
                            <div class="choose-title">${app.lang.user.after_registration_choose1_title}</div>
                            <div class="choose-desc">${app.lang.user.after_registration_choose1_desc}</div>
                        </div>
                        <div class="box-choose choose2">
                            <div class="choose-title">${app.lang.user.after_registration_choose2_title}</div>
                            <div class="choose-desc">${app.lang.user.after_registration_choose2_desc}</div>
                        </div>
                                    
                        <button type="button" class="tip-button disabled" >${app.lang.common.next}</button>
                    </div>
                `;
                break;
            case 'terms_of_use':
                //app.topbar(app.lang.frontpage.how_it_works.title);
                app.topbar();
                // it goes from the translations
                break;
            case 'unsubscribed':
                app.topbar();
                this.setTitle(app.lang.user.unsubscribed_title);
                html = `
                    <div class="block-unsubscribed">
                        <div>${app.lang.user.unsubscribed_email_address.replace('{email}', app.current_params.get('unsubscribed_email'))}</div>
                        <br/>
                        <br/>
                        <div>${app.lang.user.unsubscribed_content}</div>
                                            
                        <a href="#settings_notifications" class="tip-button" >${app.lang.user.unsubscribed_button}</a>
                    </div>
                `;
                break;
        }
        
        var self = this;

        if(html == '') {
            var tx = app.lang.common.content_page[title];
            if(tx != 'default') {
                html = tx;
            }
        }
        
        if (title == 'terms_of_use' && !app.checkTermsOfUseAcceptance()) {
            html += `
                <div class="buttons">
                    <a class='tip-button btn-accept' onclick='app.acceptTermsOfUseAcceptance();'>${app.lang.user.accept}</a>
                    <a class='tip-button tip-button-transparent btn-reject' onclick='app.blocks.terms_of_use_approve.renderRejectedTermsOfUseAcceptance();'>${app.lang.user.reject}</a>
                </div>
            `;
        }
        
        this.dom.find('.content_page').html(html);

        this.dom.find('.accordion section header').click(function() {
            $(this).parent().toggleClass('active');
        });

        this.dom.find('.nav-section').click(function() {
            $([document.documentElement, document.body]).animate({
                 scrollTop: $("#" + $(this).data('section_id')).offset().top
            }, 1000);
        });
        
        this.dom.find('.box-choose').click(function() {
            self.dom.find('.box-choose').removeClass('selected');
            $(this).addClass('selected');
            self.dom.find('.disabled').removeClass('disabled');
        });
        
        if(title == 'after_registration') {
            this.dom.find('button').click(function() {
                if (!$(this).hasClass('disabled')) {
                    if(self.dom.find('.box-choose.choose2').hasClass('selected')) {
                        app.renderPage('create_group_step_by_step');
                    }else {
                        app.renderPage('leagues_after_registration');
                    }
                }
            });
        }
        
        
    }

    scrollTo(open) {
        if(open) {
            this.dom.find('.accordion section header').each(function() {
                if(this.innerText == open) {
                    $(this).trigger('click');
                    var top = $(this).position().top;
                    var st = top - 50;
                    if(st > 0) {
                        $(document.scrollingElement).scrollTop(st);
                    }
                }
            });
        }
    }

    changePage(page) { 
        var t = this;      

        if (page.current != 'home' && page.previous == 'home') {
            this.dom.find('img.menu').hide();
        }

        if (page.current == 'home' && page.previous != 'home') {
            this.dom.find('img.menu').show();
            this.dom.find('.back').hide();
        }

        if (page.current == 'group_search' && page.previous != 'group_search') {
            this.dom.find('img.cog').show();
        }

        if (page.current != 'group_search' && page.previous == 'group_search') {
            this.dom.find('img.cog').hide();
        }     
        
        if (page.current == 'menu') {
            //event.stopPropagation();
            if (!t.menu_visible) {
                t.menu_visible = true;
                t.dom.find('div.menu, .back').show();
                t.dom.find('img.menu').hide();
                app.showOverlay();
            } else {
                t.menu_visible = false;
                t.dom.find('div.menu').hide();
                app.hideOverlay();
            }
        } 

        if (page.current != 'menu' && page.previous == 'menu') {
            if (t.menu_visible) {
                t.menu_visible = false;
                t.dom.find('div.menu').hide();
                app.hideOverlay();
            }
        }
    }
}
