class BlockFooter {
    constructor() {
        
    }

    setDom(dom) {
    }
    
    getHtml() {
        if (config.style == 'fussballdaten') {
            let html = `
            <div class='row'>
                <div>
                    <a href=''><img src='images/${config.style}/footer_logo.svg' alt='' /></a>
                </div>
                <div class='ta-r'>
                    <a target='_blank' href='https://www.fussballdaten.de/datenschutzerklaerung/'>${app.lang.common.menu.privacy}</a>
                    <a class='' href='#contact'>${app.lang.common.menu.contact}</a>
                    <a target='_blank' href='https://www.fussballdaten.de/impressum/'>${app.lang.common.menu.impress}</a>
                </div>
            </div>`;

            return html;
        } else {
            let html = `
            <div class='row'>
                <div>
                    <a href=''><img src='images/${config.style}/footer_logo.svg' alt='' /></a>
                </div>
                <div class='ta-r'>
                    <a href='#privacy'>${app.lang.common.menu.privacy}</a>
                    <a class='' href='#contact'>${app.lang.common.menu.contact}</a>
                    <a href='#impress'>${app.lang.common.menu.impress}</a>
                </div>
            </div>`;

            return html;
        }
    }
}