
var Langs = Langs || {};
Langs.en = Langs.en || {};

Langs.en.common = {
	title: 'Betano World Tipster League',
	welcome: '',
	footer: '',
	home: 'HOME',
	lets_bet: 'LETS BET!',
    change_bet: 'CHANGE BET',
	bet: 'BET:', 
	pts: 'Pts',
	completed: 'COMPLETED',
	matchday: 'MATCHDAY',
    matchday2: 'SEASON',
    how_it_works: 'So geht\'s',
	ranking: 'Ranking',
    evaluation: 'Evaluation',
	groups_header: 'RANKS / GROUPS',
	group_manager: 'GROUP MANAGER',
	group_search: 'Group name',
	create_group: 'CREATE GROUP',
	rank: 'RANK',
	points: 'POINTS',
    round_points: 'Round points',
    all_points: 'All&nbsp;points',
	recent_groups: 'RECENT GROUPS',
	join_group: 'JOIN TO THE GROUP',
	open_groups: 'OPEN GROUPS',
	no_groups_found: '<span class="sorry">SORRY!</span><span class="no-groups-text"> No Groups Found</span>',
	searched_groups: 'SEARCHED GROUPS',
	tutorial: 'TUTORIAL',	
	next: 'NEXT',
	users: 'USERS',
    back: 'BACK',
	group_header: 'LEADERBOARD',
	ranking_total: 'Total',
    choose_leagues: 'Choose leagues',
	select_leagues: "Choose leagues",
    liga: 'LIGA',
	season: 'SEASON',
    performance: 'Performance',
	skip_tutorial: 'LET\'S GO',
	tutorial_header: '<span class="welcome">WILLKOMMEN BEIM</span> <span class="game">FAIRSTEN TIPPSPIEL</span>',
	tutorial_top: 'SO WIRD\'S GEMACHT',
	chart_locked: 'Please log in to see your charts',
    chart_nodata: 'There is no enough data to show the chart',
    winning_percent_chart_label: 'TREFFERQUOTE',
	risk_chart_label: 'RISIKOFREUDE',
    charts_header: 'Statistics',
	promote_title: 'Congrats!',
	promote_message: 'You have been promoted to division ',
	relegate_title: 'Ups!',
    relegate_message: 'You have been relegated to division ',
    relegate_message_100: 'You have been relegated to Freizeitliga',
    no_league_change_title: 'Tor-Update!',
    no_league_change_message: 'Du spielst weiterhin in Liga {division}.',
    no_league_change_message_100: 'Du spielst weiterhin in Freizeitliga.',
    round_wins: 'Rounds won',
    division100: 'Freizeitliga',
	division100_short: 'Freizeit',
	division1: 'LIGA 1, STAFFEL 1',
    matchday_improvement_title: 'Great stuff!',
    matchday_improvement_message: 'On matchday {round} you have improved your ranking from {old_position} to {position}. Keep it up!',
	risk: 'RISIKO',
	days: [
		'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
	],
	days_short: [
		'SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'
	],
    halftime: 'Half',
	other_user: 'Other user',
	form: 'Form',
	table: 'Table',
        trend: 'Trend',
        team: 'Team',
        matchs_played: 'Matchs',
        goals_for: 'Goals',
        points: 'Points',
        last_game: 'Last game',
	vs: 'vs',
	score: 'Score',
	position: 'Position',
	possible_points: 'possible points',
	win: 'win',
	turbo: '(turbo)',
	draw: 'Draw',
	no_bet: 'No bet yet',
    your_points: 'Your points',
	your_max_playsday_points: 'Your max. round points',
	versionnumber: 'Versionnumber',
	ad3_text: 'SPIEL DEINEN TIPP MIT 10 Ă˘â€šÂ¬ UND GEWINNE',
    ad3_no_bet: 'Hol dir jetzt 100% Neukundenbonus bis 100Ă˘â€šÂ¬',
	ad3_button: 'JETZT WETTEN',
	ad3_warning: '18+ GlÄ‚Ä˝cksspiel kann sÄ‚Ä˝chtig machen',
	page_not_found: 'Page not found',
	please_wait: 'Please wait...',
	unknown_error: 'Unknown error',
    close: 'Close',
    correct: 'CORRECT',
    incorrect: 'INCORRECT',
	save: 'Save',
	change: 'Change',
    overall: 'Overall',
    overall2: 'Overall ranking',
	season_progress: 'Season progress',
	league_history: 'League history',
    wins: 'Wins',
    division: 'Division',
    division_history: 'Seasons progress',
	cancel: 'Cancel',
	division_level: 'Division',
	division_position: 'Position',
	startet_in: 'Startet in',
	min: 'min',
	hours: 'hours',
	hour: 'hour',
	daysStr: 'days',
	day: 'day',
	ok: 'OK',
	yes: 'Yes',
	no: 'No',
	link_copied: 'Link copied!',
	chart_view: 'View',
	menu: {
		/*contents_included: 'Contents included',*/
		about_us: 'About us',
		contact: 'Contact',
		privacy: 'Privacy',
		impress: 'Impress',
		faq: 'FAQ',
		invite_friends: 'Invite friends',
		logout: 'Log out',
	},
	content_page: {
		about_us: 'default',
		contact: 'default',
		privacy: 'default',
		impress: 'default',
		faq: 'default'
	},
	go_back: 'Back',
    server_disconnection: 'Server connection broken. Reconnection in {seconds} seconds.',
    server_reconnection: 'Trying to reconnect to the server...',
	invite_friends: 'Invite friends',
	app_invite_title: 'Race To 90',
	app_invite_desc: 'Send the link to your friends <br/> to join Betano World Tipster League',
	send_ticket_with: 'Send ticket with',
    finished: 'Finished!',
    share_title: 'Betano World Tipster League',
    share_message: 'Share message',
	chart_info_box: {
		overall: `
			<h2 style="text-align: center">{title}</h2>
			<b>Trans key: "{key}".</b> 
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam velit augue, consectetur in purus quis, interdum pellentesque risus. Fusce ac orci malesuada, dignissim nibh quis, dictum augue. Suspendisse malesuada, lectus non varius gravida, lectus sapien consequat leo, in malesuada sapien lorem eu nibh. Aenean vulputate in arcu sed mattis.
		`,
		group: `
			<h2 style="text-align: center">{title}</h2>
			<b>Trans key: "{key}".</b> 
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam velit augue, consectetur in purus quis, interdum pellentesque risus. Fusce ac orci malesuada, dignissim nibh quis, dictum augue. Suspendisse malesuada, lectus non varius gravida, lectus sapien consequat leo, in malesuada sapien lorem eu nibh. Aenean vulputate in arcu sed mattis.
		`,
		division_history: `
			<h2 style="text-align: center">{title}</h2>
			<b>Trans key: "{key}".</b> 
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam velit augue, consectetur in purus quis, interdum pellentesque risus. Fusce ac orci malesuada, dignissim nibh quis, dictum augue. Suspendisse malesuada, lectus non varius gravida, lectus sapien consequat leo, in malesuada sapien lorem eu nibh. Aenean vulputate in arcu sed mattis.
		`,
		division_history: `
			<h2 style="text-align: center">{title}</h2>
			<b>Trans key: "{key}".</b> 
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam velit augue, consectetur in purus quis, interdum pellentesque risus. Fusce ac orci malesuada, dignissim nibh quis, dictum augue. Suspendisse malesuada, lectus non varius gravida, lectus sapien consequat leo, in malesuada sapien lorem eu nibh. Aenean vulputate in arcu sed mattis.
		`,
		winning: `
			<h2 style="text-align: center">{title}</h2>
			<b>Trans key: "{key}".</b> 
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam velit augue, consectetur in purus quis, interdum pellentesque risus. Fusce ac orci malesuada, dignissim nibh quis, dictum augue. Suspendisse malesuada, lectus non varius gravida, lectus sapien consequat leo, in malesuada sapien lorem eu nibh. Aenean vulputate in arcu sed mattis.
		`,
		risk: `
			<h2 style="text-align: center">{title}</h2>
			<b>Trans key: "{key}".</b> 
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam velit augue, consectetur in purus quis, interdum pellentesque risus. Fusce ac orci malesuada, dignissim nibh quis, dictum augue. Suspendisse malesuada, lectus non varius gravida, lectus sapien consequat leo, in malesuada sapien lorem eu nibh. Aenean vulputate in arcu sed mattis.
		`
	},
    spieltag_custom_1: '',
    spieltag_custom_2: '',
    spieltag_custom_3: '',
    spieltag_custom_4: '',
    spieltag_custom_5: '',
    spieltag_custom_6: '',
    spieltag_custom_7: '',
    spieltag_custom_8: '',
    spieltag_custom_9: '',
    spieltag_custom_10: '',
	statistics: 'Statistics',
	trends: 'Trends'
};
