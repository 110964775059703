class BlockEditProfile {
    constructor(dom) {
        this.dom = dom;
        this.isSubmited = false;
        this.avatar_file_uri = false; // new avatar file on mobile to send

        eventHandler.observe('block_register', ['language_change', 'user_login', 'update_profile'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    renderOld() {
        if (!this.dom) return;
        
        if(!app.isAuth()) {
            this.dom.empty();
            return ;
        }
        var t = this;
        
        var tipp_reminder_html = '';
        var avatar_url = app.getUserAvatar();
        var reminder = app.user.reminder ? 'checked="checked"' : '';
        var notifications = app.user.notifications ? 'checked="checked"' : '';
        var notifications_html = '';
        if(config.app_type == 'mobile') {
            notifications_html = `
                <div class="form-group notifications-group">
                    <label class="toggle-control">
                        <input name='notifications' type="checkbox" ${notifications}>
                        <span class="control"></span>
                        ${app.lang.common.settings.notifications}
                    </label>
                </div>
            `;
        }

        var read_attr = !app.config.email_change ? 'readonly="readonly"' : '';
        
        if(app.config.reminder_confirm) {
            if(app.user.reminder) {
                tipp_reminder_html = `
                    <div class="form-group reminder-group">
                        <label class="toggle-control">
                            <input name='reminder' type="checkbox" ${reminder}>
                            <span class="control"></span>
                            ${app.lang.user.tip_reminder}
                        </label>
                    </div>
                `;
            }else {
                tipp_reminder_html = `
                    <div class="form-group reminder-group">
                        <span class="btn-turn-on-reminder">${app.lang.user.turn_on_tip_reminder}</span>
                        <span class="msg-turn-on-reminder" style="display: none" >${app.lang.user.turn_on_tip_reminder_confirm_msg}</span>
                        <label>
                            ${app.lang.user.tip_reminder}
                        </label>
                    </div>
                `;
            }
        }else {
            tipp_reminder_html = `
                <div class="form-group reminder-group">
                    <label class="toggle-control">
                        <input name='reminder' type="checkbox" ${reminder}>
                        <span class="control"></span>
                        ${app.lang.user.tip_reminder}
                    </label>
                </div>
            `;
        }
        
        let leagues_html = '';
        leagues_html += `
            <div class="group-leagues form-group">
                <h4>${app.lang.common.choose_leagues}</h4>
        `;

        for(var id_league of app.data.id_leagues) {
            leagues_html += `
                <div class='form_checkbox'>
                    <label>
                        <input ${app.user.leagues.indexOf(parseInt(id_league)) != -1 ? 'checked="checked"' : ''} type="checkbox" name="leagues" value="${id_league}" />
                        ${app.lang.common['league_name_'+id_league]}
                    </label>
                </div>
            `;
        }
        leagues_html += '</div><br />';
        
        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.user.edit_profile}</h1>
                <div class='block_register bl'>
                    <form class="form-register form">
                        <div class="ta-c">
                            <div class="form-group form-group-avatar">
                                <input type="file" name="avatar" class="file-avatar" accept=".png, .jpg, .jpeg" />
                                <img class="${app.mobile ? 'med-img-avatar' : ''}" src="${avatar_url}" onerror="app.errorUserAvatar(this)" />
                                <div class="user-name"></div>
                                <span class='avatar-change'><a href='' onclick='$(".file-avatar").click(); return false;'>${app.lang.common.change}</a></span>
                            </div>
                        </div>

                        <div class="form-group login-group form-group-border">
                            <input class='noautocomplete' type="text" name="login" id="login" value="${app.user.login}" placeholder="${app.user.login}" />
                            <span class='label'>${app.lang.user.change_login}</span>
                        </div>
        
                        ${leagues_html}
        
                        ${ !app.isMybet ? `
                            <div class="form-group password-group form-group-border">
                                <input class='noautocomplete' type="password" name="password" id="password" value="************" placeholder="************" />
                                <span class='label'>${app.lang.user.change_password}</span>
                            </div>
                            <div class="form-group email-group form-group-border">
                                <input class='noautocomplete' type="text" name="email" id="email" value="${app.user.email}" placeholder="${app.user.email}" />
                                <span class='label'>${app.lang.user.change_email}</span>
                            </div>

                            ${tipp_reminder_html}
                            ${notifications_html}
                        ` : ''}
        
                        <div class="form-group">
                            <button type="submit" class="btn-sportde" >${app.lang.common.save}</button>
                        </div>
        
                        ${ (!app.isMybet || !app.user.mybet_id) ? `
                            <div class="footer-line">
                                <span class="fl-l btn-link btn-logout">${app.lang.user.logout}</span>
                                <span class="fl-r btn-link btn-delete-account">${app.lang.user.delete_account}</span>
                                <div class="clear"></div>
                            </div>
                        ` : ''}
                    </form>
                    
                </div>
            </div>
        `;

        this.dom.html(html)

        this.$form = $(this.dom).find('form');
        this.$form.submit(e => {
            this.onsubmit();
            return false;
        });
        var form_fileds = {
            avatar: {type: 'avatar'},
            reminder: { },
            login: {valid: {min_length: 4}},
            email: {valid: {email: true}},
            password: {valid: {min_length: 4}},
            leagues: { type: 'multi_checkbox'}
        };
        if(app.mobile) {
            form_fileds.notifications = {};
        }
        this.form = new Form(this.$form, form_fileds);
        
        this.$form.find('input.white').focus(function() {
            if ($(this).val() == $(this).attr('placeholder')) {
                $(this).val('');
            }
            $(this).removeClass('noautocomplete');
        });

        this.$form.find('.btn-logout').click(e => {
            app.logout();
        });

        this.$form.find('.btn-delete-account').click(e => {
            app.renderPage('delete_profile');
        });
        
        this.$form.find('.btn-turn-on-reminder').click(e => {
            this.$form.find('.btn-turn-on-reminder').hide();
            this.$form.find('.msg-turn-on-reminder').show();
            
            ws.request('user/turnOnTippReminderConfirm');
        });
        
        return this;
    }
    
    render() {
        if (!this.dom) return;
        
        if(!app.isAuth()) {
            this.dom.empty();
            return ;
        }
        var t = this;
        
        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.user.edit_profile}</h1>
                <div class='block_edit_profile bl'>
                    <form class="form-edit_profile form form-sm">
        
                        <div class="box">
                            <div class="box-title">${app.lang.user.account_info}</div>
                            <div class="form-group login-group form-group-border">
                                <input type="text" name="login" value="${app.user.login}" placeholder="${app.lang.user.user_name}" />
                            </div>
                            <div class="form-group email-group form-group-border">
                                <input ${!app.config.email_change ? 'disabled="disabled"' : ''} type="text" name="email" value="${app.user.email}" placeholder="${app.lang.user.email}" />
                            </div>
                            <div class="form-group password-group form-group-border">
                                <img src="images/showpassword2.svg" class="show-password-icon">
                                <input type="password" name="password" class="password-input" value="" placeholder="${app.lang.user.password}" />
                            </div>
                            <div class="form-group password-group form-group-border">
                                <img src="images/showpassword2.svg" class="show-password-icon">
                                <input type="password" name="new_password" class="password-input" value="" placeholder="${app.lang.user.new_password}" />
                            </div>
                            <div class="form-group password-group form-group-border">
                                <img src="images/showpassword2.svg" class="show-password-icon">
                                <input type="password" name="new_password_repeat" class="password-input" value="" placeholder="${app.lang.user.new_password_repeat}" />
                            </div>
                        </div>
        
                        <div class="form-group line-buttons">
                            <button type="submit" class="tip-button" >${app.lang.common.save}</button>
                            <button type="button" class="tip-button tip-button-transparent btn-delete-account">${app.lang.user.delete_account}</button>
                        </div>
                    </form>
                    
                </div>
            </div>
        `;

        this.dom.html(html);

        this.$form = $(this.dom).find('form');
        this.$form.submit(e => {
            this.onsubmit();
            return false;
        });
        var form_fileds = {
            login: {valid: {min_length: 4}},
            email: {valid: {email: true}},
            password: {valid: {min_length: 4}},
            new_password: {valid: {min_length: 4}},
            new_password_repeat: {valid: {min_length: 4, repeat: 'new_password'}},
        };
        this.form = new Form(this.$form, form_fileds);

        this.$form.find('.btn-delete-account').click(e => {
            app.renderPage('delete_profile');
        });
        this.$form.find('.show-password-icon').click(function() {
            var password_input = $(this).closest('.form-group').find('input');
            if(!password_input.hasClass('password-visible')) {
                password_input.addClass('password-visible').attr('type', 'text');
            }else {
                password_input.removeClass('password-visible').attr('type', 'password');
            }
        });
        
        return this;
    }
    
    async trigger_change_avatar() {
        $(".file-avatar").click();
    }

    async onsubmit() {
        this.$form.find('input').each(function() {
            if ($(this).val() == $(this).attr('placeholder')) {
                $(this).val('');
            }
        });

            
        if(this.isSubmited) {
            return ;
        }
        this.isSubmited = true;

        try {
            var data = this.form.getData(true);
            this.form.fields.password.required = this.form.fields.new_password.required = this.form.fields.new_password_repeat.required = data.password || data.new_password || data.new_password_repeat;
            
            if(!this.form.isValid()) {
                this.isSubmited = false;
                return ;
            }
            
            /*
            if(!data) {
                data = { reminder: '', leagues: [] };
            }
            if(app.mobile) {
                data.notifications = data.notifications ? '1' : '0';
            }else {
                data.notifications = '';
            }
            */
           
            var result = await ws.request('user/updateProfile', data);
            if(result.success) {
                app.setUser(result.user);
                eventHandler.trigger('update_profile');
                app.renderPage('home');
            }else {
                this.form.setServerInvalids(result.messages, data);
            }
        }catch(err) {
            console.log(err);
        }

        this.isSubmited = false;
    }
}
