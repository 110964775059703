rand = function(min, max) {
    return Math.round(Math.random() * (max - min) + min);
}

addDummyData = function() {
    /*************************************************************************/
    // Add dummy data
    /*************************************************************************/
    /*var games = [];
    var bets = [];

    for(var i = 1; i < 6; i++)
    for(var j = 0; j < 5; j++) {
        var game1 = {
            id: 'game1_'+i+'_'+j,
            league: 'Bundesliga',
            matchday: i,
            time: Date.now() + 1000,
            duration: 120*60,
            team1: {
                name: 'Leverkusen',
                img: 'images/teams/leverkusen.png',
                score: Math.round(Math.random() * 6),
                position: 1 + Math.round(Math.random() * 8)
            },
            team2: {
                name: 'Dortmund',
                img: 'images/teams/dortmund.png',
                score: Math.round(Math.random() * 6),
                position: 1 + Math.round(Math.random() * 8)
            }
        }
    
        var game2 = {
            id: 'game2_'+i+'_'+j,
            league: 'Bundesliga',
            matchday: i,
            time: Date.now() + 1000,
            duration: 45*60,
            team1: {
                name: 'Bayern',
                img: 'images/teams/bayern.png',
                score: Math.round(Math.random() * 6),
                position: 1 + Math.round(Math.random() * 8)
            },
            team2: {
                name: 'Bremen',
                img: 'images/teams/bremen.png',
                score: Math.round(Math.random() * 6),
                position: 1 + Math.round(Math.random() * 8)
            }
        }

        game1.time = Date.now() + (i-4) * 24*60*60 * 1000 + 1000;
        games[game1.id] = game1;

        if (i % 2) {
            var bet = {
                bet: 2,
                points: i % 3 ?  Math.round(Math.random() * 100) : 0,
            }
            bets['game1_'+i+'_'+j] = bet;
        }

        game2.time = Date.now() + (i-4) * 24*60*60 * 1000 + 1000;
        if (j < 4) {
            games[game2.id] = game2;
        }

        if (i % 2) {
            var bet = {
                bet: 1,
                points: i % 3 ? Math.round(Math.random() * 100) : 0,
            }
            bets['game2_'+i+'_'+j] = bet;
        }
    }*/

    /*
    var groups = [                
        {
            ad: true,
            image: 'images/ad1.png',
            url: 'https://betano.de',
        },
        {
            id: 1,
            title: 'Global Leaderboard',
            position: Math.round(Math.random() * 40) + 1,
            previous_position: Math.round(Math.random() * 40) + 1,
            users: rand(1, 9999)
        },
        {
            id: 2,
            title: 'The League Leaderboard',
            position: Math.round(Math.random() * 40) + 1,
            previous_position: Math.round(Math.random() * 40) + 1,
            users: rand(1, 9999)
        },
        {
            id: 3,
            title: 'SCHALKE LOVERS',
            position: Math.round(Math.random() * 40) + 1,
            previous_position: Math.round(Math.random() * 40) + 1,
            users: rand(1, 9999)
        },
        {
            id: 4,
            title: 'Dortmund is the best',
            position: Math.round(Math.random() * 40) + 1,
            previous_position: Math.round(Math.random() * 40) + 1,
            users: rand(1, 9999)
        },
        {
            id: 5,
            title: 'Bayern lovers',
            position: Math.round(Math.random() * 40) + 1,
            previous_position: Math.round(Math.random() * 40) + 1,
            users: rand(1, 9999)
        }
    ];
    */

    var tutorial = [
        {
            top: 'Beim Tipp auf Sieg oder Unentschieden ist<br/> das genaue Endergebnis egal.',
            bottom: '<i>DORTMUND GEWINNT</i><br /> du kassierst <b>55 Punkte</b>',
            image: 'images/tut-group1.png',
        },
        {
            top: 'Tippe auf Sieg, Unentschieden oder Turbo (Sieg mit mind. 2 Toren Unterschied).',
            bottom: '<i>SIEG DORTMUND</i><br />du kassierst <b>32 Punkte</b>',
            image: 'images/tut-group2.png',
        },
        {
            header: '<b>THIRD</b> SLIDE',
            top: 'Beim Tipp auf Sieg oder Unentschieden ist das genaue Endergebnis egal.',
            bottom: '<i>UNENTSCHIEDEN</i><br /> du kassierst <b>71 Punkte</b>',
            image: 'images/tut-group3.png',
        },
        {
            header: '<b>FOURTH</b> SLIDE',
            top: 'Sammle mehr oder weniger Punkte, je nachdem, wie riskant Dein Tipp ist.',
            bottom: '<i>LEVERKUSEN GEWINNT</i><br /> du kassierst <b>63 Punkte</b>',
            image: 'images/tut-group4.png',
        }
    ]

    //app.setData('bets', bets);
    //app.setData('scores', games);
    //app.setData('groups', groups);
    //app.setData('charts', charts);
    console.log(tutorial);
    app.setData('tutorial', tutorial);
}

generateGroupUsers = function() {
    var points = 2000;
    var users = [];
    var added = false;

    for (var i = 0; i < 20; i++) {
        points -= rand(0, 200);        

        if (app.isAuth() && points < app.user.points && !added) {
            var user = {        
                id: app.user.id,   
                name: app.user.name,
                position: i+1,
                previous_position: rand(1, 30),
                points: app.user.points,
                avatar: 'default-avatar.svg'
            }
    
            users.push(user);
            i++;
            added = true;
        }

        var user = {          
            id: i + 1000,  
            name: (i % 2 ? 'USER NAME '+(i+1) : 'JOHN KOWALSKY '+(i+1)),
            position: i+1,
            previous_position: rand(1, 30),
            points,
            avatar: 'avatar'+(i % 3 + 2)+'.png'
        }

        users.push(user);
    }

    return users;
}