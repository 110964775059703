
class BlockDivisions {

    constructor(dom) {
        this.dom = dom;
        this.dom_content = false;
        this.dom_group = false;
        this.last_shown_division = -1;
        this.rendered_max_division = -1;

        eventHandler.observe('block_divisions', ['language_change'], () => {
            this.render();
        }, this);

        eventHandler.observe('block_divisions', ['set_data_max_division', 'set_data_user_division'], () => {
            if(this.rendered_max_division != app.data.max_division) {
                this.render();
            }
        }, this);
    }

    setDom(dom) {
        this.dom = dom;

        var html = `<div class='scroll'></div>`;
        this.dom.html(html);
        this.dom_content = this.dom;
        //this.dom_content.hide();
        
        return this;
    }

    render() {
        if (!this.dom_content) {
            return this;
        }
        if(!app.data.max_division || app.data.max_division == '0') {
            $('#divisions').addClass('h0');
            this.rendered_max_division = app.data.max_division;
            return this;
        }
        $('#divisions').removeClass('h0');
        var max_division = parseInt(app.data.max_division);

        if(!app.data.user_division) {
            return this;
        }
        this.rendered_max_division = app.data.max_division;

        var html = `
            <div class='block_divisions'>
        `;

        for(var i = 1; i <= max_division; i++) {
            html += `
                <div class="bl division division-level" data-division="${i}" data-is_rendered="0" >
                    <div class="l1">${i}</div>
                    <div class="l2"><span class="space"></span>${app.lang.common.division_level}</div>
                </div>
            `;
        }

        html += `
            <div class="bl division division-level division-100" data-division="100" data-is_rendered="1" >
                <div class="l1">${(max_division+1)}</div>
                <div class="l2"><span class="space"></span>${app.lang.common.division_level}</div>
            </div>
        `;

        html += '</div>';

        this.dom_content.html(html);
        var self = this;

        this.dom_content.find('.division-level').click(function() {
            self.dom_content.find('.division-level').removeClass('active');
            self.dom_content.find('.division-group').removeClass('active').hide();

            var attr = $(this).data();
            if(attr.division == 1 && app.data.user_division.division == 1) {
                app.renderPage('group', {
                    id: app.data.user_division.id
                });
            }else if(attr.division == 100) {
                app.blocks.group.render(null, null, null, 100, 0);

                attr.is_rendered = 1;
                self.last_shown_division = attr.division;
                $(this).addClass('active');
            }else if(attr.is_rendered == 1) {
                if(self.last_shown_division == attr.division) {
                    self.last_shown_division = -1;
                }else {
                    self.dom_content.find('.division-group.division-'+attr.division).show();
                    self.last_shown_division = attr.division;
                    $(this).addClass('active');
                    $(this).next().trigger('click');
                }
            }else {
                var html = self.renderDivisionGroups(attr.division);
                $(this).after(html);
                
                attr.is_rendered = 1;
                self.last_shown_division = attr.division;
                $(this).addClass('active');
                $(this).next().trigger('click');
            }
            self.setDotActive();
            $(window).resize();
        });

        /*
        $(this.dom_content).find('.block_divisions').dotsScroll({
            dot: "<div class='dot'></div>",
            active_dot_class: "dot_active",
            inside_element_width: 245,
        });
        */
        if(!app.mobile) {
            $(this.dom_content).addClass('native-scroll').swipeIndicator();
        }else {
            $(this.dom_content).addClass('mobile-scroll');            
        }

        $(this.dom_content).on('click', '.division-group', function() {
            if($(this).hasClass('active')) {

                return false;
            }
            self.dom_content.find('.division-group').removeClass('active');
            self.setDotActive();
            var attr = $(this).addClass('active').data();
            app.renderPage('group', attr);
            //app.blocks.group.render(attr.id || null, null, null, attr.division, attr.position);
        });
    }

    renderDivisionGroups(division) {
        var html = '';
        var to_create = Math.pow(2, division - 1);

        for(var i = 1; i <= to_create; i++) {
            var attr_html = '';
            if(app.data.user_division.division == division && app.data.user_division.position == i-1) {
                attr_html = 'data-id="'+app.data.user_division.id+'"';
            }
            html += `
                <div class="bl division division-group division-${division}" data-division="${division}" data-position="${i-1}" ${attr_html} >
                    <div class="l2">${i}. ${app.lang.common.division_position}</div>
                    <div class="l1">${division}. ${app.lang.common.division_level}</div>
                </div>
            `;
        }

        return html;
    }

    setDotActive() {
        this.dom_content.find('.division.active-dot').removeClass('active-dot');
        if(app.user.division == 100 || app.user.division == 0) {
            this.dom_content.find('.division-level[data-division="100"]').addClass('active-dot');
        }else if(app.user && app.user.division) {
            var user_active_el = this.dom_content.find('.division-group[data-division="'+app.user.division+'"][data-position="'+(app.user.division_position)+'"]').addClass('active-dot');
            if(user_active_el.length == 0 || !user_active_el.is(':visible')) {
                this.dom_content.find('.division-level[data-division="'+app.user.division+'"]').addClass('active-dot');
            }
        }
    }
    
    setActivePosition(division, position) {
        var $divActive = this.dom_content.find('.division-group[data-division="'+division+'"][data-position="'+position+'"]');
        if($divActive.length == 1 && $divActive.hasClass('active')) {
            return ;
        }
        var $div = this.dom_content.find('.division-level[data-division="'+division+'"]');
        
        this.dom_content.find('.division-level').removeClass('active');
        this.dom_content.find('.division-group').removeClass('active').hide();

        var attr = $div.data();

        if (attr) {
            if(attr.is_rendered == 1) {
                this.dom_content.find('.division-group.division-'+attr.division).show();
                this.last_shown_division = attr.division;
                $div.addClass('active');
            }else {
                var html = this.renderDivisionGroups(attr.division);
                $div.after(html);

                attr.is_rendered = 1;
                this.last_shown_division = attr.division;
                $div.addClass('active');
            }
        }
        this.setDotActive();
        
        var $div2 = this.dom_content.find('.division-group[data-division="'+division+'"][data-position="'+position+'"]');
        var active_el = $div2.addClass('active');
        if(active_el.length == 0) {
            return ;
        }
        if(this.dom_content.hasClass('native-scroll')) {
            var pos = active_el[0].offsetTop - $('#divisions').width() / 2 + 77;
            $(this.dom_content).find('.block_divisions').animate({ scrollTop: pos}, 0);
        }else {
            var pos = active_el[0].offsetLeft - $('#divisions').width() / 2 + 77;
            $(this.dom_content).animate({ scrollLeft: pos}, 0);            
        }

        $(window).resize();

        /*
        this.last_shown_division = -1;
        this.dom_content.find('.division-level').removeClass('active');
        this.dom_content.find('.division-group').removeClass('active').hide();
            
        this.dom_content.find('.division-level[data-division="'+division+'"]').click();
        this.setDotActive();
        var active_el = this.dom_content.find('.division-group[data-division="'+division+'"][data-position="'+position+'"]').addClass('active');
        if(active_el.length == 0) {
            return ;
        }
        var pos = active_el[0].offsetLeft - $(window).width() / 2 + 95;
        $(this.dom_content).animate({ scrollLeft: pos}, 0);
        $(window).resize();
        */
    }

}
