$.fn.swipe = function(options) {
    var t = this;
    var slide = null;
    var transformElement = null
    var newSlide = null;
    var startClientX = 0;
    var transformSelector = options.transformElement || false;

    var start = function(e) {
        slide = this;
        if(typeof e.touches == 'object' && typeof e.touches[0]) {
            startClientX = e.touches[0].clientX;
        }else {
            startClientX = e.clientX;
        }
        if(transformSelector) {
            transformElement = $(slide).find(transformSelector);
        }else {
            transformElement = $(slide);
        }
    };

    var move = function(e) {
        if(!slide) {
            return ;
        }
        var mClientX;
        if(typeof e.touches == 'object' && typeof e.touches[0]) {
            mClientX = e.touches[0].clientX;
        }else {
            mClientX = e.clientX;
        }
        var diff = mClientX - startClientX;
        transformElement.css('transform', 'translateX('+diff+'px)');

        if(Math.abs(diff) < 100) {
            return ;
        }
        newSlide = null;

        if(diff < 0) {
            //next
            if($(slide).is(':last-children')) {
                
            }else {
                newSlide = $(slide).next();
            }
        }else {
            //prev
            if($(slide).is(':first-children')) {
                
            }else {
                newSlide = $(slide).prev();
            }
        }
        
        if(newSlide && newSlide.length > 0) {
            options.onSlide.call(newSlide);
        }
        transformElement.css('transform', 'translateX(0px)');
        slide = null;
    };

    var end = function(e) {
        if(!slide) {
            return ;
        }

        transformElement.css('transform', 'translateX(0px)');
        slide = null;
    };

    this.children().bind('touchstart', start);
    this.children().bind('mousedown', start);

    $(document).bind('touchmove', move);
    $(document).bind('mousemove', move);

    $(document).bind('touchend', end);
    $(document).bind('mouseup', end);

    this.addClass('swipe-container');

};