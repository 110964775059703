
var Langs = Langs || {};
Langs.en = Langs.en || {};

Langs.en.group = {
    overall_ranking: 'GESAMT<br />RANGLISTE',
    create_group: 'Create group',
    add_group_for_style_green: '<b>Gruppen</b><br/>erstellen / suchen',
    your_group: 'Your group',
    create_group_info: 'Create group info',
    name: 'Name',
    invitation: 'Invitation',
    invitation_info: 'Sende den Link deinen Freunden damit<br />sie der Gruppe beitreten',
    private: 'Private',
    password: 'Password',
    finished: 'Finished!',
    invite_friends: 'Invite friends',
    group_not_exists: 'Group not exists',
    hi: 'Hi',
    join_info: 'Bitte gib den Zugangscode ein, um der<br />Gruppe „Gruppenname“ beizutreten',
    join_info_public: 'now you can join the group.',
    join_password: 'Join password',
    join_btn: 'Join to group',
    can_not_edit_this_group: 'Can not edit this group',
    edit_group: 'Edit group',
    change_name: 'Change name',
    change_password: 'Change password',
    copy_link: 'Copy link',
    new_data_refresh_btn: 'Reload',
    new_data_refresh_title: 'Reload!',
    new_data_refresh_message: 'Data has been changed. Please reload.',
    start_match_title: 'Anpfiff!',
    start_match_message: 'Verfolge deine Punkte jetzt <br/> im Live-Ranking!',
    tipp: 'TIPP',
    unjoin_btn: 'Unjoin',
    unjoin_confirm: 'Do you want to unjoin from this group?',
    delete_group: 'Delete group',
    unjoin_group: 'Unjoin Group',
    confirm_delete_group: 'Confirm delete group',
    confirm_delete_group_info: 'Confirm delete group info text',
    confirm_unjoin_group: 'Confirm unjoin group',
    confirm_unjoin_group_info: 'Confirm unjoin group info text',
    no_thanks: 'No, Thanks!',
    privacy: 'Privacy',
    send_ticket_with: 'Send ticket with',
    team: 'Team',
    share_title: 'Betano World Tipster League',
    share_message: 'Share message',
    confirm_reg_button: 'Zur anmeldung',
    thanks_for_register: 'Danke für Deine Registrierung.',
    thanks_for_register_message: 'Du erhältst eine Anmeldebestätigung per E-Mail.',
    confirm_reg_button_done: 'OK',
    thanks_for_register_done: 'Deine Registrierung wurde bestätigt.',
    thanks_for_register_done_message: 'Bitte gib dein Passwort ein und melde dich an.',
    whatsapp_share_message: 'WhatsApp share message',
    rank: 'RanK',
    create_group_headline: 'Create your own betting group.',
    save_group: 'Create betting group',
    select_leagues_for_bet: 'Select the competitions <br /> you want to bet on.'
}