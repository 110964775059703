class BlockScores {
    constructor(dom) {
        this.dom = dom;
        this.source = app;
        this.social = false;
        this.matchday_translations = false;
        
        this.dom_content = false;
        this.current_day_only = true;
        this.scrolled_to_current = false;
        this.scroll_to_matchday_counter = 0;

        this.scrollStepMultiply = 3;

        eventHandler.observe('block_scores', ['language_change', 'user_login', 'set_data_scores', 'set_data_tips'], () => {
            if (!this.social) {
                this.render();
                this.setOtherDaysVisibility();
                if(app.current_page == 'home') {
                    this.scrollToCurrentMatchday();
                }
            }
        }, this);
        
        eventHandler.observe('block_scores', ['refresh_live_matches'], (live) => {
            if(this.source) {
                this.refreshLiveMatches(); 
           }
        }, this);

        eventHandler.observe('block_scores', ['change_page'], () => {
            this.changePage();
        }, this);     
    }
    
    setSocial(source) {
        this.source = source;
        this.social = true; 
        return this;
    }

    setDom(dom) {
        var self = this;
        this.dom = dom;

        var html = ``;
        this.dom.html(html);
        this.dom_content = this.dom;
        
        $(this.dom_content).on('click', '.fixture', function() {
            if(app.current_page == 'group') {
                var round = $(this).attr('matchday');
                var fixture = $(this).attr('fixture');
                if($(this).hasClass('g-active')) {
                    //app.blocks.group.render(app.current_group.id, round);
                    app.renderPage('group', {id: app.current_group.id, round: round, id_league: app.blocks.group.id_league});
                }else {
                    //app.blocks.group.render(app.current_group.id, round, fixture);
                    app.renderPage('group', {id: app.current_group.id, round: round, fixture: fixture, id_league: app.blocks.group.id_league});
                }
            }
        });
        
        $(this.dom_content).on('click', '.clickable', function() {
            if(app.current_page == 'group' || self.social) {
                return ;
            }
            app.renderPage('bets', { id: $(this).attr('fixture') });            
        });
        
        return this;
    }

    render() {
        if (!this.dom_content) {
            return this;
        }
        
        if (this.social && !this.source) {
            return this;
        }
        		
        var t = this;
        var games = [];
        var upcomming = 0;
        var matchday = -999;
        var current_matchday = app.getCurrentMatchday(this.source);
        
        // Testing: make the game live
        for(var i in this.source.data.scores) {
            if (this.source.data.scores[i].id == 871303) {
                //this.source.data.scores[i].is_live = true;
            }
        }

        // Live matches as first
        this.source.data.scores = this.source.data.scores.sort((a, b) => {
            if (a.matchday == current_matchday && b.matchday == current_matchday) {
                if (a.is_live) {
                    return -1;
                }
                
                if (b.is_live) { 
                    return 1;                
                }
                
                if (a.time < b.time) {
                    return -1;
                } else {
                    return 1;
                }
            }
            
            return 0;
        });

        for(var i in this.source.data.scores) {
            if (app.isAuth() || this.source.data.scores[i].matchday == current_matchday) {
                if (this.source.data.scores[i].matchday > matchday && app.isAuth()) {   
                    
                    var html = this.renderMatchdayButton(this.source.data.scores[i].matchday, current_matchday, this.source.data.scores[i].matchday_name);
                    games.push(html);

                    matchday = this.source.data.scores[i].matchday;
                }

                if (this.source.data.scores[i].matchday == current_matchday) {
                    upcomming++;
                    if (upcomming == 1) {
                        this.source.data.scores[i].first_upcomming = true;
                    }
                }

                var html = this.renderGame(this.source.data.scores[i]);
                games.push(html);
            }
        }

        var games_html = games.join('');
        var html_classes = 'block_scores';
        if(app.config.euro_mod) {
            html_classes += ' euro_mod';
        }
        var html = `
            <div class='${html_classes}'>
                ${games_html}
            </div>
        `;

        this.dom_content.html(html);
        this.renderGameCharts();

        $(this.dom_content).find('.matchday').click(function() {
            if(app.current_page == 'group') {
                if (!$(this).hasClass('g-active')) {
                    var round = $(this).attr('for-matchday');
                    app.renderPage('group', {id: app.current_group.id, round: round, id_league: app.blocks.group.id_league});
                    //app.blocks.group.render(app.current_group.id, round);
                }else {
                    app.renderPage('group', {id: app.current_group.id, id_league: app.blocks.group.id_league});
                    //app.blocks.group.render(app.current_group.id);
                }
                return ;
            }
            if (!$(this).hasClass('active')) {                
                $(this).addClass('active');
                $(this).parent().find('div[matchday="'+$(this).attr('for-matchday')+'"]').addClass('show');
            } else {
                $(this).removeClass('active');
                $(this).parent().find('div[matchday="'+$(this).attr('for-matchday')+'"]').removeClass('show');
            }

            $(window).resize();
            t.scrollToMatchday(this);
        });        
        
        /*
        $(this.dom_content).find('.block_scores').dotsScroll({
            dot: "<div class='dot'></div>",
            active_dot_class: "dot_active",
            inside_element_width: 198,
        });*/
        //$(this.dom_content).addClass('scroll').hScroll2();
        if(!app.mobile) {
            $(this.dom_content).addClass('native-scroll').swipeIndicator({stepMultiply: this.scrollStepMultiply});
        }else {
            $(this.dom_content).addClass('mobile-scroll');
        }
        
        this.scrollToUpcommingGame();

        var t = this;
        return this;
    }

    showCurrentMatchDay() {
        var m = this.source.current_round;
        $(this.dom_content).find(`div.matchday[for-matchday="${m}"]`).addClass('active');
        $(this.dom_content).find(`div.fixture[matchday="${m}"]`).addClass('show');
    }

    setGroupActive(round, id_fixture) {
        $(this.dom_content).find('.matchday.g-active').removeClass('g-active');
        $(this.dom_content).find('div[matchday].g-show').removeClass('g-show');
        
        if(round) {
            var $md = $(this.dom_content).find('.matchday[for-matchday="'+round+'"]').addClass('g-active');
            $(this.dom_content).find('div[matchday="'+round+'"]').addClass('g-show');
            if(!id_fixture && $md.length > 0) {
                this.scrollToMatchday($md[0]);
            }
        }
        
        $(this.dom_content).find('.g-active[fixture]').removeClass('g-active');
        if(id_fixture) {
            $(this.dom_content).find('[fixture="'+id_fixture+'"]').addClass('g-active');
        }
        $(window).resize();
        setTimeout(() => {
            $(window).resize();
        }, 100);        
    }

    setOtherDaysVisibility() {
        this.current_day_only = !app.isAuth();

        if (this.current_day_only) {
            $(this.dom_content).find('.other_day, .active').removeClass('show');
        } else {
            $(this.dom_content).find('.active').addClass('show');
        }
        
        return this;
    }    
    
    refreshLiveMatches() {
        for (var match of app.live_matches) {
            this.refreshGame(match);
        }
    }
    
    refreshGame(game) {
        if (this.dom_content) {
            var html = this.renderGame(game);
            var el = this.dom_content.find('div[fixture="'+game.id+'"]');
            var html2 = $(html).html();
            if (el.html() != html2) {
                el.html(html2);
            }
            this.renderGameCharts();
        }
    }

    renderGame(game) {
        var time = new Date(game.time);
        var minutes = helpers.fixMinutes(time.getMinutes());
        var footer = '';
        var _class = game.first_upcomming ? 'upcomming ' : '';
        var progress = 0;
        var timer_html = '';
        game.chart_value = '';

        // For live game force:
        //game.is_betable = false; game.is_live = 1; game.team1.score = 3; game.team2.score = 2;

        if (game.is_live) {
            if (game.status_short == 'HT') {
                timer_html = `<div class="timer">${app.lang.common.halftime}</div>`;
            } else {
                if (game.timer > 90) {
                    progress = 100;
                } else {
                    progress = Math.round(game.timer / 90 * 100);
                }
                
                timer_html = '<div class="timer">'+game.timer+' \'<div class="progress" style="width: '+progress+'%"></div></div>';
            }
        }
        
        var live_html = game.is_live ? `<div class="live"><span>${app.lang.common.live_match}</span></div>`+timer_html : '';
        var leading_arrow = '';
        var score_or_time;

        var current_day;
        if (game.is_current_matchday) {
            current_day = 'current_day show';
            _class = '';
        }else if (game.is_next_matchday) {
            current_day = 'next_day show';
            _class = '';
        } else {
            current_day = 'other_day';
        }

        var datetime = game.has_bet ? ` - ${time.getHours()}:${minutes}` : '';
        var date = !game.is_finished ? `${app.lang.common.days_short[time.getDay()]}. ${time.getDate()}.${time.getMonth()+1}.${datetime}` : `${app.lang.common.completed}`
        
        var points = 0;
        var possible_points = 0;
        var label;
        var footer_label;
        var max_points = game.options[0].points > game.options[4].points ? game.options[0].points : game.options[4].points;
        
        if (this.source.data.bets[game.id] !== undefined) {
            possible_points = game.options[this.source.data.bets[game.id]].points;
            switch(parseInt(this.source.data.bets[game.id])) {
                case 0: if (game.team1.score - game.team2.score >= 2) { points = game.options[0].points; }; 
                    label = `1 <img src="images/${config.style ? config.style + '/' : 'sportde-'}thunder-turbo.svg">`;
                    footer_label = `1 <img src="images/${config.style ? config.style + '/' : 'sportde-'}thunder-bet.svg">`;
                    break;
                case 1: if (game.team1.score - game.team2.score >= 1) { points = game.options[1].points; }; label = '1'; break;
                case 2: if (game.team1.score - game.team2.score == 0) { points = game.options[2].points; }; label = 'X'; break;
                case 3: if (game.team1.score - game.team2.score <= -1) { points = game.options[3].points; }; label = '2'; break;
                case 4: if (game.team1.score - game.team2.score <= -2) { points = game.options[4].points; }; 
                    label = `2 <img src="images/${config.style ? config.style + '/' : 'sportde-'}thunder-turbo.svg">`; 
                    footer_label = `2 <img src="images/${config.style ? config.style + '/' : 'sportde-'}thunder-bet.svg">`; 
                    break;
            }
        }
        if(!footer_label) {
            footer_label = label;
        }
        
        if (game.is_clickable && !this.social) {
            _class += ' clickable';
        }
        
        if (game.is_betable) {
            // Upcomming game
            _class += ' upcomming';
            score_or_time = `<div class='time'>${time.getHours()}:${minutes}</div>`;
            
            if (/*game.options[0].points && */!this.social) { // All games have link even if we can't realy bet
                _class += ' betable';
            
                if (this.source.data.bets[game.id] !== undefined) {
                    footer = `<span class="set_bet_link" >${app.lang.common.change_bet}</span>`;
                    var data = {
                        game_id: game.id,
                        i: '',
                        chart_id: 'circle_score_'+game.id,
                        points: possible_points,
                        label: label
                    }
                    score_or_time = app.blocks.bets.getChartCircleHtml(data);
                    game.chart_value = possible_points / max_points;                
                } else {
                    if(game.options[0].points !== null) {
                        footer = `<span class="set_bet_link" >${app.lang.common.lets_bet}</span>`;
                    }else {
                        footer = `<span class="set_bet_link inactive" >${app.lang.common.lets_bet_soon}</span>`;                        
                    }
                }            
            }
            var startet_in = helpers.getLeftTimeStr(game.time);
            if(startet_in) {
                footer += `<span class="fixture_startet_in">${app.lang.common.startet_in} ${startet_in}</span>`;
            }
        } else {
            var bg = '';
            var bet = { bet: '-', points: '-' }
            var bet_class = 'bet-none';
            
            // Game started or completed
            if (this.source.data.bets[game.id] !== undefined) {
                bet = { bet: footer_label, points: points }
                if (game.status_short != 'NS') {
                    bg = points > 0 ? 'green' : 'red';
                    bet_class = points > 0 ? 'bet-right' : 'bet-wrong';
                    //bet.bet = label.replace('thunder-turbo.svg', 'thunder-turbo-yellow.svg');
                }
            }
            
            if (game.has_bet && !this.social) {                
                bet = { bet: game.label, points: game.points }
                if (game.status_short != 'NS') {
                    bet_class = game.points > 0 ? 'bet-right' : 'bet-wrong';
                    bg = game.points > 0 ? 'green' : 'red';
                    //bet.bet = label.replace('thunder-turbo.svg', 'thunder-turbo-yellow.svg');
                }
            }

            footer = `
                <div class='footer ${bg}'>
                    <div>${app.lang.common.bet}</div>
                    <div>${bet.bet}</div>
                    <div>${bet.points} ${app.lang.common.pts}</div>
                </div>
                <div class="footer-sm-line"></div>
            `;
            _class += ' ' + bet_class;
                
            if(game.team1.display_score != game.team1.score || game.team2.display_score != game.team2.score) {
                score_or_time = `<div class='score score-for-calculate'>${game.team1.score}<br /><br />${game.team2.score}</div>`;
            }else {
                score_or_time = '';
            }
            score_or_time += `<div class='score'>${game.team1.display_score}<br /><br />${game.team2.display_score}</div>`;

            if(game.is_live && game.team1.score != game.team2.score) {
                leading_arrow = '<img class="leading_arrow '+(game.team1.score > game.team2.score ? 'leading_team1' : 'leading_team2')+'" src="images/arrow.svg" />'
            }
        }
        
        var chart_value = game.chart_value ? "chart_value='"+game.chart_value+"'" : '';
        var html = `
            <div class='bl ${_class} ${current_day} fixture' matchday='${game.matchday}' fixture='${game.id}'>
                ${live_html}
                <div class='league' ${chart_value}>${app.data.leagues_name[this.source.id_league]}</div>
                <div class='date'>
                    ${date}
                </div>
                <div class='teams'>
                    <div>
                        <div><img src='${config.web_front_url}/${game.team1.img}?home' alt='' />${game.team1.name}</div>
                        <div><img src='${config.web_front_url}/${game.team2.img}?away' alt='' />${game.team2.name}</div>
                    </div>
                    ${score_or_time}
                </div>    
                ${leading_arrow}
                ${footer}
            </div>
        `;

        return html;
    }
    
    refreshTimeToStart() {
        for(var game of app.current_matchday_matches) {
            if (game.is_betable) {
                var startet_in = helpers.getLeftTimeStr(game.time);
                if(startet_in) {
                    $('[fixture="'+game.id+'"] .fixture_startet_in').html(`${app.lang.common.startet_in} ${startet_in}`);
                }            
            }
        }
    }
    
    renderGameCharts() {
        this.dom.find('div.bl .league[chart_value]').each(function() {
            var game_html = $(this).parent();
            app.blocks.bets.drawChartCircle('circle_score_'+game_html.attr('fixture'), $(this).attr('chart_value'));
        });
    }
    
    updateRoundScores(id_group = 0) {
        if(id_group == 'overall_ranking') {
            id_group = 0;
        }
        if (this.source.user) {
            var scores = this.source.user.round_scores[id_group] || {};
            this.dom.find('.matchday .score-value').each(function() {
                var round = parseInt(this.getAttribute('round'));
                this.innerText = scores[round] || '0';
            });
        }
    }

    renderMatchdayButton(matchday, current_matchday, matchday_name) {
        var active, matchday_text;
        if (matchday == current_matchday) {
            active = 'current_day active';
        }else if (matchday == (current_matchday + 1)) {
            active = 'next_day active';
        } else {
            active = 'other_day';
        }
        
        if (app.lang.common['spieltag_custom_'+matchday] && app.lang.common['spieltag_custom_'+matchday] != '') {
            matchday_text = `<div class="l11">${app.lang.common['spieltag_custom_'+matchday]}</div>`;
        } else {
            let l11 = matchday + `<span class="dot">.</span>`;
            let l12 = app.lang.common.matchday;
            var translated = this.getMatchdayNameTrasnlated(matchday_name);
            if(translated) {
                l11 = translated[1] || '';
                l12 = translated[2] || '';
            }
            matchday_text = `<div class="l11">${l11}</div>
                    <div class="l12">${l12}</div>`;
        }
        
        var html = `
            <div class='bl matchday ${active}' for-matchday='${matchday}' matchday_name="${matchday_name}" >
                <div class="l1">
                    ${matchday_text}
                </div>
                <div class="l2">${app.lang.common.ranking}</div>
                <div class="round-score"><span class="score-value" round="${matchday}">${this.source.user.round_scores[0][matchday] || '0'}</span> ${app.lang.common.pts}</div>
            </div>
        `;
        
        
        /*if(app.config.euro_mod && active != 'other_day') {
            html += `
                <div class='euro_mod-matchday' for-matchday='${matchday}'>
                    ${this.getMatchdayNameTrasnlated(matchday_name)}
                </div>
            `;
        }*/

        return html;
    }
    
    getMatchdayNameTrasnlated(name) {
        if (!this.matchday_translations) {
            this.matchday_translations = app.config.rounds_translation.split("\n");
            for(var n in this.matchday_translations) {
                this.matchday_translations[n] = this.matchday_translations[n].split(";");
            }
        }
        
        if (this.matchday_translations) {
            for(var n of this.matchday_translations) {
                if (n[0] && n[0] == name) {
                    return n;
                }
            }
        }
        
        return false;
    }

    scrollToUpcommingGame(i) {
        var up = $(this.dom_content).find('.upcomming.current_day');
        if (up.length) {
            up = up[0];
            if ($(up).is(':visible')) {
                if(this.dom_content.hasClass('native-scroll')) {
                    var pos = $(up)[0].offsetTop - $(window).width() / 2 + 95;
                    $(this.dom_content).children().animate({ scrollTop: pos}, 0);
                }else {
                    var pos = $(up)[0].offsetLeft - $(window).width() / 2 + 95;
                    $(this.dom_content).animate({ scrollLeft: pos}, 0);
                }
                this.scrolled_to_current = true;
            } else {
                if ($(this.dom_content).css('display') != 'none') {
                    if (i && i < 20) {
                        setTimeout(() => { this.scrollToUpcommingGame(i ? i+1 : 1); }, 30);
                    }
                }
            }
        }
    }
    
    scrollToCurrentMatchday(i = 0) {
        var md = $(this.dom_content).find('.matchday.current_day.active');
        if(md.length > 0) {
            if (md.is(':visible') && md.next().is(':visible') ) {
                this.scrollToMatchday(md[0]);
            }else {
                if (i < 20 && $(this.dom_content).css('display') != 'none') {
                    setTimeout(() => { this.scrollToCurrentMatchday(i+1); }, 30);
                }
            }
        }else if (i < 20) {
            setTimeout(() => {
                this.scrollToCurrentMatchday(i+1);
            }, 10);
        }
    }

    scrollToMatchdayNr(round, i = 0) {
        var md = $(this.dom_content).find('.matchday[for-matchday="'+round+'"]');
        if(md.length > 0) {
            if (md.is(':visible') && md.next().is(':visible') ) {
                this.scrollToMatchday(md[0]);
            }else {
                if (i < 20 && $(this.dom_content).css('display') != 'none') {
                    setTimeout(() => { this.scrollToMatchdayNr(round, i+1); }, 30);
                }
            }
        }
    }
    
    scrollToMatchday(element, start) {
        if(this.dom_content.hasClass('native-scroll')) {
            if (element) {
                var pos = element.offsetTop;
                $(this.dom_content).children().scrollTop(pos);
            } else if(!element && start) {
                $(this.dom_content).children().scrollTop(0);
            }
        }else {
            if (element) {
                var pos = element.offsetLeft - 15;
                $(this.dom_content).animate({ scrollLeft: pos}, 0);
            } else if(!element && start) {
                $(this.dom_content).animate({ scrollLeft: 0}, 0);
            }
        }
        this.scroll_to_matchday_counter++;
    }

    changePage() {
        if(app.current_page != 'group') {
            this.scroll_to_matchday_counter = 0;
        }
    }
}
