
var Langs = Langs || {};
Langs.de = Langs.de || {};

Langs.de.group = {
    overall_ranking: 'GESAMT<br />RANGLISTE',
    add_group: 'Gruppen',
    add_group_for_style_green: '<b>Gruppen</b><br/>erstellen / suchen',
    create_group: 'Gruppe erstellen',
    your_group: 'Erstelle eine Gruppe',
    create_group_info: 'Gib einen Gruppennamen ein und wähle, ob die Gruppe privat oder öffentlich sein soll. Jeder Spieler kann öffentlichen Gruppen beitreten. Für private Gruppen wird ein Passwort benötigt. Um die Gruppe zu bearbeiten, geh in die Gruppe und wähle dann die Einstellungen oben rechts.',
    name: 'Name',
    invitation: 'Gruppe teilen',
    invitation_info: 'Kopiere diesen Link und teile ihn mit deinen Freunden',
    private: 'Privat',
    public: 'Öffentlich',
    password: 'Passwort',
    finished: 'Fertig!',
    invite_friends: 'Freunde einladen',
    group_not_exists: 'Die Gruppe existiert nicht',
    hi: 'Tritt der Gruppe bei',
    join_info: 'Bitte gib das Passwort der Gruppe ein, um der Gruppe beizutreten. Wenn du das Passwort nicht kennst, frag den Admin der Gruppe.',
    join_info_public: 'Dies ist eine öffentliche Gruppe. Du kannst einer öffentlichen Gruppe jederzeit beitreten oder sie wieder verlassen. Um die Gruppe zu verlassen, geh in die Gruppe und wähle dann die Einstellungen oben rechts.',
    join_password: 'Passwort',
    join_btn: 'Gruppe beitreten',
    can_not_edit_this_group: 'Gruppe kann nicht editiert werden',
    edit_group: 'Einstellungen Gruppe',
    group_name: 'Gruppenname',
    name_placeholder: 'Max. 30 Zeichen',
    logo: 'Logo',
    change_name: 'Namen ändern (Admin)',
    change_password: 'Passwort ändern',
    copy_link: 'Link kopieren',
    new_data_refresh_title: 'Tor-Update!',
    new_data_refresh_message: 'Die Spielstände werden<br/> aktualisiert.',
    start_match_title: 'Anpfiff!',
    start_match_message: 'Verfolge deine Punkte jetzt <br/> im Live-Ranking!',
    new_data_refresh_btn: 'Ok',
    tipp: 'TIPP',
    unjoin_btn: 'Gruppe verlassen',
    unjoin_confirm: 'Möchtest du die Gruppe verlassen?',
    delete_group: 'Tipprunde verlassen',
    unjoin_group: 'Gruppe verlassen',
    confirm_delete_group: 'Möchtest du die Gruppe löschen?',
    confirm_delete_group_info: 'Bitte bestätige, dass du die Gruppe wirklich löschen möchtest.',
    confirm_unjoin_group: 'Möchtest du die Gruppe wirklich verlassen?',
    confirm_unjoin_group_info: 'Wenn du die Tippgruppe verlässt, werden keine weiteren Punkte gezählt bist du wieder beitrittst.',
    no_thanks: 'Nein, danke!',
    privacy: 'Datenschutz',
    send_ticket_with: 'Einladung versenden mit',
    team: 'Gruppe',
    share_title: 'Betano World Tipster League',
    share_message: "Hast du es drauf? Komm in meine Gruppe '{group}' und miss dich mit mir in der World Tipster League:",
    whatsapp_share_message: "Hast du es drauf? Komm in meine Gruppe *'{group}'* und miss dich mit mir in der World Tipster League:",
    rank: 'Rang',
    more_info: 'Mehr Infos',
    warning_msg: '18+ | Wetten kann süchtig machen. Hilfe: BZGA.de.',
    sponsored_challenge_join: 'Challenge annehmen',
    confirm_reg_button: 'Zur anmeldung',
    thanks_for_register: 'Danke für Deine Registrierung.',
    thanks_for_register_message: 'Du erhältst eine Anmeldebestätigung per E-Mail.',
    confirm_reg_button_done: 'OK',
    thanks_for_register_done: 'Deine Registrierung wurde bestätigt.',
    thanks_for_register_done_message: 'Bitte gib dein Passwort ein und melde dich an.',
    
    sponsored_challenge_not_join: 'Nein, Danke!',
    scp: 'Prices',
    invite_friends_text: 'Kopiele diesen Link und teile ihn mit deinen Freunden',
    password_reset_confirm: 'Passwort zurücksetzen',
    password_reset_confirm_message: 'Du erhältst eine Bestätigung zum Zurücksetzen des Passworts per E-Mail',
    group_in_leagues: 'Ausgewählte Ligen',
    add_league: 'Wettbewerb hinzufügen',
    group_type: 'Gruppentyp',
    group_type_desc: 'Möchtest du, dass deiner Tipprunde jeder beitreten kann oder nur von dir eingeladene Personen?',
    invite_friends_title: 'Mitglieder einladen',
    invite_friends_desc: 'Teile folgenden Link mit deinen Freunden & Kollegen um sie in deine Tippgruppe einzuladen.',
    sharing_failed: 'Sharing failed',
    sharing_success: 'Sharing success',
    group_overall_ranking_info: 'Klicke auf den Spieltag um das ranking zu sehen.',
    group_overall_ranking_round_info: 'Click on Matchday again to see global ranking.',
    group_overall_ranking_fixture_info: 'Klicke auf den Spieltag um das Ranking zu sehen.',
    group_ranking_info: 'Tippe auf die Spiele um die Einzelwertungen zu sehen.',
    division_ranking_info: 'Platz 1. und 2. steigen auf – Platz 15. - 18. steigen ab',
    create_or_search_title: 'Erstellen oder beitreten?',
    create_or_search_desc: 'Möchtest du eine neue oder eine bestehende Tipprunde suchen und dieser beitreten?',
    create_or_search_btn_create: 'Tipprunde erstellen',
    create_or_search_btn_search: 'Tipprunde suchen',
    new_group: 'Neue Tippgruppe',
    new_group_step_1_title: 'Welchen Namen soll die Tippgruppe haben ?',
    new_group_name_placeholder: 'Max. 20 Zeichen',
    new_group_step_2_title: 'Welche Wettbewerbe wollt ihr tippen?',
    new_group_step_3_title: 'Soll deine Tippgruppe öffentlich sein?',
    new_group_step_3_public_desc: 'Deine Tippgruppe ist für alle tipptastic User sichtbar und jeder kann ihr beitreten.',
    new_group_step_3_private_desc: 'Deiner Tippgruppe kann man nur über einen Einladungslink beitreten.',
    new_group_password_placeholder: 'Passwort',
    new_group_invite_frinds: 'Freunde einladen',
    new_group_step_4_title: 'Tippgruppe erstellt!',
    new_group_step_4_desc: 'Glückwunsch – deine Tippgruppe wurde erfolgreich angelegt. Lade jetzt deine Freunde ein und legt los.',
    choose_logo: 'Hinzufügen',
    add_user: 'Mitglieder hinzufügen',
    group_users: 'Mitglieder',
    create_group_headline: 'Erstelle deine eigene Tipprunde',
    save_group: 'Tipprunde erstellen',
    select_leagues_for_bet: 'Wähle die Wettbewerbe aus <br /> die Du tippen möchtest.',
    password_is_required: 'Das Passwort darf nicht leer sein',
    password_is_too_short: 'Das Passwort ist zu kurz',
    confirm_unjoin_user_by_admin: 'Do you really want to delete?'
};