class BlockSponsoredChallenge {
    constructor(dom) {
        this.dom = dom;

        eventHandler.observe('block_sponsored_challenge', ['language_change'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    render(id) {
        if (!this.dom) return;
        
        this.dom.empty();
        if (!id) return;
                
        this.group = (app.data.groups_to_add.concat(app.data.groups)).find(x => x.id == id);
        this.is_joined = !!app.data.groups.find(x => x.id == id);
        if(!helpers.isSponsoredChallengeGroup(this.group)) {
            return ;
        }
        var data = this.group.options;        
        var positions_html = '';
        
        for(var i in data.sponsored_challenge) {
            var item = data.sponsored_challenge[i];
            if(!item.position || !item.title) {
                continue;
            }
            positions_html += `
                <div class="position-content">
                    <div class="position-img">
                        <img src="${config.web_front_url}/${data.files['sponsored_challenge_img_'+i]}" />
                    </div>
                    <div class="position-title">${item.title}</div>
                    <div class="position-short_desc">${item.short_desc}</div>
                    <div class="position-more-info">
                        <button type="button" class="btn-sportde-short btn-challenge btn-more-info" data-i="${i}" >${app.lang.group.more_info}</button>
                    </div>
                </div>
            `;
        }
        
        var join_html = '';
        var not_join_html = '';
        if(!this.is_joined) {
            join_html = `
                <div class="join-content">
                    <div>
                        <button type="button" class="tip-button tip-button-yellow btn-join">${app.lang.group.sponsored_challenge_join}</button>
                    </div>
                </div>
            `;

            not_join_html = `
                <div class="not-join-content">
                    <div>
                        <span class="btn-link btn-not-join">${app.lang.group.sponsored_challenge_not_join}</span>
                    </div>
                </div>
            `;
        }
        
        var html = `
            <div class="block_sponsored_challenge">
                <div class="general-content">
                    <div class="general-title">${data.general_title}</div>
                    <div class="general-img">
                        <img src="${config.web_front_url}/${data.files.general_img}" />
                    </div>

                    ${join_html}

                    <div class="general-description">
                        ${data.general_description}
                    </div>
                </div>
                <div class="positions-content">
                    ${positions_html}
                </div>
        
                ${not_join_html}
        
                <!--<div class="warning-msg">${app.lang.group.warning_msg}</div>-->
            </div>
        `;
        
        this.dom.html(html);
        
        this.dom.find('.btn-more-info').click(function() {
            var i = $(this).data('i');
            var item = data.sponsored_challenge[i];
            if(!item.position) {
                return;
            }
            if(this.dialog) {
                this.dialog.close();
            }
            
            var html = `
                <div class="dialog_sponsored_challenge">
                    <div class='pr_title'>${item.title}</div>
                    <div class='pr_img'><img src="${config.web_front_url}/${data.files['sponsored_challenge_img_'+i]}" /></div>
                    <div class='pr_logo'><img src="${config.web_front_url}/${data.files['sponsored_challenge_logo_'+i]}" /></div>
                    <div class='pr_message'>${item.long_desc}</div>
                </div>
            `;
            
            this.dialog = new Dialog();
            this.dialog
                .html(html)
                .btn(app.lang.common.ok, () => {
                    this.dialog.close();
                }, {black: false, sportde: true})
                .show();
        });
        
        this.dom.find('.btn-not-join').click(() => {
            app.renderPage('home');
        });
        
        this.dom.find('.btn-join').click(() => {
            app.blocks.group_join.joinToAdGroup(this.group);
        });
        this.dom.find('.warning-msg').click(() => {
            if(this.dialog) {
                this.dialog.close();
            }
            
            var html = `
                <div class="dialog_sponsored_challenge_help">
                    <div class='pr_message'>${data.help_description.replaceAll("\n", '<br/>')}</div>
                </div>
            `;
            
            this.dialog = new Dialog();
            this.dialog
                .html(html)
                .btn(app.lang.common.ok, () => {
                    this.dialog.close();
                }, {black: true})
                .show();
        });
    }
}