
var Langs = Langs || {};
Langs.de = Langs.de || {};

Langs.de.common = {
	title: 'Betano World Tipster League',
	welcome: '',
	footer: '',
	home: 'STARTSEITE',
    home2: 'Startseite',
	lets_bet: 'Tipp abgeben',
	lets_bet_soon: 'Tippabgabe in Kürze',
    change_bet: 'TIPP ÄNDERN',
	bet: 'TIPP:', 
	pts: 'Pkt.',
    league_name: 'EM 2021', 
	completed: 'BEENDET',
    how_it_works: 'So geht\'s',
    conditions: 'Teilnahmebedingungen',
	matchday: 'SPIELTAG',
	matchday2: 'Spieltag',
	ranking: 'Rangliste',
    evaluation: 'AUSWERTUNG',
	groups_header: 'DEINE RANGLISTEN & GRUPPEN',
	group_manager: 'GRUPPENMANAGER',
	group_search: 'Gruppe suchen',
	group_search_title: 'Öffentliche Gruppen',
	group_search_desc: 'Du kannst öffentlichen Gruppen kostenlos beitreten',
	search: 'Suche',
	create_group: 'Gruppe erstellen',
    remis: 'Remis',
    win_probability: 'Siegwahrscheinlichkeit',
	rank: 'RANG',
	points: 'PUNKTE',
    round_points: 'Punkte Spieltag',
    all_points: 'Punkte Gesamt',
	recent_groups: 'LETZTE GRUPPEN',
	open_groups: 'TRITT EINER ÖFFENTLICHEN GRUPPE BEI',
	no_groups_found: '<span class="sorry">SORRY!</span> <span class="no-groups-text"> Deine Suchanfrage ergab keine Treffer.</span>',
	searched_groups: 'Suchergebnis',
	join_group: 'Gruppe beitreten',
	tutorial: 'TUTORIAL',	
	next: 'Weiter',
    fields_required_info: '*Pflichtfelder',
    choose_leagues: 'Wettbewerbe verwalten',
    choose_leagues_info: 'Wähle die Wettbewerbe aus<br/>die Du tippen möchtest.',
    take_over: 'Übernehmen',
	users: 'USER',
	user: 'User',
    back: 'Zurück',
	group_header: 'Rangliste',
	ranking_total: 'Gesamt',
    no_trends: 'Keine trends',
	season: 'SAISON',
    liga: 'LIGA',
    performance: 'Performance',
	skip_tutorial: 'TUTORIAL ÜBERSPRINGEN',
	tutorial_top: "So einfach geht's",
	chart_locked: 'Log dich ein um vollständige Statistiken zu sehen',
    chart_nodata: 'Es gibt noch nicht genügend Daten für diese Statistik',
    winning_percent_chart_label: 'TREFFERQUOTE',
    risk_chart_label: 'RISIKOFREUDE',
    charts_header: 'Statistiken',
    promote_title: 'Gratulation!',
    promote_message: 'Du bist in Liga {pos} aufgestiegen.',
    relegate_title: 'UH-OH!',
    relegate_message: 'Du bist in Liga {pos} abgestiegen.',
    relegate_message_100: 'Du bist in die Freizeitliga abgestiegen',
    no_league_change_title: 'Tor-Update!',
    no_league_change_message: 'Du spielst weiterhin in Liga {division}.',
    no_league_change_message_100: 'Du spielst weiterhin in Freizeitliga.',
	league_history: 'Liga Verlauf',
    division100: 'Freizeitliga',
	division100_short: 'Freizeit',
	division1: 'LIGA 1, STAFFEL 1',
    join_to_other_leagues_title: 'Neue Wettbewerbe in Ihren Gruppen',
    join_to_other_leagues: 'Es gibt neue Wettbewerbe:<br /><b>{leagues_names}</b><br /><br /> in der Gruppe:<br /><b>{groups_names}</b><br />Möchten Sie sich ihnen anschließen?',
    matchday_improvement_title: 'Great stuff!',
    matchday_improvement_message: 'On matchday {round} you have improved your ranking from {old_position} to {position}. Keep it up!',
    risk: 'RISIKO',
	days: [
		'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
	],
	days_short: [
		'Sp', 'Mp', 'Di', 'Mi', 'Do', 'Fr', 'Sa'
	],
    halftime: 'HZ',
	other_user: 'Andere User',
    terms_of_use_accept_title: 'Änderungen der Nutzungsbedingungen',
    terms_of_use_accept_message: 'Tippe auf ZUSTIMMEN, um die neuen <a href="#terms_of_use">Nutzungsbedingungen</a> zu akzeptieren. Du kannst unseren Support&Feedback nutzen, wenn du weitere Informationen erhalten möchtest. ',
    terms_of_use_accept_title2: 'Achtung!',
    terms_of_use_accept_message2: 'Um tipptastic weiter nutzen zu können, musst du unseren Nutzungsbedingungen zustimmen. Ohne Zustimmung muss dein Account leider geschlossen werden.',
	form: 'Form',
	table: 'Tabelle',
        trend: 'Trend',
        team: 'Team',
        matchs_played: 'Spiele',
        goals_for: 'Tore',
        points: 'Pkt.',
        last_game: 'Letztes Spiel',
	vs: 'vs',
	score: 'Punktestand',
	position: 'Rang',
    position2: 'Position',
    position3: 'Platz',
    points_to: 'Punkte Zum',
    points_to_promote: 'Augstieg',
    points_to_relegate: 'Abstieg',
    bets: 'Tipps',
	possible_points: 'Mögliche Punkte',
	win: 'Sieg',
	turbo: '(turbo)',
	draw: 'Unentschieden',
	no_bet: 'Noch nicht getippt',
	bet_choose_desc: 'Tippe jetzt auf deinen Favoriten und sichere<br/>Dir wichtige Punkte. Entscheide weise!',
    your_points: 'Deine Punkte',
	your_max_playsday_points: 'Deine max. Spieltagspunkte',
	versionnumber: 'Versionsnummer',
	ad3_text: 'SPIEL DEINEN TIPP MIT 10 € UND GEWINNE',
	ad3_button: 'JETZT WETTEN',
	ad3_warning: '18+ Glücksspiel kann süchtig machen',
    ad3_no_bet: 'Hol dir jetzt 100% Neukundenbonus bis 100€',
	page_not_found: 'Seite nicht gefunden',
	please_wait: 'Bitte warten...',
	unknown_error: 'Es ist ein Fehler aufgetreten',
    close: 'Schließen',
    correct: 'RICHTIG',
    incorrect: 'FALSCH',
	save: 'Speichern',
	change: 'Ändern',
    overall: 'Gesamt',
    overall2: 'Gesamtwertung',
    season_progress: 'Saison Fortschritt',
    wins: 'Siege',
    round_wins: 'Spieltag Siege',
    division: 'Liga',
    division_history: 'Saison Fortschritt',
	cancel: 'Abbrechen',
	division_level: 'LIGA',
	division_position: 'STAFFEL',
	division_position_lower: 'Staffel',
	startet_in: 'Startet in',
	min: 'min',
	hours: 'Stunden',
	hour: 'Stunde',
	daysStr: 'Tagen',
	day: 'Tag',
	ok: 'OK',
	thanks: 'Thanks',
	yes: 'Ja',
	no: 'Nein',
	link_copied: 'Link in die Zwischenablage kopiert',
	chart_view: 'Mehr davon',
    menu_title: 'Menü',
	menu: {
		/*contents_included: 'Inhalte',*/
		about_us: 'Über uns',
		contact: 'Kontakt',
		privacy: 'Datenschutz',
		impress: 'Impressum',
		faq: 'FAQ',
        how_it_works: 'how_it_works',
		invite_friends: 'Mit freunden teilen',
		terms_of_use: 'Nutzungsbedingungen',
		logout: 'Ausloggen',
        legal: 'Rechtliches',
        unsubscribed: 'Unsubscribed'
	},
	content_page: {
		about_us: 'default',
		contact: 'default',
		privacy: 'default',
		terms_of_use: 'default',
		impress: 'default',
        how_it_works: 'default',
		faq: 'default'
	},
	go_back: 'Zurück',
	impress: 'Impressum',
	privacy: 'Datenschutz',
    server_disconnection: 'Serververbindung unterbrochen. Wiederverbindung in {seconds} Sekunden.',
	server_reconnection: 'Der Versuch, mit dem Server zu verbinden ...',
	invite_friends: 'Mit freunden teilen',
	app_invite_title: 'Betano World Tipster League',
	app_invite_desc: 'Sende den Link deinen Freunden<br/> um sie zur World Tipster League einzuladen',
	send_ticket_with: 'Einladung versenden mit',
    finished: 'Fertig!',
    live_match: 'Live',
    share_title: 'Betano World Tipster League',
    share_message: 'Hast du es drauf? Komm in meine Gruppe und spiel mit mir in der World Tipster League:',
    share_message_app: 'Hast du es drauf? Komm in meine Gruppe und spiel mit mir in der World Tipster League: {app_url}',
    share_message_web: 'Hast du es drauf? Komm in meine Gruppe und spiel mit mir in der World Tipster League: {web_url}',
	chart_info_box: {
		overall: `
			<h2 style="text-align: center">{title}</h2>
			<b>Trans key: "{key}".</b> 
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam velit augue, consectetur in purus quis, interdum pellentesque risus. Fusce ac orci malesuada, dignissim nibh quis, dictum augue. Suspendisse malesuada, lectus non varius gravida, lectus sapien consequat leo, in malesuada sapien lorem eu nibh. Aenean vulputate in arcu sed mattis.
		`,
		group: `
			<h2 style="text-align: center">{title}</h2>
			<b>Trans key: "{key}".</b> 
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam velit augue, consectetur in purus quis, interdum pellentesque risus. Fusce ac orci malesuada, dignissim nibh quis, dictum augue. Suspendisse malesuada, lectus non varius gravida, lectus sapien consequat leo, in malesuada sapien lorem eu nibh. Aenean vulputate in arcu sed mattis.
		`,
		division_history: `
			<h2 style="text-align: center">{title}</h2>
			<b>Trans key: "{key}".</b> 
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam velit augue, consectetur in purus quis, interdum pellentesque risus. Fusce ac orci malesuada, dignissim nibh quis, dictum augue. Suspendisse malesuada, lectus non varius gravida, lectus sapien consequat leo, in malesuada sapien lorem eu nibh. Aenean vulputate in arcu sed mattis.
		`,
		division_history: `
			<h2 style="text-align: center">{title}</h2>
			<b>Trans key: "{key}".</b> 
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam velit augue, consectetur in purus quis, interdum pellentesque risus. Fusce ac orci malesuada, dignissim nibh quis, dictum augue. Suspendisse malesuada, lectus non varius gravida, lectus sapien consequat leo, in malesuada sapien lorem eu nibh. Aenean vulputate in arcu sed mattis.
		`,
		winning: `
			<h2 style="text-align: center">{title}</h2>
			<b>Trans key: "{key}".</b> 
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam velit augue, consectetur in purus quis, interdum pellentesque risus. Fusce ac orci malesuada, dignissim nibh quis, dictum augue. Suspendisse malesuada, lectus non varius gravida, lectus sapien consequat leo, in malesuada sapien lorem eu nibh. Aenean vulputate in arcu sed mattis.
		`,
		risk: `
			<h2 style="text-align: center">{title}</h2>
			<b>Trans key: "{key}".</b> 
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam velit augue, consectetur in purus quis, interdum pellentesque risus. Fusce ac orci malesuada, dignissim nibh quis, dictum augue. Suspendisse malesuada, lectus non varius gravida, lectus sapien consequat leo, in malesuada sapien lorem eu nibh. Aenean vulputate in arcu sed mattis.
		`
	},
    bets_sum_odd_top_title: "Kombi-Tipp",
    bets_sum_odd_title_1: "Gewinne mit deinem Tipp",
    bets_sum_odd_title_2: "Und 10€ Einsatz!",
    bets_sum_odd_link: "Los geht’s",
    bets_sum_odd_info: "Glücksspiel kann süchtig machen. Spielen Sie verantwortungsbewusst.",
    bet_title: "tippen",
    euro_magazine_link_text: "Zur EM Übersicht",
    spieltag_custom_1: '',
    spieltag_custom_2: '',
    spieltag_custom_3: '',
    spieltag_custom_4: '',
    spieltag_custom_5: '',
    spieltag_custom_6: '',
    spieltag_custom_7: '',
    spieltag_custom_8: '',
    spieltag_custom_9: '',
    spieltag_custom_10: '',
    choose_league: 'Übersicht Wettbewerb',
	select_leagues: "Auswahl Rangliste",
    success: 'Success',
    error: 'Fehler',
    settings: {
        edit_profile: 'Einstellungen',
        leagues: 'Ligen / Wettbewerbe',
        notifications: 'Benachrichtigungen',
        push_notifications: 'Push - Benachrichtigungen',
        faq: 'FAQ',
        more: 'Mehr...',
        more_info: 'Mehr Infos',
        how_it_works: 'So geht’s',
        support: 'Support & Feedback',
        one_league_is_required: 'Eine Liga ist erforderlich',
        leagues_have_been_saved: 'Die Ligen wurden gerettet',
        notifications_have_been_saved: 'Benachrichtigungen wurden gespeichert'
    },
	statistics: 'Statistik',
	trends: 'Trends',
	whatsapp: 'Whats App',
	share: 'Link teilen',
    to_overview: 'Zur Übersicht',
    after_registration_reminder_step_title: 'Kein Spiel verpassen!',
    after_registration_reminder_step_desc: 'Nutze unsere Tipperinnerung um zukünftig kein Spiel zu verpassen.',
    after_registration_reminder_step_btn_show: 'Benachrichtigung erlauben',
    after_registration_reminder_step_btn_close: 'Nein, danke',
    after_registration_reminder_step_footer_info: 'Diese Einstellung ist jederzeit in deinem Profil änderbar',
    app_review_yes: 'Yes',
    app_review_no: 'No',
    app_review_later: 'Later',
    app_review_subject: 'Gefällt dir tipptasctic?',
    app_review_description: 'Möchten Sie es jetzt im App Store bewerten?',
    
};
