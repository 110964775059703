class BlockGroups {
    constructor(dom) {
        this.dom = dom;
        this.source = app;
        this.social = false;
        
        this.dom_content = false;
        this.dom_group = false;

        eventHandler.observe('block_groups', ['language_change', 'user_login', 'set_data_groups', 'group_sync_added', 'set_data_group_hiddens'], () => {
            this.render();
        }, this);
    }
    
    setSocial(source) {
        this.source = source;
        this.social = true;        
        return this;
    }

    setDom(dom) {
        this.dom = dom;

        var html = `
            <div class='block_groups_header'>
                ${app.lang.common.groups_header}
            </div>
        `;
        this.dom.html(html);
        this.dom_content = this.dom;
        
        return this;
    }

    sortGroups() {
        this.source.data.groups.sort((a, b) => {
            if(helpers.isAdGroup(a) != helpers.isAdGroup(b)) {
                return helpers.isAdGroup(a) ? -1 : 1;
            }
            if((a.division == 0) != (b.division == 0)) {
                return a.division == 0 ? 1 : -1;
            }
            if(a.id == 'overall_ranking') {
                return -1;
            }
            if(b.id == 'overall_ranking') {
                return 1;
            }

            if(a.id_owner != b.id_owner) {
                if (app.user) {
                    if(a.id_owner == app.user.id) {
                        return -1;
                    }
                    if(b.id_owner == app.user.id) {
                        return 1;
                    }
                }
            }

            return a.id > b.id ? -1 : 1;
        });
    }

    setUnloginSource() {
        this.source = {
            data: {
                groups: []
            }
        };
        this.source.data.groups.push({
            id: 'overall_ranking',
            name: app.lang.group.overall_ranking,
            image_thumb: false
        });
        if(!app.config.euro_mod) {
            this.source.data.groups.push({
                id: 'division',
                name: app.lang.common.division,
                image_thumb: false
            });
        }
    }

    render() {
        var self = this;
        if (!this.dom_content) {
            return this;
        }

        if(!app.isAuth() && !this.social) {
            this.setUnloginSource();
        }else if(!this.social) {
            var ad_groups = helpers.getAdGroups();
            this.source = {
                data: {
                    groups: app.data.groups.concat(ad_groups)
                }
            };
        }

        var groups = [];
        this.sortGroups();

        for(var group of this.source.data.groups) {
            if(typeof group.options != 'object') {
                try {
                    group.options = JSON.parse(group.options);
                }catch(err) {
                    group.options = {};
                }
            } else if (!group.options) {
                group.options = {};
            }
            
            if(helpers.isSponsoredChallengeGroup(group)) {
                if(config.disable_finance) {
                    continue;
                }
                if(app.data.groups.find(x => x.id == group.id)) {
                    groups.push(this.renderGroup(group));
                }else {
                    groups.push(this.renderAd(group));
                }
            }else if (helpers.isAdGroup(group)) {
                groups.push(this.renderAd(group));
            } else {
                groups.push(this.renderGroup(group));
            }
        }

        var groups_html = groups.join('');

        var html = `
            <div class='block_groups_header'>
                ${app.lang.common.groups_header}
            </div>
            <div class='scroll'>
                <div class='block_groups'>
                    ${this.renderAddGroup()}
                    ${groups_html}
                </div>
            </div>
        `;

        this.dom_content.html(html);        

        this.dom_content.find('.add').click(function() {
            if(!app.isAuth()) {
                app.renderPage(app.getUserCookie('rt90_register') == '1' ? 'login' : 'register');
            } else {
                //app.renderPage('group_search');
                //app.renderPage('group_create_or_search');
                app.blocks.group_search.createOrSearchDialog();
            }         
        });

        this.dom_content.find('.group').click(function() {          
            if(!app.isAuth()) {
                app.renderPage(app.getUserCookie('rt90_register') == '1' ? 'login' : 'register');
            }
            
            /*if(!self.social) {
                return false;
            }*/
            
            //app.blocks.group.render($(this).attr('groupid'));
            var groupId = $(this).attr('groupid');
            if(!app.data.groups.find(x => x.id == groupId)) {
                var to_add = app.data.groups_to_add.find(x => x.id == groupId);

                if(to_add && helpers.isSponsoredChallengeGroup(to_add)) {
                    self.sponsoredChallenge(to_add);
                }else if(to_add && to_add.options.type == 1) {
                    self.promoBox(to_add);
                }else if(to_add) {
                    app.renderPage('join_to_group', {c: to_add.url_code});
                }
            }else {
                app.renderPage('group', {id: groupId});
            }
        });

        this.dom_content.find('.btn-close').click(function() {
            var data = $(this).data();
            ws.request('group/hide', data);
            $(this).closest('.group').remove();
            return false;
        });

        /*
        $(this.dom_content).find('.block_groups').dotsScroll({
            dot: "<div class='dot'></div>",
            active_dot_class: "dot_active",
            inside_element_width: 125,
        });
        */
        //$(this.dom_content).find('.scroll').hScroll2();
        //$(this.dom_content).find('.scroll').addClass('mobile_overflow_x');
        if(groups.length < 8) {
            $(this.dom_content).addClass('no-scroll').css('overflow-x', 'hidden');            
        }
        if(!app.mobile) {
            $(this.dom_content).find('.scroll').addClass('native-scroll').swipeIndicator();
        }else {
            $(this.dom_content).find('.scroll').removeClass('scroll').addClass('mobile-scroll');            
        }
        this.scrollToFirst();
        return this;
    }

    sponsoredChallenge(group) {
        app.renderPage('sponsored_challenge', {id: group.id});
    }
    
    promoBox(group) {
        if(group.options.promo_target == 1) {
            var dialog = new Dialog();
            dialog.html(group.options.promo_free_text)
                .btn(app.lang.group.new_data_refresh_btn, () => {
                    dialog.close();
                }, {black: false, sportde: true})
                .show();
        }else {
            app.renderPage('faq', {open: group.options.promo_faq});
        }
    }

    renderAd(ad) {
        if(config.disable_finance) {
            return '';
        }
        if(typeof ad.options != 'object') {
            try {
                ad.options = JSON.parse(ad.options);
            }catch(err) {
                ad.options = {};
            }
        }
        
        var style = `
            background-image: url(${config.web_front_url}/groups/${ad.id}_thumb_${config.style == 'fussballdaten' ?  'fussballdaten_' : ''}${ad.image_thumb}); 
            background-repeat: no-repeat;
            background-position: center; 
        `;
        if(ad.options.home_btn_bg_color) {
            style += `background-color: ${ad.options.home_btn_bg_color}; `;
        }
        if(ad.options.home_btn_bg_size) {
            style += `background-size: ${ad.options.home_btn_bg_size}%; `;
        }
        if(ad.options.home_btn_bg_position) {
            style += `background-position: ${ad.options.home_btn_bg_position}; `;
        }
        
        var home_close = '';
        if(ad.options.home_close == 1) {
            home_close = '<span class="btn-close" data-url_code="'+ad.url_code+'" >X</span>';
        }
        var html = `
            <div class='bl group ad' data-groupid='${ad.id}' groupid='${ad.id}' urlcode='${ad.url_code}' style="${style}">
                <div class="title">${ad.options.home_btn_text || ''}</div>
                ${home_close}
            </div>            
        `;

        return html;
    }

    renderGroup(group) {
        var arrow = '';
        var content = '';
        //group.position = 4433; group.previous_position = 5555;
        if(group.id == 'overall_ranking') {
            group.name = app.lang.group.overall_ranking;
        }

        if (app.isAuth()) {
            if(group.previous_position > 0) {
                if (group.position < group.previous_position) {
                    arrow = `<img class='arrow-indicator' src='images/${config.style ? config.style+'/' : ''}up-arrow2.svg' />`;
                }

                if (group.position > group.previous_position) {
                    arrow = `<img class='arrow-indicator down' src='images/${config.style ? config.style+'/' : ''}down-arrow2.svg' />`;
                }
            }
            
            if (!group.hasOwnProperty('previous_position') || group.previous_position == null || group.previous_position == '0') {
                group.previous_position = '';
            }
            
            var br = group.position <= 999 ? '<br />' : '';
            var group_position_class = group.position.toString().length > 2 ? 'small' : '';
            content = `
                <div class='positions ${group_position_class}'>
                    ${group.position}. <span>${arrow}${helpers.formatPreviousPosition(group.position, group.previous_position)}</span>${br}
                    <span class="position-label">${app.lang.group.rank}</span>
                </div>`;
        } else {
            content = `<div class='lock'></div>`;
        }
        
        var group_name = helpers.getGroupName(group).replace(',', '<br />');
        var style = '';
        var extra_class = '';
        if(group.options.home_ranking_bg_color) {
            style += 'background-color: '+group.options.home_ranking_bg_color+'; ';
            extra_class += 'set-home_ranking_bg_color ';
        }
        if(group.options.home_ranking_text_color) {
            style += 'color: '+group.options.home_ranking_text_color+'; ';
            extra_class += 'set-home_ranking_text_color ';
        }

        var html = `
            <div class='bl group ${extra_class}' groupid='${group.id}' style='${style}'>
                <div class='title break-word'>${group_name}</div>
                ${content}
            </div>
        `;

        return html;
    }

    renderAddGroup() {
        var classes = '';
        var text = app.lang.group.add_group;
        classes += 'bl group ';
        text = app.lang.group.add_group_for_style_green;
        
        var html = `
            <div class='add ${classes}'>${text}</div>
        `;

        return html;
    }

    scrollToFirst() {
        
    }
}
