class BlockCharts {
    constructor(dom) {
        this.dom = dom;
        this.source = app;
        this.social = false;
        
        this.charts = false;
        this.sorted_charts = [];
        this.dom_content = false;
        this.default_color = '#FF9900';
        this.scrolled_to_current_round = false;
        
        //Green
        //#FF9900 - full
        //#DFFDEE - soft
        
        //Green-Blue
        //#89EBE7 - full
        //#DDF8F7 - soft
        
        //Blue
        //#89E0EB full
        //#E0F7FA soft
        
        this.colors = ['#054850', '#fff001'];// #ff9900, '#DFFDEE', '#89EBE7', '#DDF8F7', '#89E0EB', '#E0F7FA'];
        this.color_index = 0;
        this.force_line_color = config.base_color;
        if(config.style == 'green') {
            this.colors = ['#333333', '#499834'];
            this.force_line_color = '#499834';
        }

        eventHandler.observe('block_charts', ['language_change', 'user_login', 'set_data_charts', 'set_data_groups', 'division_history'], () => {
            if (!this.social) {
                this.rewriteChartsData();
                this.render();
            }
        }, this);
        
        eventHandler.observe('block_charts', ['set_data_risk'], (data) => {
            if (!this.social && this.charts.risk) {
                this.charts.risk.percent = data ? data : 0;
                this.updateRiskChart();
            }
        }, this);        
    }
    
    setSocial(source) {
        this.source = source;
        this.social = true;        
        return this;
    }

    setDom(dom) {
        this.dom = dom;

        var html = `<div class='scroll'></div>`;
        this.dom.html(html);

        this.dom_content = this.dom;
        
        return this;
    }
    
    rewriteChartsData() {
        this.charts = { };
        var overall = { };
        var id_prefix = this.social ? 'social_' : '';
        var scores = [];

        if (app.isAuth() && this.source) {     
            if (this.source.user.groups_history && this.source.user.groups_history.length > 1) {
                for (var group of this.source.user.groups_history) {                    
                    // Overall
                    if (group.id_group == 0) {
                        overall[group.round] = group.position;
                        scores[group.id_group] = 0;
                    } else {
                        if (!scores.hasOwnProperty(group.id_group)) {
                            var last_round = app.data.groups_history.find(x => x.id_group == 0 && x.round == group.round - 1);
                            scores[group.id_group] = last_round ? last_round.score : 0;
                        }
                    }
                }
                
                for (var group of this.source.user.groups_history) {
                    var name = group.id_group != 0 ? group.name : app.lang.common.overall2;
                                        
                    if (name) {
                        if (!this.charts.hasOwnProperty('group'+group.id_group)) {
                            this.charts['group'+group.id_group] = { 
                                id: id_prefix+'chart_group'+group.id_group,
                                id_group: group.id_group,
                                type: 'line',
                                title: name,
                                color: this.getNextColor(),
                                color2: this.getNextColor(),
                                data: [],
                                fillround: 1,
                                max_y: group.id_group == 0 ? app.config.users_count : 0,
                                info_key: group.id_group == 0 ? 'overall' : 'group',
                                link: {name: 'group', params: {id: group.id_group == 0 ? 'overall_ranking' : group.id_group}}
                            };
                        }   

                        if (this.charts['group'+group.id_group].fillround < group.round) {
                            for (var k = this.charts['group'+group.id_group].fillround; k < group.round; k++) {
                                this.charts['group'+group.id_group].data.push({
                                    x: k - 1,
                                    y: null,
                                    label: '-',
                                    footer: {
                                        text1: k,
                                        arrow: '',
                                        text2: app.lang.common.matchday
                                    },
                                    footer2: {
                                        text1: app.lang.common.overall, 
                                        val1: '-',
                                        text2: app.lang.common.points, // app.lang.common.matchday, 
                                        val2: scores[group.id_group] ? scores[group.id_group] : '-', //app.getCurrentMatchday()+".", 
                                        text3: app.lang.common.wins, 
                                        val3: this.source.user.groups_wins[group.id_group] || 0, 
                                    }
                                });
                                
                                this.charts['group'+group.id_group].fillround++;
                            }
                        }
                        
                        var pts = group.score - scores[group.id_group];
                        this.charts['group'+group.id_group].data.push({
                            x: group.round,
                            y: group.position,
                            label: group.position + '.',
                            footer: {
                                text1: group.round,
                                arrow: '',
                                text2: app.lang.common.matchday
                            },
                            footer2: {
                                text1: app.lang.common.round_points, 
                                val1: pts ? pts : '-', // overall[group.round] ? overall[group.round]+"." : '-'
                                text2: app.lang.common.all_points, // app.lang.common.matchday, 
                                val2: group.score ? group.score : '-', //app.getCurrentMatchday()+".", 
                                text3: app.lang.common.round_wins, 
                                val3: this.source.user.groups_wins[group.id_group] || 0, 
                            }
                        });
                        
                        scores[group.id_group] = group.score;
                        
                        
                        this.charts['group'+group.id_group].fillround++;
                    }
                }
            }
            
            if (this.source.user.division_history) {
                this.charts.division_history = {         
                    id: id_prefix+'division_history_ch',
                    type: 'line',
                    title: app.lang.common.league_history, 
                    color: this.getNextColor(),
                    color2: this.getNextColor(),
                    data: [],
                    info_key: 'division_history',
                    link: {name: 'group', params: {id: app.user.id_group}}
                }
                        
                var wins = 0;
                var max_season = 0;
                var max_division = 0;
                this.charts.division_history.data = [];

                var division_history_wins = 0;
                for (var i in this.source.user.division_history) {
                    if (this.source.user.division_history[i].position == 1 && (i == 0 || this.source.user.division_history[i-1].season != this.source.user.division_history[i].season) 
                        && i < this.source.user.division_history.length - 1 && this.source.user.division_history[i].season != app.config.current_season) {
                        wins++;
                        division_history_wins++;
                    }
                    
                    if (this.source.user.division_history[i].division > max_division && this.source.user.division_history[i].division != 100) {
                        max_division = this.source.user.division_history[i].division;
                    }
                    
                    if (this.source.user.division_history[i].season > max_season) {
                        max_season = this.source.user.division_history[i].season;
                    }
                    
                    this.source.user.division_history[i].wins = wins;
                }

                var prev = false;
                for (var k = 0; k < max_season; k++) {
                    var data = {
                        season: k + 1,
                        wins: data ? data.wins : 0,
                    };
                    
                    for (var i in this.source.user.division_history) {
                        if (this.source.user.division_history[i].season == k + 1) {
                            data = this.source.user.division_history[i];
                            break;
                        }
                    } 
                    
                    if (prev) {
                        if (prev.division < data.division) {
                            this.charts.division_history.data[this.charts.division_history.data.length - 1].footer.arrow = 'down';
                        }

                        if (prev.division > data.division) {
                            this.charts.division_history.data[this.charts.division_history.data.length - 1].footer.arrow = 'up';
                        }
                    }

                    this.charts.division_history.data.push({
                        //x: k,
                        x: data.division !== undefined ? (data.division == 100 ? max_division+1 : data.division) : 1,
                        y: data.position !== undefined ? data.position : '-',
                        label: (data.position ? data.position+"." : '-'), 
                        footer: {
                            //text1: data.season,
                            text1: data.division !== undefined ? (data.division == 100 ? app.config.max_division + 1 : data.division) : '',
                            arrow: '',
                            //text2: app.lang.common.season
                            text2: data.division !== undefined ? app.lang.common.liga : ''
                        },
                        footer2: {
                            text1: app.lang.common.season,  
                            val1: data.season, 
                            text2: app.lang.common.points, 
                            val2: (data.score ? data.score : '-'), 
                            text3: app.lang.common.wins, 
                            val3: division_history_wins
                        }
                    });
                    
                    prev = data;
                }
            }
        }
        
        this.charts.winning = {
            id: id_prefix+'winning_ch',
            type: 'circle',
            title: app.lang.common.winning_percent_chart_label,
            correct: 0,
            label1: app.lang.common.correct,
            incorrect: 0,
            label2: app.lang.common.incorrect,
            info_key: 'winning'
        };
        
        this.charts.risk = {
            id: id_prefix+'chart_risk',
            type: 'bar',
            title: app.lang.common.risk_chart_label,
            label: app.lang.common.risk,
            correct_turbo: 0,
            incorrect_turbo: 0,
            percent: 0,
            footer: '',
            info_key: 'risk'
        };
        
        this.charts.winning.value1 = app.isAuth() && this.source ? this.source.user.correct : 0;
        this.charts.winning.value2 = app.isAuth() && this.source ? this.source.user.incorrect : 0;
        this.charts.risk.percent = app.isAuth() && this.source ? this.source.user.risk : 0;
        this.charts.risk.correct_turbo = app.isAuth() && this.source ? this.source.user.correct_turbo : 0;
        this.charts.risk.incorrect_turbo = app.isAuth() && this.source ? this.source.user.incorrect_turbo : 0;
        
        this.sorted_charts = [];
        for(var c in this.charts) {
            this.sorted_charts.push(c);
        }
        var charts_priority = ['risk', 'winning', 'group0', 'division_history'];
        this.sorted_charts.sort((a,b) => {
            for(var c of charts_priority) {
                if(a == c) {
                    return -1;
                }
                if(b == c) {
                    return 1;
                }
            }
            
            return a.id_group > b.id_group ? -1 : 1;;
        });

        this.scrolled_to_current_round = false;
                
        return this;
    }

    render() {
        if (!this.dom_content) {
            return this;
        }
        
        if (!this.charts) {
            this.rewriteChartsData();
        }
        
        var html;
        var charts_html = '';
        var charts = [];
        var self = this;
        if (this.social) {
        }
        
        if (app.isAuth()) {
            var charts = [];
            for(var i of this.sorted_charts) {
                switch(this.charts[i].type) {
                    case 'bar':
                        html = this.addChartBar(this.charts[i]);
                        break;
                    case 'line':                        
                        html = this.addChartTable(this.charts[i]);
                        break;
                    case 'circle':
                        html = this.addChartCircle(this.charts[i]);
                        break;
                    }

                if (html) {
                    charts.push(html);
                    charts_html = charts.join('');
                }
            }
        } else {
            for(var chart in this.charts) {
                charts_html += `
                <div class='bl chart_bar lock'>
                    <div class='title'>${this.charts[chart].title}</div>
                    <div class='lock_info'>${app.lang.common.chart_locked}</div>
                    <div class='lock'></div>
                </div>
                `;
            }
        }
        
        var html = `
            <div class='block_charts'>
                ${charts_html}
            </div>
        `;

        $('#'+this.dom_content.attr('id')+'_header').remove();
        this.dom_content.before(`
            <div class='block_charts_header' id='${this.dom_content.attr('id')+'_header'}'>
                ${app.lang.common.charts_header}
            </div>
        `);
        this.dom_content.html(html);

        this.dom_content.find('.lock').click(function() {
            app.renderPage(app.getUserCookie('rt90_register') == '1' ? 'login' : 'register');
        })
        
        if (app.isAuth()) {
            for(var chart in this.charts) {                
                switch(this.charts[chart].type) {
                    case 'bar':
                        this.drawChartBar(this.charts[chart]);
                        break;
                    case 'circle':
                        this.drawChartCircle(this.charts[chart]);
                        break;
                    case 'line':
                        //this.drawChartLine(this.charts[chart]);
                        this.drawChartTable(this.charts[chart], chart);
                        break;
                }
            }
        }
        
        if(!app.mobile) {
            $(this.dom_content).addClass('native-scroll').swipeIndicator({});
        }else {
            $(this.dom_content).addClass('mobile-scroll');
        }
        $(this.dom_content).find('.block_charts').dotsScroll({
            dot: "<div class='dot'></div>",
            active_dot_class: "dot_active",
            inside_element_width: 344,
            show_dots_scroll: true,
            is_native_scroll: true
        });

        this.dom_content.find('.info_icon').click(function() {
            var data = $(this).data();
            var chart_id = $(this).parent().attr('id');
            var chart = Object.values(self.charts).find(x => x.id == chart_id) || {};
            var msg = app.lang.common.chart_info_box[data.info_key];
            msg = msg.replace('{title}', chart.title);
            msg = msg.replace('{key}', data.info_key);
            var dialog = new Dialog();
            dialog.html(msg)
                .btn(app.lang.common.ok, () => {
                    dialog.close();
                }, {black: false, sportde: true})
                .show();
        });

        this.dom_content.find('.title').click(function() {
            var chart_id = $(this).parent().attr('id');
            var chart = Object.values(self.charts).find(x => x.id == chart_id) || {};
            if(chart && chart.link) {
                app.renderPage(chart.link.name, chart.link.params);
            }
        });
        
        this.dom_content.find('.pager > div:first-child').click(function() {
            let s = $(this).parent().find('.sc').scrollLeft() - 200;
            if (s < 0) {
                s = 0;
            }
            
            $(this).parent().find('.sc').animate({ scrollLeft: s }).promise().done(function () {
                self.pagerArrowStatus();
            });
        })
        
        this.dom_content.find('.pager > div:last-child').click(function() {
            let s = $(this).parent().find('.sc').scrollLeft() + 200;
            $(this).parent().find('.sc').animate({ scrollLeft: s }).promise().done(function () {
                self.pagerArrowStatus();
            });
        })
        
        setTimeout(function() { self.pagerArrowStatus(); }, 500);
        
//        $(this.dom_content).find('.scr').each(function() {
//            $(this).dotsScroll({
//                dot: "<div class='dot'></div>",
//                active_dot_class: "dot_active",
//                inside_element_width: 190,
//                count_elements: false,
//                hidden_navigation: true,
//                dest_active: 1,
//                show_dots_scroll: false
//            });
//        });

        this.scrollToCurrentRound();
        return this;
    }
    
    pagerArrowStatus = function() {
        this.dom_content.find('.pager > div:last-child').each(function() {
            let s = $(this).parent().find('.sc').scrollLeft();
            let c = $(this).parent().find('.tab_chart > div').length * 119 - 20;
            //console.log(s, s+462, c);
            
            if (s <= 0) {
                 $(this).parent().find('div:first-child').addClass('off');
            } else {
                $(this).parent().find('div:first-child').removeClass('off');
            }
            
            if (s + $(this).parent().width() >= c) { 
                $(this).addClass('off');
            } else {
                $(this).removeClass('off');
            }
        });
    }

    scrollToCurrentRound() {
        $(this.dom_content).find('.sc').each(function() {
            $(this).hScroll({ move: 150 });
            if ($(this).parent().attr('id') == 'division_history_ch' || $(this).parent().attr('id') == 'social_division_history_ch') {
                $(this).animate({ scrollLeft: (app.current_round / 2 - 4) * 55}, 0);
            } else {
                $(this).animate({ scrollLeft: (app.current_round - 6) * 55 }, 0);
            }
        });
    }
    
    drawChartBar(chart) {
        var lines = [];
        var color;
        var bars = 24;
        var per_bar = 6;
        var value = Math.round(bars * chart.percent / 100);
        var w = 170;
        var h = 18;
        var y1 = 16;
        var sw = 2;
        var stroke = '#b2b7b5';
        var stroke_selected = config.base_color;
        if(config.style == 'green') {
            w = 250;
            h = 22;
            y1 =22;
            per_bar = 10;
            stroke = '#B4B4B4';
            if(window.innerWidth < app.breakPoint2) {
                w = 168;
                h = 15;
                y1 =15;
                per_bar = 6.5;
            }
        }
        
        for (var i = 0; i < bars; i++) {
            color = config.base_color;
            if(config.style == 'green') {
                color = '#DCFA32';
            }
            lines.push(`<line x1="${i * per_bar}" y1="${y1}" x2="${i * per_bar + 13}" y2="0" style="stroke:${i <= value ? color : stroke};stroke-width:${sw}" />`);
        }
        var svg = `<svg width="${w}" height="${h}">`+lines.join('')+`</svg>`;
        $('#'+chart.id+' .svg').html(svg);
    }

    addChartBar(chart) {
        var footer = '';
        /*if (chart.footer) {
            footer = '<div class="footer">'+chart.footer+'</div>';
        }*/

        footer = `<div class="footer">
                <div>
                    <b class='correct'>${chart.correct_turbo}</b> <img src='images/lightstorm3.png' alt='' /> ${app.lang.common.correct}
                </div>
                <div>
                    <b class='incorrect'>${chart.incorrect_turbo}</b> <img src='images/lightstorm3.png' alt='' /> ${app.lang.common.incorrect}
                </div></div>`;
        
        if (!chart.percent) {
            chart.percent = 0;
        }
        
        var html = `
            <div class='bl chart_bar' id='${chart.id}'>
                <div class="info_icon" data-info_key="${chart.info_key}" ><img src="images/${config.style ? config.style+'/' : 'sportde_'}info_icon.svg" /></div>
                <div class='title'>${chart.title}</div>
                <div class='svg'></div>
                <div class='labels'>
                    <div class='left'><img src='images/${config.style ? config.style+'/' : 'sportde-'}thunder.svg' alt='' /></div>
                    <div class='right'><b>${chart.percent}<small>%</small></b><br /><span class='chart-label'>${chart.label}</span></div>
                </div>
                ${footer}
                <div class='home-footer'><a href="#profile">${app.lang.common.chart_view}</a></div>
            </div>
        `;

        return html;
    }
    
    updateRiskChart() {
        $('#'+this.charts.risk.id+' .labels b').html(this.charts.risk.percent + '<small>%</small>');
        $('#'+this.charts.risk.id+' .correct').html(this.charts.risk.correct_turbo);
        $('#'+this.charts.risk.id+' .incorrect').html(this.charts.risk.incorrect_turbo);
        this.drawChartBar(this.charts.risk);
    }
    
    updateWinningChart() {
        var chart = this.charts.winning;
        var percent = chart.value1 + chart.value2 > 0 ? Math.round(chart.value1 / (chart.value1 + chart.value2) * 100) : '-';
        $('#'+chart.id+' .left b').html(chart.value1);
        $('#'+chart.id+' .center b').html(percent + '<small>%</small>');
        $('#'+chart.id+' .right b').html(chart.value2);
        this.drawChartCircle(chart);
    }
    
    updateDivisionHistoryChart() {
        if (this.charts.hasOwnProperty('division_history')) {
            var html = this.addChartLine(this.charts.division_history);
            $('#'+this.charts.division_history.id).replaceWith(html);
            this.drawChartLine(this.charts.division_history);
        }
    }

    drawChartCircle(chart) {
        var home_chart = $('#'+chart.id+' .svg-home')[0];
        var stroke = '#b4b4b4';
        var stroke_selected = config.base_color;
        var size = 161;
        var r = 78;
        if(window.innerWidth < app.breakPoint) {
            size = 140;
            r = 56;
        }

        if(config.style == 'green') {
            stroke = 'rgba(0,0,0,0.18)';
            stroke_selected = '#000';
            
        }
        if (home_chart) {
            var svg = `
                    <svg width="70" height="119">
                        <line x1="47" y1="28" x2="23" y2="85" stroke="${stroke_selected}" stroke-width="2"></line>
                    </svg>`;

            $('#'+chart.id+' .svg-home').html(svg);
        }

        var d = helpers.describeArc(size/2, size/2, r, 0, ( chart.value1 + chart.value2 > 0 ? (chart.value1 / (chart.value1 + chart.value2)) : 0 ) * 360 - 1);
        var svg = `
            <svg width="${size}" height="${size}">
                <circle cx="${size/2}" cy="${size/2}" r="${r}" stroke="${stroke}" stroke-width="2" fill='white' />
                <path id="arc1" fill="none" stroke="${stroke_selected}" stroke-width="2" d="${d}" />
            </svg>`;

        $('#'+chart.id+' .svg').html(svg);
    }

    addChartCircle(chart) {
        var percent = chart.value1 + chart.value2 > 0 ? Math.round(chart.value1 / (chart.value1 + chart.value2) * 100) : '-';
        
        var html = `
            <div class='bl chart_circle' id='${chart.id}'>
                <div class="info_icon" data-info_key="${chart.info_key}" ><img src="images/${config.style ? config.style+'/' : 'sportde_'}info_icon.svg" /></div>
                <div class='title'>${chart.title}</div>
                <div class='svg-home'></div>
                <div class='svg'></div>
                <div>
                    <div class='left'><b>${chart.value1}</b><br /><span class='chart-label'>${chart.label1}</span></div>
                    <div class='center'><b>${percent}<small>%</small></b></div>
                    <div class='right'><b>${chart.value2}</b><br /><span class='chart-label'>${chart.label2}</span></div>
                </div>
                <div class='home-footer'><a href="#profile">${app.lang.common.chart_view}</a></div>
            </div>
        `;

        return html;
    }
    
    addChartTable(chart) {
        if (!chart.hasOwnProperty('data') || chart.data.length < 2) {
            return false;
            
            return `<div class='bl chart_bar'>
                    <div class='title'>${chart.title}</div>
                    <div class='lock_info'>${app.lang.common.chart_nodata}</div>
                </div>`
        }
        
        var link = '';
        if (chart.footer_link) {
            link = `<a href='${chart.footer_link.url}'>${chart.footer_link.anchor}</a>`;
        }

        var height, footer, footer2;

        if (chart && chart.data && chart.data[0] && chart.data[0].footer) {
            footer = ''; //<div class="footer"></div>';

            if (chart.data[0].footer2) {
                height = 106;
                footer2 = '<div class="footer2"><div><div></div><div></div></div><div><div></div><div></div></div><div><div></div><div></div></div></div>';                
            } else {
                height = 112;
            }
            
        } else {
            height = 152;
            footer = '';
        }
        
        var width = chart.data.length * 54.5;

        chart.canvas_height = height;
        chart.canvas_width = width;
        
        if(config.style == 'green' && app.mobile) {
            chart.canvas_height = 50;
        }
        
        var html = `
            <div class='bl chart_line' id='${chart.id}'>
            <div class="info_icon" data-info_key="${chart.info_key}" ><img src="images/${config.style ? config.style+'/' : 'sportde_'}info_icon.svg" /></div>
                <div class='title'>${chart.title}</div>
                <div class='pager'>
                    <div></div>
                    <div class='sc'>
                        <div class='scr'>
                            <div class='svg'></div>
                            ${footer}
                            <div class='tooltip'><span>...</span><span><span></span></span></div>                
                        </div>
                    </div>
                    <div></div>
                </div>
                                
                ${footer2}
                ${link}
                <div class='home-footer'><a href="#profile">${app.lang.common.chart_view}</a></div>
                
            </div>
        `;

        return html;
    }

    drawChartTable(chart, chart_name = '') {
//        if (chart.id == "division_history_ch") {
//            console.log(chart.title, chart);
//        }
        
        //this.drawChartLine(chart);
        //console.log('chart '+chart_name, chart);
        
        let html = `<div class='tab_chart'>`, class1 = '', class2 = '';
        let prev = 0, prev_liga = -1;
        for (let round of chart.data ) {
            if (round.y !== null) {
                let current = chart_name == 'division_history'
                        ? (app.config.current_season == round.x ? 'current' : '')
                        : (app.current_round == round.x ? 'current' : '');
                let arrow = '', arrow_top = '';

                if (prev && prev < round.y) {
                    arrow = `<div class='down'></div>`;
                }
                if (prev && prev > round.y) {
                    arrow = `<div class='up'></div>`;
                }
                
                if (chart.id == 'division_history_ch') {
                    if (round.x === null) {
                        round.x = 0;
                    }
                    
                    if (prev_liga != -1 && prev_liga < round.x) {
                        arrow_top = `<div class='down'></div>`;
                    }
                    if (prev_liga != -1 && prev_liga > round.x) {
                        arrow_top = `<div class='up'></div>`;
                    }
                }
                
                class1 = arrow_top != '' ? 'class="pl"' : '';
                class2 = arrow != '' ? 'class="pl"' : '';
                
                var footer2 = `
                        <div>
                            <div><div class='f2-text'>${round.footer2.text1}</div></div>
                            <div><div class='f2-value'>${round.footer2.val1}</div></div>
                        </div>
                        <div>
                            <div><div class='f2-text'>${round.footer2.text2}</div></div>
                            <div><div class='f2-value'>${round.footer2.val2}</div></div>
                        </div>
                        <div>
                            <div><div class='f2-text'>${round.footer2.text3}</div></div>
                            <div><div class='f2-value'>${round.footer2.val3}</div></div>
                        </div>
                    `;

                html += `
                <div class='${current}'>
                    <div class='to_footer'>
                        ${footer2}
                    </div>
                
                    <div ${class1}>${arrow_top} <b>${round.x}.</b> <br /> <span>${app.lang.common[chart_name == 'division_history' ? 'division' : 'matchday2']}</span></div>
                    <div ${class2}>${arrow} <b>${round.y}.</b><br /> <span>${app.lang.common.position}</span></div>
                </div>`;

                prev = round.y;
                prev_liga = round.x;
            }
        }
        
        html += '</div>';
        
        let onEvent = function() {
            $('#'+chart.id+' .svg .tab_chart > div').removeClass('active');
            $(this).addClass('active');
            let html = $(this).find('.to_footer').html();
            $(this).parents('.chart_line').find('.footer2').html(html);
        };
        
        $('#'+chart.id+' .svg').html(html);
        setTimeout(function() {
            $('#'+chart.id+' .svg').parents('.sc').scrollLeft(10000);
            if (chart.id == 'division_history_ch') {
                $('#'+chart.id+' .svg .tab_chart > div:last-child').trigger('click');
            } else {
                $('#'+chart.id+' .svg .tab_chart > div.current').trigger('click');
            }
        }, 240);
        
        $('#'+chart.id+' .svg .tab_chart > div').on('click', onEvent);
    }
    
    addChartLine(chart) {
        if (!chart.hasOwnProperty('data') || chart.data.length < 2) {
            return false;
            
            return `<div class='bl chart_bar'>
                    <div class='title'>${chart.title}</div>
                    <div class='lock_info'>${app.lang.common.chart_nodata}</div>
                </div>`
        }
        
        var link = '';
        if (chart.footer_link) {
            link = `<a href='${chart.footer_link.url}'>${chart.footer_link.anchor}</a>`;
        }

        var height, footer, footer2;

        if (chart && chart.data && chart.data[0] && chart.data[0].footer) {
            footer = ''; //<div class="footer"></div>';

            if (chart.data[0].footer2) {
                height = 106;
                footer2 = '<div class="footer2"><div><div></div><div></div></div><div><div></div><div></div></div><div><div></div><div></div></div></div>';                
            } else {
                height = 112;
            }
            
        } else {
            height = 152;
            footer = '';
        }
        
        var width = chart.data.length * 54.5;

        chart.canvas_height = height;
        chart.canvas_width = width;
        
        if(config.style == 'green' && app.mobile) {
            chart.canvas_height = 50;
        }
        
        var html = `
            <div class='bl chart_line' id='${chart.id}'>
            <div class="info_icon" data-info_key="${chart.info_key}" ><img src="images/${config.style ? config.style+'/' : 'sportde_'}info_icon.svg" /></div>
                <div class='title'>${chart.title}</div>
                <div class='sc'>
                    <div class='scr'>
                                
                        <div class='svg'></div>
                        ${footer}
                        <div class='tooltip'><span>...</span><span><span></span></span></div>                
                    </div>
                </div>
                ${footer2}
                ${link}
                <div class='home-footer'><a href="#profile">${app.lang.common.chart_view}</a></div>
                
            </div>
        `;

        return html;
    }

    drawChartLine(chart) {
        
        var path = '<path d="';
        var sdots = '';
        
        if(!chart || !chart.data || !chart.data[0]) {
            return ;
        }
                
        var offset_x = 1;
        var offset_y = 1;

        var start_point = -1;
        var min_x = 0, max_x = 0, min_y = 0, max_y = 0;
        for (var i = 0; i < chart.data.length; i++) {
            if (chart.data[i].y !== null) {
                start_point = i;
                break;
            }
        }
        
        if (chart.max_y) {
            max_y = chart.max_y;
        }
        
        if (start_point != -1) {
            for(var p of chart.data) {
                if (p.x < min_x) {
                    min_x = p.x;
                }

                if (p.x > max_x) {
                    max_x = p.x;
                }

                if (p.y < min_y) {
                    min_y = p.y;
                }

                if (p.y > max_y) {
                    max_y = p.y;
                }
            }

            var scale_x = max_x != min_x ? chart.canvas_width / (max_x - min_x) : 1;
            var scale_y = max_y != min_y ? (chart.canvas_height - 6) / (max_y - min_y) : 1;

            for(var p of chart.data) {
                p.x = (p.x - min_x) * scale_x;
                p.y = p.y !== null ? (p.y - min_y) * scale_y : null;
                p.from_right = chart.canvas_width - p.x;
            }

            path += 'M'+ chart.data[start_point].x + ',' + chart.data[start_point].y;

            for(var i = start_point; i < chart.data.length-1; i ++) {
                if (chart.data[i].y === null) {
                    continue;
                }

                var next = false;
                for(var j = i + 1; j < chart.data.length; j++) {
                    if (chart.data[j].y !== null) {
                        next = j; break;
                    }
                }

                if (next) {
                    var x_mid = Math.round((chart.data[i].x + chart.data[next].x) / 2);
                    path += ' C' + x_mid + ',' + chart.data[i].y + ' ' + x_mid + ',' + chart.data[next].y + ' '  + chart.data[next].x + ',' + chart.data[next].y;
                        
                    //sdots += '<circle cx="'+(chart.data[next].x)+'" cy="'+(chart.data[next].y)+'" r="2" stroke="red" stroke-width="2" fill="red" />';
                    //sdots += '<circle cx="'+x_mid+'" cy="'+y_mid+'" r="2" stroke="blue" stroke-width="2" fill="red" />';
                }
            }

            // Add footer legend
            $('#'+chart.id+' .footer').empty();
            
            for(var i = 0; i < chart.data.length; i ++) {
                if (chart.data[i].footer) {
                    var icon;
                    if (chart.data[i].footer.arrow == 'up') {
                        icon = `<img class="arrow-indicator" src="images/${config.style ? config.style+'/' : ''}up-arrow2.svg" />`;
                    } else if (chart.data[i].footer.arrow == 'down') {
                        icon = `<img class="arrow-indicator" src="images/${config.style ? config.style+'/' : ''}down-arrow2.svg" />`;
                    } else {
                        icon = '';
                    }

                    $('#'+chart.id+' .footer').append(`
                    <div class='point point${i}' data-point='${i}' >
                        <b>${chart.data[i].footer.text1}</b> ${icon}<br />
                        <span class='match-season-number'>${chart.data[i].footer.text2}</span>
                    </div>
                    `);
                }
            }

            path += '" stroke="'+(this.force_line_color || chart.color || this.default_color)+'" fill="transparent" stroke-width="2.0" />';
            var svg = `<svg width="${chart.canvas_width}" height="${chart.canvas_height}">${path}${sdots}</svg>`;
            $('#'+chart.id+' .svg').html(svg);

            // Dots
            /*for(var i = 0; i < chart.data.length; i ++) {
                ctx.fillRect(chart.data[i].x - 2, chart.data[i].y-2, 3, 3);
            }*/

            if (chart.color2) {
                $('#'+chart.id+' .tooltip > span:first-child, #'+chart.id+' .tooltip > span > span').addClass('fs-15').css({'background-color': chart.color2, 'color': chart.color});
            }

            var dist = function(x, y, point) {
                return Math.abs(point.x - x) + Math.abs(point.y - y);
            }

            var renderPoint = function(point) {
                $('#'+chart.id+' .tooltip > span:first-child').html(point.label);
                if (point.from_right < point.x) {
                    $('#'+chart.id+' .tooltip').css({
                        left: 'auto',
                        right: point.from_right + 10,
                        top: point.y + offset_y,
                    }).removeClass('left').fadeIn();
                } else {
                    $('#'+chart.id+' .tooltip').css({
                        right: 'auto',
                        left: point.x + offset_x  ,
                        top: point.y + offset_y,
                    }).addClass('left').fadeIn();
                }

                $('#'+chart.id+' .footer > div').css('background-color', 'white').removeClass('highlight');
                $('#'+chart.id+' .footer > div.point'+point.i).css('background-color', chart.color || '#054850' /*this.default_color*/).addClass('highlight');

                if (point.footer2) {
                    var footer2 = `
                        <div>
                            <div><div class='f2-text'>${point.footer2.text1}</div></div>
                            <div><div class='f2-value'>${point.footer2.val1}</div></div>
                        </div>
                        <div>
                            <div><div class='f2-text'>${point.footer2.text2}</div></div>
                            <div><div class='f2-value'>${point.footer2.val2}</div></div>
                        </div>
                        <div>
                            <div><div class='f2-text'>${point.footer2.text3}</div></div>
                            <div><div class='f2-value'>${point.footer2.val3}</div></div>
                        </div>
                    `;

                    $('#'+chart.id+' .footer2').html(footer2);
                }
                
                if (point.title) {
                    $('#'+chart.id+' .title').html(point.title);
                }
            };

            $('#'+chart.id+' svg').mousemove(function(event) {
                var x = event.pageX - $(this).offset().left + offset_x;
                var y = event.pageY - $(this).offset().top;
                var point;

                for(var i = 0; i < chart.data.length-1; i++) {
                    if (x >= chart.data[i].x && x < chart.data[i+1].x) {
                        if (dist(x, y, chart.data[i]) < dist(x, y, chart.data[i+1])) {
                            point = chart.data[i];
                            point.i = i;
                        } else {
                            point = chart.data[i+1];
                            point.i = i+1;
                        }
                        break;
                    }        
                }

                if (!point) {
                    point = chart.data[i];
                    point.i = i;
                }

                if (point && point.y !== null) {
                    renderPoint(point);  
                }
            });

            $('#'+chart.id+' .footer > div.point').mouseenter(function() {
                var data = $(this).data();
                var i = data.point;
                var point = chart.data[i];
                if (point.y !== null) {
                    point.i = i;
                    renderPoint(point);
                }
            });
            
            $('#'+chart.id+' .footer > div.point').click(function() {
                var data = $(this).data();
                var i = data.point;
                $(this).parent().parent().parent().animate({ scrollLeft: (i - 3) * 60 }, 200);
            });
            
            for(var i = chart.data.length-1; i >= 0; i--) {
                if (chart.data[i].y !== null) {
                    $('#'+chart.id+' .footer > div.point:nth-child('+(i+1)+')').mouseenter();
                    break;
                }
            }
        }
    }
    
    getNextColor() {
        if (this.color_index == this.colors.length) {
            this.color_index = 0;
        }
        
        return this.colors[this.color_index++];
    }
}
