class BlockSetResetPassword {
    constructor(dom) {
        this.dom = dom;
        this.isSubmited = false;

        eventHandler.observe('block_set_reset_password', ['language_change', 'user_set_reset_password'], () => {
            if(!app.isAuth()) {
                this.render();
            }
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    render(params) {
		if (!this.dom || !params) return;
        
        var id = params.get('i');
        var hash = params.get('h');
		
        if(!id || !hash) {
            var html = `
                <div class="block-form">
                    <h1 class="page-title">${app.lang.common.page_not_found}</h1>
                </div>
            `;
            this.dom.html(html);
            return this;
        }

        var title_html = '';
        if(app.lang.user.hello) {
            title_html = `<h1 class="form-title">${app.lang.user.hello}</h1>`;
        }

        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.user.set_reset_password}</h1>
                <div class='block_set_reset_password bl'>
                    <form class="form-set_reset_password form">
                        ${title_html}
                        
                        <div class="set_new_password_content">
                            <br/><br/>
                            <div class="message please_wait">
                                ${app.lang.common.please_wait}
                            </div>
                            <br/><br/><br/>
                        </div>

                        <div class="footer-line">
                            <span>${app.lang.user.you_have_account}</span>
                            <span class="fl-r btn-login c-black">${app.lang.user.login}</span>
                            <div class="clear"></div>
                        </div>
                    </form>
                </div>
            </div>
        `;

        this.dom.html(html)

        this.$form = $(this.dom).find('form');
        this.$form.submit(e => {
            this.onsubmit(id, hash);
            return false;
        });
        
        this.$form.find('.btn-login').click(e => {
            app.renderPage('login');
        });

        this.checkUrlHash(id, hash);

        return this;
    }

    async checkUrlHash(id, hash) {
        this.isSubmited = true;
        try {
            var result = await ws.request('user/checkResetPassword', {id, hash});
            if(result.success) {
                this.renderForm();
                this.isSubmited = false;
            }else {
                this.$form.find('.set_new_password_content .message').addClass('error').html(app.lang.user[result.message] || result.message);
            }
        }catch(err) {
            console.log(err);
            this.$form.find('.set_new_password_content .message').addClass('error').html(app.lang.common.unknown_error);
        }
    }

    renderForm() {
        var html = `
            <div class="form-info">${app.lang.user.set_reset_password_info}</div>

            <div class="form-group password-group">
                <input type="password" name="password" value="" placeholder="${app.lang.user.password}" />
            </div>

            <div class="form-group">
                <button type="submit" class="tip-button" >${app.lang.user.set_reset_password}</button>
            </div>
        `;

        this.$form.find('.set_new_password_content').html(html);

        this.form = new Form(this.$form, {
            password: {required: true, valid: {min_length: 4}}
        });
    }

    async onsubmit(id, hash) {
        if(this.isSubmited) {
            return ;
        }
        this.isSubmited = true;

        try {
            if(!this.form.isValid()) {
                this.isSubmited = false;
                return ;
            }
            var data = this.form.getData();
            data.id = id;
            data.hash = hash;
            var result = await ws.request('user/setNewResetPassword', data);
            
            if(result.success) {
                app.renderPage('login');
            }else {
                this.form.setServerInvalids(result.messages, data);
            }
        }catch(err) {
            console.log(err);
        }

        this.isSubmited = false;
    }

}