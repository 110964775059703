
var Langs = Langs || {};
Langs.de = Langs.de || {};

Langs.de.frontpage = {
	login: 'Einloggen',
    register: 'Registrieren',
    register_now: 'Jetzt anmelden',
    header: 'Tippe die spiele der bundesliga, beweise dein fussballwissen<br />und spiel dich in die tipster-bundesliga!',
    header2: '<h1>Dein kostenloses Tippspiel zur Bundesliga und WM.</h1>Hier wird nicht mehr nach Ergebnis getippt. Unser Tippsystem macht das Tippen wieder fair und spannend. 2:1 tippen war gestern, sorry ;-)',
    info1: '<h2>Jeden Spieltag attraktive Preise abstauben</h2>Mit jedem richtigen Tipp kannst du dein Punktekonto aufpolieren und die Tabellenspitze erklimmen.<br />Auf die besten Tipper warten am Gipfel attraktive Preise - also worauf wartest du noch.',
    info1_button: 'Kostenlos mitspielen',
    info2 :'<h2>Innovative & schnelle Tippeingabe</h2> Ob zu Hause oder unterwegs - das Erfassen der Tipps ist bei tipptastic so einfach und schnell wie  Alphonso Davies über außen kommt.',
    info3: '<h2>Auf- und Absteiger wie in den echten Ligen </h2> Unser einzigartiges Ligensystem lässt keine Langeweile aufkommen. Nimm die Herausforderung an und sammel Punkte für die Meisterschaft oder gegen den Abstieg. ',
    info4: '<h2>Tippe die besten Ligen Europas, Euro- und Weltmeisterschaft</h2>Bei uns kannst du die besten Ligen und Wettbewerbe Europas gleichzeitig tippen. Ob Deutschland, England  oder Spanien hier findest du sie alle. ',
    info5: '<h2>Allein  gegen die Community oder private Tipprunde mit Freunden</h2>Tritt auf eigene Faust gegen unsere großartige Community an oder eröffne kostenlos und schnell eine private Tipprunde mit deinen Freunden, Arbeitskollegen, Mitspielern, ...',
    info5_button: 'Tipprunde eröffnen',
    mobile_info_text: 'Einfach. Anders. Tippen.',
    create_account: 'Jetzt kostenlos Spielen',
    create_group: 'Jetzt Gruppe gründen',
    app_store: 'App Store',
    play_store: 'Play Store',
    impressum: 'Impressum',
    privacy: 'Daten',
    privacy_center: 'Privacy center',
    group_header: 'FAST GESCHAFFT!',
    group_message: 'Lorem ipsum <b>GROUP NAME</b> lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum ',
    copyrights: '© 2020 World tipster League',
    section1: '<h2>Risiko wird belohnt</h2>Mann oder Memme? Gehst du Risiko und liegst damit richtig, verdienst du mehr Punkte. Ist doch nur fair, oder?',
    section2: '<h2>Schaff den sprung nach ganz oben</h2>Keine Zeit zum Verschnaufen, denn nach jedem 2. Spieltag kannst du auf- oder absteigen.<br /><br />Hast du das Zeug für die Tipster-Bundesliga?',
    section3: '<h2>Spiele mit freunden oder mit der ganzen welt</h2>Spiele in eigenen Gruppen mit deinen Freunden oder miss dich im Global Ranking mit der ganzen Welt.',
    section4: '<h2>Tippen & Gewinnen</h2>',
    section5: '<h2>So einfach geht es</h2>Tippe das Ergebnis jedes Spiels. Je mehr Risiko du eingehst, desto mehr Punkte erhältst du. Glaubst du, eine Mannschaft gewinnte mit mehr als 2 Toren? Dann wähle den Turbo und erhalte zusätzliche Punkte.',
    step1: 'Registriere Dich kostenlos',
    step2: 'Tipp die Spiele der EM',
    step3: 'Spiel gegen ganz Deutschland oder deine Freunde',
    presented_by: 'präsentiert von',
    bet_now: 'Jetzt Tippen',
    how_it_works: {
        title: 'So gehts',
        header_info: 'Du hast keine Lust mehr ständig Ergebnisse einzutragen? Keine Lust auf die 2:1 Tipper die immer die gleichen Punkte kassieren? Du willst dich mit den besten in einer Liga messen? Dann bist du hier genau richtig. Sieh dir an, wie wir es machen:',
        section_1_title: 'So wird getippt',
        section_2_title: 'Unser Ligensystem',
        section_3_title: 'Allein oder Gruppe',
        section_4_title: 'Belohnungen',
        section_1_text: 'Bei uns tippst Du auf einen Sieg oder ein Unentschieden, egal wie das Ergebnis aussieht. Je nachdem, wie viel Risiko Du bereit bist, einzugehen, erhältst Du mehr oder weniger Punkte. <br /><br /> Hast Du den Mut, einen Außenseitersieg vorherzusagen? Mit unserem TURBO tippst Du auf einen Sieg mit mindestens 2 Toren Vorsprung. <br /><br /> Mehr Risiko - mehr Punkte.',
        section_2_text: 'Bei uns ist eine ganze Saison in kleine Einheiten von jeweils 2 Runden unterteilt. Die Punkte aus beiden Runden werden addiert.<br /><br />Platz 1 - 2 führen zum Aufstieg.<br />Platz 15 - 18 spielen gegen den Abstieg.',
        section_3_text: 'Bei uns kannst du sowohl als Einzeltipper gegen die gesamte Community oder in einer Tippgruppe gegen deine Freunde antreten. Erstelle private oder öffentliche Tippgruppen und lade alle dazu ein.',
        section_4_text: 'Bei uns kannst du jeden Spieltag attraktive Preise gewinnen. Ob Aufsteiger, Tagessieger oder Challenge Gewinner. Auf dich warten jede Menge Trophäen.'

    }
};
