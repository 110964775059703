class BlockAd1 {
    constructor(dom) {
        this.dom = dom;
        
        eventHandler.observe('block_header', ['language_change', 'user_login', 'refresh_app_config'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    render() {
        if (!this.dom) return;
        //var time = Math.round(new Date().getTime() / 1000);
        var html = `
            <div class='block_ad1'>
                
            </div>
        `;

        this.dom.html(html);
        if(!config.disable_finance) {
            this.dom.find('.block_ad1').html(app.config.ad_footer);
            
            $('body').addClass('is-aa-footer');
        }
        
        return this;
    }
}