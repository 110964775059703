class BlockGroupSearchOld {
    constructor(dom) {
        this.dom = dom;
        this.not_found_group = false;
        this.last_search_text = null;
        this.last_page = 0;
        this.can_get_next_page = true;
        this.groups = [];
        this.exists_ids = [];
        this.scrollStepMultiply = 2;

        eventHandler.observe('block_group', ['language_change', 'user_login', 'set_data_groups'], () => {
            this.baseRender();
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        this.baseRender();
        
        return this;
    }

    baseRender() {
        if(!this.dom) {
            return this;
        }
        var t = this;
        
        var html = `            
            <div class='block_group_search pw'>
                <div>
                    <span class='title  ffant'>${app.lang.common.group_manager}</span>
                    <input type='text' name='group_search' class='group-search-box' placeholder='${app.lang.common.group_search}' />
                </div>
                <div class='header'>${app.lang.common.open_groups}</div>
            </div>
            <div class='block_group_results'>
                <div class='results'>...</div>
            </div>
            <div class='group-search-create-group'>
                <a href='#create_group' class='nodec'><button class='btn-sportde'>${app.lang.common.create_group}</button></a>
            </div>
        `;
                
        this.dom.html(html); 
        var isProcessSearch = false;
        this.dom.find('input').keyup(function() {
            /*
            var text = $(this).val().toLowerCase();            
            t.dom.find('.results .group').each(function() {
                if ($(this).find('b').html().toLowerCase().includes(text)) {
                    $(this).show();
                } else {
                    $(this).hide();                    
                }
            })
            */
            if(isProcessSearch) {
                return ;
            }

            isProcessSearch = true;
            setTimeout(() => {                
                t.last_search_text = $(this).val();
                t.last_page = 0;
                t.can_get_next_page = false;
                var results = t.search(t.last_search_text);
                t.dom_content.empty();
                if(t.not_found_group) {
                     $(t.dom).find('.block_group_results').removeClass('native-scroll').children().css('transform', '');
                } else {
                    if(!app.mobile) {
                        $(t.dom).find('.block_group_results').addClass('native-scroll');
                    }else {
                        $(t.dom).find('.block_group_results').addClass('mobile-scroll');                        
                    }
                    t.can_get_next_page = true;
                }
                t.dom_content.html(results);

                if(config.app_type != 'mobile') {
                    $(window).resize();
                }
                isProcessSearch = false;
            }, 500);
        });

        this.dom_content = this.dom.find('.results');
        this.dom_content.on('click', '.settings', function() {
            var groupid = $(this).data().groupid;
            app.renderPage('group_edit', {id: groupid});
            return false;
        });
        this.dom_content.on('click', '.group[urlcode]', function() {
            var groupId = $(this).data('groupid');
            if(t.exists_ids.indexOf(groupId) != -1) {
                app.renderPage('group', {id: groupId});
            }else {
                var to_add = app.data.groups_to_add.find(x => x.id == groupId);
                
                if(to_add && helpers.isSponsoredChallengeGroup(to_add)) {
                    app.blocks.groups.sponsoredChallenge(to_add);
                }else if(to_add && to_add.options.type == 1) {
                    app.blocks.groups.promoBox(to_add);
                }else if(to_add) {
                    app.renderPageNoHistory('join_to_group', {c: $(this).attr('urlcode')});
                }
            }
            return false;
        });
        this.dom_content.on('click', '.btn-close', function() {
            var data = $(this).data();
            ws.request('group/hide', data);
            $(this).closest('.group').remove();
            return false;
        });
    }

    render() {
        if (!this.dom_content) {
            return this;
        }

        this.last_search_text = null;
        this.last_page = 0;
        var results = this.search();
        this.dom_content.html(results);
        this.scrollToFirst();
        this.scrollEvent();
        
        /*
        this.dom_content.dotsScroll({
            dot: "<div class='dot'></div>",
            active_dot_class: "dot_active",
            inside_element_width: 245,
        });*/
        if (this.not_found_group) {
            $(this.dom).find('.block_group_results').removeClass('native-scroll').children().css('transform', '');
        } else {
            if(!app.mobile) {
                $(this.dom).find('.block_group_results').addClass('native-scroll').swipeIndicator({stepMultiply: this.scrollStepMultiply});
            }else {
                $(this.dom).find('.block_group_results').addClass('mobile-scroll');                
            }
        }
        
        return this;
    }

    scrollEvent() {
        this.dom_content.scroll(e => {
            var c = this.dom_content[0];
            var sh = c.scrollHeight;
            var st = c.scrollTop;
            var h = this.dom_content.innerHeight();
            var d = 100;
            

            if (st < 0) {
                st = 0;
            }
            if(this.can_get_next_page && st + h + d > sh) {
                this.can_get_next_page = false;
                this.last_page++;
                var results = this.search(this.last_search_text, this.last_page);
                if(!this.not_found_group) {
                    this.dom_content.append(results);
                    this.can_get_next_page = true;
                }
            }
        });
    }

    renderAd(ad) {
        if(config.disable_finance) {
            return '';
        }
        ad.options.home_close = 0;
        if(ad.options.manager_btn_bg_position) {
            ad.options.home_btn_bg_position = ad.options.manager_btn_bg_position;
        }
        return app.blocks.groups.renderAd(ad);
    }

    loadGroups() {
        this.exists_ids = app.data.groups.map(x => x.id);
        this.groups = app.data.groups_to_add.concat(app.data.groups.filter(x => x.id != 'overall_ranking' && x.division == 0));
        this.groups = helpers.uniqBy(this.groups, x => x.id);
        this.groups.sort((a,b) => {
            if((!!a.image_thumb) != (!!b.image_thumb)) {
                return !a.image_thumb ? 1 : -1;
            }
            if(a.users_count == b.users_count) {
                return a.id < b.id ? 1 : -1;
            }
            return a.users_count < b.users_count ? 1 : -1;
        });
    }

    search(text, page = 0) {
        var html = '';
        var limit = 30;
        var i = 0;
        var j = 0;
        this.not_found_group = false;
        var skip = page * limit;

        if(text) {
            text = text.toLowerCase().trim();
            this.dom.find('.header').html(app.lang.common.searched_groups);
        }else{
            this.dom.find('.header').html(app.lang.common.open_groups);
            if(page == 0) {
                this.loadGroups();
            }
        }

        for(var group of this.groups) {
            if (!group.ad && (!text || group.name.toLowerCase().indexOf(text) != -1)) {
                if(group.private == 1 && !text) {
                    continue;
                }
                if(typeof group.options != 'object') {
                    try {
                        group.options = JSON.parse(group.options);
                    }catch(err) {
                        group.options = {};
                    }
                }

                if(group.options && group.options.type && group.options.type == 1) {
                    continue;
                }

                if(j < skip) {
                    j++;
                    continue;
                }

                if(helpers.isAdGroup(group)) {
                    html += this.renderAd(group);
                    i++;
                    continue;
                }

                var lockHtml = '';
                var ad = ''; var bg = ''; var locked = ''; var ad_invisible = '';
                if(group.private == 1) {
                    lockHtml = `<div class='lock'></div>`;
                }
                if(group.private == 1 && !text) {
                    locked = 'group-locked';
                }
                if(app.user && group.id_owner == app.user.id) {
                    lockHtml = `<div data-groupid='${group.id}' class='settings'></div>`;
                }
                if(group.image_thumb) {
                    ad = 'ad';
                    let style = `
                        background-image: url(groups/${group.id}_thumb_${group.image_thumb}); 
                        background-repeat: no-repeat;
                        background-position: center; 
                    `;
                    if(group.options.home_btn_bg_color) {
                        style += `background-color: ${group.options.home_btn_bg_color}; `;
                    }
                    if(group.options.home_btn_bg_size) {
                        style += `background-size: ${group.options.home_btn_bg_size}%; `;
                    }
                    bg = `style="${style}"`;
                    ad_invisible = 'd-none';
                }
                if(!group.users_count) {
                    group.users_count = 0;
                }
                
                var group_image = '';
                if (!group.image_thumb && group.options && group.options.image) {
                    group_image = group.options.image ? config.web_front_url+`/usergroup/${group.options.image}` : `images/${config.style ? config.style + '/' : 'sportde-'}default-avatar.svg`;
                    group_image = `<img src="${group_image}" alt=""><br />`;
                }
                var join_link = `
                    <span class='${ad_invisible}'><a class="no-history" href="#join_to_group?c=${group.url_code}">${app.lang.common.join_group}</a></span>
                `;
                var exists = '';
                var exists_dot = '';

                if(this.exists_ids.indexOf(group.id) != -1) {
                    join_link = '';
                    exists = 'is-in-group';
                    exists_dot = '<span class="is-in-group-dot"></span>';
                }
                
                html += `
                    <div class='bl group ${ad} ${locked} ${exists}' ${bg} data-groupid='${group.id}' groupid='${group.id}' urlcode='${group.url_code}' >
                        ${lockHtml} ${exists_dot}
                        <div>
                            <div class='center group_image'>
                                ${group_image}
                                <b>${group.name}</b>
                                <div class='users ${ad_invisible}'>
                                    ${app.lang.common.users} - ${group.users_count}
                                </div>
                            </div>
                        </div>
                        ${join_link}
                    </div>
                `;
                i++;
            }
            if(i >= limit) {
                break;
            }
        }

        if(app.isAuth() && !html.length) {
            html += `<h1 class='no-groups-found'>${app.lang.common.no_groups_found}</h1>`;
            this.not_found_group = true;
        }

        return html;
    }

    scrollToFirst() {
    }
}