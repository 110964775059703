/*
    TBD : Time To Be Defined
    NS : Not Started
    1H : First Half, Kick Off
    HT : Halftime
    2H : Second Half, 2nd Half Started
    ET : Extra Time
    P : Penalty In Progress
    FT : Match Finished
    AET : Match Finished After Extra Time
    PEN : Match Finished After Penalty
    BT : Break Time (in Extra Time)
    SUSP : Match Suspended
    INT : Match Interrupted
    PST : Match Postponed
    CANC : Match Cancelled
    ABD : Match Abandoned
    AWD : Technical Loss
    WO : WalkOver
 */

class Helpers {
    isLive(game) {
        //var in_active = ['TBD', 'NS', 'FT', 'AET', 'PEN', 'SUSP', 'INT', 'PST', 'CANC', 'ABD', 'AWD', 'WO'];
        var live = ['1H', 'HT', '2H', 'P', 'BT', 'INT', 'ET'];
        return game.id && live.includes(game.status_short);
    }
    
    isCompleted(game) {
        return ['FT', 'AET', 'PEN', 'SUSP', 'PST', 'CANC', 'ABD', 'AWD'].includes(game.status_short);
    }
    
    isOvertime(game) {
        return ['ET','P','AET','PEN','BT'].includes(game.status_short);
    }
    
    getMatchTimer(game) {
        return game.elapsed;
        
        /*var sh = new Date(game.half);
        if (sh < Date.now()) {
            return Math.ceil((Date.now() - sh) / 1000 / 60) + 45;
        }
        
        var gt = new Date(game.started);
        var timer = Math.ceil((Date.now() - gt) / 1000 / 60);
        if (timer > 45) {
            return 'HT';
        }
        
        return timer;*/
    }
    
    fixMinutes(min) {
        if (min < 10) {
            return '0' + min;
        }

        return min;
    }

    getLeftTimeStr(time) {
        var left = Math.floor((time - Date.now()) / 1000);
        if(left < 0) {
            return '';
        }
        if(left > 86400) {
            var days = Math.round(left / 86400);
            return days + ' ' + app.lang.common[days > 1 ? 'daysStr' : 'day'];
        }else if(left > 3600) {
            var hours = Math.round(left / 3600);
            return hours + ' ' + app.lang.common[hours > 1 ? 'hours' : 'hour'];
        }else {
            var minutes = Math.round(left / 60);
            return minutes + app.lang.common.min;
        }
    }

    isSponsoredChallengeGroup(group) {
        return group.type == 3;
    }
    getSponsoredChallengeItem(group, user_position) {
        if(!this.isSponsoredChallengeGroup(group) && !this.isOverallRankingSponsoredChallengeGroup(group)) {
            return false;
        }
        var data = group.options;
        if(!data){
            return false;
        }
        for(var i in data.sponsored_challenge) {
            var item = data.sponsored_challenge[i];
            if(!item.position) {
                continue;
            }
            var tab = item.position.split('-');
            if(!tab[1]) {
                tab[1] = tab[0];
            }
            if(parseInt(user_position) >= parseInt(tab[0]) && parseInt(user_position) <= parseInt(tab[1])) {
                item.img = data.files['sponsored_challenge_img_'+i];
                item.logo = data.files['sponsored_challenge_logo_'+i];
                return item;
            }
        }
        
        return false;
    }
    
    isOverallRankingSponsoredChallengeGroup(group) {
        if(typeof group.options != 'object') {
            try {
                group.options = JSON.parse(group.options);
            }catch(err) {
                group.options = {};
            }
        } else if (!group.options) {
            group.options = {};
        }
        return group.id == 'overall_ranking' && group.options.sponsored_challenge && group.options.sponsored_challenge.length > 0 && group.options.sponsored_challenge[0].position;
    }
    
    isAdGroup(group) {
        return group.type == 3 || group.type == 1 || !!group.image_thumb; //
    }

    getAdGroups() {
        var exists_ids = app.data.groups.flatMap(x => x.id);
        return app.data.groups_to_add.filter(x => (x.type == 1 || !!x.image_thumb) && exists_ids.indexOf(x.id) == -1 && app.data.group_hiddens.indexOf(x.id) == -1);
    }
    
    getGroupName(group) {
        if (group) {
            if (group.division == 100) {
                var max_division = parseInt(app.data.max_division);
                return app.lang.common.division100.replace('{division}', (max_division+1));
            }

            if (group.division == 1 ) {
                return app.lang.common.division1.replace('{league_name}', app.lang.common['league_name_'+(group.id_league || app.id_league)]);
            }

            if (group.division !== undefined && group.division > 0) {
                return group.name.replace(', ', `. `+app.lang.common.liga+ ` / `).replace('pyramid', app.lang.common.division_position_lower).replace('Division', '');
                /*var name = group.name.replace('pyramid', app.lang.common.division_position);
                return name.replace('Division', app.lang.common.liga);*/
            }
            
            return group.name;
        } else {
            console.log('ERROR: no group!');
            return '';
        }
    }

    uniqid(length) {
        var dec2hex = [];
        for (var i = 0; i <= 15; i++) {
            dec2hex[i] = i.toString(16);
        }

        var uuid = '';
        for (var i = 1; i <= 36; i++) {
            if (i === 9 || i === 14 || i === 19 || i === 24) {
                uuid += '-';
            } else if (i === 15) {
                uuid += 4;
            } else if (i === 20) {
                uuid += dec2hex[(Math.random() * 4 | 0 + 8)];
            } else {
                uuid += dec2hex[(Math.random() * 16 | 0)];
            }
        }

        if (length) uuid = uuid.substring(0, length);
        return uuid;
    }

    formatPoints(point) {
        if(typeof point == 'string') {
            point = parseInt(point);
        }
        if(point === null) {
            point = 0;
        }
        if(isNaN(point)) {
            point = 0;
        }
        if (point > 999) {
            var rest = (point % 1000);
            if (rest < 10) {
                rest = '00'+rest;
            } else if (rest < 100) {
                rest = '0'+rest;
            }
            
            return Math.floor(point / 1000)+'.'+rest;
        }

        return point;
    }

    formatPreviousPosition(current_position, previous_position) {
        if(!previous_position || previous_position == null || previous_position == '0') {
            return '';
        }
        var html = Math.abs(current_position - previous_position);
        if(html == '0' || html == 0) {
            html = '';
        }
        if(app.debug) {
            html += ' (' + previous_position + ')';
        }
        return html;
    }

    formData(form) {
        var list = $(form).serializeArray();
        var data = {};
        for(var i in list) {
            let row = list[i];
            data[row.name] = row.value;
        }
        return data;
    }

    int2Str(number) {
        var str = '';
		var map = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','0','1','2','3','4','5','6','7','8','9'];
		var n = map.length;
		var r;
		while(number > 0) {
			r = number % n;
			number = Math.floor(number / n);
			str = map[r] + str;
		}
		if(str == '') {
			str = '!';
		}
		return str;
    }

    getBetSign(bet) {
        var label = [
            `1 <img src="images/${config.style ? config.style + '/' : 'sportde-'}thunder-turbo.svg" />`,
            '1',
            'X',
            '2',
            `2 <img src="images/${config.style ? config.style + '/' : 'sportde-'}thunder-turbo.svg" />`
        ];
        return label[bet] ? label[bet] : '-';
    }

    async isFileImage(file) {
        var mime_type = await this.getFileMimeType(file);
        return ['image/png', 'image/gif', 'image/jpeg'].indexOf(mime_type) != -1;
    }
    
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }
    
    describeArc(x, y, radius, startAngle, endAngle){
        var start = helpers.polarToCartesian(x, y, radius, endAngle);
        var end = helpers.polarToCartesian(x, y, radius, startAngle);
        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        var d = [
            "M", start.x, start.y, 
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");

        return d;       
    }

    getFileMimeType(file) {
        return new Promise((resolve, reason) => {

            const getMimetype = (signature) => {
                switch (signature) {
                    case '89504E47':
                        return 'image/png'
                    case '47494638':
                        return 'image/gif'
                    case '25504446':
                        return 'application/pdf'
                    case 'FFD8FFDB':
                    case 'FFD8FFE0':
                    case 'FFD8FFE1':
                        return 'image/jpeg'
                    case '504B0304':
                        return 'application/zip'
                    default:
                        return 'Unknown filetype'
                }
            };
            
            const filereader = new FileReader();

            filereader.onloadend = function(evt) {
                if (evt.target.readyState === FileReader.DONE) {
                    const uint = new Uint8Array(evt.target.result)
                    let bytes = []
                    uint.forEach((byte) => {
                        bytes.push(byte.toString(16))
                    })
                    const hex = bytes.join('').toUpperCase()

                    var mime_type = getMimetype(hex);

                    resolve(mime_type);
                }else {
                    reason(evt);
                }
            };

            const blob = file.slice(0, 4);
            filereader.readAsArrayBuffer(blob);
        });
    }
    copyToClipboard(text, alerEvent) {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(text).select();
        document.execCommand("copy");
        $temp.remove();

        if(alerEvent) {
            var $a = $('<div/>')
                .addClass('link-copied-alert')
                .css({left: alerEvent.pageX + 5, top: alerEvent.pageY - 30})
                .html(app.lang.common.link_copied)
                .appendTo(document.body);
            setTimeout(() => {
                $a.remove();
            }, 1000);
        }
    }
    removeArrayItems(array, predicate) {
        while(true) {
            var index = array.findIndex(predicate);
            if(index == -1) {
                break;
            }
            array.splice(index, 1);
        }
    }
    uniqBy(a, key) {
        let seen = new Set();
        return a.filter(item => {
            let k = key(item);
            return seen.has(k) ? false : seen.add(k);
        });
    }
    
    versionCompare(v1, v2, options) {
        var lexicographical = options && options.lexicographical,
            zeroExtend = options && options.zeroExtend,
            v1parts = v1.split('.'),
            v2parts = v2.split('.');

        function isValidPart(x) {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
        }

        if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
            return NaN;
        }

        if (zeroExtend) {
            while (v1parts.length < v2parts.length) v1parts.push("0");
            while (v2parts.length < v1parts.length) v2parts.push("0");
        }

        if (!lexicographical) {
            v1parts = v1parts.map(Number);
            v2parts = v2parts.map(Number);
        }

        for (var i = 0; i < v1parts.length; ++i) {
            if (v2parts.length == i) {
                return 1;
            }

            if (v1parts[i] == v2parts[i]) {
                continue;
            }
            else if (v1parts[i] > v2parts[i]) {
                return 1;
            }
            else {
                return -1;
            }
        }

        if (v1parts.length != v2parts.length) {
            return -1;
        }

        return 0;
    }
    
    getScrollParent(node) {
        if (node == null) {
            return null;
        }

        if (node.scrollHeight > node.clientHeight) {
            return node;
        } else {
            return this.getScrollParent(node.parentNode);
        }
    }
    
    sleep(ms) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, ms);
        });
    }
}

helpers = new Helpers();
