
class FCM {
    constructor() {        
        this._token = null;
        this._active = false;
        if (config.app_type != 'mobile' && typeof FirebasePlugin == 'undefined') {
            return ;
        }
        this._active = true;

        FirebasePlugin.onTokenRefresh(token => {
            this._token = token;
            this.SaveToken();
        }, error => {
            console.log('Firebase onTokenRefresh Error: ' + error);
        });

        FirebasePlugin.onMessageReceived(this.onNotificationOpen, function (error) {
            console.log('Firebase Error: ' + error);
        });
    }
    SaveToken () {
        if (app.isAuth() && this._token && this._active) {
            
            ws.send('user/saveFcmToken', {token: this._token});
            
            FirebasePlugin.subscribe("notifications", function(){
                
            }, function(error){
                 console.error("Error subscribing to topic: " + error);
            });
        }
    }
    GetToken () {
        return new Promise((resolve, reason) => {
            if (!this._active) {
                reason("Not active!");
                return;
            }
            if (this._token) {
                resolve(this._token);
                return;
            }
            FirebasePlugin.getToken(token => {
                this._token = token;
                resolve(this._token);
            }, function (error) {
                console.log('Get token error: ' + error);
                reason('Get token error: ' + error);
            });
        });
    }
    onNotificationOpen (notification) {
        if(!notification.title || !notification.body || !notification.type) {
            return ;
        }
        var id_league = null;
        if(notification.id_league) {
            id_league = parseInt(notification.id_league);
            if(isNaN(id_league)) {
                id_league = null;
            }
        }
        if(notification.tap || notification.tap == 'background') {
            if(id_league != app.id_league && app.user.leagues.indexOf(id_league) != -1) {
                app.loadAllData(id_league);
            }
            return ;
        }
        var notShow = ['promote_inform'];
        if(notShow.indexOf(notification.type) != -1) {
            return ;
        }
        
        var html = `
            <h3 class="ta-c">${notification.title}</h3>
            <p class="ta-c">${notification.body}</p>
        `;
        (new Dialog())
        .html(html)
        .btn(app.lang.common.ok, function() {
            this.close();
        })
        .show();
    }
};

var fcm;