class BlockDeleteProfile {
    constructor(dom) {
        this.dom = dom;
        this.isSubmited = false;

        eventHandler.observe('block_delete_profile', ['language_change', 'user_login'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    render() {
        if (!this.dom) return;
        
        if(!app.isAuth()) {
            this.dom.empty();
            return ;
        }
    
        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.user.delete_account}</h1>
                <div class='block_delete_profile bl'>
                    <form class="form-login delete-profile-form form">
                        <div class='ta-c'>
                            <img src='images/warning.svg' class='warning' alt='' />
                            <h1 class="form-title">${app.lang.user.confirm_delete_account}</h1>
                            <div class="form-info">${app.lang.user.confirm_delete_account_info}</div>
                        </div>
                        <br /><br />

                        <div class="form-group">
                            <button type="submit" class="tip-button tip-button-red " >${app.lang.user.delete_account}</button>
                        </div>
                        <div class="form-group ta-c">
                            <a href="" class="tip-button tip-button-transparent tip-button-border btn-cancel" >${app.lang.common.cancel}</a>
                        </div>
                    </form>
                </div>
            </div>
        `;

        this.dom.html(html);
        this.dom.find('.btn-cancel').click(function() {
            app.back();
            return false;
        });

        this.$form = $(this.dom).find('form');
        this.$form.submit(e => {
            this.delete();
            return false;
        });
    }

    async delete() {
        await ws.send('user/delete');
        await app.logout();
        //app.renderPage('home');
    }
}