class Event {
	constructor() {
		this.callbacks = [];
		this.observer_id = 1;
	}

	observe(observer_name, names, callback, observer_block = null) {
		var observer = {
			id: this.observer_id++,
			observer_name: observer_name,
			callback: callback,
			observer_block: observer_block
		};

		for(var i in names) {			
			this.callbacks[names[i]] = this.callbacks[names[i]] || [];
			this.callbacks[names[i]].push(observer);
		}
	}

	trigger(name, params, now = false) {
		if (this.callbacks[name]) {
			var prio = {
				1: [],
				2: [],
				3: []
			};

			this.callbacks[name].forEach(function(observer) {
				if(now) {
					prio[1].push(observer);
					return ;
				}
				try {
					if(observer.observer_block) {
						if(observer.observer_block.dom) {
							if(observer.observer_block.dom.is(':visible')) {
								prio[1].push(observer);
							}else {
								prio[3].push(observer);
							}
						}
					}else {
						prio[2].push(observer);
					}
				}catch(err) {
					prio[2].push(observer);
				}
			});

			var runCallbacks = (p) => {
				prio[p].forEach(function(observer) {
					try {
						observer.callback(params);
					}catch(err) {
						console.log('Event trigger error: ' + name, err);
					}
				});
				p++;
				if(prio[p]) {
					setTimeout(() => {
						runCallbacks(p);
					}, 10);
				}
			};
			runCallbacks(1);
            
            if(app && app.mobile) {
                $('.block_container:not(.native-scroll):not(.no-scroll), .mobile_overflow_x').css('overflow-x', 'visible');
            }
		}
	}
}

var eventHandler = new Event();
