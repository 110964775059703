
class BlockGroupInviteFriends {
    constructor(dom) {
        this.dom = dom;
        this.log_dialog = null;
        this.group = null;

        eventHandler.observe('block_group_invite_friends', ['language_change'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    async render(params) {
        if (!this.dom) return;
        
        if(!app.isAuth()) {
            this.dom.empty();
            return ;
        }

        var html = `
            <div class="message please_wait">
                ${app.lang.common.please_wait}
            </div>
        `;
        this.dom.html(html);

        var id = params ? params.get('id') : null;
        var group = null;
        if(id) {
            group = app.data.groups.find(x => x.id == id);
            if(!group) {
                var res1 = await ws.request('group/get', {id: id});
                group = res1.group;
            }
        }
        this.group = group;

        if(!group) {
            var html = `
                <div class="page-message error">
                    ${app.lang.group.can_not_edit_this_group}
                </div>
            `;
            this.dom.html(html);
            return this;
        }

        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.group.invite_friends}</h1>
                <div class='block_group_invite_friends block_invite_friends bl bl20'>
                    <div class="team-label">${app.lang.group.team}</div>
                    <div class="group-name">${group.name}</div>
                    <div class="send-ticket-with">${app.lang.group.send_ticket_with}</div>
                    <div class="share-buttons">
                        <div class="btn-whatsapp"></div>
                        <div class="btn-share"></div>
                    </div>
                    <button type="button" class="btn-black btn-finish">${app.lang.group.finished}</button>
                </div>
            </div>
        `;

        this.dom.html(html);

        this.dom.find('.btn-whatsapp').click(this.whatsapp.bind(this));
        this.dom.find('.btn-share').click(this.share.bind(this));

        this.dom.find('.btn-finish').click(function() {
            //app.renderPage('group_edit', {id: group.id});
            app.renderPage('group', {id: group.id});
            return false;
        });

        return this;
    }

    async logError(message) {
        if(this.log_dialog) {
            this.log_dialog.close();
        }
        this.log_dialog = new Dialog(); 
        this.log_dialog
            .html(message)
            .btn(app.lang.common.close, () => {
                this.log_dialog.close();
                this.log_dialog = null;
            })
            .show();
    }

    async whatsapp() {
        if (config.app_type == 'mobile') {
            var options = {
                message: app.lang.group.share_message.replace('{group}', this.group.name),
                subject: app.lang.group.share_title,
                url: config.web_front_url + '#'+this.group.url_code+'-group'
            };
            if(config.share_url) {
                options.url = config.share_url + 'groupid=' + this.group.url_code;
            }
            
            var onSuccess = function(result) {
                flash.success(app.lang.group.sharing_success);                
            };

            var onError = function(msg) {
                flash.error(app.lang.group.sharing_failed, msg);
            };
            
            window.plugins.socialsharing.shareViaWhatsApp(options.message, [] /* img */, options.url, onSuccess, onError);
        } else {
            const text = app.lang.group.whatsapp_share_message.replace('{group}', this.group.name);
            //const url = window.location.origin + '#join_to_group?c=' + this.group.url_code;
            var url = window.location.origin + window.location.pathname + '#'+this.group.url_code+'-group';
            if(config.share_url) {
                url = config.share_url + 'groupid=' + this.group.url_code;
            }
            window.open('whatsapp://send?text='+encodeURIComponent(text + "\n" + url));
        }
    } 

    async share(callback = null) {
        if (config.app_type == 'mobile') {
            var options = {
                message: app.lang.group.share_message.replace('{group}', this.group.name),
                subject: app.lang.group.share_title,
                url: config.web_front_url + '#'+this.group.url_code+'-group'
            };
            if(config.share_url) {
                options.url = config.share_url + 'groupid=' + this.group.url_code;
            }
            
            var onSuccess = function(result) {
                flash.success(app.lang.group.sharing_success); 
                if(typeof callback == 'function') {
                    callback(result);
                }
            };

            var onError = function(msg) {
                flash.error(app.lang.group.sharing_failed, msg);
                if(typeof callback == 'function') {
                    callback(null, msg);
                }
            };
            
            window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
        } else {
            if (navigator.share === undefined) {
                //this.logError('Error: Unsupported feature: navigator.share');
                //flash.error(app.lang.group.sharing_failed, 'Unsupported feature: navigator.share');
                await this.copyLink();
                if(typeof callback == 'function') {
                    //callback(null, 'Unsupported feature: navigator.share');
                    callback(true);
                }
                return;
            }

            const title = app.lang.group.share_title;
            const text = app.lang.group.share_message.replace('{group}', this.group.name) + "\n";
            //const url = window.location.origin + '#join_to_group?c=' + this.group.url_code;
            var url = window.location.origin + window.location.pathname + '#'+this.group.url_code+'-group';
            if(config.share_url) {
                url = config.share_url + 'groupid=' + this.group.url_code;
            }
            
            try {
                await navigator.share({title, text, url});
                if(typeof callback == 'function') {
                    callback(true);
                }
            } catch (error) {
                //this.logError('Error sharing: ' + error);
                if(typeof callback == 'function') {
                    callback(null, error);
                }
            }
        }
    } 
    
    getJoinLink() {
        var join_link = (config.app_type == 'mobile'
            ? config.web_front_url
            : window.location.origin + window.location.pathname) + '#' + this.group.url_code + '-group';
        if(config.share_url) {
            join_link = config.share_url + 'groupid=' + this.group.url_code;
        }
        return join_link;
    }
    
    async copyLink(event) {
        var join_link = this.getJoinLink();
        helpers.copyToClipboard(join_link);
        flash.success(app.lang.common.link_copied); 
    }
}