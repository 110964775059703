class BlockGroupJoin {
    constructor(dom) {
        this.dom = dom;
        this.isSubmited = false;

        eventHandler.observe('block_group_join', ['language_change'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    async render(params) {
        if (!this.dom) return;
        
        if(!app.isAuth()) {
            this.dom.empty();
            return ;
        }

        app.backCallback = function() {
            app.renderPage(app.previous_page, null, true);
            return false;
        };

        var url_code = params ? params.get('c') : null;
        if(!url_code) {
            await app.renderPage('home');
            flash.error(app.lang.common.page_not_found);
            return this;
        }

        let group = app.data.groups.find(x => x.url_code == url_code);
        if(group) {
            app.renderPage('group', {'id': group.id } );
            return ;
        }

        this.dom.empty();

        this.getGroup(url_code);

        return this;
    }

    async getGroup(url_code) {
        try {
            var result = await ws.request('group/getByUrlCode', {url_code});
            
            if(result.success) {
                if(result.group.image_thumb) {
                    this.renderAd(result.group);
                }else {
                    this.renderForm(result.group);
                }
            }else {
                await app.renderPage('home');
                flash.error(app.lang.group[result.message]);                
            }
        }catch(err) {
            console.log(err);
            await app.renderPage('home');
            flash.error(app.lang.common.unknown_error); 
        }
    }

    async renderAd(group) {
        if(typeof group.options != 'object') {
            try {
                group.options = JSON.parse(group.options);
            }catch(err) {
                group.options = {};
            }
        }

        document.body.style.backgroundColor = group.bg_color;
        var top_html = '';
        if(group.options.top_line_1 || group.options.top_line_2 || group.options.top_line_3) {
            top_html = `
                <div class="group-top-text">
                    <div class="line-1">${group.options.top_line_1}</div>
                    <div class="line-2">${group.options.top_line_2}</div>
                    <div class="line-3">${group.options.top_line_3}</div>
                </div>
            `;
        }
        var image_big = '';
        if(group.image_big) {
            image_big = `
                <div class="group-image_big">
                    <img src="groups/${group.id}_big_${group.image_big}" />
                </div>
            `;
        }
        var button_style = '';
        if(group.button_color) {
            button_style += `background-color: ${group.button_color}; `;
        }
        if(group.bg_color) {
            button_style += `color: ${group.bg_color}; `;
        }
        var privacy
                
        if (config.style == 'fussballdaten') {
            privacy = `
                <div class="group-privacy">
                    <a target='_blank' href='https://www.fussballdaten.de/datenschutzerklaerung/'>${app.lang.group.privacy}</a>
                </div>
            `;
        } else {
            privacy = `
                <div class="group-privacy">
                    <a href="#privacy">${app.lang.group.privacy}</a>
                </div>
            `;
        }
        
        var html = `
            <div class="block-group-ad-content">
                ${top_html}
                ${image_big}
                <div class="group-text">
                    ${group.text}
                </div>
                <div class="group-btn">
                    <button type="button" class="btn-white group-join-btn" style="${button_style}" >${group.button_text}</button>
                </div>
                <div class="group-btn-hide">
                    <a href="#">${app.lang.group.no_thanks}</a>
                </div>
                <div class="group-image_logo">
                    <img src="groups/${group.id}_logo_${group.image_logo}" />
                </div>
                ${privacy}
            </div>
        `;
        this.dom.html(html);
        $('html').attr('page_style', group.options.page_style);

        this.dom.find('.group-join-btn').click(e => {
            this.joinToAdGroup(group);
            return false;
        });

        this.dom.find('.group-btn-hide a').click(e => {
            this.hideAdGroup(group);
            return false;
        });

        /*
        this.dom.find('.group-privacy a').click(e => {
            window.open(group.button_link);
            return false;
        });
        */
    }

    async joinToAdGroup(group) {
        var data = {};
        data.url_code = group.url_code;
        var result = await ws.request('group/join', data);
        
        if(result.success) {
            if (result.added) {
                app.data.groups.push(result.group);
                eventHandler.trigger('set_data_groups', {}, true);
            }
            app.renderPage('group', {id: result.group.id});
        }
    }

    async hideAdGroup(group) {
        var data = {};
        data.url_code = group.url_code;
        var result = await ws.request('group/hide', data);
        if(result.success) {
            app.data.group_hiddens = result.group_hiddens;
            eventHandler.trigger('set_data_group_hiddens');
            app.renderPage('home', null, true);
        }
    }

    async renderForm(group) {
        this.isSubmited = false;
        var passwordHtml = '';
        if(group.private == 1) {
            passwordHtml = `
                <div class="form-group password-group">
                    <input type="password" name="password" value="" placeholder="${app.lang.group.join_password}" />
                </div>
            `;
        }
        
        if(typeof group.options != 'object') {
            try {
                group.options = JSON.parse(group.options);
            }catch(err) {
                group.options = {};
            }
        }
        
        var group_image = '';
        if (!group.image_thumb && group.options && group.options.image) {
            group_image = group.options.image ? config.web_front_url+`/usergroup/${group.options.image}` : 'images/group_default.png';
            group_image = `<div class="image"><img src="${group_image}" alt=""></div><br />`;
        }
        
        var join_message;
        if (group.image_big) {
            join_message = `<img src='groups/${group.id}_big_${group.image_big}' />`;
        } else if(group.private == 1) {
            join_message = `<div class="box-title">${app.lang.group.hi}</div>
                        <div class="box-desc">${app.lang.group.join_info}</div>`;
        } else {
            join_message = `<div class="box-title">${app.lang.group.hi}</div>
                        <div class="box-desc">${app.lang.group.join_info_public}</div>`;
        }
        
        var html = `
            <div class="block-form">
                <h1 class="page-title">${group.name}</h1>
                <div class='block_group_join bl'>
                    <form class="form-group_join form">        
                            ${group_image}
                        <div class="box">

                            ${join_message}

                            ${passwordHtml}
                        </div>

                        <div class="group-join-message error"></div>

                        <div class="form-group form-group-submit line-buttons">
                            <button type="submit" class="tip-button group-join-btn" >${app.lang.group.join_btn}</button>
                        </div>
                    </form>
                </div>
            </div>
        `;

        this.dom.html(html);

        this.$form = $(this.dom).find('form');
        this.$form.submit(e => {
            this.onsubmit(group);
            return false;
        });
        var fields = {};
        if(group.private == 1) {
            fields.password = {required: true, valid: {min_length: 4}};
        }
        this.form = new Form(this.$form, fields);
    }

    async onsubmit(group) {
        if(this.isSubmited) {
            return ;
        }
        this.isSubmited = true;

        try {
            if(!this.form.isValid()) {
                this.isSubmited = false;
                return ;
            }
            var data = this.form.getData();
            data.url_code = group.url_code;
            var result = await ws.request('group/join', data);
            if(result.success) {
                if (result.added) {
                    app.data.groups.push(result.group);
                    eventHandler.trigger('set_data_groups', {}, true);
                }
                if(result.user) {
                    app.setUser(result.user);
                    eventHandler.trigger('update_profile');
                }
                app.renderPage('group', {id: result.group.id});
            }else {
                if(result.messages) {
                    this.form.setServerInvalids(result.messages, data);
                }else if(result.message) {
                    this.$form.find('.group-join-message').html(app.lang.group[result.message]);
                }
            }
        }catch(err) {
            console.log(err);
        }

        this.isSubmited = false;
    }
};