
var Lib = Lib || {};

Lib.Facebook = {
	LoginAndGetData: function(callback) {
		var fb = Lib.Facebook;
		var fbGetData = function() {
			facebookConnectPlugin.api("me/?fields=id,name,email,picture.type(large),link,gender", [],
                function (result) {
					var data = {
                        fb_id: result.id,
                        fb_name: result.name,
                        fb_email: result.email,
                        fb_link: result.link,
                        fb_picture: result.picture.data.url,
						gender: result.gender
                    };
                    callback(data);
                }, function (error) {
                    console.log(error);
                    //alert("Failed: " + JSON.stringify(error));
                    //alert('Błąd połaczenia z facebookiem (Kod: 101)');
                }
            );  
		};
		fb.IsLogged(fbGetData, function() {
            fb.Login(fbGetData);
        });
	},
    Login: function(fbLoginSuccess) {
        facebookConnectPlugin.login(["public_profile"], fbLoginSuccess,
            function (error) {
				console.log(error);
                //alert('fb/login error: ' + JSON.stringify(error));
                //$('<textarea/>').html(JSON.stringify(error)).prependTo('body');
                //alert('Błąd połaczenia z facebookiem (Kod: 100)');
            }
        );
    },
    IsLogged: function (onLegged, onNotLogged) {
        facebookConnectPlugin.getLoginStatus(function (status) {
            //alert("current status: " + JSON.stringify(status));
            if(status.status == 'connected') {
                if(onLegged) {
                    onLegged(status);
                } 
            }else {
                if(onNotLogged) {
                    onNotLogged(status);
                } 
            }
        });
    },
    AppInvite: function() {
        facebookConnectPlugin.appInvite({
                url: config.fbInviteUrl
                //picture: config.fbInvitePicture
            }, function(obj){
				if(obj) {
                    if(obj.completionGesture == "cancel") {
                        // user canceled, bad guy
                    } else {
                        // user really invited someone :)
                    }
                } else {
                    // user just pressed done, bad guy
                }
            }, function(obj){
				Lib.Error.onError(obj, 'facebook AppInvite');
            }
        );
    },
    Logout: function() {
        facebookConnectPlugin.logout(function() {
            localStorage.clear();
            app.account = null;
            app.loadAccount();
        });
    },
	GetFriends: function(callback) {
		var frinds_ids = [];
        var saveFrinds = function() {
			callback(frinds_ids);
        };
        var onSuccessFriends = function(result) {
            if(result && result.data) {
                for(var i in result.data) {
                    var friend = result.data[i];
                    frinds_ids.push(friend.id);
                }
            }
            
            if(result && result.paging && result.paging.cursors && result.paging.cursors.after) {
                facebookConnectPlugin.api('me/friends?limit=500&after='+result.paging.cursors.after, ["user_friends"],
                    onSuccessFriends, function onError(error) {
                        saveFrinds();
                    }
                );
            }else {
                saveFrinds();
            }
        };
		
		facebookConnectPlugin.api('me/friends', ["user_friends"], onSuccessFriends, 
			function onError(error) {
				Lib.Error.onError(error, 'facebook friends');
			}
		);
	},
	SaveFriendsOnServer: function(callback) {
		Lib.Facebook.GetFriends(function(frinds_ids) {
			api.post('account/save-fb-friends', {friends: frinds_ids}, {
				complete: callback
			});
		});
	},
	ShareUrl: function(href) {
		facebookConnectPlugin.showDialog({
				method: "share",
				href: href
			}, function (response) {
				
			}, function (error) {
				Lib.Error.onError(error, 'facebook ShareUrl');
			}
		);
	}
};