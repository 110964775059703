class BlockGroup {
    constructor(dom) {
        this.dom = dom;
        this.last_group = null;
        this.group = null;
        this.page = 0;
        this.prev_st = 0;
        this.can_get_next_page = false;
        this.highlight_user_div = null;
        this.current_user_user_div = null;
        this.block_scores_is_sm = false;
        this.round = null;
        this.id_fixture = null;
        this.group_id = null;
        this.set_data_groups_counter = 0;
        this.refresh_dialog = null;
        this.current_user_line_indicator = null;
        this.show_possible_score = false;
        this.min_page = 99999;

        eventHandler.observe('block_group', ['language_change', 'user_login', 'set_data_groups'], () => {
            return ;
            if(app.current_page != 'group') {
                this.set_data_groups_counter++;
                return ;
            }
            if(this.set_data_groups_counter == 0) {
                this.refreshData();
            }else {
                //this.refreshDataDialog();
            }
            this.set_data_groups_counter++;
        }, this);

        eventHandler.observe('block_group', ['updated_goals'], () => {
            if(app.current_page != 'group') {
                return ;
            }
            this.refreshDataDialog();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;

        var html = `            
            <div class='block_group'></div>
        `;
        this.dom.html(html);

        return this;
    }

    async refreshData(page = 0) {
        this.render(this.group_id, this.round, this.id_fixture, this.division, this.position, false, true, page);
    }

    async refreshDataDialog() {
        if(this.is_division_group) {
            return ;
        }
        if(this.refresh_dialog) {
            this.refresh_dialog.close();
        }
        this.refresh_dialog = new Dialog(); 
        
        var html = '';
        html += `<div class='ta-c'>`;
        html += `<lottie-player src="animations/${config.style}/ball-animation.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"    autoplay></lottie-player>`;
        html += `</div>`;
        html += `<div class='pr_message'>`+app.lang.group.new_data_refresh_message+'</div>';

        this.refresh_dialog
            .html(html)
            .btn(app.lang.group.new_data_refresh_btn, () => {
                this.refreshData();
                this.refresh_dialog.close();
            }, {black: false, sportde: true})
            .show();
    }

    async renderDivisionGroup(division, position, page) {
        $('#scores').hide();
        $('#divisions').show();
        $('#group_page').show();
        
        this.resetProperties();
        this.is_division_group = true;
        this.division = division;
        this.position = position;
        this.header_dom_content = app.blocks.divisions.dom_content.add(app.$topbar);
        
        var html = '';
        var result = await ws.request('group/getDivisionUsers', {division, position, page});
        
        if(result.success) {
            var group = result.group;
            var group_name = helpers.getGroupName(group);
            this.group = result.group;
            var list_html = this.renderUsers(result.list);
            this.start_page = 0;
            
            app.topbar();

            var group_image = `<img src="images/${config.style ? config.style + '/' : 'sportde-'}group-logo.svg" class="sportde-group-logo" alt="">`;
            if(app.data.division_badges.hasOwnProperty(division) && app.data.division_badges[division]) {
                group_image = `<img src="${config.web_front_url}/divisions/${app.data.division_badges[division]}" class="liga-logo" alt="">`;
            }
            
            var ranking_info = `<div class="group-info">${app.lang.group.division_ranking_info}</div>`;

            html = `            
                <div class='block_group division max_division-${app.data.max_division}'>
                    <div class='block_group_header'>
                        ${group_image} ${group_name} ${ranking_info}
                    </div>
                    <div class='block_group_pts'>${app.lang.common.pts}</div>
                    <div class="block_group_users_list">${list_html}</div>
                    <div class="block_group_current_user bottom"></div>
                </div>
            `;

            this.can_get_next_page = result.is_next;
            this.last_group = result.group;
            app.current_group = result.group;
            this.renderHeader(result.group);
        }

        if(this.group) {
            this.dom.html(html);
            this.block_group_users_list = this.dom.find('.block_group_users_list');
            this.scrollEvent();
        }
        
        this.header_dom_content.removeClass('fixed').children().removeClass('sm');
        this.dom.removeClass('fixed');
    }

    resetProperties() {
        var html = `
            <div class="message please_wait">
                ${app.lang.common.please_wait}
            </div>
        `;
        this.dom.html(html);
        this.dom.removeClass('is-league');
        this.page = 0;
        this.start_page = 9999999;
        this.can_get_next_page = false;
        this.group = null;
        this.highlight_user_div = null;
        this.current_user_user_div = null;
        this.block_scores_is_sm = false;
        this.round = null;
        this.id_fixture = null;
        this.block_group_users_list = null;
        this.current_user_user_div = null;
        this.is_division_group = false;
        this.division = false;
        this.position = false;
        this.start_from_other_division = false;
        this.user_count = 0;
        this.header_dom_content = this.id_league != 0 ? app.blocks.scores.dom_content.add(app.$topbar) : app.$topbar;
        this.display_bets = false;
        this.current_user_line_indicator = null;
        this.show_possible_score = false;
        this.min_page = 99999;
    }

    async render(group_id, round, id_fixture, division, position, scrollToUpcommingGame, noChangeHeader, page = 0, id_league = 0) {
        if (!this.dom) {
            return this;
        }
        this.group_id = group_id;

        if(!group_id &&  division) {
            this.renderDivisionGroup(division, position, page);
            return this;
        }
        if(!group_id && this.last_group) {
            this.renderHeader(this.last_group);
            return this;
        }

        if(group_id == 'overall_ranking' || group_id == 0) {
            id_league = app.id_league;
        }       

        var group = app.data.groups.find(x => x.id == group_id);
        if(!group) {
            group = await ws.request('group/getUserGroup', {group_id, id_league});
        }
        if(group.type) {
            id_league = app.id_league;
        }
        if(!id_league && group.division) {
            id_league = app.id_league;
        }
        
        if(group.ids_leagues && group.ids_leagues.indexOf(id_league) == -1) {
            id_league = group.id_league;
        }
        
        this.id_league = id_league;
        
        this.resetProperties();
        app.topbar();
        var html = ''; 
        
        this.round = round;
        this.id_fixture = id_fixture;
        
        if (group) {
            this.start_page = 9999999;

            var group_name = helpers.getGroupName(group);

            if (typeof ga !== 'undefined' && allowGATracking !== false) {
                ga('send', 'event', 'Group', 'open', group_name);
            }

            this.group = group;
            if(helpers.isSponsoredChallengeGroup(this.group)) {
                $('#scores').hide();
                $('#divisions').hide();
            } else {
                this.renderBlockGroupHeader(noChangeHeader, scrollToUpcommingGame, id_league);
            }

            var result = await ws.request('group/getUsers', {id: group_id, round: this.round, id_fixture: this.id_fixture, page: page, id_league: this.group.id_league});

            if (result.page < this.min_page) {
                this.min_page = result.page;
            }

            //$('#testing').html('Render: '+ document.scrollingElement.scrollTop+ ',')

            if(result.success) {
                //this.show_possible_score = result.user_row.hasOwnProperty('possible_score');
                var list_html = this.renderUsers(result.list, false, id_fixture);
                var current_html = this.renderUsers([result.user_row], true, id_fixture);
                var league_name = id_league ? app.data.leagues_name[id_league] : app.lang.common.ranking_total;

                this.page = result.page;
                if (this.page < this.start_page) {
                    this.start_page = this.page;
                }

                this.user_count--;
                var bet = '';
                var _class = '';
                if(this.id_fixture) {
                    bet = `<span class="bet">${app.lang.group.tipp}</span>`;
                    _class = 'for-fixture ';
                }
                var group_header = '';
                if(group.id == 'overall_ranking') {
                    if(id_fixture) {
                        var fixture = app.data.scores.find(x => x.id == id_fixture);
                        group_header = `<b>${app.lang.common.group_header}</b> - ${fixture.team1.name} - ${fixture.team2.name}`;
                    }else if(round) {
                        group_header = `<b>${app.lang.common.group_header}</b> - ${round}. ${app.lang.common.matchday}`;
                    }else {
                        group_header = `<b>${app.lang.common.group_header}</b> - ${app.lang.common.ranking_total}`;
                    }
                    app.topbar(league_name);
                } else if(this.group.division == 0) {
                    //league_name += ' <img src="images/angle-down-solid.svg" />';
                    if(id_fixture) {
                        var fixture = app.data.scores.find(x => x.id == id_fixture);
                        group_header = `<span class="group-league">${league_name}</span> - ${fixture.team1.name} - ${fixture.team2.name}`;
                    } else if(round) {
                        group_header = `<span class="group-league">${league_name}</span> - ${round}. ${app.lang.common.matchday}`;
                    } else if(id_league) {
                        group_header = `<span class="group-league">${league_name}</span> - ${app.lang.common.ranking_total}`;
                    } else {
                        group_header = `<span class="group-league">${league_name}</span>`;
                    }
                    if(group.type) {
                        app.topbar(group.name, league_name);
                    }else {
                        app.topbar(group.name, league_name, this.chooseLeagues.bind(this));
                    }

                } else {
                    group_header = `<b>${group_name}</b>`;
                    app.topbar(group_name);
                }

                if(typeof group.options != 'object') {
                    try {
                        group.options = JSON.parse(group.options);
                    }catch(err) {
                        group.options = {};
                    }
                }

                var group_image = group.options && group.options.image ? `${config.web_front_url}/usergroup/${group.options.image}` : `images/${config.style ? config.style + '/' : 'sportde-'}group-logo.svg`;
                var group_image_class = group.options && group.options.image ? '' : 'wtl-logo';

                group_image = `<img src="${group_image}" class="${group_image_class}" alt="">`;
                if(this.group.division > 0 && app.data.division_badges.hasOwnProperty(this.group.division) && app.data.division_badges[this.group.division]) {
                    group_image = `<img src="${config.web_front_url}/divisions/${app.data.division_badges[this.group.division]}" class="liga-logo" alt="">`;
                }

                var extra_cols = '';
                if((helpers.isSponsoredChallengeGroup(this.group) || helpers.isOverallRankingSponsoredChallengeGroup(this.group)) && !this.round && !this.id_fixture) {
                    extra_cols += `
                        <span class="scp">${app.lang.group.scp}</span>
                    `;
                    _class += ' sponsored-challenge-group ';
                }
                if(this.group.division != 0) {
                    _class += ` division max_division-${app.data.max_division} `;
                }

                let ranking_info = ``;
                if(group_id == 'overall_ranking' || group_id == 0) {
                    if(id_fixture) {
                        ranking_info = `<div class="group-info">${app.lang.group.group_overall_ranking_fixture_info}</div>`;                        
                    }else if(round) {
                        ranking_info = `<div class="group-info">${app.lang.group.group_overall_ranking_round_info}</div>`;
                    }else {
                        ranking_info = `<div class="group-info">${app.lang.group.group_overall_ranking_info}</div>`;                        
                    }
                } else if (this.group.division != 0) {
                    ranking_info = `<div class="group-info">${app.lang.group.division_ranking_info}</div>`;
                } else if (id_league && !helpers.isSponsoredChallengeGroup(this.group)) {
                    ranking_info = `<div class="group-info">${app.lang.group.group_ranking_info}</div>`;
                }
                
                extra_cols += `
                    <span class="name"># <span>${app.lang.common.user}</span></span>
                `;
                
                html = `            
                    <div class='block_group ${_class}'>
                        <div class='block_group_header'>
                            ${group_header}
                            ${ranking_info}
                        </div>
                        <div class='block_group_pts'>${bet}${extra_cols}<span class="pts">${app.lang.common.pts}</span></div>
                        <div class="block_group_users_list">${list_html}</div>
                        ${this.getIinviteFriendsHtml()}
                        <div class="block_group_current_user bottom"><div><div>${current_html}</div></div></div>
                    </div>
                `;

                this.can_get_next_page = result.is_next;
                this.last_group = group;
                app.current_group = group;
                this.renderHeader(group);

                if (page == -1) {
                    setTimeout(function() { 
                        //$('.block_group_users_list .user:first-child').css('background', 'red');
                        var usertop = $('.block_group_users_list .highlight').position();
                        usertop = usertop.top - 230;
                        if (usertop < 11) {
                            usertop = 11;
                        }
                        window.scrollTo(0, usertop); }, 
                    80);

                    this.prependUsers();
                }
            }else {
                console.log(group, 'group');
                console.log(result, 'error result');
            }
            
            //this.dom.hide().html(html).show();
            this.dom.html(html);
            this.block_group_users_list = this.dom.find('.block_group_users_list');
            this.highlight_user_div = this.block_group_users_list.find('.user.highlight');
            this.current_user_user_div = this.dom.find('.block_group_current_user');

            $('#group_page').show();
            
            this.block_scores_is_sm = false;
            this.header_dom_content.removeClass('fixed').children().removeClass('sm');
            this.dom.removeClass('fixed');
            this.block_group_users_list.removeClass('keep-scroll');
            if(!this.is_division_group) {
                if(this.id_league == 0) {
                    this.current_user_user_div.css('top', '22px');                    
                }else {
                    this.current_user_user_div.css('top', this.header_dom_content.height() + 'px');
                }
            }

            this.dom.find('.btn_group_invite_friends').click(e => {
                app.renderPage('group_edit', {id: this.group.id});
            });
            this.dom.find('.btn-unjoin-group').click(e => {
                app.renderPage('group_unjoin', {id: this.group.id});
            });
            this.dom.find('.btn-to-overview').click(e => {
                app.renderPage('home');
            });

            this.showCurrentUser();
            this.scrollEvent();
            
            var bif = app.blocks.group_invite_friends;
            bif.group = this.group;
            this.dom.find('.btn-whatsapp').click(bif.whatsapp.bind(bif));
            this.dom.find('.btn-share').click(bif.share.bind(bif));
            this.dom.find('.btn-copy_link').click(bif.copyLink.bind(bif));
            
            if(this.id_league) {
                this.dom.addClass('is-league');
            }
        }else {
            console.log('not found group');
            app.renderPage('home');
        }

        return this;
    }

    getJoinLink() {
        var bif = app.blocks.group_invite_friends;
        bif.group = this.group;

        return bif.getJoinLink();
    }
    
    getIinviteFriendsHtml() {
        if(this.group.id == 'overall_ranking' || this.group.division != 0) {
            return '';
        }
        
        var html = `
            <div class="invite-friends-box block-form">
                <div class="title">${app.lang.group.invite_friends_title}</div>
                <div class="desc">${app.lang.group.invite_friends_desc}</div>
                <div class="menu btn-whatsapp" style="display:${app.mobile ? 'grid' : 'none' }">
                    <span>
                        <img src="images/whatsapp.svg" />
                    </span>
                    <span>
                        ${app.lang.common.whatsapp}
                    </span>
                    <span></span>
                </div>
                <div class="menu btn-share" style="display:${app.mobile ? 'grid' : 'none' }">
                    <span>
                        <img src="images/share.svg" />
                    </span>
                    <span>
                        ${app.lang.common.share}
                    </span>
                    <span></span>
                </div>
                <div class="btn-link" style="display:${app.mobile ? 'none' : 'block' }">
                    <button class="tip-button join-link-button" >${this.getJoinLink()}</button>
                </div>
                <div class="form-group line-buttons">
                    <button class="tip-button tip-button-block tip-button-color-yellow btn-copy_link" >${app.lang.group.copy_link}</button>
                </div>
            </div>
            <div class="form-group line-buttons box-unjoin-buttons">
                <button class="tip-button tip-button-transparent tip-button-border btn-to-overview" >${app.lang.common.to_overview}</button>
            </div>
        `;
        
        return html;
    }
    
    async chooseLeagues() {
        var self = this;
        let group_leagues = await ws.request('group/getGroupLeagues', {id: this.group_id});
        let leagues_html = '';
        leagues_html += `
            <div class="group-leagues form-group">
                <h4 class="info">${app.lang.common.choose_leagues}</h4>
        `;
        
        for(var id_league of app.data.id_leagues) {
            if(group_leagues.indexOf(parseInt(id_league)) != -1 && app.user.leagues.indexOf(parseInt(id_league)) != -1) {
                leagues_html += `
                    <div class='tab3'>
                        <div>
                            <div class='flag' style='background-image: url(${config.web_front_url}/images/leagues/${id_league}.svg)'>

                            </div>
                            <div>
                                ${app.lang.common['league_name_'+id_league]}
                            </div>
                            <div>
                                <input ${this.id_league == id_league ? 'checked="checked"' : ''} type="checkbox" name="leagues" value="${id_league}" />
                            </div>
                        </div>
                    </div>
                `;
            }
        }

        leagues_html += `
            <div class='tab3'>
                <div>
                    <div class='flag' >

                    </div>
                    <div>
                        ${app.lang.common.ranking_total}
                    </div>
                    <div>
                        <input ${this.id_league == 0 ? 'checked="checked"' : ''} type="checkbox" name="leagues" value="0" />
                    </div>
                </div>
            </div>
        `;

        leagues_html += '</div><br />';
        
        var html = `
            <div class="block-form">
                <h1 class="page-title">${app.lang.common.select_leagues}</h1>
                <div class="form form-sm form-notifications form-separators">
                    ${leagues_html}
                </div>
            </div>
        `;
        
        var leagues = $(html);
        leagues.dialog();
        leagues.find('input').click(function() {
            leagues.dialog().close();
            let id_league = parseInt(this.value);
            self.render(self.group_id, null, null, null, self.position, true, false, 0, id_league);
            this.set_data_groups_counter++;
            if(id_league && id_league != app.id_league) {
                app.loadAllData(id_league);
            }
        });
    }

    renderBlockGroupHeader(noChangeHeader, scrollToUpcommingGame, id_league = 0) {
        $('#group_page').removeClass('hiden-scores');
        
        if(this.group.division == 0 && this.group.id != 'overall_ranking' && id_league == 0) {
            $('#scores').hide();
            $('#group_page').addClass('hiden-scores');
        }else if(!noChangeHeader) {
            if(this.group.division == 0) {
                app.blocks.scores.updateRoundScores(this.group.id);
                $('#scores').show();
                app.blocks.scores.setGroupActive(this.round, this.id_fixture);
                if(scrollToUpcommingGame && !this.round && !this.id_fixture && app.blocks.scores.scroll_to_matchday_counter == 0) {
                    //$(app.blocks.scores.dom_content).find('.current_day').addClass('g-show');
                    //$(app.blocks.scores.dom_content).find('.matchday.current_day').addClass('g-active');
                    //app.blocks.scores.scrollToUpcommingGame();
                    app.blocks.scores.scrollToCurrentMatchday();
                }else if(scrollToUpcommingGame && app.blocks.scores.scroll_to_matchday_counter == 0) {
                    app.blocks.scores.scrollToMatchdayNr(this.round);
                }
            }else {
                $('#scores').hide();
                $('#divisions').show();
                this.header_dom_content = app.blocks.divisions.dom_content.add(app.$topbar);
                app.blocks.divisions.setActivePosition(this.group.division, this.group.division_position);
            }
        }else {
            if(this.group.division != 0) {
                this.header_dom_content = app.blocks.divisions.dom_content.add(app.$topbar);
            }
        }
    }

    scrollEvent() {
        var h = window.innerHeight;
        var d = 150 + (this.dom.find('.invite-friends-box').outerHeight() || 0);

        $(window).off('scroll.block_users').bind('scroll.block_users', e => {
            if(!this.is_division_group) {
                this.showCurrentUser();
            }

            var sh = document.scrollingElement.scrollHeight;
            var st = document.scrollingElement.scrollTop;
            if (st < 0) {
                st = 0;
            }
            //$('#testing').html('Page '+ this.page + ', '+ Math.round(st) +' + '+ h +' + ' + d +' = '+Math.round(st + h + d)+' > ' + sh+ '<br />'+Math.round(this.prev_st)+' '+this.can_get_next_page);
            //$('#testing').html($('#testing').html() + st+ ',')
            
            
            if(st < 10 && this.prev_st != -1 && st < this.prev_st && this.page > 0 && this.start_page > 0) {
                this.prependUsers();
            }
            
            if (this.prev_st != -1) {
                this.prev_st = st;
            }
            
            var sh = document.scrollingElement.scrollHeight;
            
            if(this.can_get_next_page && st + h + d > sh && this.prev_st != -1) {
                this.appendUsers();
            }

            var st_compare = (!app.mobile && !config.disabled_ad_grid && app.config.ad_header) ? parseInt(app.config.ad_header_height) + 50 : 10;
            
            if(st > st_compare && !this.block_scores_is_sm && this.user_count > 5) {
                if(helpers.isSponsoredChallengeGroup(this.group)) {
                    this.current_user_user_div.css('top', 0);
                }else {
                    this.block_scores_is_sm = true;
                    if(this.block_group_users_list.innerHeight() < h - 20) {
                        this.block_group_users_list.addClass('keep-scroll');
                    }
                    this.header_dom_content.addClass('fixed').children().addClass('sm');
                    $('body').addClass('scrolled');
                    
                    this.dom.addClass('fixed');
                    if(!this.is_division_group) {
                        //alert('Page '+ this.page + ', '+ Math.round(st) +' + '+ h +' + ' + d +' = '+Math.round(st + h + d)+' > ' + sh+ '<br />'+Math.round(this.prev_st)+' '+this.can_get_next_page);
                        //$('#testing').html($('#testing').html() + 'GRR '+ st+ ',')
                        let h = 0;
                        this.header_dom_content.each(function() { h += $(this).height() });
                        h -= 1;
                        this.current_user_user_div.css('top', h + 'px');
                    }
                }
            }else if(st < 10 && this.block_scores_is_sm && this.start_page == 0) {
                this.block_scores_is_sm = false;
                this.header_dom_content.removeClass('fixed').children().removeClass('sm');
                $('body').removeClass('scrolled');
                
                this.dom.removeClass('fixed');
                this.block_group_users_list.removeClass('keep-scroll');
                if(!this.is_division_group) {
                    let h = 0;
                    this.header_dom_content.each(function() { h += $(this).height() });
                    h -= 1;
                    this.current_user_user_div.css('top', h + 'px');
                }
            }
        });
    }

    showCurrentUser() {
        var shown = false;
        if(!this.current_user_user_div) {
            return false;
        }
        if(this.user_count <= 5) {
            shown = true;
        }else if(this.highlight_user_div && this.highlight_user_div.length > 0) {
            var pt = this.highlight_user_div.offset().top;
            var pb = pt + this.highlight_user_div.height();
            var st = document.scrollingElement.scrollTop;
            var bs = 25;
            if(helpers.isSponsoredChallengeGroup(this.group)) {
                bs += 20;
            }else {
                bs += this.header_dom_content.height();
            }
            var h = window.innerHeight;

            if(pb >= st + bs && pt <= st + h) {
                shown = true;   
            }else {
                if(pb < st + bs) {
                    this.current_user_user_div.removeClass('bottom').addClass('top');
                }else {
                    this.current_user_user_div.removeClass('top').addClass('bottom');
                }
            }
        }

        if(shown) {
            this.current_user_user_div.hide();
        }else {
            this.current_user_user_div.show();
        }
    }

    async prependUsers() {
        if (this.min_page) {
            this.prev_st = -1;
            if (this.block_group_users_list) {
                this.block_group_users_list.css('opacity', 0.2);
            }
                
            this.min_page--;
            var result;

            if (this.is_division_group) {
                result = await ws.request('group/getDivisionUsers', {division: this.division, position: this.position, page: this.min_page});
            } else {
                result = await ws.request('group/getUsers', {id: this.group.id, round: this.round, id_fixture: this.id_fixture, page: this.min_page, id_league: this.id_league});        
            }
            
            if (result.page < this.min_page) {
                this.min_page = result.page;
            }
            
            if(result.success) {
                var html = this.renderUsers(result.list, false, this.id_fixture);
                this.block_group_users_list.prepend(html);
                
                var t = this;
                setTimeout(function() { 
                    t.block_group_users_list.css('opacity', 1);
                    var scrollY = 1390 + 63;
                    window.scrollTo(0, scrollY); 
                    t.prev_st = 0;

                    if(t.min_page == 0) {
                        t.start_page = 0;
                    }
                }, 80);
                
                if(this.highlight_user_div && this.highlight_user_div.length == 0) {
                    this.highlight_user_div = this.block_group_users_list.find('.user.highlight');
                }    
            }
        }
    }
    
    async appendUsers() {
        this.can_get_next_page = false;
        this.page ++;
        var result;
        
        if(this.is_division_group) {
            result = await ws.request('group/getDivisionUsers', {division: this.division, position: this.position, page: this.page});
        }else {
            result = await ws.request('group/getUsers', {id: this.group.id, round: this.round, id_fixture: this.id_fixture, page: this.page, id_league: this.id_league});        
        }
        if(result.success) {
            var html = this.renderUsers(result.list, false, this.id_fixture);
            this.block_group_users_list.append(html);
            this.can_get_next_page = result.is_next;        
            if(this.highlight_user_div && this.highlight_user_div.length == 0) {
                this.highlight_user_div = this.block_group_users_list.find('.user.highlight');
            }    
        }
    }
    
    renderHeader(group) {
        if(group.division == 0 && group.id > 0 && group.id != 'overall_ranking') {
            app.topbarSettings(() => {
                app.renderPage('group_edit', {id: group.id});
            });
        }
    }

    renderUsers(list, static_current_user = false, id_fixture = null) {
        var html = '';
        var number_of_promoted_users = app.config.number_of_promoted_users;
        var number_of_relegated_users = number_of_promoted_users * 2;        
        var pyramid_user_count = app.config.pyramid_user_count;
        
        if (this.group.division == 100) {
            number_of_promoted_users = number_of_promoted_users * Math.pow(2, app.data.max_division);
        }
        
        for(var user of list) {
            var arrow = '';
            var position = '';
            
            if (!user.previous_position || user.position == user.previous_position) {
                position = "<img class='dot-indicator' src='images/equal-dot.svg' />";
            } else {
                if (user.position > user.previous_position) {
                    arrow = `<img class='arrow-indicator down' src='images/${config.style ? config.style+'/' : ''}down-arrow2.svg' />`;
                } else {
                    arrow = `<img class='arrow-indicator' src='images/${config.style ? config.style+'/' : ''}up-arrow2.svg' />`;
                }

                position = `${arrow} ${helpers.formatPreviousPosition(user.position, user.previous_position)}`;
            }

            var highlight = '';
            if (app.isAuth() && user.id_user == app.user.id) {
                highlight = 'highlight';
            }

            var avatar_url = app.getUserAvatar(user);

            var bet = '';
            var possible_score = '';
            var division_badge = '';

            if(this.id_fixture) {
                bet += `
                    <div>
                        ${helpers.getBetSign(user.bet)}
                    </div>
                `;
            }else if(this.round && this.show_possible_score) {
                possible_score += '&nbsp;<span class="possible_score">/&nbsp;' + helpers.formatPoints(user.possible_score) + '</span>';
            }

            var line_indicator = '';
            if(this.group.division) {              
                if(static_current_user) {
                    line_indicator = this.current_user_line_indicator;
                }else {
                    if(this.user_count < number_of_promoted_users && this.group.division > 1) {
                        line_indicator = `<span class="promotion-line-indicator"></span>`;
                    }else if(this.user_count >= list.length - number_of_relegated_users && this.group.division != 100) {
                        line_indicator = `<span class="relegation-line-indicator"></span>`;
                    }
                }
                
                if(app.isAuth() && user.id_user == app.user.id) {
                    this.current_user_line_indicator = line_indicator;
                }
            }
            
            if(line_indicator === null) {
                line_indicator = '';
            }

            if(highlight && line_indicator) {
                highlight += ' is-line-indicator';
            }

            if(!static_current_user && this.group.division == 0 && app.config.league_system && user.division !== null) {
                var division = user.division != 100 && user.division != 0 ? user.division : parseInt(app.data.max_division) + 1;
                division_badge += `<div onclick="app.renderPage('group', {division: ${user.division}, position: ${user.division_position}}); event.stopPropagation(); return false;" class="division-badge" ><small>${app.lang.common.liga}</small><br />${division}</div>`;
            }
            
            var extra_cols = '';
            if((helpers.isSponsoredChallengeGroup(this.group) || helpers.isOverallRankingSponsoredChallengeGroup(this.group)) && !this.round && !this.id_fixture) {
                var scp = helpers.getSponsoredChallengeItem(this.group, user.position);
                if(scp) {
                    extra_cols += `
                        <div class="scp"><img src="${config.web_front_url}/${scp.logo}" /></div>
                    `;                    
                }else {
                    extra_cols += `
                        <div class="scp"></div>
                    `;                    
                }
            }

            html = html + `
                <div class='user ${highlight}' onclick='if ($(this).parents(".block_group_current_user").length) { app.blocks.group.refreshData(-1); } else { app.getSocial({login: "${user.login}"}); }'>
                    <div class='${id_fixture ? 'd-none': 'user-ranking'}'>
                        ${line_indicator}
                        <b>${user.position}. </b><br />
                        ${position}
                    </div>
                    <div>
                        <img src='${avatar_url}' alt='' onerror="app.errorUserAvatar(this)" class="sm-img-avatar img-avatar" />
                        ${division_badge}
                    </div>
                    <div>
                        ${user.login}
                    </div>
                    ${extra_cols}
                    ${bet}
                    <div>
                        ${helpers.formatPoints(user.score)}${possible_score}
                    </div>
                </div>
            `;
            this.user_count++;
        }

        return html;
    }
}
