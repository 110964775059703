class BlockAd2 {
    constructor(dom) {
        this.dom = dom;
        
        eventHandler.observe('block_header', ['language_change', 'user_login'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    render() {
        if (!this.dom) return;
        
        var html = `
            <div class='block_ad2'>
                <div><img src='images/ad_right.jpg' /></div>
            </div>
        `;

        this.dom.html(html)
        return this;
    }
}