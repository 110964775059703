
class BlockGroupUnjoin {
    constructor(dom) {
        this.dom = dom;
        this.isSubmited = false;

        eventHandler.observe('block_group_unjoin', ['language_change', 'user_login'], () => {
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    render(id_group) {
        if (!this.dom) return;
        
        if(!app.isAuth() || !id_group) {
            this.dom.empty();
            app.renderPage('home');
            return ;
        }
        var group = app.data.groups.find(x => x.id == id_group);
        if(!group) {
            this.dom.empty();
            app.renderPage('home');
            return ;
        }

        var html = `
            <div class="block-form">
                <h1 class="page-title"</h1>
                <div class='block_group_delete bl'>
                    <form class="form">
                         <div class="">
                            <div class='ta-c'>
                                <img src='images/warning.svg' class='warning' alt='' />
                                <div class="box-title">${app.lang.group.confirm_unjoin_group}</div>
                                <div class="box-desc">${app.lang.group.confirm_unjoin_group_info}</div>
                            </div>
                        </div>

                        <div class="form-group line-buttons">
                            <button type="submit" class="tip-button tip-button-red" >${app.lang.group.unjoin_group}</button>
                            <a href="#home" class="tip-button tip-button-transparent" >${app.lang.common.cancel}</a>
                        </div>
                    </form>
                </div>
            </div>
        `;

        this.dom.html(html);

        this.$form = $(this.dom).find('form');
        this.$form.submit(e => {
            this.unjoin(id_group);
            return false;
        });
    }

    async unjoin(id_group) {
        var ret = await ws.request('group/unjoin', {id: id_group});
        for(let id of ret.ids) {
            var index = app.data.groups.findIndex(x => x.id == id);
            if(index != -1) {
                app.data.groups.splice(index, 1);
            }
            helpers.removeArrayItems(app.data.groups_history, x => x.id_group == id);
        }
        
        await ws.request('data/getGroups');
        app.renderPage('home');
    }
}