class BlockSupport {
    constructor(dom, login_required = true) {
        this.dom = dom;
        this.login_required = login_required;
        this.isSubmited = false;

        eventHandler.observe('block_support', ['language_change', 'user_login'], () => {
            this.render();
        }, this);
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }
    
    render() {
        if (!this.dom) return;
        
        if(this.login_required && !app.isAuth()) {
            this.dom.empty();
            return ;
        }
        
        var html = `
            <div class="block-form">
                <h1 class="page-title">${this.login_required ? app.lang.user.support_and_feedback: app.lang.user.contact}</h1>
                <p class="support-info">${this.login_required ? app.lang.user.support_and_feedback_info: app.lang.user.contact_info}</p>
                <div class='block_support bl'>
                    <form class="form-support form form-sm">
                        <div class="box">
                            <div class="form-group name-group form-group-border">
                                <input type="text" name="name" value="${app.user ? app.user.login : ''}" placeholder="${app.lang.user.user_name}" />
                            </div>
                            <div class="form-group email-group form-group-border">
                                <input type="text" name="email" value="${app.user ? app.user.email : ''}" placeholder="${app.lang.user.email}" />
                            </div>
                            <div class="form-group subject-group form-group-border">
                                <input type="text" name="subject" value="" placeholder="${app.lang.user.subject}" />
                            </div>
                            <div class="form-group message-group form-group-border">
                                <textarea name="message" placeholder="${app.lang.user.message}"></textarea>
                            </div>
                        </div>
                        <div class="form-group line-buttons">
                            <input type="hidden" name="message_type" value="${this.login_required ? 'support' : 'contact'}" />
                            <button type="submit" class="tip-button" >${app.lang.user.send_message}</button>
                        </div>
                    </form>
                </div>
            </div>
        `;

        this.dom.html(html)

        this.$form = $(this.dom).find('form');
        this.$form.submit(e => {
            this.onsubmit();
            return false;
        });
        var form_fileds = {
            name: {valid: {min_length: 4}},
            email: {valid: {email: true}},
            subject: {valid: {min_length: 4}},
            message: {valid: {min_length: 4}},
            message_type: {valid: true}
        };

        this.form = new Form(this.$form, form_fileds);
        
        return this;
    }

    async onsubmit() {
        this.$form.find('input').each(function() {
            if ($(this).val() == $(this).attr('placeholder')) {
                $(this).val('');
            }
        });
            
        if(this.isSubmited) {
            return ;
        }
        this.isSubmited = true;

        try {
            var data = this.form.getData(false);
            
            if(!this.form.isValid()) {
                this.isSubmited = false;
                return ;
            }
           
            var result = await ws.request('user/sendMessage', data);

            if(result.success) {
                this.$form[0].reset();
                app.renderPage('settings_more');
                flash.success(app.lang.user.send_message_success_title, app.lang.user.send_message_success_text);
            }else {
                this.form.setServerInvalids(result.messages, data);
                flash.error(app.lang.user.send_message_error_text);
            }
        }catch(err) {
            console.log(err);
        }

        this.isSubmited = false;
    }
}
