class BlockTutorial {
    constructor(dom) {
        this.dom = dom;

        eventHandler.observe('block_tutorial', ['language_change', 'set_data_tutorial'], () => {
            this.render();
        }, this);
        
        eventHandler.observe('block_tutorial2', ['change_page'], (data) => {            
            var page = app.getPageWithPrevious();
            if (page.current != 'tutorial' && page.previous == 'tutorial') {
                if (app.go_after_tutorial) {
                    app.renderPage(app.go_after_tutorial, app.go_after_tutorial_params);
                    app.go_after_tutorial = app.go_after_tutorial_params = null;
                }
            }
        });
    }

    setDom(dom) {
        this.dom = dom;
        return this;
    }

    render(page) {
        if (!this.dom) return;
        
        if (app.data.tutorial) {
            if(!app.config.league_system && app.lang.tutorial.hasOwnProperty('button5')) {
                delete app.lang.tutorial.button5;
                delete app.lang.tutorial.button6;
                delete app.lang.tutorial.bottom5;
                delete app.lang.tutorial.bottom6;
                delete app.lang.tutorial.top5;
                delete app.lang.tutorial.top6;
            }
            var pages = '';
            var dots = '';
            var i = 1;
            var pagescount = Object.keys(app.lang.tutorial).length / 3;
            
            for(var k = 1; k < pagescount + 1; k++) {
                
                pages += `      
                    <div class='page' index='${i}'>      
                        <div class='description-wrap'>
                            <div class='top description'>
                            </div>
                        </div>
                        <div class='scroll'>
                            <img src='images/${config.style ? config.style + '/tutorial/' : 'tutorial/sportde-'}tut-${k}.png' alt='' />
                        </div>                    
                        <div class='bottom'>
                            ${app.lang.tutorial['bottom'+k]}
                        </div>
                        <a class='tutorial-button' href='#home'>${app.lang.tutorial['button'+k]}</a>
                    </div>
                `;

                dots += `<span index="${i}"></span>`;
                i++;
            }

            var html = `                            
                <div class='block_tutorial'>   
                    <div class='header-wrap'>             
                        <div class='header main-header'>
                            <!-- <img src='images/sportde-group-logo.svg' /> -->
                        </div>
                    </div>
                    <div class='tut-wrap'>
                        <div class='top top-header'>
                            ${app.lang.common.tutorial_top}
                        </div>
                    </div>
                    <div class='pages'>
                        ${pages}
                    </div>
                    <div class='dots'>
                        ${dots}
                    </div>
                </div>
            `;

            
                    
            this.dom.html(html);
            this.dom.find('.block_tutorial div:first-child').show();
            this.dom.find('.dots span:first-child').addClass('active');

            var t = this;

            $(this.dom).find('.dots span').click(function() {
                t.showSlide($(this).attr('index'));
            });

            $(this.dom).find('.pages').swipe({
                transformElement: 'img',
                onSlide: function() {
                    t.showSlide($(this).attr('index'));
                }
            });

            //$('#content #header').find('.home-icon img').attr('src', 'images/sportde-home-icon-yellow.svg');
            
            /*this.dom.find('button').click(function() {
                var page = $(t.dom).find('.page:visible');
                $(page).hide();

                if ($(page).next('.page').length) {
                    index = page.next().fadeIn().attr('index');
                } else {
                    index = t.dom.find('.page:first-child').fadeIn().attr('index');
                }

                t.dom.find('.scroll').mCustomScrollbar('update');
            });
            */
        }

        return this;
    }

    showSlide(index) {
        var t = this;
        t.dom.find('.page, .page img').hide();
        t.dom.find('.page:nth-child('+index+')').show();
        t.dom.find('.page:nth-child('+index+') img').fadeIn();
        t.dom.find('.scroll').mCustomScrollbar('update');
        t.dom.find('.dots .active').removeClass('active');
        t.dom.find('.dots span:nth-child('+index+')').addClass('active');
    }
}