var Langs = Langs || {};
Langs.de = Langs.de || {};

Langs.de.seo = {
    title: 'Betano World Tipster League',
    description: 'Betano World Tipster League - become the leader',
    keywords: 'betano tip tipster league football bet',
    author: 'Betano World Tipster League',
    middle_text: 'Nie mehr 2. Liga! im kostenlosen bundesliga tippspiel Tippst du die spiele der bundesliga wie nie zuvor.<br /><br />Hast Du das zeug, dich bis ganz nach oben in die tipster bundesliga zu spielen?st Du das z',
};
Langs.de.seo_green = {
    title: 'tippspiel.fussballdaten',
    description: 'tippspiel.fussballdaten',
    keywords: 'tippspiel.fussballdaten',
    author: 'tippspiel.fussballdaten',
    middle_text: 'Nie mehr 2. Liga! im kostenlosen bundesliga tippspiel Tippst du die spiele der bundesliga wie nie zuvor.<br /><br />Hast Du das zeug, dich bis ganz nach oben in die tipster bundesliga zu spielen?st Du das z',
};

Langs.de.seo_fussballdaten = Langs.de.seo_green;
Langs.de.seo_mybet = Langs.de.seo_green;
