class BlockLeagues {
    constructor(dom) {
        this.dom = dom;
        this.source = app;
        this.social = false;
        this.scrollLeft = 0;
        this.socialScrollLeft = 0;
        
        eventHandler.observe('block_leagues', ['set_data_user', 'update_profile', 'user_login'], () => {
            if(this.social) {
                this.renderTopbar();
            }else {
                this.render();
            }
        }, this);
    }
    
    setSocial(source) {
        this.source = source;
        this.social = true;        
        return this;
    }
    
    setDom(dom) {
        this.dom = dom;

        var html = ``;
        this.dom.html(html);
        this.dom_content = this.dom;
        
        return this;
    }

    render() {
        if (!this.dom_content) {
            return this;
        }
        var self = this;

        let leagues = ``;
        if (this.source.user && this.source.user.leagues) {
            for(let j in this.source.user.leagues) {
                let i = this.source.user.leagues[j];
                let clas = i == this.source.id_league ? 'selected' : '';
                leagues += `<div data-id='${i}' class='${clas}'>${app.lang.common['league_name_'+i]}</div>`;
            }
        }else if(!this.source.user) {
            for(let i in app.data.leagues_name) {
                let clas = i == this.source.id_league ? 'selected' : '';
                leagues += `<div data-id='${i}' class='${clas}'>${app.lang.common['league_name_'+i]}</div>`;
            }
        }

        let league_name = this.source.id_league ? `<div class='user-leagues' data-id='${this.source.id_league}'>${app.lang.common['league_name_'+this.source.id_league]} <img class="arrow-down" src="images/angle-down-solid.svg" /></div>` : '';
        //let display_leagues = app.mobile ? league_name : leagues;
        let display_leagues = leagues;
        
        var html = `
            <div class='block_leagues bt'>
                <div>
                    ${display_leagues}
                    <div class="leagues-settings"><img src="images/topbar_settings.svg"></div>
                </div>
            </div>
        `;
        
        this.dom_content.html(html);
        
        if(self.social) {
            self.dom_content.find('.block_leagues > div').scrollLeft(self.socialScrollLeft);
        }else {
            self.dom_content.find('.block_leagues > div').scrollLeft(self.scrollLeft);
        }
        
        var clicked = false;
        
        this.dom_content.find('div[data-id]').click(function() {
            if(clicked && Date.now() - clicked < 10000) {
                return ;
            }
            clicked = Date.now();
            let id = $(this).data('id');
            if(self.social) {
                self.socialScrollLeft = self.dom_content.find('.block_leagues > div').scrollLeft();
                app.refreshSocial(id);
            }else {
                self.scrollLeft = self.dom_content.find('.block_leagues > div').scrollLeft();
                app.loadAllData(id);
            }
        });
        this.dom_content.find('.leagues-settings').click(function() {
            app.blocks.settings.leaguesChoose(true, false, 'home');
        });
        
        this.dom_content.find('.user-leagues').click(async () => {
            let options = [];
            for(let j of this.source.user.leagues) {
                options.push({ value: j, label: app.data.leagues_name[j] });
            }
            
            dialogs.choose(app.lang.common.choose_league, options, (id_league) => {
                if(self.social) {
                    app.refreshSocial(id_league);
                }else {
                    app.loadAllData(id_league);
                }
            }, this.source.id_league);
        });
        
        return this;
    }
    
    selectCurrentLeague() {
        this.dom_content.find(`> div > div > div.selected`).removeClass('selected');
        this.dom_content.find(`> div > div > div[data-id="${this.source.id_league}"]`).addClass('selected');
    }
    
    renderTopbar() {
        if(app.current_page != 'social' && this.social) {
            return ;
        }
        if(this.source.user.leagues.length <= 1) {
            app.topbar('', app.data.leagues_name[this.source.user.id_league]);
        }else {
            app.topbar('', app.data.leagues_name[this.source.user.id_league], () => {
                dialogs.leagues([this.source.user.id_league], (id_league) => {
                    if(this.social) {
                        app.refreshSocial(id_league);
                    }else {
                        app.loadAllData(id_league);
                    }
                }, this.source.user.leagues, app.lang.common.choose_league, true, app.lang.user.select_user_league_info);
            });
        }
    }
}
